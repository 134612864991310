<script lang="ts">
	import { graphql, type WoGetProductDescendants$result } from '$houdini'
	import type { SampleValue, SampleValueSublot, SublotBatch, WoDocumentStore } from '../work-order'
	import type { GroupedEntityLoader } from 'utility/grouped-entity-loader'
	import type { i18n } from 'types/common'

	import { Table, Td, type Column } from '@isoftdata/svelte-table'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'

	import { getEventValue } from '@isoftdata/browser-event'
	import { getContext } from 'svelte'
	import { klona } from 'klona'
	import { v4 as uuid } from '@lukeed/uuid'
	import BatchLookupModal from './BatchLookupModal.svelte'
	import { SvelteSet } from 'svelte/reactivity'
	import Popover from '@isoftdata/svelte-popover'
	import hasPermission from 'utility/has-permission'

	type ProductDescendant = WoGetProductDescendants$result['getProductDescendants'][number]

	interface Props {
		isDisabled?: boolean
		isReadonly?: boolean
		plantId: number
		sampleValue: SampleValue
		resultChange?: (result: string) => void
	}

	let {
		//
		isDisabled,
		isReadonly,
		plantId,
		sampleValue = $bindable(),
		resultChange,
	}: Props = $props()

	let batchLookupModal: BatchLookupModal | undefined = $state()
	let loadingBatchIndex = $state<number | null>(null)
	/** lot/aovp ids to keep the expiration field enabled until save
	 * IE if the field is empty, they can edit it, but we need to keep it enabled until they save
	 */
	let batchesWithSavedExpirations = new SvelteSet<number>()

	const confirmActionThatRequiresReVerify = getContext<() => boolean>('confirmActionThatRequiresReVerify')
	const productDescendantsLoader = getContext<GroupedEntityLoader<ProductDescendant>>('productDescendantsLoader')
	const woDocumentStore = getContext<WoDocumentStore>('woDocumentStore')

	const { t: translate } = getContext<i18n>('i18next')

	const columns: Array<Column<SampleValueSublot>> = [
		{
			name: translate('workOrder.optionValueLots.productColumn', 'Product'),
			property: 'product[name]',
			minWidth: '150px',
		},
		{
			name: translate('workOrder.optionValueLots.lotNumberColumn', 'Lot #'),
			property: 'productBatch[name]',
			minWidth: '125px',
		},
		{
			name: translate('workOrder.optionValueLots.expirationColumn', 'Expiration'),
			property: 'productBatch[expiration]',
			minWidth: '125px',
		},
		{
			name: translate('workOrder.optionValueLots.itemNumberColumn', 'Item #'),
			property: 'supplierItemNumber',
			minWidth: '150px',
		},
		{
			name: translate('workOrder.optionValueLots.quantityColumn', 'Quantity'),
			property: 'quantity',
			minWidth: '75px',
		},
		{
			name: '',
			icon: 'trash',
			property: 'id',
			width: '1rem',
			align: 'center',
		},
	]

	function updateSampleValueValue<K extends keyof SampleValue>(key: K, value: SampleValue[K]) {
		if (!confirmActionThatRequiresReVerify()) {
			return
		}

		sampleValue[key] = value

		// Just trigger the change detection in the parent component with this for now
		// This function is just used for single lot / expiration anyways
		resultChange?.(sampleValue.result)
	}

	/** Batch object might not exist yet when we set a value, so this function will do that for you */
	function getBatchWithValue<K extends keyof SublotBatch>(batch: SublotBatch | null, key: K, value: SublotBatch[K]) {
		return {
			...(batch ?? {
				id: 0,
				name: '',
				expiration: '',
			}),
			[key]: value,
		}
	}

	async function updateTotalQuantity() {
		sampleValue.totalQuantity = await getTotalQuantity()
		if (sampleValue.analysisOption.entryMethod === 'TOTAL_QUANTITY') {
			sampleValue.result = sampleValue.totalQuantity.toString()
		}
	}

	async function updateSubLotValue<K extends keyof SampleValueSublot>(
		lotIndex: number,
		key: K,
		value: SampleValueSublot[K],
	) {
		if (!confirmActionThatRequiresReVerify() || !sampleValue) {
			return false
		}

		sampleValue.lots[lotIndex][key] = value

		loadingBatchIndex = lotIndex
		await updateTotalQuantity()

		// Try to look up the product by item number, but only if we haven't saved yet
		if (key === 'supplierItemNumber' && !sampleValue.lots[lotIndex].id) {
			const products = await productDescendantsLoader(sampleValue.analysisOption.product?.id ?? 0)
			const itemNumberProduct = products.find(p => p.itemNumber === value || p.supplierItemNumber === value)
			if (itemNumberProduct) {
				// This will trigger the product change event, which will check for a matching batch and update that
				await updateSubLotValue(lotIndex, 'product', {
					id: itemNumberProduct.id,
					name: itemNumberProduct.name,
				})
			}
		} else if (key === 'product') {
			const lot = sampleValue.lots[lotIndex]
			// If they select a different product, then it's a different batch
			// Check to see if we have a batch for the new product with the same name already
			const maybeFoundBatch = lot.productBatch?.name
				? await batchLookupModal?.find({
						name: lot.productBatch.name,
						plantId,
						productId: lot.product.id,
					})
				: undefined
			// Use the batch if we found it, otherwise it's a new batch with the same name
			if (maybeFoundBatch) {
				sampleValue.lots[lotIndex].productBatch = maybeFoundBatch
			} else if (sampleValue.lots[lotIndex].productBatch?.id) {
				sampleValue.lots[lotIndex].productBatch.id = 0
			}
		} else if (key === 'productBatch') {
			// These will be computed 4real in API, but might as well do it on the client too
			sampleValue.expiration = getSoonestExpirationDate(sampleValue.lots)
			sampleValue.lot = sampleValue.lots.map(lot => lot.productBatch?.name ?? '').join(',')
		}
		loadingBatchIndex = null
		resultChange?.(sampleValue.result)

		return true
	}

	async function getTotalQuantity() {
		if (!sampleValue.analysisOption.product) {
			return 0
		}
		const res = await getTotalQuantityQuery.fetch({
			variables: {
				input: {
					productId: sampleValue.analysisOption.product.id,
					products: sampleValue.lots.map(lot => ({
						quantity: lot.quantity ?? 0,
						productId: lot.product.id,
					})),
				},
			},
			policy: 'CacheOrNetwork',
		})
		return res.data?.getTotalQuantity ?? 0
	}

	function getSoonestExpirationDate(lots: Array<SampleValueSublot>) {
		return lots.reduce((soonest: string | null, lot) => {
			if (!lot.productBatch || !lot.productBatch.expiration) {
				return soonest
			}
			if (!soonest || lot.productBatch.expiration < soonest) {
			}
			return soonest
		}, null)
	}

	/**
	 * Validate that the expiration date is after when the sub lot was created
	 */
	function expirationIsValid(lot: SampleValueSublot) {
		if (!lot.productBatch?.expiration) {
			return true
		}
		// Since I put a fake date on the lot when it was created, I can just check that
		// And not worry about checking "now" if it's missing
		return lot.productBatch.expiration >= lot.created.slice(0, 10)
	}

	async function getItemNumberIsValidClass(lot: SampleValueSublot) {
		if (!lot.supplierItemNumber) {
			return ''
		}
		const products = await productDescendantsLoader(sampleValue.analysisOption.product?.id ?? 0)
		const itemNumberMatches = products.some(
			p =>
				p.id === lot.product.id &&
				(p.itemNumber === lot.supplierItemNumber || p.supplierItemNumber === lot.supplierItemNumber),
		)

		return itemNumberMatches ? 'is-valid' : 'is-invalid'
	}

	const getTotalQuantityQuery = graphql(`
		query WoGetTotalLotQuantity($input: GetTotalQuantityInput!) {
			getTotalQuantity(input: $input)
		}
	`)
</script>

<!-- @component
	Lot fields for option values
	Intended to be used inside OptionValueInput
-->

{#if sampleValue?.analysisOption.inventoryMode === 'SINGLE_LOT'}
	<div class="form-row">
		<div class="col-6">
			<Input
				label={translate('common:lot#', 'Lot #')}
				disabled={isDisabled}
				readonly={isReadonly}
				value={sampleValue.lot}
				onchange={event => updateSampleValueValue('lot', getEventValue(event))}
			></Input>
		</div>
		<div class="col-6">
			<Input
				label={translate('common:expiration', 'Expiration')}
				type="date"
				disabled={isDisabled}
				readonly={isReadonly}
				value={sampleValue.expiration}
				onchange={event => updateSampleValueValue('expiration', getEventValue(event))}
			></Input>
		</div>
	</div>
{:else if sampleValue?.analysisOption.inventoryMode === 'MULTIPLE_LOTS'}
	<!--Column resizing doesn't work on this table either, but I probably don't care  -->
	<Table
		responsive
		parentClass="mt-1"
		striped={false}
		{columns}
		rows={sampleValue.lots}
		idProp="uuid"
		rowSelectionIdProp="uuid"
	>
		{#snippet children({ row: lot })}
			{@const lotIndex = lot.originalIndex}
			<tr>
				<Td property="product[name]">
					<Select
						showLabel={false}
						value={lot.product.id}
						disabled={isDisabled || isReadonly || !!lot.id}
						options={productDescendantsLoader(sampleValue.analysisOption.product?.id ?? 0)}
						onchange={async event => {
							const productId = Number(getEventValue(event))
							const product = (await productDescendantsLoader(sampleValue.analysisOption.product?.id ?? 0)).find(
								p => p.id === productId,
							)
							if (!product) {
								return
							}

							updateSubLotValue(lotIndex, 'product', {
								id: product.id,
								name: product.name,
							})
						}}
					>
						{#snippet option({ option })}
							<option value={option.id}>{option.name}</option>
						{/snippet}
					</Select>
				</Td>
				<Td property="productBatch[name]">
					<Input
						required
						showLabel={false}
						disabled={isDisabled || (!!lot.id && !!lot.productBatch?.id)}
						readonly={isReadonly}
						value={lot.productBatch?.name ?? ''}
						showAppend={!lot.id}
						onchange={async event => {
							// Not moving this into updateSubLotValue at least for now
							// Seems more complicated to figure out the intent in that function than it is here
							loadingBatchIndex = lotIndex // updateSubLotValue will set this back to null
							const maybeFoundBatch = await batchLookupModal?.find({
								name: getEventValue(event),
								plantId,
								productId: lot.product.id,
							})

							const otherBatch = getBatchWithValue(lot.productBatch, 'name', getEventValue(event))
							// If they enter a different name, then it's a new batch
							if (otherBatch.id) {
								otherBatch.id = 0
							}

							updateSubLotValue(lotIndex, 'productBatch', maybeFoundBatch ?? otherBatch)
						}}
					>
						{#snippet append()}
							<Button
								outline
								iconClass="search"
								isLoading={loadingBatchIndex === lotIndex}
								disabled={isDisabled || isReadonly || !!lot.id}
								onclick={() => {
									batchLookupModal?.open({
										lot,
										plantId,
										productId: lot.product.id,
										index: lotIndex,
									})
								}}
							></Button>
						{/snippet}
					</Input>
				</Td>
				<Td property="productBatch[expiration]">
					{@const expirationValid = expirationIsValid(lot)}
					<Input
						showLabel={false}
						type="date"
						disabled={isDisabled || (!!lot.id && !hasPermission('PRODUCT_CAN_EDIT_BATCHES', plantId))}
						readonly={isReadonly}
						class={expirationValid ? '' : 'is-invalid'}
						value={lot.productBatch?.expiration ?? ''}
						showAppend={woDocumentStore.sampleValueLotExpirationWasUpdated(lot.id)}
						onchange={event => {
							if (!!lot.id && !lot.productBatch?.expiration) {
								batchesWithSavedExpirations.add(lot.id)
							} else if (!!lot.id) {
								woDocumentStore.sampleValueLotExpirationChanged(lot.id)
							}

							updateSubLotValue(
								lotIndex,
								'productBatch',
								getBatchWithValue(lot.productBatch, 'expiration', getEventValue(event)),
							)
						}}
					>
						{#snippet append()}
							<Popover
								color="warning"
								iconClass="exclamation-triangle"
							>
								{#snippet header()}
									{translate('workOrders.optionValueLots.expirationEditHeader', 'Expiration Date Edited')}
								{/snippet}

								{#snippet body()}
									{translate(
										'workOrders.optionValueLots.expirationEditBody',
										'This expiration date has been edited, and upon saving, will update the expiration date on historical lots.',
									)}
								{/snippet}
							</Popover>
						{/snippet}
					</Input>
				</Td>
				<Td property="supplierItemNumber">
					{#await getItemNumberIsValidClass(lot)}
						{@render itemNumberInput(lot, lotIndex)}
					{:then theClass}
						{@render itemNumberInput(lot, lotIndex, theClass)}
					{/await}
				</Td>
				<Td property="quantity">
					<Input
						showLabel={false}
						type="number"
						disabled={isDisabled}
						readonly={isReadonly}
						value={lot.quantity ?? ''}
						onchange={event => updateSubLotValue(lotIndex, 'quantity', Number(getEventValue(event)))}
					></Input>
				</Td>
				<Td property="id">
					<Button
						outline
						size="sm"
						color="danger"
						iconClass="trash"
						disabled={isDisabled || isReadonly}
						onclick={() => {
							if (
								confirm(
									translate('workOrder.optionValueLots.deleteLotConfirm', 'Are you sure you want to delete this lot?'),
								)
							) {
								if (lot.id) {
									woDocumentStore.sampleValueLotDeleted(sampleValue.id, lot.id)
								}

								updateSubLotValue(lotIndex, 'quantity', 0)
								sampleValue.lots.splice(lotIndex, 1)
							}
						}}
					></Button>
				</Td>
			</tr>
		{/snippet}
		{#snippet noRows({ visibleColumnsCount })}
			<tr>
				<td
					colspan={visibleColumnsCount}
					class="text-center"
				>
					{translate(
						'workOrder.optionValueLots.noLotsPlaceholder',
						'No lots. Click "$t(workOrder.optionValueLots.newLot)" to add one',
					)}
				</td>
			</tr>
		{/snippet}
	</Table>

	<div class="mt-3">
		<Button
			outline
			size="sm"
			color="success"
			iconClass="plus"
			disabled={isDisabled || isReadonly}
			onclick={() => {
				if (!sampleValue.analysisOption.product) {
					return
				}
				const newLot: SampleValueSublot = {
					id: 0,
					// Will be overwritten in the API, but gives us something to compare batch expiration to
					created: new Date().toISOString(),
					// By default, the product being produced by the option. Can change it to a child later.
					product: klona(sampleValue.analysisOption.product),
					productBatch: {
						id: 0,
						name: '',
						expiration: '',
					},
					supplierItemNumber: '',
					quantity: 1,
					uuid: uuid(),
				}
				sampleValue.lots.push(newLot)
				updateTotalQuantity()
			}}
			>{translate('workOrder.optionValueLots.newLot', 'New Lot')}
		</Button>
	</div>
{/if}

{#snippet itemNumberInput(lot: SampleValueSublot, lotIndex: number, theClass?: string)}
	<Input
		showLabel={false}
		disabled={isDisabled}
		readonly={isReadonly}
		class={theClass}
		value={lot.supplierItemNumber ?? ''}
		onchange={event => updateSubLotValue(lotIndex, 'supplierItemNumber', getEventValue(event))}
	></Input>
{/snippet}

<BatchLookupModal
	confirm={(newLot, index) => {
		sampleValue.lots[index] = newLot
		// trigger change detection in parent
		resultChange?.(sampleValue.result)
	}}
	bind:this={batchLookupModal}
></BatchLookupModal>
