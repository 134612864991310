export default {
    "name": "WoAnalysisData",
    "kind": "HoudiniFragment",
    "hash": "f9b8f33acfd80099bc54f75ce6eb0b745939d8fdc7199e743f7f5bf6fc91bfc5",

    "raw": `fragment WoAnalysisData on Analysis {
  id
  name
  active
  analysisType
  instructions
  requireAuthentication
  batchUnit
  batchVolume
  createdProductId
  options {
    ...WoAnalysisOptionData
    id
  }
  __typename
}

fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  inventoryMode
  entryMethod
  unit
  sectionName
  requiredToCompleteSection
  product {
    id
    name
  }
  __typename
}
`,

    "rootType": "Analysis",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "analysisType": {
                "type": "AnalysisType",
                "keyRaw": "analysisType",
                "visible": true
            },

            "instructions": {
                "type": "String",
                "keyRaw": "instructions",
                "visible": true
            },

            "requireAuthentication": {
                "type": "Boolean",
                "keyRaw": "requireAuthentication",
                "visible": true
            },

            "batchUnit": {
                "type": "String",
                "keyRaw": "batchUnit",
                "visible": true
            },

            "batchVolume": {
                "type": "Float",
                "keyRaw": "batchVolume",
                "nullable": true,
                "visible": true
            },

            "createdProductId": {
                "type": "PositiveInt",
                "keyRaw": "createdProductId",
                "nullable": true,
                "visible": true
            },

            "options": {
                "type": "AnalysisOption",
                "keyRaw": "options",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "option": {
                            "type": "String",
                            "keyRaw": "option",
                            "visible": true
                        },

                        "valueType": {
                            "type": "ValueType",
                            "keyRaw": "valueType",
                            "visible": true
                        },

                        "thresholdType": {
                            "type": "ThresholdType",
                            "keyRaw": "thresholdType",
                            "visible": true
                        },

                        "rank": {
                            "type": "NonNegativeInt",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "defaultType": {
                            "type": "DefaultType",
                            "keyRaw": "defaultType",
                            "visible": true
                        },

                        "defaultValue": {
                            "type": "String",
                            "keyRaw": "defaultValue",
                            "nullable": true,
                            "visible": true
                        },

                        "inventoryMode": {
                            "type": "InventoryMode",
                            "keyRaw": "inventoryMode",
                            "visible": true
                        },

                        "entryMethod": {
                            "type": "EntryMethod",
                            "keyRaw": "entryMethod",
                            "visible": true
                        },

                        "unit": {
                            "type": "String",
                            "keyRaw": "unit",
                            "visible": true
                        },

                        "sectionName": {
                            "type": "String",
                            "keyRaw": "sectionName",
                            "visible": true
                        },

                        "requiredToCompleteSection": {
                            "type": "Boolean",
                            "keyRaw": "requiredToCompleteSection",
                            "visible": true
                        },

                        "product": {
                            "type": "Product",
                            "keyRaw": "product",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "WoAnalysisOptionData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=a18088bf14e5d697be4dd4e70bb309719245adbd8c77349beaaeca1dc4c409f4";