export default {
    "name": "WoAnalysisOptionData",
    "kind": "HoudiniFragment",
    "hash": "6474fcef205e2cd875e94f81134c4ee98272129f941152580721ae61f4848cf0",

    "raw": `fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  inventoryMode
  entryMethod
  unit
  sectionName
  requiredToCompleteSection
  product {
    id
    name
  }
  __typename
}
`,

    "rootType": "AnalysisOption",
    "stripVariables": [],

    "selection": {
        "fields": {
            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "option": {
                "type": "String",
                "keyRaw": "option",
                "visible": true
            },

            "valueType": {
                "type": "ValueType",
                "keyRaw": "valueType",
                "visible": true
            },

            "thresholdType": {
                "type": "ThresholdType",
                "keyRaw": "thresholdType",
                "visible": true
            },

            "rank": {
                "type": "NonNegativeInt",
                "keyRaw": "rank",
                "visible": true
            },

            "defaultType": {
                "type": "DefaultType",
                "keyRaw": "defaultType",
                "visible": true
            },

            "defaultValue": {
                "type": "String",
                "keyRaw": "defaultValue",
                "nullable": true,
                "visible": true
            },

            "inventoryMode": {
                "type": "InventoryMode",
                "keyRaw": "inventoryMode",
                "visible": true
            },

            "entryMethod": {
                "type": "EntryMethod",
                "keyRaw": "entryMethod",
                "visible": true
            },

            "unit": {
                "type": "String",
                "keyRaw": "unit",
                "visible": true
            },

            "sectionName": {
                "type": "String",
                "keyRaw": "sectionName",
                "visible": true
            },

            "requiredToCompleteSection": {
                "type": "Boolean",
                "keyRaw": "requiredToCompleteSection",
                "visible": true
            },

            "product": {
                "type": "Product",
                "keyRaw": "product",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=22e68963c6d47db3081595eb1cd4c2ba23f9b709590d955c694dc90b1220f5e3";