import type { Promisable } from 'type-fest'

type LoadFn<T> = (parentId: number) => Promise<T>

/** Returns a function that will load items based on another key, usually a parent entity's id. */
export function makeGroupedEntityLoader<Item>(
	items: Array<Item>,
	initialParentId: number,
	loadFn: LoadFn<Array<Item>>,
): GroupedEntityLoader<Item> {
	type TheMap = Record<number, Promisable<Array<Item>>>
	const map: TheMap = initialParentId > 0 ? { [initialParentId]: items } : {}
	const pendingPromises: Record<number, Promise<Array<Item>>> = {}

	async function getItems(parentId: number): Promise<Array<Item>> {
		if (parentId in map && Array.isArray(map[parentId])) {
			return map[parentId]
		} else if (parentId in pendingPromises) {
			return pendingPromises[parentId]
		} else {
			const promise = loadFn(parentId)
			pendingPromises[parentId] = promise
			const newItems = await promise
			map[parentId] = newItems
			delete pendingPromises[parentId]
			return newItems
		}
	}

	return getItems
}

export type GroupedEntityLoader<Item> = (parentId: number) => Promise<Array<Item>>

export type EntityOptGroup<T> = (parentIds: Array<number>) => Promise<EntityOptGroupResult<T>>
export type EntityOptGroupResult<T> = Record<
	number,
	{
		optGroupLabel: string
		options: Array<T>
	}
>

type Options<T> = {
	loadFn: LoadFn<Array<T>>
	makeOptGroupLabel: (parentId: number) => string
}

/** Wraps a GroupedEntityLoader and returns an object that can be used for option groups in a <select> */
export function makeEntityOptGroup<Item>(items: Array<Item>, initialParentId: number, options: Options<Item>) {
	const loader = makeGroupedEntityLoader(items, initialParentId, options.loadFn)
	// Whenever the parentIds change, we need to call the laoder, so it fetches the stuff for that plant, and then builds a list from them
	return async (parentIds: Array<number>) => {
		const lists = await Promise.all(
			parentIds.map(async (parentId): Promise<[number, Array<Item>]> => [parentId, await loader(parentId)]),
		)
		return lists.reduce((acc: EntityOptGroupResult<Item>, [parentId, list]) => {
			acc[parentId] = {
				optGroupLabel: options.makeOptGroupLabel(parentId),
				options: list,
			}

			return acc
		}, {})
	}
}

/** Converts an EntityOptGroup to a flat Array, de-duplicating any items that might be in multiple groups */
export function entityOptGroupToArray<T extends { id: number }>(optGroup: EntityOptGroupResult<T>) {
	const idsAlreadyInList = new Set<number>()
	return Object.values(optGroup).reduce((acc: Array<T>, { options }) => {
		return acc.concat(
			options.filter(item => {
				if (!idsAlreadyInList.has(item.id)) {
					idsAlreadyInList.add(item.id)
					return true
				}
				return false
			}),
		)
	}, [])
}
