<script lang="ts">
	import type { i18n, SvelteAsr } from 'types/common'

	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'

	import { getContext, type ComponentProps } from 'svelte'
	import type { Writable } from 'svelte/store'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	export let asr: SvelteAsr
	export let lastSavedTime: string
	export let saveResetProps: Writable<ComponentProps<typeof SaveResetButton>>

	const { t: translate } = getContext<i18n>('i18next')
	const tabs: ComponentProps<AsrNavTabBar>['tabs'] = [
		{
			title: translate('productManagement.productManagement', 'Products'),
			name: 'app.product-management.product',
			parameters: {},
			hidden: false, // todo permissions
			disabled: false, // todo permissions
		},
		{
			title: translate('productManagement.recipeManagement', 'Recipes'),
			name: 'app.product-management.recipes',
			parameters: {},
			hidden: false, // todo permissions
			disabled: false, // todo permissions
		},
	]

	$: showNavTabBar = tabs.filter(tab => !tab.hidden && !tab.disabled).length > 1
</script>

<div class="card">
	<StateCardHeader
		icon={{
			prefix: 'fak',
			class: 'fa-solid-boxes-stacked-gear',
		}}
		title={translate('productManagement.productManagement', 'Products')}
	>
		{#snippet right()}
			<span class="mr-3 align-self-center"
				>{translate('common:lastSaved', 'Last saved')}: {lastSavedTime
					? new Date(parseInt(lastSavedTime, 10)).toLocaleTimeString()
					: 'never'}</span
			>
			<SaveResetButton
				{...$saveResetProps}
				resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true })}
			></SaveResetButton>
		{/snippet}

		{#if showNavTabBar}
			<AsrNavTabBar
				{tabs}
				{asr}
			></AsrNavTabBar>
		{/if}
	</StateCardHeader>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>
