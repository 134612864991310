<script lang="ts">
	import type { SampleValueSublot } from '../work-order'
	import type { i18n } from 'types/common'

	import LoadItemModal from '@isoftdata/svelte-load-item-modal'

	import { klona } from 'klona'

	import { graphql, type ReplaceLotBatchSearch$result } from '$houdini'
	import { getContext } from 'svelte'

	interface Props {
		confirm?: (lot: SampleValueSublot, index: number) => void
	}

	let {
		//
		confirm,
	}: Props = $props()

	type BatchLookupResult = ReplaceLotBatchSearch$result['productBatches']['data'][number]

	let lot = $state<SampleValueSublot | null>(null)
	let productId = $state<number | null>()
	let plantId = $state<number | null>(null)
	let lotIndex = $state<number | null>(null)
	let loadItemModal: LoadItemModal<BatchLookupResult> | undefined = $state()

	const { t: translate } = getContext<i18n>('i18next')

	export function open(context: { lot: SampleValueSublot; productId: number; plantId: number; index: number }) {
		reset() // closing with esc doesn't fire the close event so just reset on open for now
		lot = klona($state.snapshot(context.lot))
		productId = context.productId
		plantId = context.plantId
		lotIndex = context.index
		loadItemModal?.open()
	}

	export async function find(lookup: { name: string; productId: number; plantId: number }) {
		const { data } = await searchQuery.fetch({
			variables: {
				filter: {
					name: lookup.name,
					productIds: [lookup.productId],
					plantIds: [lookup.plantId],
				},
			},
		})
		return data?.productBatches.data[0]
	}

	function reset() {
		lot = null
		productId = null
		lotIndex = null
	}

	const searchQuery = graphql(`
		query ReplaceLotBatchSearch($filter: ProductBatchFilter) {
			productBatches(filter: $filter) {
				data {
					id
					name
					expiration
					product {
						id
						name
						unitConversion
					}
				}
			}
		}
	`)
</script>

<LoadItemModal
	allowEmptySearch
	title={translate('workOrder.lookupBatchModal.title', 'Search for a Batch')}
	inputPlaceholder="{translate('workOrder.lookupBatchModal.inputPlaceholder', 'Search for a batch by name (lot #)')}..."
	noResultsText={translate('workOrder.lookupBatchModal.noResultsText', 'No batches found')}
	itemTitle=""
	itemIdProp="id"
	itemDisplayProp="id"
	doSearch={async searchString => {
		if (!productId || !plantId) {
			return []
		}

		const { data } = await searchQuery.fetch({
			variables: {
				filter: {
					name: searchString || undefined,
					productIds: [productId],
					plantIds: [plantId],
					statuses: ['ACTIVE'],
				},
			},
		})
		return data?.productBatches.data.filter(b => b.name) ?? []
	}}
	on:chooseItem={({ detail: { item: batch } }) => {
		if (lot && lotIndex !== null) {
			lot.productBatch = {
				id: batch.id,
				name: batch.name,
				expiration: batch.expiration,
			}
			lot.product = klona(batch.product)
			confirm?.(lot, lotIndex)
		}
	}}
	bind:this={loadItemModal}
	let:item
>
	<div class="d-flex justify-content-between">
		<strong>{item.name}</strong>
		<div>
			{item.expiration
				? translate('workOrder.lookupBatchesModal.expiration', 'Expires: {{- expiration}}', {
						expiration: new Date(item.expiration + 'T00:00:00').toLocaleDateString(),
					})
				: ''}
		</div>
	</div>
</LoadItemModal>
