<script lang="ts">
	import type { DisplaySample, Sample, SampleValue, WorkOrder } from '../work-order'
	import type { AnalysisOptionChoice, DocumentTree, RestrictionTree } from 'client/states/work-order/edit/edit'
	import type { Mediator, i18n } from 'types/common'
	import type { GroupedEntityLoader } from 'utility/grouped-entity-loader'

	import { v4 as uuid } from '@lukeed/uuid'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { graphql } from '$houdini'

	import { acceptabilityToResultStatus } from 'utility/value-acceptability-map'
	import { slide } from 'svelte/transition'
	import financialNumber from 'financial-number'
	import { getContext, tick } from 'svelte'

	import Label from '@isoftdata/svelte-label'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'
	import Select from '@isoftdata/svelte-select'
	import AcceptabilityPopover from 'components/AcceptabilityPopover.svelte'
	import OptionValueLots from './OptionValueLots.svelte'

	interface Props {
		mode?: 'TESTING' | 'RECIPE'
		workOrder: WorkOrder
		sample: Sample | DisplaySample
		// even though technically this should always be defined, I'm getting some weird errors about it being undefined sometimes, probably due to a race condition - it might get an undefined SV before it can un-render this component
		sampleValue: SampleValue | null | undefined
		id?: string
		labelType?: 'COMPACT' | 'NORMAL'
		disabled?: boolean
		showModifiedIcons?: boolean
		showLabel?: boolean
		/** This should match the global setting Scanner: showthresholds*/
		allowShowThresholdsTable?: boolean
		showOnlyApplicableThresholds?: boolean
		readonly?: boolean
		change?: (context: { value: string }) => void
	}

	let {
		mode = 'TESTING',
		workOrder,
		// sample status is the only thing mutated on this
		sample = $bindable(),
		sampleValue = $bindable(),
		id = uuid(),
		labelType = 'COMPACT',
		disabled = false,
		showModifiedIcons = true,
		showLabel = true,
		allowShowThresholdsTable = false,
		showOnlyApplicableThresholds = $bindable(true),
		readonly = false,
		change,
	}: Props = $props()
	let isLoading = $state(false)
	let acceptabilityPopover: AcceptabilityPopover | undefined = $state(undefined)
	let showDocument = $state(false)

	const { t: translate } = getContext<i18n>('i18next')
	const choicesLoader = getContext<GroupedEntityLoader<AnalysisOptionChoice>>('choicesLoader')
	const formatOptionName = getContext<(option: { option: string; unit: string }) => string>('formatOptionName')

	const documentTree = getContext<DocumentTree>('documentTree')
	const restrictionTree = getContext<RestrictionTree>('restrictionTree')

	const restriction = $derived(
		$restrictionTree &&
			sampleValue &&
			restrictionTree.get({
				analysisOptionId: sampleValue.analysisOption.id,
				plantId: sample.plant?.id ?? workOrder.plant?.id,
				locationId: sample.location?.id ?? null,
				productId: sample.product?.id ?? null,
			}),
	)

	const document = $derived(
		$documentTree &&
			sampleValue &&
			documentTree.get({
				analysisId: sample.analysis.id,
				analysisOptionId: sampleValue.analysisOption.id,
				plantId: sample.plant?.id ?? workOrder.plant?.id,
				productId: sample.product?.id,
				severityClassId: sample.location?.severityClass?.id,
			}),
	)

	const mediator = getContext<Mediator>('mediator')
	// Not so small that it's unreadable, but leaves enough room for the edited/document icons on the right of the inputs
	const MIN_INPUT_WIDTH_PX = 40

	// We only care about the choices if the valueType === 'CHOICE', and only for displaying them in a dropdown, so load them just-in-time
	let choices: Array<AnalysisOptionChoice> = $state([])

	function validator<T>(value: string | T) {
		if (sampleValue?.analysisOption.valueType === 'INTEGER' && Number(sampleValue.result) % 1 !== 0) {
			return translate('workOrder.integerRequired', 'Value must be an integer')
		}

		return !!value ? true : showLabel ? `${requiredTooltip}` : ''
	}

	function makeOptions(choices: Array<AnalysisOptionChoice>, result: string) {
		const options = choices?.filter(shouldShowChoice).map(choice => choice.choice) ?? []
		if (result && !options.includes(result)) {
			options.push(result)
		}
		return options
	}

	function shouldShowChoice(choice: AnalysisOptionChoice) {
		// Plant must match if present (null plantId = global)
		if (choice.plantId && choice.plantId !== sample.plant?.id) {
			return false
		}
		// If no location, only show choices with no SC or default SC
		if (!sample.location && choice.severityClass && !choice.severityClass.default) {
			return false
		}
		// If location, only show choices with no SC or matching SC
		if (sample.location && choice.severityClass?.id && choice.severityClass?.id !== sample.location.severityClass?.id) {
			return false
		}
		// Product must match if present
		if (choice.productId && choice.productId !== sample.product?.id) {
			return false
		}
		// Product batch must match if present
		if (choice.productBatchId && choice.productBatchId !== workOrder.productBatch?.id) {
			return false
		}
		// Required Option/Choice/Constraint must match if present
		if (choice.requiredAnalysisOptionId && choice.requiredChoice && choice.requiredConstraint) {
			const requiredOptionValue = sample.sampleValues?.find(
				sv => sv && sv.analysisOption.id === choice.requiredAnalysisOptionId,
			)
			let result = requiredOptionValue?.result?.toString() ?? ''
			if (
				(choice.requiredConstraint === 'MAXIMUM' && !(result < choice.requiredChoice)) ||
				(choice.requiredConstraint === 'MINIMUM' && !(result > choice.requiredChoice)) ||
				// Seems like the desktop treats absent values as '0' or '' when testing equality so we need to check for both
				(choice.requiredConstraint === 'NOT_EQUAL' &&
					(result === choice.requiredChoice || (result || '0') === choice.requiredChoice)) ||
				(choice.requiredConstraint === 'NONE' &&
					result !== choice.requiredChoice &&
					(result || '0') !== choice.requiredChoice)
			) {
				return false
			}
		}
		return true
	}

	function onDocumentClick() {
		showDocument = !showDocument && !!document
	}

	async function onValueChange(value: string) {
		if (!sampleValue) {
			return
		}
		isLoading = true
		try {
			// WO screen will update acceptability for all SVs, but we still need to fetch it for the table here.
			const [{ data }] = await Promise.all([
				getValueAcceptabilityQuery.fetch({
					variables: {
						currentResult: (value ?? '').toString(),
						productBatchId: workOrder.productBatch?.id,
						analysisOptionId: sampleValue.analysisOption.id,
						productId: sample.product?.id,
						plantId: workOrder.plant?.id,
						severityClassId: sample.location?.severityClass?.id,
						productionVolume: mode === 'RECIPE' ? sample.productionVolume : undefined,
					},
				}),
				await acceptabilityPopover?.getThresholdTableData(),
				tick(),
			])
			if (data) {
				sampleValue.resultStatus = acceptabilityToResultStatus[data.getValueAcceptability]
			}
		} catch (err) {
			mediator.call('showMessage', {
				heading: translate('workOrder.errorFetchingAcceptabilityHeading', 'Error Fetching Acceptability'),
				message: (err as Error).message,
				color: 'danger',
				time: false,
			})
		}

		await tick()
		if (sample.status === 'OPEN') {
			sample.status = 'SAMPLED'
		}
		// If it's saved, update the lastModified date so they see the pencil. This isn't sent to the API, it just updates the UI
		if (sampleValue.id && sampleValue.filledOut && sampleValue.lastModified && value !== sampleValue.result) {
			sampleValue.lastModified = new Date().toISOString()
		}
		isLoading = false
		change?.({ value })
	}

	// selects can't be readonly, so just make them inputs instead.
	let valueType = $derived(
		sampleValue?.analysisOption.valueType === 'CHOICE' && readonly
			? 'TEXT'
			: (sampleValue?.analysisOption.valueType ?? 'TEXT'),
	)
	let inputType = $derived.by((): 'text' | 'number' | 'date' | 'time' | 'datetime-local' => {
		switch (valueType) {
			case 'TEXT':
				return 'text'
			case 'NUMBER':
			case 'INTEGER':
			case 'CURRENCY':
				return 'number'
			case 'DATE':
				return 'date'
			case 'TIME':
				return 'time'
			case 'DATETIME':
				return 'datetime-local'
			default:
				return 'text'
		}
	})
	let labelClass = $derived(labelType === 'COMPACT' ? 'badge px-0' : '')
	let optionLabel = $derived.by(() => {
		if (!sampleValue) {
			return ''
		}

		let label = formatOptionName(sampleValue.analysisOption)

		if (sampleValue?.defaultValue !== null && mode === 'RECIPE') {
			label += ` Ideal: ${sampleValue.defaultValue}`
		}

		return label
	})
	let isDisabled = $derived(disabled || restriction === 'HIDDEN' || restriction === 'INACTIVE')
	let isRequired = $derived(restriction === 'REQUIRED_TO_CLOSE' || restriction === 'REQUIRED_TO_PERFORM')
	// for some reason isReadonly = false still makes the input readonly
	let isReadonly = $derived(
		readonly || restriction === 'READONLY' || (sampleValue && sampleValue.analysisOption.entryMethod !== 'USER_ENTERED')
			? true
			: undefined,
	)
	let boolIsValid = $derived(
		valueType === 'BOOLEAN' && (!isRequired || sampleValue?.result === 'True' || sampleValue?.result === 'False'),
	)
	let requiredTooltip = $derived.by(() => {
		if (sampleValue?.analysisOption.requiredToCompleteSection) {
			return translate('workOrder.requiredToCompleteSection', 'Required to Complete Section')
		} else if (restriction === 'REQUIRED_TO_PERFORM') {
			return translate('workOrder.requiredToPerform', 'Required to Perform')
		} else if (restriction === 'REQUIRED_TO_CLOSE') {
			return translate('workOrder.requiredToClose', 'Required to Close')
		}
	})

	$effect(() => {
		if (choicesLoader && sampleValue?.analysisOption.valueType === 'CHOICE') {
			choicesLoader(sampleValue.analysisOption.id).then(value => (choices = value ?? []))
		}
	})

	const getValueAcceptabilityQuery = graphql(`
		query getValueAcceptability(
			$currentResult: String!
			$productBatchId: PositiveInt
			$analysisOptionId: PositiveInt!
			$productId: PositiveInt
			$severityClassId: PositiveInt
			$plantId: PositiveInt
			$productionVolume: NonNegativeFloat
		) {
			getValueAcceptability(
				currentResult: $currentResult
				productBatchId: $productBatchId
				analysisOptionId: $analysisOptionId
				productId: $productId
				severityClassId: $severityClassId
				plantId: $plantId
				productionVolume: $productionVolume
			)
		}
	`)
</script>

<!-- @component
Input for option values on the work order screen. This component has a lot of stuff that you won't need in readonly contexts, so maybe don't use it for that.
-->

{#if document && showDocument}
	<div
		class="border bg-white mb-1"
		style="min-width: 300px;"
		transition:slide={{ duration: 100 }}
	>
		<object
			class="w-100 h-100"
			title={document.file.name}
			data={document.file.path}
			type={document.file.mimeType}
		></object>
	</div>
{/if}
{#if !sampleValue}
	<i class="text-muted">{translate('common:notApplicableAbbreviation', 'N/A')}</i>
{:else if restriction === 'INACTIVE'}
	<Input
		disabled={isDisabled}
		{showLabel}
		{labelClass}
		label={optionLabel}
		title={translate('workOrder.inactiveOptionTitle', 'This option is inactive')}
		value={sampleValue.result}
	>
		{#snippet prepend()}
			<Button
				disabled
				style="min-width: 35.5px;"
			></Button>
		{/snippet}
	</Input>
{:else if valueType == 'CHOICE'}
	<Select
		{id}
		disabled={isDisabled || isReadonly}
		{showLabel}
		label={optionLabel}
		title={optionLabel}
		showAppend={!!document}
		required={isRequired}
		hint={mode === 'RECIPE' && sampleValue.analysisOption.product?.name
			? sampleValue.analysisOption.product?.name
			: undefined}
		{labelClass}
		emptyValue=""
		validation={{ validator, value: sampleValue.result }}
		style="min-width: {MIN_INPUT_WIDTH_PX}px;"
		labelParentClass="flex-nowrap-hack"
		options={makeOptions(choices, sampleValue.result)}
		append={appends}
		prepend={prepends}
		bind:value={sampleValue.result}
		onchange={() => sampleValue && onValueChange(sampleValue.result)}
	></Select>
	{#if isReadonly}
		<span class="sr-only">{sampleValue.result}</span>
	{/if}
{:else if valueType === 'BOOLEAN'}
	<Label
		controlFor={id}
		label={optionLabel}
		{labelClass}
		required={isRequired}
		hint={isRequired && !sampleValue.result
			? requiredTooltip
			: mode === 'RECIPE' && sampleValue.analysisOption.product?.name
				? sampleValue.analysisOption.product?.name
				: ''}
		hintClass="{isRequired && !sampleValue.result ? 'text-danger' : ''} font-60"
		{showLabel}
	>
		<div
			{id}
			class="btn-group btn-group-toggle w-100"
			data-toggle="buttons"
		>
			{@render prepends()}
			<label
				class="btn btn-sm cursor-pointer text-truncate"
				class:btn-secondary={stringToBoolean(sampleValue.result)}
				class:btn-outline-secondary={boolIsValid && !stringToBoolean(sampleValue.result)}
				class:btn-outline-danger={!boolIsValid}
				class:active={stringToBoolean(sampleValue.result)}
				style:font-weight={boolIsValid ? 'normal' : 'bold'}
				class:disabled={isDisabled || isReadonly}
				style:cursor={isDisabled || isReadonly ? 'unset' : 'pointer'}
				style:min-width="{MIN_INPUT_WIDTH_PX / 2}px"
			>
				<input
					id="radio-true-{id}"
					type="radio"
					autocomplete="off"
					name="options"
					disabled={isDisabled}
					readonly={isReadonly}
					value={true}
					onclick={() => {
						// Hack because radio buttons can't be readonly?
						if (sampleValue && !isReadonly && !isDisabled) {
							sampleValue.result = sampleValue.result === 'True' ? '' : 'True'
							onValueChange(sampleValue.result)
						}
					}}
				/>
				{translate('workOrder.true', 'True')}
			</label>
			<label
				class="btn btn-sm cursor-pointer text-truncate"
				class:btn-secondary={boolIsValid && !stringToBoolean(sampleValue.result) && sampleValue.result !== ''}
				class:btn-outline-secondary={(boolIsValid && stringToBoolean(sampleValue.result)) || sampleValue.result === ''}
				class:btn-outline-danger={!boolIsValid}
				class:active={!stringToBoolean(sampleValue.result) && sampleValue.result !== ''}
				class:disabled={isDisabled || isReadonly}
				style:font-weight={boolIsValid ? 'normal' : 'bold'}
				style:cursor={isDisabled || isReadonly ? 'unset' : 'pointer'}
				style:min-width="{MIN_INPUT_WIDTH_PX / 2}px"
			>
				<input
					id="radio-false-{id}"
					type="radio"
					autocomplete="off"
					name="options"
					disabled={isDisabled}
					readonly={isReadonly}
					value={false}
					onclick={() => {
						// Hack because radio buttons can't be readonly?
						if (sampleValue && !isReadonly && !isDisabled) {
							sampleValue.result = sampleValue.result === 'False' ? '' : 'False'
							onValueChange(sampleValue.result)
						}
					}}
				/>
				{translate('workOrder.false', 'False')}
			</label>
			{#if !boolIsValid}
				<span
					class="input-group-text text-danger border-danger border-left-0"
					style="border-top-left-radius: 0; border-bottom-left-radius: 0; padding: .25rem .5rem; border-left: none;"
					title={translate('workOrder.missingRequiredBooleanValue', 'Value is required, but is not filled out.')}
				>
					<Icon
						prefix="far"
						icon="circle-exclamation"
					></Icon>
				</span>
			{:else if !!document}
				{@render appends()}
			{/if}
		</div>
	</Label>
{:else}
	<Input
		{id}
		disabled={isDisabled}
		readonly={isReadonly}
		{showLabel}
		{labelClass}
		label={optionLabel}
		showAppend={!!document}
		required={isRequired}
		hint={mode === 'RECIPE' && sampleValue.analysisOption.product?.name
			? sampleValue.analysisOption.product?.name
			: undefined}
		type={inputType}
		validation={{ validator, value: sampleValue.result }}
		style="min-width: {MIN_INPUT_WIDTH_PX}px;"
		labelParentClass="flex-nowrap-hack"
		maxlength={100}
		append={appends}
		prepend={prepends}
		bind:value={sampleValue.result}
		onchange={() => {
			if (!sampleValue) {
				return
			} else if (sampleValue.analysisOption.valueType === 'INTEGER') {
				const parsed = parseInt(sampleValue.result, 10)
				sampleValue.result = Number.isNaN(parsed) ? '' : parsed.toString()
			} else if (sampleValue.analysisOption.valueType === 'NUMBER') {
				sampleValue.result = financialNumber(sampleValue.result).toString(6)
			} else if (sampleValue.analysisOption.valueType === 'CURRENCY') {
				sampleValue.result = financialNumber(sampleValue.result).toString(2)
			}
			onValueChange(sampleValue.result)
		}}
	></Input>
{/if}

{#if sampleValue && sampleValue.analysisOption.inventoryMode !== 'NONE'}
	<OptionValueLots
		{isDisabled}
		isReadonly={readonly || restriction === 'READONLY'}
		plantId={sample.plant?.id ?? workOrder.plant?.id}
		bind:sampleValue
		resultChange={value => onValueChange(value)}
	></OptionValueLots>
{/if}

{#snippet prepends()}
	<AcceptabilityPopover
		{allowShowThresholdsTable}
		checkThresholdsData={sampleValue
			? {
					analysisOptionId: sampleValue.analysisOption.id,
					plantId: sample.plant?.id ?? workOrder.plant?.id,
					currentResult: sampleValue.result,
					productId: sample.product?.id,
					productBatchId: workOrder.productBatch?.id,
					severityClassId: sample.location?.severityClass?.id,
					productionVolume: mode === 'RECIPE' ? (sample.productionVolume ?? undefined) : undefined,
				}
			: undefined}
		filledOut={sampleValue?.filledOut}
		{isLoading}
		lastModified={sampleValue?.lastModified}
		resultStatus={sampleValue?.resultStatus}
		{showModifiedIcons}
		bind:showOnlyApplicableThresholds
		bind:this={acceptabilityPopover}
	></AcceptabilityPopover>
{/snippet}

{#snippet appends()}
	{#if document}
		<Button
			outline
			size="sm"
			tabindex={-1}
			iconClass="info"
			title={translate('workOrder.documentButtonTitle', 'Click to view document')}
			style="min-width: 35.5px; max-width: 35.5px;"
			onclick={onDocumentClick}
		></Button>
	{/if}
{/snippet}

<style>
	/* Temp hack to make font sizes at the very least consistent */
	:global(small.font-60) {
		font-size: 60%;
	}

	:global(.form-group.flex-nowrap-hack .input-group) {
		flex-wrap: nowrap !important;
	}

	/* Fix for radio buttons that are label>btn.btn-secondary>input not showing that they are focused */
	.btn-secondary:focus-within,
	.btn-outline-secondary:focus-within {
		border-color: #545b62;
		box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
	}

	.btn-outline-danger:focus-within {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
</style>
