import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/UserAccountConfigurationSettings'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class UserAccountConfigurationSettingsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "UserAccountConfigurationSettingsStore",
			variables: false,
		})
	}
}

export async function load_UserAccountConfigurationSettings(params) {
	await initClient()

	const store = new UserAccountConfigurationSettingsStore()

	await store.fetch(params)

	return {
		UserAccountConfigurationSettings: store,
	}
}
