export default {
    "name": "CreateAndUpdateProducts",
    "kind": "HoudiniMutation",
    "hash": "bee7b59fc441f8ad680ccbf5b4031d731b165b798639c7557d1a8d7d678bb875",

    "raw": `mutation CreateAndUpdateProducts($products: [NewUpdateProduct!]!) {
  createAndUpdateProducts(products: $products) {
    id
    active
    category
    description
    inUseAtPlantIDs
    name
    parentProductId
    productType
    tags {
      id
      name
      entityType
      active
    }
    attachmentCount
    attachments {
      id
      public
      rank
      fileId
      file {
        path
        name
        created
        updated
        type
        hash
        mimeType
        size
        id
      }
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createAndUpdateProducts": {
                "type": "Product",
                "keyRaw": "createAndUpdateProducts(products: $products)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "nullable": true,
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "inUseAtPlantIDs": {
                            "type": "PositiveInt",
                            "keyRaw": "inUseAtPlantIDs",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "parentProductId": {
                            "type": "PositiveInt",
                            "keyRaw": "parentProductId",
                            "nullable": true,
                            "visible": true
                        },

                        "productType": {
                            "type": "ProductType",
                            "keyRaw": "productType",
                            "visible": true
                        },

                        "tags": {
                            "type": "EntityTag",
                            "keyRaw": "tags",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityTagType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachmentCount": {
                            "type": "UnsignedInt",
                            "keyRaw": "attachmentCount",
                            "visible": true
                        },

                        "attachments": {
                            "type": "ProductFile",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "SafeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "fileId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "fileId",
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "DateTime",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "hash": {
                                                    "type": "String",
                                                    "keyRaw": "hash",
                                                    "visible": true
                                                },

                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "size": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "size",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "products": "NewUpdateProduct"
        },

        "types": {
            "NewAnalysisOptionChoice": {
                "active": "Boolean",
                "analysisOptionId": "PositiveInt",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            },

            "AnalysisOptionChoiceUpdate": {
                "active": "Boolean",
                "analysisOptionId": "PositiveInt",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "id": "PositiveInt",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            },

            "NewProductBatch": {
                "analysisOptionChoiceIdsToRemove": "PositiveInt",
                "analysisOptionChoicesToAdd": "NewAnalysisOptionChoice",
                "analysisOptionChoicesToUpdate": "AnalysisOptionChoiceUpdate",
                "description": "String",
                "end": "DateTime",
                "expiration": "Date",
                "locationId": "PositiveInt",
                "name": "String",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "quantity": "Float",
                "start": "DateTime",
                "status": "ProductBatchStatus"
            },

            "UpdateProductBatch": {
                "analysisOptionChoiceIdsToRemove": "PositiveInt",
                "analysisOptionChoicesToAdd": "NewAnalysisOptionChoice",
                "analysisOptionChoicesToUpdate": "AnalysisOptionChoiceUpdate",
                "description": "String",
                "end": "DateTime",
                "expiration": "Date",
                "id": "PositiveInt",
                "locationId": "PositiveInt",
                "name": "String",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "quantity": "Float",
                "start": "DateTime",
                "status": "ProductBatchStatus",
                "uuid": "String"
            },

            "NewUpdateProduct": {
                "active": "Boolean",
                "analysisOptionChoiceIdsToRemove": "PositiveInt",
                "analysisOptionChoicesToAdd": "NewAnalysisOptionChoice",
                "analysisOptionChoicesToUpdate": "AnalysisOptionChoiceUpdate",
                "barcodeFormat": "String",
                "batchesToAdd": "NewProductBatch",
                "batchesToUpdate": "UpdateProductBatch",
                "category": "String",
                "description": "String",
                "id": "PositiveInt",
                "inUseAtPlantIdsToAdd": "PositiveInt",
                "inUseAtPlantIdsToRemove": "PositiveInt",
                "itemNumber": "String",
                "name": "String",
                "parentProductId": "PositiveInt",
                "parentProductUuid": "NonEmptyString",
                "productType": "ProductType",
                "supplierItemNumber": "String",
                "tagIdsToAdd": "PositiveInt",
                "tagIdsToRemove": "PositiveInt",
                "unit": "String",
                "unitConversion": "Float",
                "uuid": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a06b5ebe090e978871d18f5ef8bec4bff56582a50cb8bfbabfaf787b87e43b55";