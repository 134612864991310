<script lang="ts">
	import type { SvelteAsr, i18n } from 'types/common'
	import type { SaveResetProps } from './configuration'

	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'

	import { getContext, type ComponentProps } from 'svelte'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	export let tabs: ComponentProps<AsrNavTabBar>['tabs']
	export let asr: SvelteAsr
	export let saveResetProps: SaveResetProps

	const { t: translate } = getContext<i18n>('i18next')
</script>

<div class="card">
	<StateCardHeader
		title={translate('common:configuration', 'Configuration')}
		icon="cog"
	>
		{#snippet right()}
			{#if $saveResetProps}
				<SaveResetButton {...$saveResetProps}></SaveResetButton>
			{/if}
		{/snippet}
		<AsrNavTabBar
			{tabs}
			{asr}
			breakpoint="md"
		/>
	</StateCardHeader>
	<div class="card-body p-0 m-3">
		<uiView></uiView>
	</div>
</div>
