export default {
    "name": "MyAccountUpdateSessionUser",
    "kind": "HoudiniMutation",
    "hash": "d1150c6d7349ad04d6e22767acbbf3039f41e1acd19d082be731ebec1e77e20f",

    "raw": `mutation MyAccountUpdateSessionUser($input: UpdateSessionUser!) {
  updateSessionUser(input: $input) {
    id
    name
    firstName
    lastName
    fullName
    recoveryEmail
    workEmail
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateSessionUser": {
                "type": "UserAccount",
                "keyRaw": "updateSessionUser(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "fullName": {
                            "type": "NonEmptyString",
                            "keyRaw": "fullName",
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "EmailAddress",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "UpdateSessionUser"
        },

        "types": {
            "UpdateSessionUser": {
                "currentPassword": "String",
                "firstName": "String",
                "lastName": "String",
                "newPassword": "String",
                "recoveryEmail": "EmailAddress"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=91bcc04608469162bf9369fd5bb46b7e8c2ff973e52e26f9716fb51ba82923ef";