<script lang="ts">
	import type { GroupedEntityLoader } from 'utility/grouped-entity-loader'
	import type { WorkOrder, Sample, SampleValue, WoMediatorProviders, WoDocumentStore } from '../work-order'
	import type { Writable } from 'svelte/store'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { AnalysisOptionChoice, DocumentTree, Product, RestrictionTree, Location } from './edit'
	import type { DocumentStatus$options } from '$houdini'
	import type { i18n, Mediator } from 'types/common'

	import Modal from '@isoftdata/svelte-modal'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import TextArea from '@isoftdata/svelte-textarea'
	import OptionValueInput from './OptionValueInput.svelte'
	import SampleAttachments from './SampleAttachments.svelte'
	import Attention from './Attention.svelte'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Button from '@isoftdata/svelte-button'
	import Autocomplete from '@isoftdata/svelte-autocomplete'

	import { getDefaultSampleValuesList } from 'utility/get-default-sample-values'
	import formatDocumentStatus from 'utility/format-document-status'
	import { isFuture, isPast } from 'date-fns'
	import { klona } from 'klona'
	import { getContext } from 'svelte'
	import hasPermission from 'utility/has-permission'
	import Input from '@isoftdata/svelte-input'

	const showModifiedIcons = getContext<Writable<boolean>>('showModifiedIcons')
	const mediator = getContext<Mediator<WoMediatorProviders>>('mediator')
	const sampleCrudStore = getContext<CrudStore<Sample, 'uuid'>>('sampleCrudStore')
	const { t: translate } = getContext<i18n>('i18next')

	const computeCanEditCurrentWorkOrder = getContext<
		(plantId: number, groupId: number | null, documentStatus: DocumentStatus$options) => boolean
	>('computeCanEditCurrentWorkOrder')
	const computeCanEditSample =
		getContext<(canEditWo: boolean, sampleStatus: DocumentStatus$options) => boolean>('computeCanEditSample')
	const computeCanEditSampleValue =
		getContext<
			(canEditWo: boolean, sampleStatus: DocumentStatus$options, sample: Sample, sampleValue: SampleValue) => boolean
		>('computeCanEditSampleValue')
	const confirmActionThatRequiresReVerify = getContext<() => boolean>('confirmActionThatRequiresReVerify')
	const woDocumentStore = getContext<WoDocumentStore>('woDocumentStore')
	const productsLoader = getContext<GroupedEntityLoader<Product>>('productsLoader')
	const locationsLoader = getContext<GroupedEntityLoader<Location>>('locationsLoader')
	const choicesLoader = getContext<GroupedEntityLoader<AnalysisOptionChoice>>('choicesLoader')
	const documentTree = getContext<DocumentTree>('documentTree')
	const restrictionTree = getContext<RestrictionTree>('restrictionTree')
	const allowShowThresholdsTable = getContext<boolean>('allowShowThresholdsTable')

	interface Props {
		mode: 'RECIPE' | 'TESTING'
		workOrder: WorkOrder
		selectedRowIds: Array<string>
		showClosedSamples: boolean
		showProductLocationImages: (ctx: {
			entityType: 'PRODUCT' | 'LOCATION'
			entityId: number
			entityName: string
			sampleId: number
		}) => void
	}

	let {
		//
		mode,
		workOrder = $bindable(),
		selectedRowIds = $bindable(),
		showClosedSamples = $bindable(),
		showProductLocationImages,
	}: Props = $props()

	let showEditModal = $state(false)
	let editingSample: Sample | null = $state<Sample | null>(null)
	let editingSampleIndex: number | null = $state(null)
	let bodyShown = $state(false)
	let svIdsToUpdate = new Set<number>()
	let initialSampleStatus: DocumentStatus$options | null = $state(null)

	let showPlantName = $derived(workOrder.samples.some(sample => sample.plant?.id !== workOrder.plant.id))
	let closedSampleCount = $derived(workOrder.samples.filter(sample => sample.status === 'CLOSED').length)
	let canEditCurrentWorkOrder = $derived(
		computeCanEditCurrentWorkOrder(workOrder.plant.id, workOrder.assignedToGroup?.id ?? null, workOrder.documentStatus),
	)
	let canEditCurrentSample = $derived(
		!!editingSample && computeCanEditSample(canEditCurrentWorkOrder, editingSample.status),
	)
	let canClosePartial = $derived(
		hasPermission('WORK_ORDERS_CAN_CLOSE_PARTIALLY_COMPLETED', editingSample?.plant?.id ?? workOrder.plant.id),
	)

	let missingRequiredToPerform = $derived(
		editingSample ? mediator.call('sampleMissingRequiredValuesForStatus', editingSample, 'SAMPLED') : [],
	)
	let missingRequiredToClose = $derived(
		editingSample ? mediator.call('sampleMissingRequiredValuesForStatus', editingSample, 'CLOSED') : [],
	)
	let requiredToPerformPresent = $derived(missingRequiredToPerform.length === 0)
	let requiredToClosePresent = $derived(missingRequiredToClose.length === 0)

	// have to filter out products/ingredients depending on the work order type
	async function getDisplayProducts(plantId: number) {
		const products = await productsLoader(plantId)
		return products.filter(
			product =>
				product &&
				(workOrder.workOrderType.showProduct === 'BOTH' || workOrder.workOrderType.showProduct === product.productType),
		)
	}

	async function editSample(sample: Sample, index: number) {
		const locations = await locationsLoader(sample.plant?.id || workOrder.plant.id)
		const products = await productsLoader(sample.plant?.id || workOrder.plant.id)
		showEditModal = true
		svIdsToUpdate.clear()
		editingSample = klona(sample)
		editingSample.product = editingSample.product?.id
			? (products.find(product => product && product.id === editingSample?.product?.id) ?? null)
			: null
		editingSample.location = editingSample.location?.id
			? (locations.find(location => location && location.id === editingSample?.location?.id) ?? null)
			: null
		initialSampleStatus = sample.status
		if (editingSample.attachments.length) {
			bodyShown = true
		}
		editingSampleIndex = index
	}

	function shouldShowOption(sv: SampleValue) {
		const restriction = restrictionTree.get({
			analysisOptionId: sv.analysisOption.id,
			plantId: editingSample?.plant?.id ?? workOrder.plant.id,
			locationId: editingSample?.location?.id ?? null,
			productId: editingSample?.product?.id ?? null,
		})
		return restriction !== 'HIDDEN' && (restriction !== 'INACTIVE' || sv.result)
	}

	async function getDefaultSampleValues(sample: Sample) {
		sample.sampleValues = await getDefaultSampleValuesList(workOrder, sample)
		editingSample = sample
	}

	function shouldShowSample(sample: Sample) {
		return (showClosedSamples || sample.status !== 'CLOSED') && sample.analysis.analysisType === mode
	}
</script>

<div class="d-flex justify-content-end p-2 border-bottom">
	<Checkbox
		inline
		label={closedSampleCount
			? translate('workOrder.showClosedSamplesCountLabel', 'Show {{closedSampleCount}} closed samples', {
					closedSampleCount,
				})
			: translate('workOrder.showClosedSamplesLabel', 'Show closed samples')}
		disabled={closedSampleCount === 0}
		bind:checked={showClosedSamples}
	></Checkbox>
</div>
<ul class="list-group list-group-flush list-group-striped">
	{#each workOrder.samples as sample, index (sample.uuid)}
		{#if shouldShowSample(sample)}
			{@const performedDate = sample.performed ? new Date(sample.performed) : null}
			{@const scheduledDate = sample.scheduled ? new Date(sample.scheduled) : null}
			{@const dueDate = sample.due ? new Date(sample.due) : null}
			<button
				class="list-group-item list-group-item-action d-flex flex-wrap flex-sm-nowrap"
				class:list-group-item-primary={selectedRowIds.includes(sample.uuid)}
				onclick={() => editSample(sample, index)}
			>
				<div class="h-100 w-xs-100 mr-2">
					<Attention
						inline
						{sample}
						sampleModified={sample.uuid in $sampleCrudStore.updated}
						save={() => mediator.call('saveWorkOrder')}
						closeSample={() => {
							sample.status = 'CLOSED'
							sampleCrudStore.update(sample)
						}}
						openAttachments={() => {
							bodyShown = true
							editSample(sample, index)
						}}
					>
						<input
							type="checkbox"
							checked={selectedRowIds.includes(sample.uuid)}
							onchange={() => {
								if (selectedRowIds.includes(sample.uuid)) {
									selectedRowIds = selectedRowIds.filter(id => id !== sample.uuid)
								} else {
									selectedRowIds.push(sample.uuid)
									selectedRowIds = selectedRowIds
								}
							}}
							onclick={e => e.stopPropagation()}
						/>
					</Attention>
				</div>
				<div class="text-left align-self-start flex-grow-1">
					<strong class="d-block">{sample.analysis.name}</strong>
					{#if showPlantName}
						{sample.plant?.name}
					{/if}
					{#if workOrder.workOrderType.showLocation}
						<div
							title={sample.location ? `Location: ${sample.location?.location}}` : ''}
							class:text-muted={!sample.location}
							class:text-italic={!sample.location}
						>
							{sample.location?.location ?? translate('workOrder.noLocation', 'No Location')}
						</div>
					{/if}
					{#if workOrder.workOrderType.showLocationDescription && sample.location?.description}
						<small class="">{sample.location.description}</small>
					{/if}
					{#if workOrder.workOrderType.showProduct !== 'NONE'}
						<div
							title={sample.product ? `Product: ${sample.product?.name}` : ''}
							class:text-muted={!sample.product}
							class:text-italic={!sample.product}
						>
							{sample.product?.name ?? translate('workOrder.noProduct', 'No Product')}
						</div>
					{/if}
				</div>
				<div class="text-sm-right w-xs-100">
					<div
						class:text-success={sample.status === 'OPEN'}
						class:text-primary={sample.status === 'SAMPLED'}
						class:text-danger={sample.status === 'CLOSED' || sample.status === 'CANCELLED'}
					>
						<strong>{formatDocumentStatus(translate, sample.status, true)}</strong>
					</div>

					{#if sample.tagNumber}<span class="d-block">Tag #{sample.tagNumber}</span>{/if}

					<!-- Display the most-relevant date here -->
					<span
						class:text-danger={dueDate && isPast(dueDate)}
						style="white-space: nowrap;"
					>
						{#if performedDate}
							Performed {performedDate.toLocaleDateString()} {performedDate.toLocaleTimeString()}
						{:else if scheduledDate && (isFuture(new Date(scheduledDate)) || !dueDate)}
							Scheduled {scheduledDate.toLocaleDateString()} {scheduledDate.toLocaleTimeString()}
						{:else if dueDate}
							Due {dueDate.toLocaleDateString()} {dueDate.toLocaleTimeString()}
						{/if}
					</span>
				</div>
			</button>
		{/if}
	{:else}
		<li class="list-group-item">
			<h6 class="text-center">
				{translate(
					'workOrder.noSamplesInList',
					'There are no samples on this work order. Click "New Sample..." to begin.',
				)}
			</h6>
		</li>
	{/each}
</ul>
<!-- Either disable confirm, or show message if required values not present -->
<Modal
	cancelButtonText={translate('workOrder.cancelLabel', 'Cancel')}
	bind:show={showEditModal}
	title={editingSample?.tagNumber
		? translate('workOrder.editSampleWithTagNumber', 'Edit Sample Tag #{{- tagNumber}}', {
				tagNumber: editingSample?.tagNumber,
			})
		: translate('workOrder.editSample', 'Edit Sample')}
	subtitle={editingSample?.analysis.name}
	modalSize="lg"
	modalBodyStyle="overflow-y: auto;"
	modalDialogClass="max-mobile-height"
	footerClass="align-items-end stack-modal-buttons"
	backdropClickCancels={false}
	confirm={() => {
		if (!confirmActionThatRequiresReVerify()) {
			return
		}
		if (editingSample && editingSampleIndex !== null) {
			workOrder.samples[editingSampleIndex] = editingSample
			sampleCrudStore.update(editingSample)
			woDocumentStore.sampleValueUpdated(Array.from(svIdsToUpdate))
			svIdsToUpdate.clear()
			initialSampleStatus = null
		}
		workOrder = workOrder
		showEditModal = false
	}}
	close={() => {
		editingSample = null
		showEditModal = false
		svIdsToUpdate.clear()
		initialSampleStatus = null
	}}
>
	{#snippet footer()}
		{#if editingSample?.status !== 'CLOSED'}
			<Button
				iconClass="lock"
				class="mb-1"
				title={translate(
					'workOrder.closeAndConfirmTitle',
					'Mark this sample as closed, and confirm any current changes.',
				)}
				disabled={!requiredToClosePresent && !canClosePartial}
				onclick={() => {
					if (
						!requiredToClosePresent &&
						canClosePartial &&
						!confirm(
							translate(
								'workOrder.closeAndConfirmPartialMessage',
								'Not all required options have been filled out. Confirm your changes and close this sample anyways?',
							),
						)
					) {
						return
					} else if (
						requiredToClosePresent &&
						!confirm(translate('workOrder.closeAndConfirmMessage', 'Confirm your changes and close this sample?'))
					) {
						return
					} else if (!requiredToClosePresent && !canClosePartial) {
						alert(
							translate(
								'workOrder.closeAndConfirmMissingRequiredError',
								'Cannot close sample until all required options are filled out.',
							),
						)
						return
					}

					if (!confirmActionThatRequiresReVerify()) {
						return
					}

					if (editingSample && editingSampleIndex !== null) {
						editingSample.status = 'CLOSED'
						workOrder.samples[editingSampleIndex] = editingSample
						sampleCrudStore.update(editingSample)
						woDocumentStore.sampleValueUpdated(Array.from(svIdsToUpdate))
						svIdsToUpdate.clear()
						initialSampleStatus = null

						editingSample = null
						showEditModal = false
					}
				}}>{translate('workOrder.closeAndConfirmLabel', 'Close Sample & Confirm')}</Button
			>
		{:else if editingSample?.status === 'CLOSED' && hasPermission('WORK_ORDERS_CAN_REOPEN', editingSample?.plant?.id ?? workOrder.plant.id)}
			<Button
				iconClass="unlock"
				class="mb-1"
				onclick={() => {
					if (
						!confirm(translate('workOrder.confirmReopen', 'Reopen this sample?')) ||
						!confirmActionThatRequiresReVerify()
					) {
						return
					}

					if (editingSample && editingSampleIndex !== null) {
						editingSample.status = 'SAMPLED'
						workOrder.samples[editingSampleIndex] = editingSample
						sampleCrudStore.update(editingSample)
						editingSample = null
						showEditModal = false
						initialSampleStatus = null
					}
				}}>{translate('workOrder.reopenSampleLabel', 'Reopen Sample')}</Button
			>
		{/if}
	{/snippet}

	{#if editingSample}
		{@const samplePlantId = editingSample?.plant?.id ?? workOrder.plant.id}
		{#if mode === 'RECIPE'}
			<div class="card mb-1">
				<div class="card-header h5">{translate('workOrder.recipeInfoHeader', 'Recipe Info')}</div>
				<div class="card-body">
					<strong>{translate('workOrder.createdProductLabel', 'Created Product:')}</strong>
					{editingSample.product?.name ?? translate('common:notApplicableAbbreviation', 'N/A')}
					<br />
					<strong>{translate('workOrder.recipeInstructionsLabel', 'Recipe Instructions:')}</strong>
					{editingSample.analysis?.instructions || translate('common:notApplicableAbbreviation', 'N/A')}
					<br />
					<Input
						label={translate('workOrder.productionVolumeLabel', 'Production Volume')}
						type="number"
						bind:value={editingSample.productionVolume}
						onchange={() => {
							if (editingSample) {
								getDefaultSampleValues(editingSample)
							}
						}}
					></Input>
				</div>
			</div>
		{/if}
		<CollapsibleCard
			bind:bodyShown
			entireHeaderToggles
			headerText={translate('workOrder.attachmentsTitle', 'Attachments')}
			cardHeaderClass="card-header d-flex justify-content-between h5"
		>
			<SampleAttachments bind:sample={editingSample} />
		</CollapsibleCard>
		<div class="form-row">
			{#if mode === 'TESTING'}
				<div class="col-12 col-md-6">
					<Autocomplete
						label={translate('workOrder.locationLabel', 'Location')}
						options={locationsLoader(samplePlantId)}
						disabled={!canEditCurrentSample}
						showAppend={!!editingSample.location?.attachmentCount}
						getLabel={location => location?.location ?? ''}
						emptyValue={null}
						bind:value={editingSample.location}
						change={() => {
							if (editingSample) {
								restrictionTree.fetchForSample(editingSample, samplePlantId)
								documentTree.fetchForSample(editingSample, samplePlantId)
							}
						}}
					>
						{#snippet append()}
							<Button
								iconClass="photo-video"
								title={translate('workOrder.locationImagesTitle', 'View location images')}
								tabindex={-1}
								onclick={async () => {
									if (editingSample?.location) {
										showProductLocationImages?.({
											entityType: 'LOCATION',
											entityId: editingSample.location.id,
											entityName: editingSample.location.location,
											sampleId: editingSample.id,
										})
									}
								}}>{editingSample?.location?.attachmentCount}</Button
							>
						{/snippet}
					</Autocomplete>
				</div>
				<div class="col-12 col-md-6">
					<Autocomplete
						label={translate('workOrder.productLabel', 'Product')}
						options={getDisplayProducts(samplePlantId)}
						disabled={!canEditCurrentSample}
						showAppend={!!editingSample.product?.attachmentCount}
						getLabel={product => product?.name ?? ''}
						emptyValue={null}
						bind:value={editingSample.product}
						change={() => {
							if (editingSample) {
								restrictionTree.fetchForSample(editingSample, samplePlantId)
								documentTree.fetchForSample(editingSample, samplePlantId)
							}
						}}
					>
						{#snippet append()}
							<Button
								iconClass="photo-video"
								title={translate('workOrder.productImagesTitle', 'View product images')}
								tabindex={-1}
								onclick={() => {
									if (editingSample?.product) {
										showProductLocationImages?.({
											entityType: 'PRODUCT',
											entityId: editingSample.product.id,
											entityName: editingSample.product.name,
											sampleId: editingSample.id,
										})
									}
								}}
								>{editingSample?.product?.attachmentCount}
							</Button>
						{/snippet}
					</Autocomplete>
				</div>
				{#if workOrder.workOrderType.showLocationDescription && editingSample.location}
					<div class="col-12">
						<TextArea
							readonly
							label={translate('workOrder.locationDescriptionLabel', 'Location Description')}
							bind:value={editingSample.location.description}
						></TextArea>
					</div>
				{/if}
			{/if}
			<div
				class="col-12"
				class:col-md-6={mode === 'TESTING'}
			>
				<TextArea
					label={mode === 'TESTING' ? translate('workOrder.samplingCommentsLabel', 'Sampling Comments') : 'Comments'}
					disabled={!canEditCurrentSample}
					bind:value={editingSample.samplingComments}
				></TextArea>
			</div>
			{#if mode === 'TESTING'}
				<div class="col-12 col-md-6">
					<TextArea
						label={translate('workOrder.testingCommentsLabel', 'Testing Comments')}
						disabled={!canEditCurrentSample}
						bind:value={editingSample.testingComments}
					></TextArea>
				</div>
			{/if}
		</div>
		<div class="form-row">
			{#each editingSample.sampleValues as sampleValue, svIndex (sampleValue.analysisOption.id)}
				{#if shouldShowOption(sampleValue)}
					<div
						class="col-12"
						class:col-md-6={sampleValue.analysisOption.inventoryMode !== 'MULTIPLE_LOTS'}
					>
						<OptionValueInput
							{mode}
							{allowShowThresholdsTable}
							disabled={(!sampleValue.analysisOption.active && !sampleValue.result) ||
								!computeCanEditSampleValue(canEditCurrentWorkOrder, editingSample.status, editingSample, sampleValue)}
							labelType="NORMAL"
							sample={editingSample}
							showLabel
							showModifiedIcons={$showModifiedIcons}
							{workOrder}
							bind:sampleValue={editingSample.sampleValues[svIndex]}
							change={async () => {
								if (editingSample && editingSampleIndex !== null) {
									await getDefaultSampleValues(editingSample)
									// only need to track it if it's an update
									if (sampleValue.id) {
										svIdsToUpdate.add(sampleValue.id)
									}
								}
							}}
						></OptionValueInput>
					</div>
				{/if}
			{/each}
		</div>
		{#if initialSampleStatus === 'OPEN' && !requiredToPerformPresent}
			<div
				class="alert mb-0"
				class:alert-danger={!canClosePartial}
				class:alert-warning={canClosePartial}
			>
				<h6>{translate('workOrder.missingRequiredToPerformError', 'Missing Values Required to Perform')}</h6>
				{missingRequiredToPerform.map(sv => sv.analysisOption.option).join(', ')}
			</div>
		{:else if initialSampleStatus === 'SAMPLED' && !requiredToClosePresent}
			<div
				class="alert alert-danger mb-0"
				class:alert-danger={!canClosePartial}
				class:alert-warning={canClosePartial}
			>
				<h6>{translate('workOrder.missingRequiredToCloseError', 'Missing Values Required to Close')}</h6>
				{missingRequiredToClose.map(sv => sv.analysisOption.option).join(', ')}
			</div>
		{/if}
	{:else}
		<h6 class="text-center">{translate('workOrder.noSampleSelected', 'No Sample Selected')}</h6>
	{/if}
</Modal>

<style>
	@media (max-width: 577px) {
		.w-xs-100 {
			width: 100% !important;
		}

		:global(.modal-footer.stack-modal-buttons div) {
			display: flex;
			flex-grow: 1;
			margin: 0;
			gap: 0.25rem;
		}

		:global(.modal-footer.stack-modal-buttons button) {
			flex-grow: 1;
		}

		:global(.modal-dialog.max-mobile-height .modal-content) {
			max-height: 95vh;
		}
	}

	input[type='checkbox'] {
		min-height: 24px;
		min-width: 24px;
	}

	.text-italic {
		font-style: italic;
	}

	ul.list-group-striped button:nth-of-type(odd):not(.list-group-item-primary),
	ul.list-group-striped li:nth-of-type(odd):not(.list-group-item-primary) {
		background-color: rgba(0, 0, 0, 0.05);
	}
</style>
