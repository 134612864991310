<script lang="ts">
	import type { i18n } from 'i18next'
	import type { ResultStatus$options } from '$houdini'
	import type { Analysis, OptionValueTypeMap, SelectedFilters } from './sampling-history'
	import type { Merge } from 'type-fest'
	import type { ValueAcceptabilityMap } from 'utility/value-acceptability-map'

	import Modal from '@isoftdata/svelte-modal'
	import { Table, type Column, type IndexedRowProps } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Icon from '@isoftdata/svelte-icon'
	import Select from '@isoftdata/svelte-select'

	import { getContext, tick } from 'svelte'
	import { klona } from 'klona'
	import { upsert } from '@isoftdata/utility-array'
	import { getEventValue, getEventValueEnum } from '@isoftdata/browser-event'
	import camelCase from 'just-camel-case'

	type FilterAnalyses = SelectedFilters['analysisList']
	type FilterAnalysis = FilterAnalyses[number]
	type FilterOption = FilterAnalyses[number]['options'][number]

	type Props = {
		analysisList: Array<Analysis>
		confirm({
			matchAllOptionFilters,
			selectedAnalyses,
		}: {
			matchAllOptionFilters: boolean
			selectedAnalyses: FilterAnalyses
		}): void
	}

	let { analysisList, confirm }: Props = $props()
	let show = $state(false)
	let showInactiveAnalyses = $state(false)
	let showProductionDocuments = $state(true)
	let showTestingDocuments = $state(true)
	let defaultResultStatus: ResultStatus$options | null = $state(null)
	let selectedAnalyses: FilterAnalyses = $state([])
	let matchAllOptionFilters = $state(false)
	let filteredAnalyses: Array<DisplayAnalysis & IndexedRowProps> = $state([])

	const { t: translate } = getContext<i18n>('i18next')
	const valueAcceptabilityMap = getContext<ValueAcceptabilityMap>('valueAcceptabilityMap')
	const acceptabilityList = Array.from(valueAcceptabilityMap.entries()).map(([key, { label }]) => ({
		key,
		label: translate(`common:valueAcceptability.${camelCase(key)}`, label),
	}))
	const columns: Array<Column<DisplayAnalysis>> = [
		{
			property: 'selected',
			name: translate('common:select', 'Select'),
			title: translate(
				'samplingHistory.analysesModal.selectedColumnTitle',
				'Find samples with specified analyses (only checked options will be shown)',
			),
		},
		{
			property: 'name',
			name: translate('common:name', 'Name'),
			title: translate('samplingHistory.analysesModal.nameColumnTitle', 'The name of the analysis or option'),
		},
		{
			property: 'resultStatus',
			name: translate('common:resultStatus', 'Result Status'),
			title: translate(
				'samplingHistory.analysesModal.resultStatusColumnTitle',
				'Choose an acceptibility to show only those options of this status.\r\nOn an analysis, this shows only samples with at least one option with that status.\r\nOn an option, this shows only samples where the corresponding option value has this specific status',
			),
		},
		// Options only
		{
			property: 'filter',
			name: translate('common:filterFrom', 'Filter'),
			title: translate(
				'samplingHistory.analysesModal.filterColumnTitle',
				'Enter a value to filter the results (or the beginning of a range for range values)',
			),
			sortType: false,
		},
		{
			property: 'filterTo',
			name: translate('common:filterTo', 'Filter To'),
			title: translate(
				'samplingHistory.analysesModal.filterToColumnTitle',
				'For range compatible options, enter the end of the filter range here',
			),
			sortType: false,
		},
		{
			property: 'lotNumber',
			name: translate('common:lotNumber', 'Lot Number'),
			title: translate('samplingHistory.analysesModal.lotNumberColumnTitle', 'The lot number of the analysis option'),
			sortType: false,
		},
		{
			property: 'expirationDateFrom',
			name: translate('common:expirationDate', 'Expiration Date'),
			title: translate(
				'samplingHistory.analysesModal.expirationDateFromColumnTitle',
				'Select a date to filter from the specified date range',
			),
			sortType: false,
		},
		{
			property: 'expirationDateTo',
			name: translate('common:expirationDateTo', 'Expiration Date To'),
			title: translate(
				'samplingHistory.analysesModal.expirationDateToColumnTitle',
				'Select a date to filter from the specified date range',
			),
			sortType: false,
		},
	]

	export async function open(newAnalysisList: FilterAnalyses, newMatchAllOptionFilters: boolean) {
		matchAllOptionFilters = newMatchAllOptionFilters
		selectedAnalyses = getComputedSelectedAnalysisList(klona(newAnalysisList))
		show = true
		await tick()
		// Scroll to previous row so the analysis row isn't hidden by the table header
		// There won't be a previous row if it's the first one, but then we don't need to scroll
		document
			.querySelector('#sh-select-analyses-table tr[data-selected="true"]')
			?.previousElementSibling?.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	function getComputedSelectedAnalysisList(selectedAnalyses: FilterAnalyses): FilterAnalyses {
		return klona(analysisList).map((analysis): FilterAnalysis => {
			const selectedAnalysisMatch = selectedAnalyses.find(selectedAnalysis => selectedAnalysis.id === analysis.id)
			if (selectedAnalysisMatch) {
				const options = analysis.options.map((option): FilterOption => {
					const optionMatch = selectedAnalysisMatch.options.find(
						selectedAnalysisOption => selectedAnalysisOption.id === option.id,
					)
					return optionMatch ?? option
				})
				return {
					...selectedAnalysisMatch,
					resultStatus: defaultResultStatus ?? undefined,
					selected: true,
					options,
				}
			} else {
				return {
					...analysis,
					resultStatus: defaultResultStatus ?? undefined,
				}
			}
		})
	}

	type DisplayAnalysis = Merge<
		FilterAnalysis,
		{
			allOptionsSelected: boolean
			anyOptionsSelected: boolean
			// Not properties of the analysis, but we need them for properties for the table
			filter?: unknown
			filterTo?: unknown
			lotNumber?: unknown
			expirationDateFrom?: unknown
			expirationDateTo?: unknown
			/** Computed before the checkbox filters go into effect */
			unfilteredIndex: number
		}
	>

	const displayAnalysisList: Array<DisplayAnalysis> = $derived.by(() => {
		return selectedAnalyses.reduce((acc: Array<DisplayAnalysis>, analysis, unfilteredIndex) => {
			if (
				(showInactiveAnalyses || analysis.active) &&
				((showProductionDocuments && analysis.analysisType === 'RECIPE') ||
					(showTestingDocuments && analysis.analysisType === 'TESTING'))
			) {
				acc.push({
					...analysis,
					options: analysis.options.filter(option => option.active),
					allOptionsSelected: analysis.options.every(option => option.selected),
					anyOptionsSelected: analysis.options.some(option => option.selected),
					unfilteredIndex,
				})
			}

			return acc
		}, [])
	})

	function toggleAllOptionsForAnalysis(toggledAnalysisId: number, forceSelected = false) {
		const analysisIndex = selectedAnalyses.findIndex(analysis => analysis.id === toggledAnalysisId)
		const currentOptionsSelection = selectedAnalyses[analysisIndex].options
		const newSelected = forceSelected || !currentOptionsSelection.every(option => option.selected)

		selectedAnalyses[analysisIndex].options = currentOptionsSelection.map(option => {
			return {
				...option,
				selected: newSelected,
			}
		})
	}

	// Seems to take an analysis from the main list and give it some properties from the filter list
	function selectAnalysis(analysis: FilterAnalysis) {
		let mutatedAnalysis = klona(analysis)
		const newSelectionState = !mutatedAnalysis.selected

		mutatedAnalysis.selected = newSelectionState
		mutatedAnalysis.options = mutatedAnalysis.options.map(option => {
			return { ...option, selected: newSelectionState }
		})

		upsert(selectedAnalyses, 'id', mutatedAnalysis)
	}

	/** Get a valid result status, or undefined, from an event's value */
	function getEventResultStatus(event: Event) {
		return (
			getEventValueEnum<ResultStatus$options | ''>(
				event,
				'',
				'ALLOWED',
				'ERROR',
				'NOT_CALCULATED',
				'OUT_OF_BOUNDS',
				'WARNING',
			) || undefined
		)
	}

	function modalSelectAnalysisResultStatus(event: Event, analysisId: number) {
		const resultStatus = getEventResultStatus(event)
		const analysisIndex = selectedAnalyses.findIndex(analysis => analysis.id === analysisId)
		selectedAnalyses[analysisIndex].resultStatus = resultStatus
	}
	function addAnalysesModalConfirm() {
		selectedAnalyses = selectedAnalyses.reduce((acc: FilterAnalyses, analysis) => {
			if (analysis.selected) {
				const options = analysis.options.filter(option => option.selected)
				return [...acc, { ...analysis, options }]
			} else {
				return acc
			}
		}, [])

		show = false
		confirm({
			matchAllOptionFilters,
			selectedAnalyses,
		})
	}

	function clearAnalysisSelections() {
		selectedAnalyses = getComputedSelectedAnalysisList([])
	}

	function updateOptionFilterValue<K extends keyof FilterOption>(
		analysisIndex: number,
		optionIndex: number,
		key: K,
		value: FilterOption[K],
	) {
		selectedAnalyses[analysisIndex].options[optionIndex][key] = value
	}

	const optionValueTypes: OptionValueTypeMap = getContext('optionValueTypes')
</script>

<Modal
	bind:show
	title={translate('samplingHistory.editSelections', 'Edit Selections')}
	modalSize="xxl"
	confirmButtonText={translate('samplingHistory.editAnalysisSelectionsModal.confirmSelections', 'Confirm Selections')}
	cancelButtonText={translate('common:cancel', 'Cancel')}
	confirmButtonIcon="check"
	close={() => (show = false)}
	confirm={addAnalysesModalConfirm}
>
	<Table
		tableId="sh-select-analyses-table"
		responsive
		stickyHeader
		rows={displayAnalysisList}
		bind:filteredRows={filteredAnalyses}
		parentClass="overflow-y-auto mh-500"
		{columns}
		showFilterLabel
		filterLabel={translate('samplingHistory.editAnalysisSelectionsModal.searchAnalyses', 'Search Analyses')}
		filterEnabled
		filterPlaceholder={translate('samplingHistory.editAnalysisSelectionsModal.searchAnalyses', 'Search Analyses')}
		filterColumnClass="col-sm-12 col-md-4 col-lg-3"
	>
		{#snippet header()}
			<div class="form-row align-items-end">
				<div class="col-auto mr-auto">
					<Checkbox
						label={translate('samplingHistory.editAnalysisSelectionsModal.inactiveAnalyses', 'Inactive Analyses')}
						bind:checked={showInactiveAnalyses}
					/>
					<Checkbox
						label={translate('samplingHistory.editAnalysisSelectionsModal.productionDocuments', 'Production Documents')}
						title={translate(
							'samplingHistory.editAnalysisSelectionsModal.productionDocumentsTitle',
							'Search recipes performed on production work orders',
						)}
						bind:checked={showProductionDocuments}
					/>
					<Checkbox
						label={translate('samplingHistory.editAnalysisSelectionsModal.testingDocuments', 'Testing Documents')}
						title={translate(
							'samplingHistory.editAnalysisSelectionsModal.testingDocumentsTitle',
							'Search samples performed on testing work orders',
						)}
						bind:checked={showTestingDocuments}
					/>
				</div>
				<div class="col-auto">
					<Select
						label={translate('samplingHistory.editAnalysisSelectionsModal.resultStatus', 'Result Status')}
						emptyText={translate('common:all', 'All')}
						options={acceptabilityList}
						bind:value={defaultResultStatus}
						onchange={() => {
							selectedAnalyses.forEach(analysis => {
								if (!analysis.resultStatus) {
									analysis.resultStatus = defaultResultStatus ?? undefined
								}
							})
						}}
					>
						{#snippet option({ option })}
							<option value={option.key}>{option.label}</option>
						{/snippet}
					</Select>
				</div>
				<div class="col-auto pb-1">
					<Checkbox
						label={translate('samplingHistory.editAnalysisSelectionsModal.matchFilters', 'Match Filters')}
						type="radio"
						btnGroupClass="w-100"
						trueLabel={translate('common:all', 'All')}
						falseLabel={translate('common:any', 'Any')}
						bind:checked={matchAllOptionFilters}
					/>
				</div>
			</div>
		{/snippet}
		{#snippet children({ row: analysis })}
			{@const analysisIndex = analysis.unfilteredIndex}
			<tr
				class:text-danger={!analysis.active}
				data-selected={analysis.selected}
			>
				<td>
					<div class="d-flex justify-content-between align-items-start">
						<input
							type="checkbox"
							checked={analysis.selected}
							onchange={() => selectAnalysis(analysis)}
						/>
						{#if analysis.selected}
							<Button
								size="xs"
								outline={!analysis.allOptionsSelected}
								iconClass="check-double"
								title={translate(
									'samplingHistory.editAnalysisSelectionsModal.toggleAllOptionsForAnalysis ',
									'Toggle all options for {{- analysis}}',
									{ analysis: analysis.name },
								)}
								onclick={() => toggleAllOptionsForAnalysis(analysis.id)}
							></Button>
						{/if}
					</div>
				</td>
				<td>{analysis.name}</td>
				<td>
					<Select
						label={translate('common:acceptability', 'Acceptability')}
						emptyText={translate('common:all', 'All')}
						showLabel={false}
						options={acceptabilityList}
						value={analysis.resultStatus ?? null}
						onchange={event => modalSelectAnalysisResultStatus(event, analysis.id)}
					>
						{#snippet option({ option })}
							<option value={option.key}>{option.label}</option>
						{/snippet}
					</Select>
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
			{#if analysis.selected}
				{#each analysis.options as analysisOption, optionIndex (analysisOption.id)}
					{@const valueTypeObject = optionValueTypes[analysisOption.valueType]}
					<tr>
						<td>
							<div class="d-flex justify-content-end">
								<input
									type="checkbox"
									checked={analysisOption.selected}
									onchange={() =>
										updateOptionFilterValue(analysisIndex, optionIndex, 'selected', !analysisOption.selected)}
								/>
							</div>
						</td>
						<td>{analysisOption.option}</td>
						<td>
							<Select
								emptyText={translate('common:all', 'All')}
								showLabel={false}
								options={acceptabilityList}
								value={analysisOption.resultStatus ?? null}
								onchange={event =>
									updateOptionFilterValue(analysisIndex, optionIndex, 'resultStatus', getEventResultStatus(event))}
							>
								{#snippet option({ option })}
									<option value={option.key}>{option.label}</option>
								{/snippet}
							</Select>
						</td>
						<td>
							<!-- filter -->
							{#if valueTypeObject.element === 'input'}
								{#if valueTypeObject.showToField}
									<div class="input-group input-group-sm">
										<div class="input-group-prepend">
											<span class="input-group-text"
												><Icon
													fixedWidth
													icon="greater-than-equal"
												></Icon>
											</span>
										</div>
										<input
											class="form-control"
											type={valueTypeObject.type}
											inputmode={valueTypeObject.inputMode}
											value={analysisOption.valueFilterFrom}
											onchange={event =>
												updateOptionFilterValue(analysisIndex, optionIndex, 'valueFilterFrom', getEventValue(event))}
										/>
									</div>
								{:else}
									<div class="input-group input-group-sm">
										<div class="input-group-prepend">
											<span class="input-group-text">
												<Icon
													fixedWidth
													icon="equals"
												></Icon>
											</span>
										</div>
										<input
											class="form-control form-control-sm"
											type={valueTypeObject.type}
											inputmode={valueTypeObject.inputMode}
											value={analysisOption.valueFilter}
											onchange={event =>
												updateOptionFilterValue(analysisIndex, optionIndex, 'valueFilter', getEventValue(event))}
										/>
									</div>
								{/if}
							{:else if valueTypeObject.element === 'select'}
								<select
									class="custom-select custom-select-sm"
									value={analysisOption.valueFilter}
									onchange={event =>
										updateOptionFilterValue(analysisIndex, optionIndex, 'valueFilter', getEventValue(event))}
								>
									<!-- empty option; intentionally left blank -->
									<option></option>
									{#if analysisOption.valueType === 'BOOLEAN'}
										<option value="True">{translate('common:true', 'True')}</option>
										<option value="False">{translate('common:false', 'False')}</option>
									{:else if analysisOption.valueType === 'CHOICE'}
										{#each analysisOption.choices as { choice }}
											{#if choice}
												<option value={choice}>{choice}</option>
											{/if}
										{/each}
									{/if}
								</select>
							{/if}
						</td>
						<td>
							<!-- filter to -->
							{#if valueTypeObject.showToField && valueTypeObject.element === 'input'}
								<div class="input-group input-group-sm">
									<div class="input-group-prepend">
										<span class="input-group-text">
											<Icon
												fixedWidth
												icon="less-than-equal"
											></Icon>
										</span>
									</div>
									<input
										class="form-control form-control-sm"
										type={valueTypeObject.type}
										inputmode={valueTypeObject.inputMode}
										value={analysisOption.valueFilterTo}
										onchange={event =>
											updateOptionFilterValue(analysisIndex, optionIndex, 'valueFilterTo', getEventValue(event))}
									/>
								</div>
							{/if}
						</td>
						{#if analysis.analysisType === 'RECIPE'}
							<td>
								<!-- lot number -->
								<input
									class="form-control form-control-sm"
									type="text"
									value={analysisOption.lotNumber}
									onchange={event =>
										updateOptionFilterValue(analysisIndex, optionIndex, 'lotNumber', getEventValue(event))}
								/>
							</td>
							<td>
								<!-- expiration date -->
								<input
									class="form-control form-control-sm"
									type="date"
									value={analysisOption.expirationFilterFrom}
									onchange={event =>
										updateOptionFilterValue(analysisIndex, optionIndex, 'expirationFilterFrom', getEventValue(event))}
								/>
							</td>
							<td>
								<!-- expiration date to -->
								<input
									class="form-control form-control-sm"
									type="date"
									value={analysisOption.expirationFilterTo}
									onchange={event =>
										updateOptionFilterValue(analysisIndex, optionIndex, 'expirationFilterTo', getEventValue(event))}
								/>
							</td>
						{:else}
							<td></td>
							<td></td>
							<td></td>
						{/if}
					</tr>
				{/each}
			{/if}
		{/snippet}
	</Table>
	{#snippet footer()}
		<div class="m-1">
			<Button
				outline
				color="secondary"
				iconClass="broom"
				onclick={clearAnalysisSelections}
				>{translate('samplingHistory.editAnalysisSelectionsModal.clearAnalysisSelections', 'Clear Analysis Selections')}
			</Button>
			<Button
				outline
				iconClass="check-double"
				title={translate(
					'samplingHistory.analysesModal.selectAllVisibleTitle',
					'Select all analyses matching the current filter',
				)}
				onclick={() => {
					filteredAnalyses.forEach(analysis => {
						if (!analysis.selected) {
							selectAnalysis(analysis)
						} else {
							toggleAllOptionsForAnalysis(analysis.id, true)
						}
					})
				}}
				>{translate('samplingHistory.analysesModal.selectAllVisibleButton', 'Select All (Visible)')}
			</Button>
		</div>
	{/snippet}
</Modal>
