<script lang="ts">
	import type { i18n } from 'types/common'
	import type { DocumentStatus$options } from '$houdini/graphql'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Sample, SampleValue, DisplaySampleValue, DisplaySample, WoDocumentStore } from '../work-order'
	import type { Writable } from 'svelte/store'

	import splitAction from 'utility/split-action'
	import session from 'stores/session'
	import { getContext, onMount, untrack, type ComponentProps } from 'svelte'
	import SampleTable from './SampleTable.svelte'
	import { Table, Td, type Column } from '@isoftdata/svelte-table'
	import Input from '@isoftdata/svelte-input'
	import OptionValueInput from './OptionValueInput.svelte'
	import { getDefaultSampleValuesList } from 'utility/get-default-sample-values'
	import SampleListGroup from './SampleListGroup.svelte'
	import { formatDateTimeForInput } from 'utility/timezone'

	interface Props extends Omit<ComponentProps<typeof SampleTable>, 'mode'> {
		//
	}

	let {
		workOrder = $bindable(),
		selectedRowIds = $bindable(),
		showProductLocationImages,
		samplesPerPage,
		optionsColumns,
		displaySamples,
		closedSampleCount,
		initialSampleStatuses,
		sampleContextMenu,
		contextMenuSample = $bindable(),
		selectedSample = $bindable(),
		hasMultipleAnalyses,
		canEditCurrentWorkOrder,
		showAttachmentsModal = $bindable(),
		optionsToShow,
		hasMultiplePlants,
	}: Props = $props()

	const sampleViewMode = getContext<Writable<'TABLE' | 'LIST'>>('sampleViewMode')
	const computeCanEditSampleValue =
		getContext<
			(
				canEditWo: boolean,
				sampleStatus: DocumentStatus$options,
				sample: DisplaySample,
				sampleValue: SampleValue,
			) => boolean
		>('computeCanEditSampleValue')
	const confirmActionThatRequiresReVerify = getContext<() => boolean>('confirmActionThatRequiresReVerify')
	const sampleCrudStore = getContext<CrudStore<Sample, 'uuid'>>('sampleCrudStore')
	const woDocumentStore = getContext<WoDocumentStore>('woDocumentStore')
	const showClosedSamples = getContext<Writable<boolean>>('showClosedSamples')
	const showOnlyApplicableThresholds = getContext<Writable<boolean>>('showOnlyApplicableThresholds')
	const allowShowThresholdsTable = getContext<boolean>('allowShowThresholdsTable')
	const showModifiedIcons = getContext<Writable<boolean>>('showModifiedIcons')
	const { t: translate } = getContext<i18n>('i18next')
	const columns: Array<Column<DisplaySampleValue>> = [
		{
			name: 'Name',
			property: 'analysisOption[option]',
		},
		{
			name: 'Ingredient',
			property: 'analysisOption[product][name]',
		},
		{
			name: 'Ideal',
			property: 'defaultValue',
		},
		{
			name: 'Value',
			property: 'result',
			minWidth: '125px',
		},
		{
			name: 'Modified By',
			property: 'lastModifiedByUser[name]',
		},
		{
			name: 'Modified On',
			property: 'lastModified',
		},
	]

	const sampleValues = $derived(selectedSample?.sampleValues.filter(sv => !!sv) ?? [])

	const samplePlantId = $derived(selectedSample?.plant?.id ?? null)

	async function getDefaultSampleValues(sample: Sample, index: number) {
		sample.sampleValues = await getDefaultSampleValuesList(workOrder, sample)
		workOrder.samples[index] = sample
	}

	// $inspect(selectedSample?.sampleValues).with((_type, sampleValues) => {
	// 	console.table(
	// 		sampleValues?.filter(Boolean).map(value => ({
	// 			option: value?.analysisOption.option,
	// 			ideal: value?.defaultValue,
	// 			value: value?.result,
	// 		})),
	// 	)
	// })

	$effect(() => {
		if (selectedSample) {
			untrack(() => {
				getDefaultSampleValues(workOrder.samples[selectedSample!.unfilteredIndex], selectedSample!.unfilteredIndex)
			})
		}
	})

	onMount(() => {
		// I guess this stuff only matters for the table mode
		if ($sampleViewMode === 'TABLE') {
			// select the first sample if there isn't one selected already
			if (!selectedRowIds.length && workOrder.samples.length) {
				selectedRowIds = [workOrder.samples[0].uuid]
			}
			if (!selectedSample && selectedRowIds.length) {
				const sample = displaySamples.find(sample => sample.uuid === selectedRowIds[0])
				if (sample) {
					selectedSample = sample
				}
			}
		}
	})
</script>

{#if $sampleViewMode === 'TABLE'}
	<div
		use:splitAction={{
			localStorageKey: 'work-order-edit-production-samples-split',
			defaultSizes: [30, 70],
			userAccountId: $session.userAccountId,
			minSize: 250,
		}}
	>
		<div class="card">
			<div class="card-header"><h6 class="mb-0">{translate('common:products', 'Products')}</h6></div>
			<div class="card-body">
				<SampleTable
					{samplesPerPage}
					{optionsColumns}
					mode={'RECIPE'}
					bind:workOrder
					bind:selectedRowIds
					bind:selectedSample
					bind:contextMenuSample
					{showProductLocationImages}
					{displaySamples}
					{closedSampleCount}
					{initialSampleStatuses}
					{sampleContextMenu}
					{hasMultipleAnalyses}
					bind:showAttachmentsModal
					{canEditCurrentWorkOrder}
					{optionsToShow}
					{hasMultiplePlants}
				></SampleTable>
			</div>
			<!-- <div class="card-footer"></div> -->
			<div class="card-header border-top">
				<h6 class="mb-0">{translate('workOrder.recipeInstructionsCardHeader', 'Recipe Instructions')}</h6>
			</div>

			<div class="card-body p-0 d-flex flex-column">
				<textarea
					readonly
					class="form-control border-0 flex-grow-1"
					value={selectedSample?.analysis?.instructions ?? translate('common:notApplicableAbbreviation', 'N/A')}
				></textarea>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<h6 class="mb-0">{translate('workOrder.ingredientsDirectionsCardHeader', 'Ingredients & Directions')}</h6>
			</div>
			<div class="card-body">
				<!-- This table refuses to work with column resizing. Figure that one out later.
				 It's not because of the split action, at the very least 🤷
				-->
				<Table
					responsive
					columnHidingEnabled
					localStorageKey="work-order-edit-production-samples-ingredients-and-directions"
					rows={sampleValues}
					parentClass="mh-60vh"
					{columns}
				>
					{#snippet children({ row: sampleValue })}
						{#if selectedSample && samplePlantId}
							{@const initialSampleStatus = initialSampleStatuses[sampleValue.uuid]}
							{@const svOgIndex = sampleValue.svOgIndex}
							<tr class="text-nowrap lot-row">
								<Td property="analysisOption[option]">{sampleValue.analysisOption.option}</Td>
								<Td property="analysisOption[product][name]">{sampleValue.analysisOption.product?.name}</Td>
								<Td property="defaultValue">{sampleValue.defaultValue}</Td>
								<Td property="result">
									<OptionValueInput
										mode="RECIPE"
										{allowShowThresholdsTable}
										disabled={!computeCanEditSampleValue(
											canEditCurrentWorkOrder,
											initialSampleStatus,
											selectedSample,
											sampleValue,
										)}
										sample={selectedSample}
										bind:sampleValue={workOrder.samples[selectedSample.unfilteredIndex].sampleValues[svOgIndex]}
										showLabel={false}
										showModifiedIcons={$showModifiedIcons}
										{workOrder}
										bind:showOnlyApplicableThresholds={$showOnlyApplicableThresholds}
										change={({ value }) => {
											if (!confirmActionThatRequiresReVerify() || !selectedSample) {
												return
											}
											workOrder.samples[selectedSample.unfilteredIndex].sampleValues[svOgIndex].result = value
											if (workOrder.samples[selectedSample.unfilteredIndex].status === 'OPEN') {
												workOrder.samples[selectedSample.unfilteredIndex].status = 'SAMPLED'
											}
											sampleCrudStore.update(workOrder.samples[selectedSample.unfilteredIndex])
											getDefaultSampleValues(
												workOrder.samples[selectedSample.unfilteredIndex],
												selectedSample.unfilteredIndex,
											)
											if (sampleValue?.id) {
												woDocumentStore.sampleValueUpdated(sampleValue.id)
											}
										}}
									></OptionValueInput>
								</Td>
								<Td property="lastModifiedByUser[name]">{sampleValue.lastModifiedByUser?.name}</Td>
								<Td property="lastModified">
									<Input
										readonly
										showLabel={false}
										type="datetime-local"
										value={formatDateTimeForInput(sampleValue.lastModified, $session.plant.timezone)}
									></Input>
								</Td>
							</tr>
						{/if}
					{/snippet}
					{#snippet noRows({ visibleColumnsCount })}
						<tr>
							<td
								colspan={visibleColumnsCount}
								class="text-center"
								>{#if !selectedSample}{translate('workOrder.noSampleSelected', 'Select a sample')}{:else}{translate(
										'workOrder.noIngredientsDirections',
										'No ingredients or directions',
									)}{/if}</td
							>
						</tr>
					{/snippet}
				</Table>
			</div>
		</div>
	</div>
{:else}
	<SampleListGroup
		mode="RECIPE"
		bind:workOrder
		bind:selectedRowIds
		bind:showClosedSamples={$showClosedSamples}
		{showProductLocationImages}
	></SampleListGroup>
{/if}
