import {
	graphql,
	type LoadProducts$result,
	type ProductSpecifications$result,
	type LoadProductsPage$result,
	type LoadProductAnalyses$result,
	type LoadProductAnalysisOptions$result,
	type ProductBatchesBatches$result,
	LoadProducts$input,
	NewProductBatch,
	NewAnalysisOptionChoice,
	AnalysisOptionChoiceUpdate,
} from '$houdini'
import { treeify, type TreeNode } from '@isoftdata/svelte-table'
import type { MetaTag } from 'components/TagSelection.svelte'
import { v4 as uuid } from '@lukeed/uuid'
import hasPermission from './has-permission'
import { Simplify, WritableDeep, type Merge } from 'type-fest'
import type { MetaSpecification } from 'components/ConfigureSpecificationCard.svelte'

export type Product = LoadProducts$result['products']['data'][number]
export type Plant = LoadProductsPage$result['plants']['data'][number]
export type SeverityClass = LoadProductsPage$result['severityClasses']['data'][number]
export type ProductSpecification = Simplify<
	WritableDeep<
		Omit<ProductSpecifications$result['analysisOptionChoices']['data'][number], 'id'> & {
			id: number | null
			uuid: string
			productUuid: string
			delete?: unknown
		}
	>
>
export type Analysis = WritableDeep<LoadProductAnalyses$result['analyses']['data'][number]>
export type AnalysisOption = LoadProductAnalysisOptions$result['analysisOptions']['data'][number]

export type UuidProduct = Merge<
	Omit<Product, 'attachments' | 'tags'>,
	{
		uuid: string
		attachments: Array<ProductAttachment>
		imageAttachments: Array<ProductAttachment>
		thumbnailPath: string | null
		inUse: boolean | undefined
		tags: Array<MetaTag>
	}
>

export type ProductNode = Merge<
	TreeNode<
		Omit<Product, 'id' | 'severityClass' | 'tags' | 'attachments'> & {
			id: number | null
			uuid: string
			attachments: ProductAttachment[]
			imageAttachments: ProductAttachment[]
			thumbnailPath: string | null
			tags: Array<MetaTag>
			inUse: boolean | undefined
			parentProductUuid: string | null
		},
		'uuid',
		'parentProductUuid'
	>,
	{ dirty?: unknown }
>

export type ProductAttachment = {
	/** New files will have a File object on them*/
	File?: File
	productId: number | null
	productUuid: string
	mimeType: string
	name: string
	path: string
	public: boolean
	rank: number
	size: number
	uuid: string
	fileId?: number
}

export type AnalysisOptionChange = {
	analysisOptionChoiceIdsToRemove: number[]
	analysisOptionChoicesToAdd: NewAnalysisOptionChoice[]
	analysisOptionChoicesToUpdate: AnalysisOptionChoiceUpdate[]
}

export function canEditPlantSpecificFieldsCheck(plantId: number) {
	return hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)
}

export function makeCanEditGlobalFieldsMap(
	products: Array<Pick<Product, 'id' | 'inUseAtPlantIDs'>>,
	thisPlantId: number,
) {
	return products.reduce(
		(acc: Map<number, boolean>, product) => {
			if (product.id && product.inUseAtPlantIDs) {
				acc.set(
					product.id,
					product.inUseAtPlantIDs.every(plantId => hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)),
				)
			}

			return acc
		},
		new Map([[-1, hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', thisPlantId)]]),
	) // -1 = selected plant only
}

export function makePermissionFunctions(products: Array<Pick<Product, 'id' | 'inUseAtPlantIDs'>>, plantId: number) {
	const canEditGlobalFieldsMap = makeCanEditGlobalFieldsMap(products, plantId)
	return {
		canEditGlobalFields: (productId?: number | null) => canEditGlobalFieldsCheck(canEditGlobalFieldsMap, productId),
		canEditPlantSpecificFields: () => canEditPlantSpecificFieldsCheck(plantId),
	}
}

export function canEditGlobalFieldsCheck(map: Map<number, boolean>, productId: number | null = null) {
	return map.get(productId ?? -1) ?? false
}

export async function checkProductReferenceCounts(productIds: number[]) {
	const zeroCountObject = {
		analysisCount: 0,
		childProductCount: 0,
		productBatchCount: 0,
		sampleCount: 0,
	}
	const { data } = await productReferenceCountsQuery.fetch({
		variables: {
			productIds,
		},
	})

	if (!data) {
		return zeroCountObject
	}

	const total = data.getProductReferenceCounts.reduce((count, cur) => {
		return {
			childProductCount: count.childProductCount + cur.childProductCount,
			analysisCount: count.analysisCount + cur.analysisCount,
			productBatchCount: count.productBatchCount + cur.productBatchCount,
			sampleCount: count.sampleCount + cur.sampleCount,
		}
	}, zeroCountObject)

	return total
}

export async function loadProducts(
	filter: LoadProducts$input['filter'],
	selectedPlantId: number,
	tagsWithUuids: MetaTag[],
	orderBy?: LoadProducts$input['orderBy'],
): Promise<{ uuidMap: Record<number, string>; productsTree: ProductNode[]; products: Product[] }> {
	const { data } = await loadProductsQuery.fetch({
		variables: {
			filter,
			pagination: {
				pageSize: 0,
			},
			orderBy: orderBy ?? ['NAME_ASC'],
		},
	})

	if (!data) {
		return {
			uuidMap: {},
			productsTree: [],
			products: [],
		}
	}

	const fileBaseUrl = '__apiUrl__'.replace('/graphql', '')

	const { uuidMap, uuidProducts } = data.products.data.reduce(
		({ uuidMap, uuidProducts }: { uuidMap: Record<number, string>; uuidProducts: Array<UuidProduct> }, product) => {
			const theUuid = uuid()
			uuidMap[product.id] = theUuid
			const attachments = product.attachments.map(attachment => ({
				...attachment,
				...attachment.file,
				productId: product.id,
				path: `${fileBaseUrl}${attachment.file.path}`,
				uuid: uuid(),
				productUuid: theUuid,
				rank: attachment.rank ?? 0,
				size: attachment.file.size ?? 0,
			}))
			const imageAttachments = attachments.filter(attachment => attachment.mimeType.startsWith('image/'))
			const foundTagsWithUuids: MetaTag[] = []
			if (product.tags) {
				product.tags.forEach(fetchedTag => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					foundTagsWithUuids.push(
						tagsWithUuids.find(tag => fetchedTag.id === tag?.id) ?? { ...fetchedTag, uuid: uuid() },
					)
				})
			}
			uuidProducts.push({
				...product,
				attachments,
				imageAttachments,
				thumbnailPath: imageAttachments[0]?.path ?? null,
				inUse: product.inUseAtPlantIDs.includes(selectedPlantId),
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				tags: foundTagsWithUuids.sort((a, b) => a.name.length - b.name.length) ?? [],
				uuid: theUuid,
			})
			return { uuidMap, uuidProducts }
		},
		{ uuidMap: {}, uuidProducts: [] },
	)
	const productsTree = treeify(
		uuidProducts.map((product: UuidProduct) => {
			const parentId: number | null = product.parentProductId
			return {
				...product,
				parentProductUuid: (parentId && uuidMap[parentId]) ?? null,
			}
		}),
		'uuid',
		'parentProductUuid',
	) as ProductNode[] // This is lying to TS but it makes the recursive children typeing correct. Tecnically its not a ProductNode[] but a TreeNode<Product>[]

	return {
		uuidMap,
		productsTree,
		products: data.products.data,
	}
}

export type Batch = Simplify<
	Merge<
		WritableDeep<ProductBatchesBatches$result['productBatches']['data'][number]>,
		{
			id: number | null
			productId: number | null
			productUuid: string
			uuid: string
		}
	>
>

export function formatNewProductBatch(
	batch: Batch & { productId: number },
	analysisChangesForBatch?: AnalysisOptionChange,
): Omit<Required<NewProductBatch>, 'quantity'> {
	return {
		productId: batch.productId,
		plantId: batch.plantId,
		description: batch.description,
		end: batch.end,
		locationId: batch.location?.id ?? null,
		start: batch.start,
		name: batch.name,
		status: batch.status,
		expiration: batch.expiration,
		analysisOptionChoicesToAdd: analysisChangesForBatch?.analysisOptionChoicesToAdd ?? [],
		analysisOptionChoicesToUpdate: analysisChangesForBatch?.analysisOptionChoicesToUpdate ?? [],
		analysisOptionChoiceIdsToRemove: analysisChangesForBatch?.analysisOptionChoiceIdsToRemove ?? [],
	}
}

/** Have to remember specifications by product and by batch (if applicable)
 *
 * This is never used in a reactive context, so we can just use a simple object instead of a store
 */
export function makeSpecificationCache(): SpecificationCache {
	const cache: Record<string, Record<string, MetaSpecification[]>> = {}

	function set(productUuid: string, specs: MetaSpecification[]): void
	function set(productUuid: string, batchUuid: string | null, specs: MetaSpecification[]): void
	function set(
		productUuid: string,
		batchUuidOrSpecs: string | MetaSpecification[] | null,
		specs?: MetaSpecification[],
	) {
		// if second arg is array, it's the specs and batchUuid is null
		if (Array.isArray(batchUuidOrSpecs)) {
			specs = batchUuidOrSpecs
			batchUuidOrSpecs = null
		}

		if (!cache[productUuid]) {
			cache[productUuid] = {}
		}

		// This might cache empty arrays, but that'll keep us from doing more lookups, (which will return []) so that's fine
		cache[productUuid][batchUuidOrSpecs ?? '-1'] = specs ?? []
	}

	return {
		get(productUuid: string, batchUuid: string | null = null): MetaSpecification[] {
			return cache[productUuid]?.[batchUuid ?? '-1'] ?? []
		},
		has(productUuid: string, batchUuid: string | null = null): boolean {
			return !!cache[productUuid]?.[batchUuid ?? '-1']?.length
		},
		set,
		delete(productUuid: string, batchUuid: string | null = null): void {
			delete cache[productUuid]?.[batchUuid ?? '-1']
			console.log('delete', productUuid, batchUuid, cache[productUuid]?.[batchUuid ?? '-1'])
		},
	}
}

export type SpecificationCache = {
	get(productUuid: string, batchUuid?: string | null): MetaSpecification[]
	has(productUuid: string, batchUuid?: string | null): boolean
	set(productUuid: string, specs: MetaSpecification[]): void
	set(productUuid: string, batchUuid: string | null, specs: MetaSpecification[]): void
	set(productUuid: string, batchUuidOrSpecs: string | MetaSpecification[] | null, specs?: MetaSpecification[]): void
	delete(productUuid: string, batchUuid?: string | null): void
}

export const loadProductsPageQuery = graphql(`
	query LoadProductsPage(
		$pagination: PaginatedInput
		$filter: EntityTagFilter
		$severityClassFilter: SeverityClassFilter
	) {
		plants(pagination: $pagination) {
			data {
				id
				code
				name
				private
			}
			info {
				pageSize
				pageNumber
			}
		}
		entityTags(filter: $filter) {
			name
			active
			entityType
			id
		}
		severityClasses(filter: $severityClassFilter, pagination: $pagination) {
			data {
				id
				name
				description
				default
				plantId
			}
		}
		analyses(pagination: $pagination) {
			data {
				id
				name
				inUseAtPlantIDs
				options {
					id
					option
					valueType
				}
			}
		}
		productCategories
	}
`)

export const loadAnalysisQuery = graphql(`
	query LoadProductAnalyses($filter: AnalysisFilter, $pagination: PaginatedInput) {
		analyses(filter: $filter, pagination: $pagination) {
			data {
				id
				name
				inUseAtPlantIDs
				options {
					id
					option
					valueType
				}
			}
		}
	}
`)

export const analysisOptionsQuery = graphql(`
	query LoadProductAnalysisOptions($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
		analysisOptions(filter: $filter, pagination: $pagination) {
			data {
				id
				active
				analysisId
				option
				unit
				valueType
				defaultValue
				defaultType
				thresholdType
				requiredToPerform
				requiredToClose
				informational
				rank
				productId
				rules {
					id
					analysisOptionId
					active
					restriction
					outcome
					description
					created
					tags {
						id
						name
						entityType
						active
					}
				}
			}
		}
	}
`)

export const loadProductsQuery = graphql(`
	query LoadProducts($filter: ProductFilter, $pagination: PaginatedInput, $orderBy: [ProductOrderBy!]) {
		products(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			data {
				id
				active
				barcodeFormat
				category
				description
				inUseAtPlantIDs
				itemNumber
				name
				parentProductId
				productType
				supplierItemNumber
				unit
				unitConversion
				tags {
					id
					name
					entityType
					active
				}
				attachmentCount
				attachments {
					id
					public
					rank
					fileId
					file {
						path
						name
						created
						updated
						type
						hash
						mimeType
						size
					}
				}
			}
		}
	}
`)

export const loadProductSpecificationsQuery = graphql(`
	query ProductSpecifications($filter: AnalysisOptionChoiceFilter, $pagination: PaginatedInput) {
		analysisOptionChoices(filter: $filter, pagination: $pagination) {
			data {
				id
				plantId
				productId
				choice
				constraint
				boundaryType
				requiredAnalysisOption {
					id
					option
					valueType
					analysis {
						id
						name
					}
				}
				requiredConstraint
				requiredChoice
				productBatchId
				severityClass {
					id
					name
				}
				analysisOption {
					id
					option
					valueType
					analysis {
						id
						name
					}
				}
			}
		}
	}
`)

const productReferenceCountsQuery = graphql(`
	query GetProductReferenceCounts($productIds: [NonNegativeInt!]!) {
		getProductReferenceCounts(productIds: $productIds) {
			analysisCount
			childProductCount
			productBatchCount
			sampleCount
		}
	}
`)

export const createTagsMutation = graphql(`
	mutation CreateProductEntityTags($input: [NewEntityTag!]!) {
		createEntityTags(input: $input) {
			active
			entityType
			id
			name
		}
	}
`)

export const updateTagsMutation = graphql(`
	mutation UpdateProductEntityTags($input: [EntityTagUpdate!]!) {
		updateEntityTags(input: $input) {
			id
		}
	}
`)

export const deleteTagsMutation = graphql(`
	mutation DeleteProductEntityTags($ids: [PositiveInt!]!) {
		deleteEntityTags(ids: $ids)
	}
`)

export const createAndUpdateProductsMutation = graphql(`
	mutation CreateAndUpdateProducts($products: [NewUpdateProduct!]!) {
		createAndUpdateProducts(products: $products) {
			id
			active
			category
			description
			inUseAtPlantIDs
			name
			parentProductId
			productType
			tags {
				id
				name
				entityType
				active
			}
			attachmentCount
			attachments {
				id
				public
				rank
				fileId
				file {
					path
					name
					created
					updated
					type
					hash
					mimeType
					size
				}
			}
		}
	}
`)

export const deleteProductsMutation = graphql(`
	mutation DeleteProducts($ids: [PositiveInt!]!) {
		deleteProducts(ids: $ids)
	}
`)

export const attachFileToProductMutation = graphql(`
	mutation AttachFileToProduct($input: NewProductFile!) {
		attachFilesToProduct(input: $input) {
			id
		}
	}
`)

export const detachFilesFromProductMutation = graphql(`
	mutation DetachFilesFromProduct($productId: PositiveInt!, $fileIds: [PositiveInt!]!) {
		detachFilesFromProduct(productId: $productId, fileIds: $fileIds)
	}
`)
