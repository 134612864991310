import type { ComponentProps } from 'svelte'
import type { AppContext } from 'types/common'

import component from './Home.svelte'
import { getSession } from 'stores/session'
import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

type Tabs = ComponentProps<AsrNavTabBar>['tabs']

export default function ({ stateRouter, i18next, hasPermission }: AppContext) {
	stateRouter.addState({
		name: 'app.home',
		route: 'home',
		defaultChild: 'dashboard',
		template: component,
		resolve(_data, _parameters) {
			const session = getSession()

			const tabs: Tabs = [
				{
					name: 'app.home.dashboard',
					title: i18next.t('home.titles.dashboard', 'Dashboard'),
					iconClass: 'chart-line',
				},
				{
					name: 'app.home.work-order-list',
					title: i18next.t('home.titles.work-order-list', 'Work Orders'),
					iconClass: 'clipboard-list-check',
					disabled: !hasPermission('WORK_ORDERS_CAN_VIEW', session.siteId),
				},
			]

			return Promise.resolve({ tabs })
		},
	})
}
