<script lang="ts">
	import { type ComponentProps, onDestroy, onMount, tick, getContext } from 'svelte'
	import { type Writable, writable } from 'svelte/store'
	import type { SvelteAsr, Mediator } from 'types/common'
	import type { Plant, SeverityClass, ProcessZone, ProductProximity } from '../Locations.svelte'
	import type { Column, IndexedRowProps } from '@isoftdata/svelte-table'
	import type { MetaTag } from 'components/TagSelection.svelte'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { LocationNode, MetaAttachment } from '../Locations.svelte'
	import type { i18n } from 'i18next'
	import type { AddRemoveStore } from 'stores/add-remove-store'

	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import getDuplicateName from 'utility/get-duplicate-name'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Modal from '@isoftdata/svelte-modal'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import Attachments, { revokeObjectURLs, type BaseAttachmentFile } from '@isoftdata/svelte-attachments'

	type IndexedRow = LocationNode & IndexedRowProps

	import { NavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'
	import Dropdown from '@isoftdata/svelte-dropdown'
	import ExpandableBadge from 'components/ExpandableBadge.svelte'
	import Table, { Td, removeFromTree, TreeRow, upsertIntoTree } from '@isoftdata/svelte-table'
	import { createLocalStorage, localWritable, persist } from '@macfja/svelte-persistent-store'
	import hasPermission from 'utility/has-permission'
	import { graphql } from '$houdini'

	export let asr: SvelteAsr
	export let plantId: number
	export let clipboard: Writable<LocationNode | null>
	export let selectedLocation: Writable<LocationNode | null>
	export let tags: Array<MetaTag>
	export let locationCrudStore: CrudStore<LocationNode, 'uuid'>
	export let plants: Array<Plant>
	export let locations: Writable<Array<LocationNode>>
	export let allowEditing: boolean
	export let processZones: Array<ProcessZone>
	export let productProximities: Array<ProductProximity>
	export let attachmentCrudStore: CrudStore<MetaAttachment, 'uuid'>
	export let tagAddRemoveStore: AddRemoveStore
	/**[PZ id][PP id] -> SC id + name*/
	export let severityClassAssignments: Record<number, Record<number, SeverityClass>>
	export let defaultSeverityClass: SeverityClass
	export let canLeaveLocationState: Writable<boolean>

	const { t: translate } = getContext<i18n>('i18next')

	const getLocationsReferenceCounts = graphql(`
		query GetLocationsReferenceCounts($locationIds: [NonNegativeInt!]!) {
			getLocationsReferenceCounts(locationIds: $locationIds) {
				childLocationCount
				investigationTriggerCount
				productBatchCount
				sampleCount
				scheduledAnalysisCount
			}
		}
	`)

	let selectedPlant: Plant

	if (plantId) {
		selectedPlant = plants.find(p => p.id === plantId) as Plant
	}

	$: plantId = selectedPlant?.id ?? null

	let theTable: Table<LocationNode>
	let isCut = false
	// since we remove the uuid from the copied location, track it separately
	let copiedLocationUuid: string | null = null
	let showAttachmentsModal = false

	let showImageThumbnails = localWritable<boolean>('showImageThumbnails', false)
	let showImageViewer = false
	let currentPhotoIndex = 0

	$: selectedRowIds = $selectedLocation?.uuid ? [$selectedLocation?.uuid] : []
	$: selectedLocationImages = $selectedLocation?.imageAttachments.map(({ path }) => path) ?? []

	const mediator = getContext<Mediator>('mediator')

	const tabs: ComponentProps<NavTabBar>['tabs'] = [
		{
			title: translate('location.locations', 'Locations'),
			name: 'locations',
		},
		{
			// Placeholder for when we implement location visualization
			title: translate('location.visualization', 'Visualization'),
			name: 'visualization',
			hidden: true,
		},
	]

	const columns: Array<Column<LocationNode>> = [
		{
			name: translate('location.codeColumn', 'Code'),
			property: 'code',
			title: translate('location.codeColumnTitle', "The location's code. Used to construct a location's name."),
		},
		{
			name: translate('location.tagsColumn', 'Tags'),
			//@ts-expect-error This works, but I haven't made the column type allow it
			property: 'tags[length]',
			width: '1rem',
			title: translate('location.tagsColumnTitle', 'Any tags associated with the location'),
		},
		{
			name: translate('location.decriptionColumn', 'Description'),
			property: 'description',
			title: translate('location.descriptionColumnTitle', 'A description of the location'),
		},
		{
			name: translate('location.proximityColumn', 'Proximity'),
			property: 'productProximity[name]',
			title: translate(
				'location.proximityColumnTitle',
				'A product proximity is an area in a plant where proximity to manufacturerd product is similar.\r\nFor example, all areas on a conveyor belt that touches the product might share a proximity.',
			),
		},
		{
			name: translate('location.zoneColumn', 'Zone'),
			property: 'processZone[name]',
			title: translate(
				'location.zoneColumnTitle',
				'A process zone is section of a plant where location severity is similar. For example, all areas on a conveyor before a cook step might share a zone.',
			),
		},
		{
			name: '',
			icon: 'photo-video',
			property: 'attachmentCount',
			width: '1rem',
			align: 'center',
			title: translate(
				'location.attachmentCountColumnTitle',
				'The number of attachments at the location. Click to view image attachments.',
			),
		},
		{
			// Does not inherit or go up the tree
			name: translate('location.testableColumn', 'Testable'),
			property: 'testable',
			width: '1rem',
			align: 'center',
			title: translate('location.testableColumnTitle', 'Whether the location can be sampled.'),
		},
		{
			// Does not inherit or go up the tree
			name: translate('location.activeColumn', 'Active'),
			property: 'active',
			width: '1rem',
			align: 'center',
			title: translate(
				'location.activeColumnTitle',
				"Whether the location is active. Inactive locations don't show up in most areas.",
			),
		},
	]
	let selectedTab: 'locations' | 'visualization' = 'locations'
	$: showTabs = tabs.filter(tab => !tab.hidden).length > 1

	async function onNewLocation(target: 'TOP' | 'PARENT' | 'SIBLING' | 'CHILD') {
		if (!$selectedLocation) {
			target = 'TOP'
		}
		// Get new parent location (null for top-level locations)
		let newParent: LocationNode | null = null
		switch (target) {
			case 'TOP':
				break
			case 'PARENT':
			case 'SIBLING':
				newParent = $selectedLocation?.parent ?? null
				break
			case 'CHILD':
				newParent = $selectedLocation ?? null
				break
			default:
				throw new Error(`Invalid value for 'target': ${target} When calling onNewLocation.`)
		}
		// "copy" the selected location, or the template if no location is selected
		const emptyLocation: Readonly<LocationNode> = Object.freeze({
			id: null,
			uuid: uuid(),
			code: '1',
			description: '',
			tags: [],
			parentLocationId: null,
			parentLocationUuid: null,
			plantId,
			productProximity: null,
			processZone: null,
			severityClass: null,
			testable: true,
			active: true,
			children: [],
			parent: null,
			attachmentCount: 0,
			location: '1',
			attachments: [],
			imageAttachments: [],
			thumbnailPath: null,
		})

		const newLocation = copyLocation($selectedLocation ?? emptyLocation, newParent, true)
		// Move stuff around if we're inserting as parent of selected location
		if (target === 'PARENT' && $selectedLocation) {
			// Get parent's children (now this location's children)
			newLocation.children = newParent?.children.map(child => cutLocationAndChildren(child, newLocation)) ?? [
				cutLocationAndChildren($selectedLocation, newLocation),
			]
			// Clear newParent's children (newLocation will be added later)
			if (newParent) {
				newParent.children = []
			} else {
				// If newParent is null, we're inserting at the top level, so remove $selectedLocation
				$locations = removeFromTree($locations, $selectedLocation, 'uuid', 'parentLocationUuid', false)
			}
			// Add all freshly moved children to crud store (only top layer's parent has changed)
			// Should keep all "new" children in the "created" object
			newLocation.children.forEach(child => locationCrudStore.update(child))
		}
		// Make unique after we possibly move children around
		const uniqueLocation = makeLocationUnique(newLocation, newParent?.children)
		// Insert new location into tree
		$locations = upsertIntoTree($locations, uniqueLocation, 'uuid', 'parentLocationUuid')

		// Insert new location into crud store
		locationCrudStore.create(uniqueLocation)
		await tick()
		$selectedLocation = uniqueLocation
		if (newParent) {
			theTable.expandRow(newParent.uuid)
		}
		document
			.querySelector(`tr[data-id="${uniqueLocation.uuid}"]`)
			?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		asr.go('app.locations.list.detail', { locationId: $selectedLocation?.id ?? null }, { inherit: true })
	}

	function makeLocationUnique(location: LocationNode, siblings: Array<LocationNode> = $locations) {
		const thisNum = location.code.match(/(\d+)/)?.[1]
		const codeNoNumber = thisNum ? location.code.replace(thisNum, '') : location.code
		const maxNum = siblings.length
			? Math.max(
					...siblings.map(sibling => parseInt(sibling.code.match(new RegExp(`^${codeNoNumber}(\\d+)`))?.[1] ?? '0')),
				)
			: 0
		if (thisNum) {
			location.code = location.code.replace(thisNum, (maxNum + 1).toString())
			// FB-8536, also increment the same number in the description if we find it
			location.description = location.description ? location.description.replace(thisNum, (maxNum + 1).toString()) : ''
		} else {
			location.code = getDuplicateName(location, siblings, 'code')
		}

		return location
	}

	async function onLocationSelect(location: LocationNode) {
		if (location.uuid !== $selectedLocation?.uuid) {
			$selectedLocation = {
				...location,
				description: location.description ?? '',
				tags: location.tags ?? [],
			}
			await tick()
			asr.go('app.locations.list.detail', { locationId: $selectedLocation?.id ?? null }, { inherit: true })
		}
	}

	/**
	 * Sets the new parent of a location
	 */
	function cutLocationAndChildren(location: LocationNode, newParent: LocationNode | null) {
		location.parentLocationId = newParent?.id ?? null
		location.parentLocationUuid = newParent?.uuid ?? null
		location.parent = newParent

		return location
	}

	function mergeTags(targetTags: Array<MetaTag>, parentTags: Array<MetaTag>) {
		const uuids = new Set<string>(targetTags.concat(parentTags).map(tag => tag.uuid))
		return tags.filter(tag => uuids.has(tag.uuid))
	}

	/**
	 * Used for both the copy/paste feature, and inserting new locations
	 * @param location The location to copy
	 * @param parentLocation The new parent location that the copied location will be inserted into
	 * @param asNewLocation Whether to copy the parent's tags, PP, PZ, etc. into the new location (used when inserting new, not when copy/pasting)
	 */
	function copyLocation(location: LocationNode, parentLocation: LocationNode | null, asNewLocation = false) {
		// Handle PP/PZ/SC changes when copying between plants
		if (location.plantId !== plantId) {
			location = getProximityAndZoneForNewPlant(location)
		}
		// remove parent and children to avoid circular references inside of klona
		const { parent, children, ...locationToCopy } = location

		const newLocation: LocationNode = {
			...klona(locationToCopy),
			id: null,
			uuid: uuid(),
			attachmentCount: 0,
			attachments: [],
			children: new Array<LocationNode>(),
			parentLocationId: parentLocation?.id ?? null,
			parentLocationUuid: parentLocation?.uuid ?? null,
			parent: parentLocation,
			plantId: parentLocation?.plantId ?? plantId,
			// copy some stuff from the parent when inserting a new location
			tags: asNewLocation ? mergeTags(location.tags ?? [], parentLocation?.tags ?? []) : mergeTags(location.tags, []),
			// Copy PP/PZ from new parent if selected location does not have them
			productProximity: asNewLocation
				? (location.productProximity ?? parentLocation?.productProximity ?? null)
				: location.productProximity,
			processZone: asNewLocation ? (location.processZone ?? parentLocation?.processZone ?? null) : location.processZone,
		}

		// Track tags to add to the new location
		newLocation.tags.forEach(tag => {
			tagAddRemoveStore.add(newLocation.uuid, tag.uuid)
		})
		return newLocation
	}

	function copyLocationAndChildren(location: LocationNode, parentLocation: LocationNode | null) {
		const newLocation = copyLocation(location, parentLocation)
		if (Array.isArray(location.children)) {
			newLocation.children = location.children.map(child => {
				return copyLocationAndChildren(child, newLocation)
			})
		}
		return newLocation
	}

	function uuidInTree(uuid: string, tree: LocationNode) {
		if (tree.uuid === uuid) {
			return true
		}
		if (Array.isArray(tree.children)) {
			return tree.children.some(child => uuidInTree(uuid, child))
		}
		return false
	}

	function getSeverityClass(location: LocationNode) {
		if (!location.productProximity || !location.processZone) {
			return defaultSeverityClass
		}
		return severityClassAssignments[location.processZone.id][location.productProximity.id] ?? defaultSeverityClass
	}

	/**
	 * Since PP/PZ and therefore SC are plant-based, we need to update the location with the new values when we paste it into a new plant
	 * @param location The location to update
	 */
	function getProximityAndZoneForNewPlant(location: LocationNode): LocationNode {
		// If the PP/PZ have the same name in the new plant, use that one, otherwise null it out
		if (location.productProximity) {
			const ppName = location.productProximity.name
			const ppSameName = productProximities.find(pp => pp.name === ppName)
			location.productProximity = ppSameName ?? null
		}

		if (location.processZone) {
			const pzName = location.processZone.name
			const pzSameName = processZones.find(pz => pz.name === pzName)
			location.processZone = pzSameName ?? null
		}

		location.severityClass = getSeverityClass(location)

		return location
	}

	async function paste(atTopLevel = false) {
		const clipboardValue = $clipboard // can't type narrow a $store, so assign it to a const instead
		if (!clipboardValue) {
			return
		}

		// Can't cut between plants
		if (isCut && clipboardValue.plantId !== plantId) {
			isCut = false
		}

		// On first cut -> paste, don't clone, just move
		const newParent = atTopLevel ? null : ($selectedLocation ?? null)

		// Make sure we're not pasting into the tree being cut BEFORE we do any cutting/copying
		if (isCut && newParent && uuidInTree(newParent.uuid, clipboardValue)) {
			return alert(
				translate(
					'location.cannotPasteIntoTreeBeingCut',
					'Cannot paste into the tree being cut. Select another area, or use the copy operation.',
				),
			)
		}

		// remove from old location if cutting
		if (isCut) {
			$locations = removeFromTree($locations, clipboardValue, 'uuid', 'parentLocationUuid', true)
		}

		// THEN we can cut/copy
		const locationToPaste = isCut
			? cutLocationAndChildren(clipboardValue, newParent)
			: copyLocationAndChildren(clipboardValue, newParent)

		if (!newParent) {
			// top level
			locationToPaste.parentLocationId = null
			locationToPaste.parentLocationUuid = null
			locationToPaste.code = getDuplicateName(locationToPaste, $locations, 'code')
		} else if ($selectedLocation) {
			// below selected location
			locationToPaste.code = getDuplicateName(locationToPaste, $selectedLocation.children, 'code')
		}

		// reinsert into tree
		$locations = upsertIntoTree($locations, locationToPaste, 'uuid', 'parentLocationUuid')

		// Only first paste after cut should be a cut
		isCut = false

		locationCrudStore.create(locationToPaste)
		$locations = $locations
		await tick()
		$selectedLocation = locationToPaste
		if (locationToPaste.parentLocationUuid) {
			theTable?.expandRow(locationToPaste.parentLocationUuid)
		}
		document.querySelector(`tr[data-id="${locationToPaste.uuid}"]`)?.scrollIntoView({ behavior: 'smooth' })
		asr.go('app.locations.list.detail', { locationId: $selectedLocation?.id ?? null }, { inherit: true })
	}

	function getAllChildren(location: LocationNode) {
		const children = new Array<LocationNode>()
		if (Array.isArray(location.children)) {
			location.children.forEach(child => {
				children.push(child)
				getAllChildren(child).forEach(child => children.push(child))
			})
		}
		return children
	}

	function getChildrenIds(location: LocationNode) {
		const childrenIds = new Array<number>()
		if (Array.isArray(location.children)) {
			location.children.forEach(child => {
				if (child.id) {
					childrenIds.push(child.id)
				}
				getChildrenIds(child).forEach(id => childrenIds.push(id))
			})
		}
		return childrenIds
	}

	async function deleteLocation(location: LocationNode, deleteChildren = false) {
		if (!location) {
			return
		}
		let locationIds = location.id ? [location.id] : []
		let locationsToDelete = [location]
		if (deleteChildren) {
			locationIds = [...locationIds, ...getChildrenIds(location)]
			locationsToDelete = [...locationsToDelete, ...getAllChildren(location)]
		}

		function doTheDelete() {
			// Mark for deletion
			locationsToDelete.forEach(loc => locationCrudStore.delete(loc))
			// Remove from tree
			$locations = removeFromTree($locations, location, 'uuid', 'parentLocationUuid', deleteChildren)
			// Set parent IDs to null and set in update map
			if (!deleteChildren) {
				location.children.forEach(child => {
					child.parentLocationId = location?.parent?.id ?? null
					locationCrudStore.update(child)
				})
			}
		}

		if (location.id) {
			const { data } = await getLocationsReferenceCounts.fetch({ variables: { locationIds } })

			const zeroCountObject = {
				childLocationCount: 0,
				investigationTriggerCount: 0,
				productBatchCount: 0,
				sampleCount: 0,
				scheduledAnalysisCount: 0,
			}

			const total = data?.getLocationsReferenceCounts
				? data.getLocationsReferenceCounts.reduce(
						(count, cur) => ({
							childLocationCount: count.childLocationCount + cur.childLocationCount,
							investigationTriggerCount: count.investigationTriggerCount + cur.investigationTriggerCount,
							productBatchCount: count.productBatchCount + cur.productBatchCount,
							sampleCount: count.sampleCount + cur.sampleCount,
							scheduledAnalysisCount: count.scheduledAnalysisCount + cur.scheduledAnalysisCount,
						}),
						zeroCountObject,
					)
				: zeroCountObject

			const safe =
				total.childLocationCount === 0 &&
				total.investigationTriggerCount === 0 &&
				total.productBatchCount === 0 &&
				total.sampleCount === 0 &&
				total.scheduledAnalysisCount === 0
			// Absolute hell of confirm() and alert() strings because ✨ Translation ✨ requires they all be distinct.
			const confirmMessage = deleteChildren
				? translate(
						'location.confirmDeleteLocationAndChildren',
						`This location and its child locations have {{childLocationCount}} child locations, {{investigationTriggerCount}} investigation triggers, {{productBatchCount}} product batches, {{sampleCount}} samples, and {{scheduledAnalysisCount}} scheduled analyses.
All objects that reference this location will either be deleted or orphaned.
Are you sure you want to delete this location? After saving, this cannot be undone.`,
						{
							childLocationCount: total.childLocationCount,
							investigationTriggerCount: total.investigationTriggerCount,
							productBatchCount: total.productBatchCount,
							sampleCount: total.sampleCount,
							scheduledAnalysisCount: total.scheduledAnalysisCount,
						},
					)
				: translate(
						'location.confirmDeleteLocationNoChildren',
						`This location has {{childLocationCount}} child locations, {{investigationTriggerCount}} investigation triggers, {{productBatchCount}} product batches, {{sampleCount}} samples, and {{scheduledAnalysisCount}} scheduled analyses.
All objects that reference this location will either be deleted or orphaned.
Are you sure you want to delete these locations? After saving, this cannot be undone.`,
						{
							childLocationCount: total.childLocationCount,
							investigationTriggerCount: total.investigationTriggerCount,
							productBatchCount: total.productBatchCount,
							sampleCount: total.sampleCount,
							scheduledAnalysisCount: total.scheduledAnalysisCount,
						},
					)
			if (safe || (hasPermission('LOCATION_CAN_PERFORM_UNSAFE_DELETE', plantId) && confirm(confirmMessage))) {
				doTheDelete()
			} else if (!safe && !hasPermission('LOCATION_CAN_PERFORM_UNSAFE_DELETE', plantId)) {
				// If I didn't have to translate these strings, it'd be a lot easier to only list the things with count > 0
				alert(
					translate(
						'location.cannotPerformUnsaveDelete',
						`This location (and its child locations) are referenced by {{childLocationCount}} child locations, {{investigationTriggerCount}} investigation triggers, {{productBatchCount}} product batches, {{sampleCount}} samples, and {{scheduledAnalysisCount}} scheduled analyses.
A location can not be deleted while it is referenced by other entities.
Either delete said entities or have an administrator with "Can perform unsafe deletes" permission perform this operation.`,
						{
							childLocationCount: total.childLocationCount,
							investigationTriggerCount: total.investigationTriggerCount,
							productBatchCount: total.productBatchCount,
							sampleCount: total.sampleCount,
							scheduledAnalysisCount: total.scheduledAnalysisCount,
						},
					),
				)
			}
		} else if (
			confirm(
				translate(
					'location.confirmDeleteUnsavedLocation',
					`All objects that reference this location will either be deleted or orphaned.
Are you sure you want to delete these locations? After saving, this cannot be undone.`,
				),
			)
		) {
			doTheDelete()
		}
	}

	async function addFilesToSelectedLocation({ detail: filesToAdd }: { detail: BaseAttachmentFile[] }) {
		if ($selectedLocation) {
			const locationId = $selectedLocation.id
			const locationUuid = $selectedLocation.uuid
			const metaAttachments: MetaAttachment[] = filesToAdd.map(file => ({
				...file,
				locationId,
				locationUuid,
				path: file.path ?? '',
				uuid: file.uuid ?? '',
			}))
			metaAttachments.forEach(file => attachmentCrudStore.create(file))
			$selectedLocation.attachments.push(...metaAttachments)
			$selectedLocation.imageAttachments.push(...metaAttachments.filter(file => file.mimeType?.startsWith('image/')))
			$selectedLocation.thumbnailPath = $selectedLocation.imageAttachments[0].path
			$selectedLocation.attachmentCount += filesToAdd.length
			$selectedLocation = $selectedLocation
			$locations = $locations
		}
	}

	function removeFilesFromSelectedLocation({ detail: filesToDelete }: { detail: BaseAttachmentFile[] }) {
		if ($selectedLocation) {
			const locationId = $selectedLocation.id
			const locationUuid = $selectedLocation.uuid
			filesToDelete.forEach(file =>
				attachmentCrudStore.delete({ ...file, locationId, locationUuid, path: file.path ?? '', uuid: file.uuid ?? '' }),
			)
			if ($selectedLocation) {
				$selectedLocation.attachments =
					$selectedLocation?.attachments?.filter(
						attachment => !filesToDelete.some(file => file.uuid === attachment.uuid),
					) ?? []
				$selectedLocation.imageAttachments =
					$selectedLocation?.imageAttachments?.filter(
						attachment => !filesToDelete.some(file => file.uuid === attachment.uuid),
					) ?? []
			}
			$selectedLocation.thumbnailPath = $selectedLocation.imageAttachments[0]?.path ?? null
			$selectedLocation.attachmentCount -= filesToDelete.length
			$selectedLocation = $selectedLocation
			$locations = $locations
		}
	}

	export function canLeaveState() {
		// Only need to check for location changes
		const hasLocationChanges = locationCrudStore.hasChanges()
		// If we've already said we can leave the top level state, don't check again in the child states
		const canLeaveParent = $canLeaveLocationState
		const canLeave =
			canLeaveParent ||
			!hasLocationChanges ||
			confirm(
				translate(
					'location.canLeaveListState',
					'You have unsaved changes. Either save your changes, or press "OK" to discard any unsaved changes and leave this page.',
				),
			)
		// Reset the flag so we don't bypass just checking this state for changes next time
		$canLeaveLocationState = false
		// Revert plant dropdown value if we don't switch
		//@ts-expect-error
		const urlPlantId = parseInt(asr.getActiveState().parameters.plantId, 10)
		if (!canLeave && urlPlantId !== plantId) {
			plantId = urlPlantId
			selectedPlant = plants.find(p => p.id === plantId) as Plant
		}
		return canLeave
	}

	onMount(() => {
		if ($selectedLocation?.id || $selectedLocation?.uuid) {
			asr.go('app.locations.list.detail', { locationId: $selectedLocation?.id ?? null }, { inherit: true })
		}
	})

	onDestroy(() => {
		// We aren't persisting the location tree with uuids when switching plants, so we can't persist any changes either.
		locationCrudStore.clear()
		revokeObjectURLs(attachmentCrudStore.createdValues)
	})
</script>

<div class="form-row">
	<div
		class="col-12"
		class:col-xl-9={!!$selectedLocation}
	>
		<div class="card h-100">
			<div class="card-header">
				{#if showTabs}
					<!-- Margin hack to make it line up with the collapsible card when tabs are shown -->
					<div style="margin-top: 3px">
						<NavTabBar
							{tabs}
							bind:selectedTab
						/>
					</div>
				{:else}
					<h5 class="card-title mb-2">{translate('location.locations', 'Locations')}</h5>
				{/if}
			</div>
			<div class="card-body pb-0">
				<Table
					tree
					responsive
					stickyHeader
					filterEnabled
					columnHidingEnabled
					parentClass="mh-60vh"
					{columns}
					rows={$locations}
					bind:selectedRowIds
					rowSelectionIdProp="uuid"
					filterColumnClass="col-12 col-md-6 col-lg-4 align-self-end"
					headerColumnClass="col-12 col-md-6 col-lg-8 align-self-end mr-auto"
					filterPlaceholder={translate('location.filterLocationsAndChildren', 'Filter Locations and Children')}
					bind:this={theTable}
				>
					{#snippet header()}
						<div class="mb-3 d-flex align-items-end flex-wrap">
							<div
								class="mr-2"
								style="min-width: 300px;"
							>
								<SiteAutocomplete
									label={translate('common:plant', 'Plant')}
									options={plants}
									bind:value={selectedPlant}
									change={plant => {
										const plantId = plant.id
										plantId && asr.go('app.locations.list', { plantId }, { inherit: false })
									}}
								/>
							</div>
							<Checkbox
								label={translate('location.showThumbnails', 'Show Thumbnails')}
								bind:checked={$showImageThumbnails}
							/>
						</div>
					{/snippet}
					{#snippet body({ rows })}
						{#each rows as row (row.uuid)}
							<TreeRow
								idProp="uuid"
								parentIdProp="parentLocationUuid"
								property="code"
								node={row}
								rowClick={ctx => onLocationSelect(ctx.node)}
							>
								{#snippet first({ node })}
									<span
										class:text-primary={node.children.length}
										title={isCut
											? translate(
													'location.cutLocationIconTitle',
													'This location has been cut, and will be moved when pasted.',
												)
											: translate(
													'location.copyLocationIconTitle',
													'This location has been copied, and will be duplicated when pasted.',
												)}
										>{#if copiedLocationUuid === node.uuid}
											<Icon
												fixedWidth
												icon={isCut ? 'scissors' : 'copy'}
											></Icon>
										{/if}</span
									>
								{/snippet}
								{#snippet children({ node })}
									{@const theTags = node.tags ?? []}
									<Td property="tags[length]">
										<div class="d-flex flex-wrap justify-content-start">
											{#each theTags as tag, index}
												<div>
													<ExpandableBadge
														class={index < theTags.length - 1 ? 'mr-1' : ''}
														text={tag.name}
													/>
												</div>
											{:else}
												<i class="text-muted">{translate('common:notApplicableAbbreviation', 'N/A')}</i>
											{/each}
										</div>
									</Td>
									<Td property="description">{node.description ?? ''}</Td>
									<Td
										property="productProximity[name]"
										style="text-wrap:nowrap"
									>
										{#if node.productProximity?.name}
											{node.productProximity?.name}
										{:else}
											<i class="text-muted">{translate('common:notApplicableAbbreviation', 'N/A')}</i>
										{/if}
									</Td>
									<Td
										property="processZone[name]"
										style="text-wrap:nowrap"
									>
										{#if node.processZone?.name}
											{node.processZone.name}
										{:else}
											<i class="text-muted">{translate('common:notApplicableAbbreviation', 'N/A')}</i>
										{/if}
									</Td>
									<Td
										property="attachmentCount"
										title={translate(
											'location.imageThumbnailTdTitle',
											'Click to view image attachments at the selected location',
										)}
									>
										<ImageThumbnail
											showImageCount
											noImagePath="images/noimage.jpg"
											fileCount={node.imageAttachments.length ?? 0}
											thumbnailFile={{ path: node.thumbnailPath ?? '' }}
											showThumbnail={$showImageThumbnails}
											on:click={async () => {
												onLocationSelect(node)
												currentPhotoIndex = 0
												await tick()
												showImageViewer = true
											}}
										/>
									</Td>
									<Td
										property="testable"
										align="center"
									>
										<Icon
											fixedWidth
											class={node.testable ? 'text-success' : 'text-danger'}
											icon={node.testable ? 'check' : 'xmark-large'}
										/>
									</Td>
									<Td
										property="active"
										align="center"
									>
										<Icon
											fixedWidth
											class={node.active ? 'text-success' : 'text-danger'}
											icon={node.active ? 'check' : 'xmark-large'}
										/>
									</Td>
								{/snippet}
							</TreeRow>
						{:else}
							<tr>
								<td
									class="text-center"
									colspan={columns.length}
								>
									{translate(
										'location.noLocationsMatchingTheCurrentFilter',
										'No Locations Matching the Current Filter',
									)}
								</td>
							</tr>
						{/each}
					{/snippet}
				</Table>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<div>
					<Dropdown
						split
						outline
						size="sm"
						color="success"
						iconClass="plus"
						title={translate(
							'location.newLocationButtonTitle',
							'Add a new location as a sibling of the selected location.',
						)}
						disabled={!allowEditing}
						onclick={() => onNewLocation('SIBLING')}
					>
						{translate('location.newLocationButton', 'New Location')}
						{#snippet dropdownItems()}
							<button
								class="dropdown-item"
								type="button"
								onclick={() => onNewLocation('TOP')}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="plus"
								/>
								{translate('location.newTopLevelLocationButton', 'New Top-Level Location')}
							</button>
							<h6 class="dropdown-header">
								{translate('location.relativeToSelectedLocationDropdownTitle', 'Relative to Selected Location')}
							</h6>
							<button
								class="dropdown-item"
								type="button"
								disabled={!$selectedLocation}
								onclick={() => onNewLocation('PARENT')}
							>
								<Icon
									fixedWidth
									class="fa-rotate-180 mr-1"
									icon="turn-down-right"
								/>
								{translate('location.aboveParentButton', 'Above (Parent)')}
							</button>
							<button
								class="dropdown-item"
								type="button"
								disabled={!$selectedLocation}
								onclick={() => onNewLocation('SIBLING')}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="down"
								/>
								{translate('location.nextToSiblingButton', 'Next to (Sibling)')}
							</button>
							<button
								class="dropdown-item"
								type="button"
								disabled={!$selectedLocation}
								onclick={() => onNewLocation('CHILD')}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="turn-down-right"
								/>
								{translate('location.belowChildButton', 'Below (Child)')}
							</button>
						{/snippet}
					</Dropdown>
					<Button
						outline
						size="sm"
						iconClass="cut"
						title={translate(
							'location.cutLocationButtonTitle',
							'Cut selected location to clipboard; the first paste will move the location, subsequent pastes will copy it.',
						)}
						disabled={!$selectedLocation || !allowEditing}
						onclick={() => {
							if ($selectedLocation) {
								isCut = true
								clipboard.set($selectedLocation)
								copiedLocationUuid = $selectedLocation.uuid
								mediator.call('showMessage', {
									heading: translate(
										'location.copiedToClipboardMessageHeading',
										`Location "{{- code}}" Copied to Clipboard`,
										{ code: $selectedLocation?.code },
									),
									message: translate(
										'location.copiedToClipboardCutPasteMessage',
										'You can now paste it to move it elsewhere.',
									),
									color: 'info',
								})
							}
						}}
						>{translate('location.cutButton', 'Cut')}
					</Button>
					<Button
						outline
						size="sm"
						iconClass="copy"
						title={translate(
							'location.copyLocationButtonTitle',
							'Copy selected location to clipboard; you can then paste it elsewhere.',
						)}
						disabled={!$selectedLocation || !allowEditing}
						onclick={() => {
							if ($selectedLocation) {
								isCut = false
								clipboard.set(copyLocationAndChildren($selectedLocation, null))
								copiedLocationUuid = $selectedLocation.uuid
								mediator.call('showMessage', {
									heading: translate(
										'location.copiedToClipboardMessageHeading',
										`Location "{{- code}}" Copied to Clipboard`,
										{ code: $selectedLocation?.code },
									),
									message: translate(
										'location.copiedToClipboardCopyPasteMessage',
										'You can now paste a copy of it elsewhere.',
									),
									color: 'info',
								})
							}
						}}
						>{translate('location.copyButton', 'Copy')}
					</Button>
					<Dropdown
						split
						outline
						size="sm"
						iconClass="paste"
						disabled={!$clipboard || !allowEditing}
						title={translate('location.pasteLocationButtonTitle', 'Paste the location from the clipboard.')}
						onclick={() => paste(!$selectedLocation)}
					>
						{translate('location.paste', 'Paste')}
						{#snippet dropdownItems()}
							<h6 class="dropdown-header">
								{translate('location.pasteDropdownHeader', 'Paste "{{location}}"...', {
									location: $clipboard?.location,
								})}
							</h6>
							<button
								class="dropdown-item"
								type="button"
								onclick={() => paste(true)}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="plus"
								/>
								{translate('location.pasteAtTheTopLevel', 'At the Top Level')}
							</button>
							<button
								class="dropdown-item"
								type="button"
								onclick={() => paste()}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="turn-down-right"
								/>
								{translate('location.pasteBelowSelectedLocation', 'Below (child of) Selected Location')}
							</button>
						{/snippet}
					</Dropdown>
					<Dropdown
						split
						outline
						size="sm"
						color="danger"
						iconClass="trash"
						title={translate('location.deleteLocationButton', 'Delete the selected location.')}
						disabled={!$selectedLocation || !allowEditing}
						onclick={() => $selectedLocation && deleteLocation($selectedLocation)}
					>
						{translate('location.deleteButton', 'Delete')}...
						{#snippet dropdownItems()}
							<h6 class="dropdown-header">{translate('location.deleteDropdownHeader', 'Delete')}...</h6>
							<button
								type="button"
								class="dropdown-item"
								onclick={() => $selectedLocation && deleteLocation($selectedLocation)}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="trash"
								/>
								{translate('location.deleteSelectedLocationOnlyButton', 'Selected Location Only')}
							</button>
							<button
								type="button"
								class="dropdown-item"
								onclick={() => $selectedLocation && deleteLocation($selectedLocation, true)}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="turn-down-right"
								/>
								{translate('location.deleteSeslectedLocationAndChildrenButton', 'Seleted Location and Children')}
							</button>
						{/snippet}
					</Dropdown>
				</div>
				<Button
					outline
					size="sm"
					iconClass="paperclip"
					title={translate(
						'location.openAttachmentsButtonTitle',
						'Open the attachment interface, where you can view and manage attachments, for the selected location.',
					)}
					disabled={!$selectedLocation}
					onclick={() => (showAttachmentsModal = true)}
				>
					{translate('location.openAttachmentsButton', 'Attachments')}...
				</Button>
			</div>
		</div>
	</div>
	<div
		class="col-12 col-xl-3"
		class:d-none={!$selectedLocation}
	>
		<uiView></uiView>
	</div>
</div>

<Modal
	closeShown={false}
	cancelShown={false}
	title="{$selectedLocation?.location} Attachments"
	backdropClickCancels={false}
	bind:show={showAttachmentsModal}
	modalSize="xxl"
	confirm={() => (showAttachmentsModal = false)}
>
	{#if $selectedLocation}
		<Attachments
			hidePublicFeatures
			hideRankFeatures
			modificationDisabled={!allowEditing}
			on:filesAdded={addFilesToSelectedLocation}
			on:filesDeleted={removeFilesFromSelectedLocation}
			fileList={$selectedLocation.attachments}
		/>
	{/if}
</Modal>

<ImageViewer
	bind:currentPhotoIndex
	title="{$selectedLocation?.location} Images"
	files={selectedLocationImages}
	bind:show={showImageViewer}
/>
