export default {
    "name": "getValueAcceptability",
    "kind": "HoudiniQuery",
    "hash": "1522631f9c961f6b24b6e1302ed2a57c072d764e6a5511153559f465ef6bf547",

    "raw": `query getValueAcceptability($currentResult: String!, $productBatchId: PositiveInt, $analysisOptionId: PositiveInt!, $productId: PositiveInt, $severityClassId: PositiveInt, $plantId: PositiveInt, $productionVolume: NonNegativeFloat) {
  getValueAcceptability(
    currentResult: $currentResult
    productBatchId: $productBatchId
    analysisOptionId: $analysisOptionId
    productId: $productId
    severityClassId: $severityClassId
    plantId: $plantId
    productionVolume: $productionVolume
  )
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getValueAcceptability": {
                "type": "Acceptability",
                "keyRaw": "getValueAcceptability(analysisOptionId: $analysisOptionId, currentResult: $currentResult, plantId: $plantId, productBatchId: $productBatchId, productId: $productId, productionVolume: $productionVolume, severityClassId: $severityClassId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "currentResult": "String",
            "productBatchId": "PositiveInt",
            "analysisOptionId": "PositiveInt",
            "productId": "PositiveInt",
            "severityClassId": "PositiveInt",
            "plantId": "PositiveInt",
            "productionVolume": "NonNegativeFloat"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=eef95974e64cb06e81d57c5497225f3389bceda1f90ff84762f7f9f5d24e9739";