<script lang="ts">
	import type { i18n } from 'types/common'
	import type { ValueAcceptabilityMap } from 'utility/value-acceptability-map'
	import type { ResultStatus$options } from '$houdini'

	import Popover from '@isoftdata/svelte-popover'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import ThresholdTable from './ThresholdTable.svelte'
	import { getContext, tick } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'

	interface Props {
		//
		id?: string
		allowShowThresholdsTable?: boolean
		checkThresholdsData?: {
			analysisOptionId: number
			currentResult: string
			productBatchId?: number
			productId?: number
			severityClassId?: number
			plantId?: number
			productionVolume?: number
		}
		filledOut?: string | null
		isLoading?: boolean
		lastModified?: string | null
		resultStatus?: ResultStatus$options
		showModifiedIcons?: boolean
		showOnlyApplicableThresholds?: boolean
	}

	let {
		//
		id = uuid(),
		allowShowThresholdsTable = false,
		showOnlyApplicableThresholds = $bindable(true),
		checkThresholdsData,
		filledOut,
		isLoading = false,
		lastModified,
		resultStatus,
		showModifiedIcons = true,
	}: Props = $props()

	let showThresholdTable = $state(false)
	let thresholdTable: ThresholdTable | undefined = $state()

	const { t: translate } = getContext<i18n>('i18next')
	const valueAcceptabilityMap = getContext<ValueAcceptabilityMap>('valueAcceptabilityMap')
	let edited = $derived(showModifiedIcons && filledOut && lastModified !== filledOut)
	let acceptabilityObject = $derived(valueAcceptabilityMap.get(resultStatus ?? 'NOT_CALCULATED')!)
	let { icon: iconClass, colorClass: color, label: acceptabilityLabel } = $derived(acceptabilityObject)

	let icon = $derived(
		// loading prop doesn't work with custom icons
		edited && iconClass && !isLoading
			? {
					prefix: 'fak' as const,
					class: `fa-lg fa-solid-${iconClass}-pen`,
				}
			: {
					class: 'fa-lg',
					prefix: 'fas' as const,
					icon: edited ? ('pen' as const) : iconClass,
				},
	)
	let formattedLastModified = $derived(lastModified ? new Date(lastModified).toLocaleString() : '')
	let formattedFilledOut = $derived(filledOut ? new Date(filledOut).toLocaleString() : '')
	async function onAcceptabilityClick() {
		showThresholdTable = !showThresholdTable && allowShowThresholdsTable
		await getThresholdTableData()
	}

	export async function getThresholdTableData() {
		await tick() // tick so data & UI are up to date
		if (showThresholdTable && checkThresholdsData) {
			await thresholdTable?.loadData?.({
				onlyApplicable: showOnlyApplicableThresholds,
				...checkThresholdsData,
			})
		}
	}
</script>

<Popover
	autoUpdate
	ignoreMaxWidth
	tabindex={-1}
	size="sm"
	class="p-1"
	{color}
	{icon}
	{isLoading}
	id="thresholdsButton{id}"
	title={translate(
		'workOrder.acceptabilityButtonTitle',
		'The acceptability ({{acceptability}}) for this sample. Click to view detailed acceptability information.',
		{
			acceptability: acceptabilityLabel,
		},
	)}
	style="min-width: 35.5px; max-width: 35.5px;"
	open={onAcceptabilityClick}
	close={() => (showThresholdTable = false)}
>
	{#snippet body()}
		<div
			class="popover-body"
			style="max-height: 400px; width: min-content; min-width: 300px;"
		>
			{#if showThresholdTable}
				<Checkbox
					inline
					label={translate('workOrder.onlyShowApplicableThresholds', 'Only show applicable thresholds')}
					bind:checked={showOnlyApplicableThresholds}
					onchange={() => {
						getThresholdTableData()
					}}
				></Checkbox>
				<ThresholdTable bind:this={thresholdTable}></ThresholdTable>
			{/if}
			{#if edited}
				{translate(
					'workOrder.lastModifiedPencilTitle',
					'Value was edited on {{- formattedLastModified}}, and was first filled out on {{- formattedFilledOut}}.',
					{
						formattedLastModified,
						formattedFilledOut,
					},
				)}
			{/if}
		</div>
	{/snippet}
</Popover>
