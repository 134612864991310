import type { AppContext } from 'types/common'
import type { ComponentProps } from 'svelte'
import type SaveResetButton from '@isoftdata/svelte-save-reset-button'
import { getSession } from 'stores/session'
import { writable } from 'svelte/store'
import component from './ProductManagement.svelte'

export default function ({ stateRouter, hasPermission }: AppContext) {
	stateRouter.addState({
		name: 'app.product-management',
		route: 'product-management',
		defaultChild: 'product',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: component,
		resolve(_data, { lastSavedTime }) {
			const { authorizedPlantIDs } = getSession()

			if (!authorizedPlantIDs.some(id => hasPermission('PRODUCT_CAN_OPEN_SCREEN', id))) {
				return Promise.reject({
					redirectTo: { name: 'app.home' },
				})
			}
			return Promise.resolve({
				lastSavedTime,
				saveResetProps: writable<ComponentProps<typeof SaveResetButton> | null>(null),
			})
		},
	})
}
