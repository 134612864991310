<script lang="ts">
	import type { i18n } from 'i18next'
	import {
		defaultChoiceData,
		defaultOptionData,
		defaultRuleData,
		makeTranslatedConstants,
		type Analysis,
		type Choice,
		type EntityTag,
		type Option,
		type Product,
		type Rule,
		type SeverityClass,
		type ParentCrudStore,
		type TagStore,
		type Plant,
		getConstraintsForValueType,
	} from 'utility/analysis-management-helper.svelte'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Readable, Writable } from 'svelte/store'
	import type { AddRemoveStore } from 'stores/add-remove-store'

	import { AmAnalysisOptionChoicesStore } from '$houdini'

	import { Table, DraggableRows, Td, type Column, type UuidRowProps } from '@isoftdata/svelte-table'
	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import DefaultOptionValueModal from './DefaultOptionValueModal.svelte'
	import MightyMorphingInput from 'components/MightyMorphingInput.svelte'
	import TagSelectionModal from './TagSelectionModal.svelte'
	import TestThresholdsModal from './TestThresholdsModal.svelte'
	import TagBadge from 'components/TagBadge.svelte'

	import { getEventChecked, getEventValue, getEventValueEnum } from '@isoftdata/browser-event'
	import { getContext, onDestroy, onMount } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import hasPermission from 'utility/has-permission'
	import session from 'stores/session'
	import userLocalWritable, { type UserLocalWritable } from '@isoftdata/svelte-store-user-local-writable'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import splitAction from 'utility/split-action'
	import TestRulesModal from 'components/analysis-management/options/TestRulesModal.svelte'
	import Icon from '@isoftdata/svelte-icon'

	export let analyses: Readonly<Array<Analysis>>
	export let analysisOptions: Array<Option>
	export let products: Array<Product>
	export let plants: Array<Plant>
	export let severityClasses: Array<SeverityClass>
	export let selectedAnalysisId: number | null
	export let selectedAnalysisUuid: string | null
	export let selectedOptionIndex: number
	export let selectedPlant: Plant
	export let canEditGlobalFields: (analysisId: number | null) => boolean

	export let analysisCrudStore: CrudStore<Analysis, 'uuid'>
	export let optionCrudStore: ParentCrudStore<Option>
	export let choiceCrudStore: ParentCrudStore<Choice>
	export let ruleCrudStore: ParentCrudStore<Rule>
	export let tagCrudStore: CrudStore<EntityTag, 'uuid'>
	export let tagAddRemoveStore: AddRemoveStore
	export let tagStore: TagStore
	export let plantTagUuids: Readable<Record<string, string>>
	export let productTagUuids: Readable<Record<string, string>>
	export let locationTagUuids: Readable<Record<string, string>>

	export let showInactive: Writable<boolean>
	let selectedPlantId: number | null
	export { selectedPlantId as plantId }
	export let recipesMode = false

	let selectedOptionTab: UserLocalWritable<'THRESHOLDS' | 'RULES'> = userLocalWritable(
		$session.userAccountId,
		'analysisManagementSelectedOptionTab',
		'THRESHOLDS',
	)
	let choicesLoading = false

	let optionsTable: Table<Option> | undefined = undefined
	let draggableRows: DraggableRows<Option> | undefined = undefined
	let defaultOptionValueModal: DefaultOptionValueModal | undefined = undefined
	let tagSelectionModal: TagSelectionModal | undefined = undefined
	let testThresholdsModal: TestThresholdsModal | undefined = undefined
	let testRulesModal: TestRulesModal | undefined = undefined

	let lazySortChoices = false
	let lazySortRules = false

	let selectedOption: Option | undefined = analysisOptions[selectedOptionIndex]
	$: selectedOption = analysisOptions[selectedOptionIndex]
	$: selectedOptionChoices = selectedOption?.choices ?? []
	$: applicableThresholds = getApplicableThresholds(selectedOption)
	$: selectedAnalysis = analyses.find(({ uuid }) => uuid === selectedAnalysisUuid) ?? null
	// Since we're entering choices not choosing one, we want to have an input not a select
	$: thresholdValueType = selectedOption.valueType === 'CHOICE' ? 'TEXT' : selectedOption.valueType

	const { t: translate } = getContext<i18n>('i18next')
	const { thresholdsWhenMap, constraintsMap, outcomeMap, thresholdsMap } = makeTranslatedConstants(translate)

	// Normally these are only triggered for the selected analysis/option,
	// But cloning analyses can trigger it for other analyses/options, so just ignore those
	const optionUnsub = optionCrudStore.entitySubscribe(analysisUuid => {
		if (selectedAnalysis && selectedAnalysis.uuid === analysisUuid) {
			analysisCrudStore.update(selectedAnalysis)
		}
	})

	const choiceUnsub = choiceCrudStore.entitySubscribe(optionUuid => {
		if (selectedOption.uuid === optionUuid && selectedAnalysis) {
			optionCrudStore.update(selectedAnalysis.uuid, selectedOption)
		}
	})

	const ruleUnsub = ruleCrudStore.entitySubscribe(optionUuid => {
		if (selectedOption.uuid === optionUuid && selectedAnalysis) {
			optionCrudStore.update(selectedAnalysis.uuid, selectedOption)
		}
	})

	function getApplicableThresholds(option: typeof selectedOption) {
		const valueType = option?.valueType
		if (valueType === 'CHOICE' || valueType === 'BOOLEAN' || valueType === 'TEXT') {
			const { BOUNDARY, ...applicableThresholds } = thresholdsWhenMap
			return applicableThresholds
		}
		return thresholdsWhenMap
	}

	function getTagUuid(entityType: EntityTag['entityType'], tagName: string): string {
		if (entityType === 'PLANT') {
			return $plantTagUuids[tagName]
		} else if (entityType === 'LOCATION') {
			return $locationTagUuids[tagName]
		} else if (entityType === 'PRODUCT') {
			return $productTagUuids[tagName]
		}
		throw new Error(`Unknown entity type: ${entityType}`)
	}

	function getDisplayTagsList(index: number) {
		return (
			selectedOption?.rules[index]?.tags.reduce((tags: Array<EntityTag>, ruleTag) => {
				let tag: EntityTag | undefined
				tag = $tagStore[ruleTag.entityType][ruleTag.uuid]
				// Don't include deleted tags in the display list, even though they're still on the rule (they will be removed on save)
				if (tag?.name && !tagCrudStore.isDeleted(tag)) {
					tags.push(tag)
				}

				return tags
			}, []) ?? []
		)
	}

	function canEditChoice(plantId: number | null) {
		if (plantId === null) {
			// Choice/Threshold is "Global" if not at a plant
			return canEditGlobalFields(selectedAnalysisId)
		}

		// Choice/Threshold is one plant only
		return hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId)
	}

	const analysisOptionChoicesQuery = new AmAnalysisOptionChoicesStore()

	// TODO, why am I throwing a bunch of choices away here and re-querying? Is it possible to just keep the choices around if I already queried for them?
	// Maybe so I'm not sending them up to the Analyses state?
	// TODO, I guess make it so the options state keeps all choices in memory but only sends dirty ones to analyses state to save between options state reloads?
	async function optionClick(index: number) {
		const lastSelectedOptionIndex = selectedOptionIndex
		if (lastSelectedOptionIndex === index || optionIsDeleted(analysisOptions[index])) {
			return
		}
		// Show choices as loading
		choicesLoading = true
		// Query choices for the new option
		const newSelectedOptionId = analysisOptions[index]?.id

		let newChoices: Array<Choice> = []

		if (newSelectedOptionId) {
			const { data } = await analysisOptionChoicesQuery.fetch({
				variables: {
					filter: {
						optionId: newSelectedOptionId,
						plantId: selectedPlantId,
						// Don't show choices with an associated batch, those are configured on the product/batch screen
						hasProductBatch: false,
					},
				},
			})

			newChoices = (data?.analysisOptionChoices.data ?? []).map(choice => ({ ...choice, uuid: uuid() }))
		}

		// Merge the cached choices with the new choices, and add on any new unsaved choices
		const cachedChoices = analysisOptions[index].choices ?? []
		const cachedChoicesMap = new Map(cachedChoices.filter(({ id }) => id).map(choice => [choice.id, choice]))
		const mergedChoices: Array<Choice> = newChoices
			.map(choice => ({ ...(cachedChoicesMap.get(choice.id) ?? choice) }))
			.concat(cachedChoices.filter(choice => !choice?.id))

		// Make sure we always have a "blank" choice for them to edit
		if (
			mergedChoices.findIndex(
				choice =>
					!choice.id &&
					!choiceCrudStore.isDeleted(analysisOptions[index].uuid, choice) &&
					!choiceCrudStore.isDirty(analysisOptions[index].uuid, choice),
			) === -1
		) {
			mergedChoices.push({
				...klona(defaultChoiceData),
				analysisOptionId: newSelectedOptionId,
				plantId: selectedPlantId,
				uuid: uuid(),
			})
		}

		// Show choices in the UI
		selectedOptionIndex = index
		analysisOptions[index].choices = mergedChoices
		choicesLoading = false
	}

	function computeOptionRowClass(row: Option & UuidRowProps) {
		const classes: Array<string> = []
		if (!row.active && !$showInactive) {
			classes.push('d-none')
		}

		if (optionIsDeleted(row)) {
			classes.push('table-danger')
		} else if (selectedOptionIndex === row.originalIndex) {
			classes.push('table-primary')
		}
		return classes.join(' ')
	}

	function addOption() {
		let newOptionIndex = analysisOptions.findIndex(
			option => !option.id && !optionIsDirty(option) && !optionIsDeleted(option),
		)

		if (newOptionIndex === -1) {
			newOptionIndex = analysisOptions.length
			analysisOptions.push({
				...klona(defaultOptionData),
				analysisId: selectedAnalysisId,
				rank: analysisOptions.length + 1,
				uuid: uuid(),
				rules: [
					{
						...klona(defaultRuleData),
						analysisOptionId: null,
						uuid: uuid(),
					},
				],
				choices: [
					{
						...klona(defaultChoiceData),
						analysisOptionId: null,
						plantId: selectedPlantId,
						uuid: uuid(),
					},
				],
			})
		}
		selectedOptionIndex = newOptionIndex
		document.querySelector<HTMLInputElement>(`#option-option-${newOptionIndex}`)?.focus()
	}

	function deleteOption(index: number) {
		if (
			!selectedAnalysisUuid ||
			!selectedAnalysis ||
			!confirm(
				'Delete this analysis option? All sample values and thresholds that reference this analysis option will be deleted as well. \r\n\r\nAfter saving, this cannot be undone.',
			)
		) {
			return
		}
		optionCrudStore.delete(selectedAnalysisUuid, analysisOptions[index])
		analysisCrudStore.update(selectedAnalysis)
		// Select the first non-deleted option
		const newSelectedOptionIndex = analysisOptions.findIndex(option => !optionIsDeleted(option))
		selectedOptionIndex = newSelectedOptionIndex
	}

	function undoDeleteOption(index: number) {
		if (selectedAnalysis) {
			optionCrudStore.unDelete(selectedAnalysis.uuid, analysisOptions[index])
			analysisCrudStore.update(selectedAnalysis)
		}
	}

	function optionIsDeleted(option: Option) {
		return !!selectedAnalysisUuid && optionCrudStore.isDeleted(selectedAnalysisUuid, option)
	}

	function optionIsDirty(option: Option) {
		return !!selectedAnalysisUuid && optionCrudStore.isDirty(selectedAnalysisUuid, option)
	}

	function updateOptionKeypath<K extends keyof Option>(index: number, key: K, value: Option[K]) {
		if (!selectedAnalysisUuid || !selectedAnalysis) {
			return
		}

		const optionCount = analysisOptions.length

		analysisOptions[index][key] = value
		if (analysisOptions[index].id) {
			optionCrudStore.update(selectedAnalysisUuid, analysisOptions[index])
		} else {
			optionCrudStore.create(selectedAnalysisUuid, analysisOptions[index])
		}

		if (index === optionCount - 1) {
			analysisOptions.push({
				...klona(defaultOptionData),
				analysisId: selectedAnalysisId,
				rank: optionCount,
				uuid: uuid(),
				rules: [
					{
						...klona(defaultRuleData),
						analysisOptionId: selectedOption?.id ?? null,
						created: new Date(),
						uuid: uuid(),
					},
				],
				choices: [
					{
						...klona(defaultChoiceData),
						analysisOptionId: selectedOption?.id ?? null,
						plantId: selectedPlantId,
						uuid: uuid(),
					},
				],
			})
			analysisOptions = analysisOptions
		}
	}

	function optionProductChanged(index: number, productId: string | number | null) {
		if (!selectedOption?.choices) {
			return
		}

		if (typeof productId === 'string') {
			productId = parseInt(productId, 10)
		}

		updateOptionKeypath(index, 'product', productId ? (products.find(({ id }) => id === productId) ?? null) : null)
	}
	function addChoice() {
		if (!selectedOption?.choices) {
			return
		}

		const choices = selectedOption.choices
		let newChoiceIndex = choices.findIndex(choice => !choice.id && !choiceIsDeleted(choice) && !choiceIsDirty(choice))
		if (newChoiceIndex === -1) {
			newChoiceIndex = choices.length
			lazySortChoices = false
			selectedOption.choices.push({
				...klona(defaultChoiceData),
				analysisOptionId: selectedOption.id,
				plantId: selectedPlantId,
				uuid: uuid(),
			})
			lazySortChoices = true
		}
		document.querySelector<HTMLInputElement>(`#choice-boundary-${newChoiceIndex}`)?.focus()
	}

	function deleteChoice(index: number) {
		if (
			confirm('Delete this value? \r\n\r\nAfter saving, this cannot be undone.') &&
			analysisOptions[selectedOptionIndex].choices &&
			selectedOption &&
			selectedAnalysis
		) {
			choiceCrudStore.delete(selectedOption.uuid, analysisOptions[selectedOptionIndex].choices![index])
		}
	}

	function undoDeleteChoice(index: number) {
		if (analysisOptions[selectedOptionIndex].choices && selectedOption && selectedAnalysis) {
			choiceCrudStore.unDelete(selectedOption.uuid, analysisOptions[selectedOptionIndex].choices![index])
		}
	}

	function choiceGlobalChecked(index: number, checked: boolean) {
		if (!analysisOptions[index]) {
			return
		}

		updateChoiceKeypath(index, 'plantId', checked ? null : selectedPlantId)
		analysisOptions[selectedOptionIndex].choices![index].severityClass = null // in theory this should already be null, but just in case
	}

	function choiceProductChanged(index: number, productId: string | number | null) {
		if (!selectedOption?.choices) {
			return
		}

		if (typeof productId === 'string') {
			productId = parseInt(productId, 10)
		}

		updateChoiceKeypath(index, 'product', productId ? (products.find(({ id }) => id === productId) ?? null) : null)
	}

	function choiceSeverityChanged(index: number, severityId: number | string | null) {
		if (!selectedOption?.choices) {
			return
		}

		if (typeof severityId === 'string') {
			severityId = parseInt(severityId, 10)
		}

		const newSeverity = severityId ? (severityClasses.find(({ id }) => id === severityId) ?? null) : null

		updateChoiceKeypath(index, 'severityClass', newSeverity)

		// can't be global since severity is plant based
		if (newSeverity) {
			selectedOption.choices[index].plantId = selectedPlantId
		}
	}

	function choiceIsDeleted(choice: Choice) {
		return !!selectedOption && choiceCrudStore.isDeleted(selectedOption.uuid, choice)
	}

	function choiceIsDirty(choice: Choice) {
		return !!selectedOption && choiceCrudStore.isDirty(selectedOption.uuid, choice)
	}

	function updateChoiceKeypath<K extends keyof Choice>(index: number, key: K, value: Choice[K]) {
		if (!analysisOptions[selectedOptionIndex].choices || !selectedOption || !selectedAnalysis) {
			return
		}

		analysisOptions[selectedOptionIndex].choices![index][key] = value

		if (analysisOptions[selectedOptionIndex].choices![index].id) {
			choiceCrudStore.update(selectedOption.uuid, analysisOptions[selectedOptionIndex].choices![index])
		} else {
			choiceCrudStore.create(selectedOption.uuid, analysisOptions[selectedOptionIndex].choices![index])
		}

		// insert new empty row if we're at the end
		if (index + 1 === analysisOptions[selectedOptionIndex].choices?.length) {
			lazySortChoices = false
			analysisOptions[selectedOptionIndex].choices?.push({
				...klona(defaultChoiceData),
				analysisOptionId: selectedOption?.id ?? null,
				plantId: selectedPlantId,
				uuid: uuid(),
			})
			analysisOptions[selectedOptionIndex].choices = analysisOptions[selectedOptionIndex].choices
			lazySortChoices = true
		}
	}

	function ruleIsDirty(rule: Rule) {
		return !!selectedOption && ruleCrudStore.isDirty(selectedOption.uuid, rule)
	}

	function ruleIsDeleted(rule: Rule) {
		return !!selectedOption && ruleCrudStore.isDeleted(selectedOption.uuid, rule)
	}

	function addRule() {
		if (!selectedOption || !selectedAnalysis) {
			return
		}

		const rules = selectedOption.rules
		let newRuleIndex = rules.findIndex(rule => !rule.id && !ruleIsDirty(rule) && !ruleIsDeleted(rule))
		if (newRuleIndex === -1) {
			newRuleIndex = rules.length
			lazySortRules = true
			selectedOption.rules.push({
				...klona(defaultRuleData),
				analysisOptionId: selectedOption.id,
				uuid: uuid(),
			})
			lazySortRules = false
		}
		document.querySelector<HTMLInputElement>(`#rule-tags-${newRuleIndex}`)?.focus()
	}

	function undoDeleteRule(index: number) {
		if (!selectedOption || !selectedAnalysis) {
			return
		}

		ruleCrudStore.unDelete(selectedOption.uuid, selectedOption.rules[index])
		optionCrudStore.update(selectedAnalysis.uuid, selectedOption)
		analysisCrudStore.update(selectedAnalysis)
	}

	function deleteRule(index: number) {
		if (!selectedOption || !selectedAnalysis) {
			return
		}

		ruleCrudStore.delete(selectedOption.uuid, selectedOption.rules[index])
	}

	function updateRuleKeypath<K extends keyof Rule>(index: number, key: K, value: Rule[K]) {
		if (!selectedOption || !selectedAnalysis) {
			return
		}

		const rulesCount = analysisOptions[selectedOptionIndex].rules.length
		analysisOptions[selectedOptionIndex].rules[index][key] = value

		if (selectedOption.rules[index].id) {
			ruleCrudStore.update(selectedOption.uuid, analysisOptions[selectedOptionIndex].rules[index])
		} else {
			ruleCrudStore.create(selectedOption.uuid, analysisOptions[selectedOptionIndex].rules[index])
		}

		if (index === rulesCount - 1) {
			lazySortRules = false
			analysisOptions[selectedOptionIndex].rules.push({
				...klona(defaultRuleData),
				analysisOptionId: selectedOption?.id ?? null,
				uuid: uuid(),
			})
			analysisOptions[selectedOptionIndex].rules = analysisOptions[selectedOptionIndex].rules
			lazySortRules = true
		}
	}

	//#region Column Definition
	const analysisOptionsTableColumns: Array<Column<Option>> = [
		{
			property: 'id',
			name: '',
			icon: 'save',
			width: '1rem',
			title: translate(
				'analysisManagment.dirtyColumnTooltip',
				'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
			),
			align: 'center',
			minWidth: '40px',
		},
		{
			property: 'rank',
			name: '',
			icon: 'bars',
			align: 'center',
			defaultSortColumn: true,
			defaultSortDirection: 'ASC',
			minWidth: '60px',
		},
		{
			property: 'option',
			name: recipesMode
				? translate('analyes.optionsStepNamecolumn', 'Step Name')
				: translate('analyses.optionsOptionColumn', 'Option'),
			minWidth: '200px',
			title: translate('analyses.optionsOptionColumnTitle', 'The option the user will be presented with'),
		},
		{
			property: 'active',
			name: translate('analyses.optionsActiveColumn', 'Active'),
			width: '1rem',
			title: translate(
				'analyses.optionsActiveColumnTitle',
				'Whether this option is active (active options will be shown on new samples)',
			),
			align: 'center',
			minWidth: '60px',
		},
		{
			property: 'defaultValue',
			name: recipesMode
				? translate('analyses.optionsIngredientVolumeColumn', 'Ingredient Volume')
				: translate('analyses.optionsDefaultValueColumn', 'Default Value'),
			minWidth: '150px',
			title: translate(
				'analyses.optionsDefaultValueColumnTitle',
				'The default value is filled in on samples as soon as they are marked as sampled.  This field supports calculation syntax (see the wiki for more information).',
			),
		},
		{
			property: 'unit',
			name: translate('analyses.optionsUnitColumn', 'Unit'),
			minWidth: '80px',
			title: translate('analyses.optionsUnitColumnTitle', 'The unit of measurement (where applicable)'),
		},
		{
			property: 'valueType',
			name: translate('analyses.optionsValueTypeColumn', 'Value Type'),
			minWidth: '100px',
			title: translate(
				'analyses.optionsValueTypeColumnTitle',
				'Choose the type of data values are collected as (such as integer or string)',
			),
		},
		{
			property: 'requiredToPerform',
			name: translate('analyses.optionsRequiredToPerformColumn', 'Required to Perform'),
			width: '1rem',
			title: translate(
				'analyses.optionsRequiredToPerformColumnTitle',
				'Required options must be filled out before saving a sample as performed. (Samples become performed once any options have values)',
			),
			align: 'center',
		},
		{
			property: 'requiredToClose',
			name: translate('analyses.optionsRequiredToCloseColumn', 'Required to Close'),
			width: '1rem',
			title: translate(
				'analyses.optionsRequiredToCloseColumnTitle',
				'Required options must be filled out before closing a work order',
			),
			align: 'center',
		},
		{
			property: 'informational',
			name: translate('analyses.optionsInformationalColumn', 'Informational'),
			width: '1rem',
			title: translate(
				'analyses.optionsInformationalColumnTitle',
				"Informational items will be filled out on WOs but won't be graphed or sent to visualizations",
			),
			align: 'center',
		},
		{
			property: 'uuid',
			name: '',
			icon: 'trash',
			sortType: false,
			width: '1rem',
			title: translate('analyses.deleteColumnTitle', 'Mark this for deletion. It will be deleted on save.'),
			align: 'center',
			minWidth: '50px',
		},
	]
	if (recipesMode) {
		analysisOptionsTableColumns.splice(
			6,
			0,
			{
				name: translate('analyses.optionsIngredientColumn', 'Ingredient'),
				property: 'product[name]',
				minWidth: '150px',
				title: translate(
					'analyses.optionsIngredientColumnTitle',
					'(Optional) an ingredient to be consumed as part of the recipe. Leave blank for any directions or checks that are part of the recipe',
				),
			},
			{
				name: '',
				property: 'product[barcodeFormat]',
				minWidth: '40px',
				icon: 'barcode',
				title: translate('analyses.optionsIngredientBarcodeColumnTitle', 'Does the ingredient have a barcode format?'),
				align: 'center',
			},
			{
				name: translate('analyses.optionsThresholdTypeColumn', 'Threshold Type'),
				property: 'thresholdType',
				minWidth: '120px',
				title: translate(
					'analyses.optionsThresholdTypeColumnTitle',
					'Controls whether the threshold constraints are restrictions on per-batch averages or production totals',
				),
			},
			{
				name: translate('analyses.optionsInventoryModeColumn', 'Inventory Mode'),
				property: 'inventoryMode',
				minWidth: '150px',
				title: translate(
					'analyses.optionsInventoryModeColumnTitle',
					'Controls whether an ingredient can have quantity/batches/lots entered',
				),
			},
			{
				name: translate('analyses.optionsEntryMethodColumn', 'Entry Method'),
				property: 'entryMethod',
				minWidth: '150px',
				title: translate(
					'analyses.optionsEntryMethodColumnTitle',
					'How data is expected to be entered for this option',
				),
			},
		)
		analysisOptionsTableColumns.splice(11, 3)
	}

	const analysisOptionChoiceTableColumns: Array<Column<Choice>> = [
		{
			property: 'id',
			name: '',
			icon: 'save',
			width: '1rem',
			title: translate(
				'analysisManagment.dirtyColumnTooltip',
				'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
			),
			align: 'center',
			minWidth: '40px',
		},
		{
			property: 'active',
			name: translate('analyses.choicesActiveColumn', 'Active'),
			width: '1rem',
			title: translate(
				'analyses.choicesActiveColumnTitle',
				'Whether this choice/threshold is active (active choices will be available on new samples and active thresholds affect subsequent acceptability tests)',
			),
			align: 'center',
			minWidth: '60px',
		},
		{
			property: 'plantId',
			name: translate('analyses.choicesGlobalColumn', 'Global'),
			width: '1rem',
			title: translate(
				'analyses.choicesGlobalColumnTitle',
				'Controls whether this choice/threshold applies to just the current plant or all plants',
			),
			align: 'center',
			minWidth: '60px',
		},
		{
			property: 'boundaryType',
			name: translate('analyses.choicesThresholdColumn', 'Threshold'),
			minWidth: '170px',
			title: translate(
				'analyses.choicesThresholdColumnTitle',
				`What type of choice is this?\r\n\t'Marginal' values are the threshold for triggering a warning;\r\n\t'Unacceptable' values are the threshold for triggering an error;\r\n\t'Acceptable' values are used to specify predefined options for choice-based fields, informational thresholds on the graphs, or minimum/maximum values on numeric fields`,
			),
		},
		{
			property: 'severityClass[name]',
			name: translate('analyses.choicesSeverityColumn', 'Severity'),
			minWidth: '150px',
			title: translate(
				'analyses.choicesSeverityColumnTitle',
				"(Optional) Which severity class this threshold/choice applies to (Or choose 'All Severities' for everything)",
			),
		},
		{
			property: 'product[name]',
			name: translate('analyses.choicesProductColumn', 'Product'),
			minWidth: '150px',
			title: translate(
				'analyses.choicesProductColumnTitle',
				"(Optional) Which product this threshold/choice applies to.\nChoosing a 'parent' product will apply to all sub-products as well. (Or choose 'All Products' for everything)",
			),
		},
		{
			property: 'constraint',
			name: translate('analyses.choicesConstraintTypeColumn', 'Constraint Type'),
			title: translate(
				'analyses.choicesConstraintTypeColumn',
				'Is this the maximum or minimum boundary/marginal/or unacceptable value?',
			),
		},
		{
			property: 'choice',
			name: translate('analyses.choicesValueColumn', 'Value'),
			minWidth: '150px',
			title: translate(
				'analyses.choicesValueColumnTitle',
				'For numeric fields, enter a value that represents a boundary for a warning level/etc.  For text/choice based options, enter possible choices.',
			),
		},
		{
			property: 'uuid',
			name: '',
			icon: 'trash',
			align: 'center',
			sortType: false,
			width: '1rem',
			title: translate('analyses.deleteColumnTitle', 'Mark this for deletion. It will be deleted on save.'),
			minWidth: '50px',
		},
	]
	if (recipesMode) {
		analysisOptionChoiceTableColumns.splice(4, 2)
	}
	const rulesColumns: Array<Column<Rule>> = [
		{
			property: 'id',
			name: '',
			icon: 'save',
			align: 'center',
			minWidth: '40px',
		},
		{
			property: 'active',
			name: translate('analyses.rulesActiveColumn', 'Active'),
			width: '1rem',
			title: translate(
				'analyses.rulesActiveColumnTitle',
				'Whether this rule is active (active rules will affect documents the next time they are edited)',
			),
			align: 'center',
			minWidth: '60px',
		},
		{
			property: 'tags',
			name: translate('analyses.rulesTagsColumn', 'Tags'),
			title: translate(
				'analyses.rulesTagsColumnTitle',
				'A group of plant, product, and/or location tags associated with this rule.\r\nIf more than one tag is chosen, all of them must be absent/present to trigger the rule.',
			),
			sortType: false,
		},
		{
			property: 'restriction',
			name: translate('analyses.rulesTagTriggerColumn', 'Tag Trigger'),
			title: translate(
				'analyses.rulesTagTriggerColumnTitle',
				'Is this rule triggered when the relevant tags are all present, or all absent?',
			),
		},
		{
			property: 'outcome',
			name: translate('analyses.rulesOutomeColumn', 'Outcome'),
			minWidth: '180px',
			title: translate(
				'analyses.rulesOutcomeColumnTitle',
				'If this rule is triggered, this is the effect that will be applied to the option',
			),
		},
		{
			property: 'description',
			name: translate('analyses.rulesDescriptionColumn', 'Description'),
			minWidth: '180px',
			title: translate('analyses.rulesDescriptionColumnTitle', 'A description of the rule'),
		},
		{
			property: 'created',
			name: translate('analyses.rulesCreatedColumn', 'Created'),
			title: translate('analyses.rulesCreatedColumnTitle', 'When the rule was created'),
		},
		{
			property: 'uuid',
			name: '',
			icon: 'trash',
			align: 'center',
			minWidth: '50px',
		},
	]

	// #endregion

	onMount(async () => {
		// Put UUIDs on the tags for all the rules - this kind of sucks, but I'm not sure of a better way to do it
		analysisOptions = analysisOptions.map(option => {
			option.rules = option.rules.map(rule => {
				rule.tags = rule.tags.map(tag => {
					return {
						...tag,
						uuid: getTagUuid(tag.entityType, tag.name),
					}
				})
				return rule
			})
			return option
		})

		// Merge in changes from the CRUD store on state load
		// Options CRUD store should have the latest version of all children too since it's kept up to date automatically
		if (selectedAnalysisUuid && optionCrudStore.hasChanges()) {
			const optionsCrud = $optionCrudStore[selectedAnalysisUuid]
			let createdOptions = Object.values(optionsCrud?.created ?? {})

			if (import.meta.hot) {
				// If we're HMRing, we need to filter out the options that are already in the state or it'll get added twice
				// This code will be tree-shaken in production builds
				createdOptions = createdOptions.filter(option => !analysisOptions.some(({ uuid }) => uuid === option.uuid))
			}

			// Update updated/deleted options with the cached options (and old uuids)
			const cachedExistingOptionsMap = new Map(
				Object.values(optionsCrud?.updated ?? {})
					.concat(Object.values(optionsCrud?.deleted ?? {}))
					.filter(option => option.id)
					.map(option => [option.id, option]),
			)

			// should be the only option without an id at this point
			const templateIndex = analysisOptions.findIndex(option => !option.id)

			let mergedOptions = analysisOptions
				.map(option => cachedExistingOptionsMap.get(option.id) ?? option) // Replace existing options with cached options if it exists
				.concat(createdOptions) // Add unsaved options

			if (templateIndex !== -1) {
				mergedOptions[templateIndex].rank = mergedOptions.length
			}

			mergedOptions.sort((a, b) => a.rank - b.rank)

			if (
				!mergedOptions[0].choices?.some(
					choice =>
						!choice.id &&
						!choiceCrudStore.isDirty(mergedOptions[0].uuid, choice) &&
						!choiceCrudStore.isDeleted(mergedOptions[0].uuid, choice),
				)
			) {
				// make sure we have the empty choice
				mergedOptions[0].choices?.push({
					...klona(defaultChoiceData),
					analysisOptionId: mergedOptions[0].id,
					plantId: selectedPlantId,
					uuid: uuid(),
				})
			}

			if (
				!mergedOptions[0].rules.some(
					rule =>
						!rule.id &&
						!ruleCrudStore.isDirty(mergedOptions[0].uuid, rule) &&
						!ruleCrudStore.isDeleted(mergedOptions[0].uuid, rule),
				)
			) {
				// make sure we have the empty rule
				mergedOptions[0].rules.push({
					...klona(defaultRuleData),
					uuid: uuid(),
				})
			}

			analysisOptions = mergedOptions

			if (!selectedOptionIndex && selectedOptionIndex !== 0) {
				selectedOptionIndex = 0
			}
		}
	})

	onDestroy(() => {
		optionUnsub()
		choiceUnsub()
		ruleUnsub()
	})
</script>

<div
	use:splitAction={{
		localStorageKey: 'analysisManagementSplitSize',
		defaultSizes: [50, 50],
		userAccountId: $session.userAccountId,
	}}
>
	<div class="card mb-2 mb-lg-0">
		<h5
			class="card-header"
			style="height: 54px;"
		>
			{recipesMode
				? translate('analyses.ingredientsDirections', 'Ingredients/Directions')
				: translate('analysisManagement.analysisOptions', 'Analysis Options')}
		</h5>
		<div class="card-body">
			<Table
				responsive
				stickyHeader
				columnHidingEnabled
				columnResizingEnabled
				localStorageKey={recipesMode ? 'recipes-ingredients-table' : 'analyses-options-table'}
				tableId="options-table"
				rows={analysisOptions}
				columns={analysisOptionsTableColumns}
				parentClass="mh-60vh"
				idProp="uuid"
				columnClickedMethod={async (...args) => {
					draggableRows?.withoutAnimation(async () => {
						await optionsTable?.defaultColumnClicked(...args)
					})
				}}
				bind:this={optionsTable}
			>
				{#snippet body({ rows: currentPageRows })}
					<DraggableRows
						firstRank={0}
						{currentPageRows}
						table={optionsTable}
						columns={analysisOptionsTableColumns}
						rankProperty="rank"
						computeClass={computeOptionRowClass}
						bind:rows={analysisOptions}
						rowClick={context => optionClick(context.row.originalIndex)}
						drop={context => {
							if (selectedAnalysis) {
								optionCrudStore.update(selectedAnalysis.uuid, context.rows[0])
								optionCrudStore.update(selectedAnalysis.uuid, context.rows[1])
								analysisCrudStore.update(selectedAnalysis)

								analysisOptions = analysisOptions
							}
						}}
						bind:this={draggableRows}
					>
						{#snippet prepend({ row })}
							<Td
								enterGoesDown
								property="id"
							>
								{#if optionIsDirty(row)}
									<i
										class="fas fa-fw fa-save"
										title="This option has unsaved changes."
									></i>
								{:else if !row.id}
									<i
										class="far fa-fw fa-floppy-disk-circle-xmark text-black-50"
										title="This option will not be saved until modified."
									></i>
								{/if}
							</Td>
						{/snippet}
						{#snippet children({ row })}
							{@const isDeleted = $optionCrudStore && optionIsDeleted(row)}
							{@const isDirty = $optionCrudStore && optionIsDirty(row)}
							<Td
								enterGoesDown
								property="option"
							>
								<Input
									id="option-option-{row.originalIndex}"
									placeholder={translate('analyses.newAnalysisOption', 'New Analysis Option')}
									showLabel={false}
									disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
									value={row.option}
									onchange={event => updateOptionKeypath(row.originalIndex, 'option', getEventValue(event))}
								/>
							</Td>
							<Td
								enterGoesDown
								property="active"
							>
								<Checkbox
									id="option-active-{row.originalIndex}"
									showLabel={false}
									disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
									checked={row.active}
									onchange={event => updateOptionKeypath(row.originalIndex, 'active', getEventChecked(event))}
								/>
							</Td>
							<Td
								enterGoesDown
								property="defaultValue"
							>
								<Input
									showLabel={false}
									id="option-default-{row.originalIndex}"
									maxlength={row.defaultType === 'FIXED' ? 100 : undefined}
									disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
									value={row.defaultValue}
									onchange={event => updateOptionKeypath(row.originalIndex, 'defaultValue', getEventValue(event))}
								>
									{#snippet prepend()}
										<Button
											outline
											disabled={!canEditGlobalFields(selectedAnalysisId)}
											onclick={async () => {
												await optionClick(row.originalIndex)
												defaultOptionValueModal?.open(selectedOption)
											}}
										>
											<i
												class="fa-fw"
												class:fas={row.defaultType !== 'PER_BATCH'}
												class:fak={row.defaultType === 'PER_BATCH'}
												class:fa-xmark={row.defaultType === 'FIXED'}
												class:fa-function={row.defaultType === 'SQL' || row.defaultType === 'STATIC_SQL'}
												class:fa-solid-xmark-circle-xmark={row.defaultType === 'PER_BATCH'}
											>
												{#if row.defaultType === 'STATIC_SQL'}
													<sup style="top: -0.7em; font-size: 70%;"><Icon icon="1" /></sup>
												{/if}
											</i>
										</Button>
									{/snippet}
								</Input>
							</Td>
							<Td
								enterGoesDown
								property="unit"
							>
								<Input
									id="option-unit-{row.originalIndex}"
									showLabel={false}
									disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
									value={row.unit}
									onchange={event => updateOptionKeypath(row.originalIndex, 'unit', getEventValue(event))}
								/>
							</Td>
							{#if recipesMode}
								<Td
									enterGoesDown
									property="product[name]"
								>
									<Select
										id="option-product-{row.originalIndex}"
										showLabel={false}
										emptyText={translate('common:none', 'None')}
										required={row.inventoryMode === 'MULTIPLE_LOTS'}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										value={row.product?.id ?? null}
										onchange={event => {
											const oldProduct = row.product
											optionProductChanged(row.originalIndex, getEventValue(event))
											// Steps with ingredients should default to Total Quantity / Multiple Lots, but don't change it if something else was already selected
											if (!oldProduct && row.entryMethod === 'USER_ENTERED' && row.inventoryMode === 'NONE') {
												updateOptionKeypath(row.originalIndex, 'entryMethod', 'TOTAL_QUANTITY')
												updateOptionKeypath(row.originalIndex, 'inventoryMode', 'MULTIPLE_LOTS')
											}
										}}
									>
										{#each products as { id, name, active }}
											{#if active || id === row.product?.id}
												<option
													value={id}
													disabled={!active}
													>{name}{active ? '' : ` (${translate('common:inactive', 'Inactive')})`}</option
												>
											{/if}
										{/each}
										{#if row.product && !products.some(({ id }) => id === row.product?.id)}
											<option
												disabled
												value={row.product?.id}
												>{row.product?.name} ({translate(
													'analyses.notInUseAtThisPlant',
													'Not in use at this plant',
												)})</option
											>
										{/if}
									</Select>
								</Td>
								<Td property="product[barcodeFormat]">
									{#if row.product}
										<Icon
											fixedWidth
											icon={row.product.barcodeFormat
												? row.inventoryMode === 'MULTIPLE_LOTS'
													? 'check'
													: 'warning'
												: 'xmark-large'}
											class={row.product.barcodeFormat
												? row.inventoryMode === 'MULTIPLE_LOTS'
													? 'text-success'
													: 'text-warning'
												: 'text-danger'}
											title={row.product.barcodeFormat
												? row.inventoryMode === 'MULTIPLE_LOTS'
													? translate('analyses.barcodeFormatSetTitle', 'Barcode format is set')
													: translate(
															'analyses.barcodeFormatSetMissingInventoryModeTitle',
															'Barcode format is set, but inventory mode is not "Multiple Lots"',
														)
												: translate('analyses.barcodeFormatNotSetTitle', 'No barcode format set')}
										/>
									{:else}
										<i class="text-muted text-nowrap">N/A</i>
									{/if}
								</Td>
								<Td
									enterGoesDown
									property="thresholdType"
								>
									<Select
										id="option-threshold-type-{row.originalIndex}"
										showLabel={false}
										showEmptyOption={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										value={row.thresholdType}
										onchange={event => {
											const value = getEventValueEnum(event, 'FIXED', 'PER_BATCH')
											updateOptionKeypath(row.originalIndex, 'thresholdType', value)
											if (value === 'PER_BATCH') {
												updateOptionKeypath(row.originalIndex, 'defaultType', 'PER_BATCH')
											}
										}}
									>
										<option value="FIXED">{translate('analyses.fixed', 'Fixed')}</option>
										<option value="PER_BATCH">{translate('analyses.perBatch', 'Per Batch')}</option>
									</Select>
								</Td>
								<Td
									enterGoesDown
									property="inventoryMode"
								>
									<Select
										id="option-inventory-mode-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										value={row.inventoryMode}
										onchange={event => {
											const inventoryMode = getEventValueEnum(event, 'NONE', 'SINGLE_LOT', 'MULTIPLE_LOTS', 'BASIC')
											updateOptionKeypath(row.originalIndex, 'inventoryMode', inventoryMode)
											// Avoid invalid state
											if (row.entryMethod === 'TOTAL_QUANTITY' && inventoryMode !== 'MULTIPLE_LOTS') {
												updateOptionKeypath(row.originalIndex, 'entryMethod', 'USER_ENTERED')
											}
										}}
									>
										<option
											value="NONE"
											title="Inventory will not be tracked for the consumed ingredient"
											>{translate('inventoryMode.none', 'None')}</option
										>
										<option
											value="SINGLE_LOT"
											title="User can enter a single lot/expiration for the consumed ingredient (quantity not supported)"
											>{translate('inventoryMode.singleLot', 'Single Lot')}</option
										>
										<option
											value="MULTIPLE_LOTS"
											title="User can enter full information for multiple lots/batches for the consumed ingredient"
											>{translate('inventoryMode.multipleLots', 'Multiple Lots')}</option
										>
										<!-- Basic inventory mode has not been implemented yet, so hide it unless it's already selected -->
										{#if row.inventoryMode === 'BASIC'}
											<option
												disabled
												title="User can enter quantity of ingredients consumed (lot/expiration not supported)"
												value="BASIC">{translate('inventoryMode.basic', 'Multiple Lots (Basic)')}</option
											>
										{/if}
									</Select>
								</Td>
								<Td
									enterGoesDown
									property="entryMethod"
								>
									<Select
										id="option-entry-method-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										value={row.entryMethod}
										onchange={event =>
											updateOptionKeypath(
												row.originalIndex,
												'entryMethod',
												getEventValueEnum(event, 'USER_ENTERED', 'MACHINE_ENTERED', 'TOTAL_QUANTITY'),
											)}
									>
										{@const isNumericValueType = row.valueType === 'INTEGER' || row.valueType === 'NUMBER'}
										{@const isMultipleLots = row.inventoryMode === 'BASIC' || row.inventoryMode === 'MULTIPLE_LOTS'}
										<option
											value="USER_ENTERED"
											title="This value will be filled out by a user"
											>{translate('entryMethod.userEntered', 'User Entered')}</option
										>
										<!-- "Machine Entered" entry method is not implemented yet, so hide it unless it's already selected -->
										{#if row.entryMethod === 'MACHINE_ENTERED'}
											<option
												disabled
												title="This value will not be filled out by a user, and will be read-only"
												value="MACHINE_ENTERED">{translate('entryMethod.machineEntered', 'Machine Entered')}</option
											>
										{/if}
										<option
											class:font-italic={!isNumericValueType || !isMultipleLots}
											disabled={!isNumericValueType || !isMultipleLots}
											title="This value will be calculated based on the total quantity of the ingredient consumed"
											value="TOTAL_QUANTITY"
											>{#if isNumericValueType && isMultipleLots}
												{translate('entryMethod.totalQuantity', 'Total Quantity')}
											{:else if !isNumericValueType}
												{translate(
													'entryMethod.totalQuantityNonNumeric',
													'Total Quantity ("Integer" or "Number" value type required)',
												)}
											{:else if !isMultipleLots}
												{translate(
													'entryMethod.totalQuantityNonBasicOrMultiple',
													'Total Quantity ("Multiple Lots" inventory mode required)',
												)}
											{/if}</option
										>
									</Select>
								</Td>
							{/if}
							<Td
								enterGoesDown
								property="valueType"
							>
								<Select
									id="option-value-type-{row.originalIndex}"
									showLabel={false}
									disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
									value={row.valueType}
									onchange={event => {
										const valueType = getEventValueEnum(
											event,
											'TEXT',
											'BOOLEAN',
											'CHOICE',
											'CURRENCY',
											'DATE',
											'DATETIME',
											'INTEGER',
											'NUMBER',
											'TIME',
										)
										updateOptionKeypath(row.originalIndex, 'valueType', valueType)
										if (row.entryMethod === 'TOTAL_QUANTITY' && valueType !== 'NUMBER' && valueType !== 'INTEGER') {
											updateOptionKeypath(row.originalIndex, 'entryMethod', 'USER_ENTERED')
										}
									}}
								>
									<option value="BOOLEAN">{translate('common:valueType.boolean', 'Boolean')}</option>
									<option value="INTEGER">{translate('common:valueType.integer', 'Integer')}</option>
									<option value="NUMBER">{translate('common:valueType.number', 'Number')}</option>
									<option value="CURRENCY">{translate('common:valueType.currency', 'Currency')}</option>
									<option value="DATE">{translate('common:valueType.date', 'Date')}</option>
									<option value="TEXT">{translate('common:valueType.text', 'Text')}</option>
									<option value="CHOICE">{translate('common:valueType.choice', 'Choice')}</option>
									<option value="TIME">{translate('common:valueType.time', 'Time')}</option>
									<option value="DATETIME">{translate('common:valueType.dateTime', 'DateTime')}</option>
								</Select>
							</Td>
							{#if !recipesMode}
								<Td
									enterGoesDown
									property="requiredToPerform"
								>
									<Checkbox
										id="option-required-to-perform-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										checked={row.requiredToPerform}
										onchange={event =>
											updateOptionKeypath(row.originalIndex, 'requiredToPerform', getEventChecked(event))}
									/>
								</Td>
								<Td
									enterGoesDown
									property="requiredToClose"
								>
									<Checkbox
										id="option-required-to-close-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										checked={row.requiredToClose}
										onchange={event =>
											updateOptionKeypath(row.originalIndex, 'requiredToClose', getEventChecked(event))}
									/>
								</Td>
								<Td
									enterGoesDown
									property="informational"
								>
									<Checkbox
										id="option-informational-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										checked={row.informational}
										onchange={event => updateOptionKeypath(row.originalIndex, 'informational', getEventChecked(event))}
									/>
								</Td>
							{/if}
							<Td
								enterGoesDown
								property="uuid"
							>
								{#if isDeleted}
									<Button
										id="option-delete-{row.originalIndex}"
										outline
										size="sm"
										color="danger"
										disabled={!canEditGlobalFields(selectedAnalysisId) || (!row.id && !isDirty)}
										iconClass="trash-undo"
										onclick={() => undoDeleteOption(row.originalIndex)}
									/>
								{:else}
									<Button
										id="option-delete-{row.originalIndex}"
										outline
										size="sm"
										color="danger"
										disabled={!canEditGlobalFields(selectedAnalysisId) || (!row.id && !isDirty)}
										iconClass="trash"
										onclick={() => deleteOption(row.originalIndex)}
									/>
								{/if}
							</Td>
						{/snippet}
					</DraggableRows>
				{/snippet}
				{#snippet noRows()}
					<tr>
						<td
							colspan={analysisOptionsTableColumns.length}
							class="text-center"
						>
							{translate('analysisManagement.noOptionsForSelectedAnalysis', 'No options for the selected analysis')}
						</td>
					</tr>
				{/snippet}
			</Table>
		</div>
		<div
			class="card-footer d-flex justify-content-between flex-wrap"
			style="gap: 0.25rem"
		>
			<Button
				outline
				size="sm"
				color="success"
				iconClass="plus"
				disabled={!canEditGlobalFields(selectedAnalysisId)}
				onclick={addOption}>{translate('analysisManagement.newOption', 'New Option')}</Button
			>
			<Button
				outline
				size="sm"
				disabled={!canEditGlobalFields(selectedAnalysisId)}
				iconClass="pen-to-square"
				onclick={() => defaultOptionValueModal?.open(selectedOption)}
				>{translate('analysisManagement.editTestDefaultValue', 'Edit/Test Default Value')}...</Button
			>
		</div>
	</div>
	<div class="card">
		<div class="card-header">
			<div class="form-row">
				<div class="col">
					<ul class="nav nav-tabs card-header-tabs align-items-end">
						<li class="nav-item w-50 text-center">
							<button
								class="nav-link w-100 cursor-pointer mr-1"
								class:active={$selectedOptionTab === 'THRESHOLDS'}
								onclick={() => ($selectedOptionTab = 'THRESHOLDS')}
							>
								{translate('analysisManagement.choicesAndThresholds', 'Choices and Thresholds')}
								{#if choicesLoading}
									<Icon isLoading />
								{:else if selectedOptionChoices}
									({selectedOptionChoices.length - 1})
								{:else}
									(0)
								{/if}
							</button>
						</li>
						<li class="nav-item w-50 text-center">
							<button
								class="nav-link w-100 cursor-pointer ml-1"
								class:active={$selectedOptionTab === 'RULES'}
								onclick={() => ($selectedOptionTab = 'RULES')}
							>
								{translate('analysisManagement.rulesAndRestrictions', 'Rules and Restrictions')}
								{#if selectedOption?.rules}
									({selectedOption.rules.length - 1})
								{:else}
									(0)
								{/if}
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="card-body">
			<div class:d-none={$selectedOptionTab !== 'THRESHOLDS'}>
				<Table
					responsive
					stickyHeader
					columnHidingEnabled
					columnResizingEnabled
					localStorageKey={recipesMode ? 'recipes-thresholds-table' : 'analyses-thresholds-table'}
					tableId="choices-thresholds-table"
					rows={selectedOptionChoices}
					columns={analysisOptionChoiceTableColumns}
					parentClass="mh-60vh"
					lazySort={lazySortChoices}
					idProp="uuid"
				>
					{#snippet children({ row })}
						{#if row.active || $showInactive}
							{@const isDeleted = $choiceCrudStore && choiceIsDeleted(row)}
							{@const isDirty = $choiceCrudStore && choiceIsDirty(row)}
							<tr class:table-danger={isDeleted}>
								<Td
									enterGoesDown
									property="id"
								>
									{#if isDirty}
										<i
											class="fas fa-fw fa-save"
											title={translate(
												'analyses.choiceAttnHasUnsavedChangesTitle',
												'This choice/threshold has unsaved changes.',
											)}
										></i>
									{:else if !isDirty && !row.id}
										<i
											class="far fa-fw fa-floppy-disk-circle-xmark text-black-50"
											title={translate(
												'analyses.choiceAttnWillNotBeSavedTitle',
												'This choice/threshold will not be saved until modified.',
											)}
										></i>
									{/if}
								</Td>
								<Td
									enterGoesDown
									property="active"
								>
									<Checkbox
										id="choice-active-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditChoice(row.plantId) || isDeleted}
										checked={row.active}
										onchange={event => updateChoiceKeypath(row.originalIndex, 'active', getEventChecked(event))}
									/>
								</Td>
								<Td
									enterGoesDown
									property="plantId"
								>
									<Checkbox
										id="choice-global-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) ||
											canEditChoice(row.plantId) ||
											!!row.severityClass ||
											!!isDeleted}
										checked={!row.plantId}
										onchange={event => choiceGlobalChecked(row.originalIndex, getEventChecked(event))}
									/>
								</Td>
								<Td
									enterGoesDown
									property="boundaryType"
								>
									<Select
										id="choice-boundary-{row.originalIndex}"
										showLabel={false}
										showEmptyOption={false}
										emptyValue="UNACCEPTABLE"
										disabled={!canEditChoice(row.plantId) || isDeleted}
										value={row.boundaryType}
										onchange={event =>
											updateChoiceKeypath(
												row.originalIndex,
												'boundaryType',
												getEventValueEnum(event, 'ALLOWED', 'BOUNDARY', 'MARGINAL', 'UNACCEPTABLE'),
											)}
									>
										{#each Object.entries(applicableThresholds) as [key, threshold]}
											<option value={key}>{threshold}</option>
										{/each}
									</Select>
								</Td>
								{#if !recipesMode}
									<Td
										enterGoesDown
										property="severityClass[name]"
									>
										<Select
											id="choice-severity-{row.originalIndex}"
											showLabel={false}
											emptyText={translate('common:allSeverities', 'All Severities')}
											disabled={!canEditChoice(row.plantId) || isDeleted}
											value={row.severityClass?.id ?? null}
											onchange={event => choiceSeverityChanged(row.originalIndex, getEventValue(event))}
										>
											{#each severityClasses as { id, name }}
												<option value={id}>{name}</option>
											{/each}
										</Select>
									</Td>
									<Td
										enterGoesDown
										property="product[name]"
									>
										<Select
											id="choice-product-{row.originalIndex}"
											showLabel={false}
											emptyText={translate('common:allProducts', 'All Products')}
											disabled={!canEditChoice(row.plantId) || isDeleted}
											value={row.product?.id ?? null}
											onchange={event => choiceProductChanged(row.originalIndex, getEventValue(event))}
										>
											{#each products as { id, name, active }}
												{#if active || id === row.product?.id}
													<option
														value={id}
														disabled={!active}
														>{name}{active ? '' : ` (${translate('common:inactive', 'Inactive')})`}</option
													>
												{/if}
											{/each}
											{#if row.product && !products.some(({ id }) => id === row.product?.id)}
												<option
													disabled
													value={row.product?.id}
													>{row.product?.name} ({translate(
														'analyses.notInUseAtThisPlant',
														'Not in use at this plant',
													)})</option
												>
											{/if}
										</Select>
									</Td>
								{/if}
								<Td
									enterGoesDown
									property="constraint"
									class="max-width: 150px;"
								>
									<Select
										id="choice-constraint-{row.originalIndex}"
										showLabel={false}
										showEmptyOption={false}
										emptyValue="NONE"
										disabled={!canEditChoice(row.plantId) || isDeleted}
										value={row.constraint}
										onchange={event =>
											updateChoiceKeypath(
												row.originalIndex,
												'constraint',
												getEventValueEnum(event, 'NONE', 'MINIMUM', 'MAXIMUM', 'NOT_EQUAL'),
											)}
									>
										{#each getConstraintsForValueType(constraintsMap, thresholdValueType) as [key, constraint]}
											<option value={key}>{constraint}</option>
										{/each}
									</Select>
								</Td>
								<Td
									enterGoesDown
									property="choice"
									class="max-width: 150px;"
								>
									<MightyMorphingInput
										id="choice-choice-{row.originalIndex}"
										placeholder={translate('analyses.mightyMorphingPlaceholder', 'Enter a value')}
										showLabel={false}
										valueType={thresholdValueType}
										disabled={!canEditChoice(row.plantId) || isDeleted}
										value={row.choice}
										on:change={event => updateChoiceKeypath(row.originalIndex, 'choice', event.detail.value)}
									></MightyMorphingInput>
								</Td>
								<Td
									enterGoesDown
									property="uuid"
								>
									{#if isDeleted}
										<Button
											id="choice-delete-{row.originalIndex}"
											outline
											size="sm"
											color="danger"
											disabled={!canEditChoice(row.plantId) || (!row.id && !isDirty)}
											iconClass="trash-undo"
											onclick={() => undoDeleteChoice(row.originalIndex)}
										/>
									{:else}
										<Button
											id="choice-delete-{row.originalIndex}"
											outline
											size="sm"
											color="danger"
											disabled={!canEditChoice(row.plantId) || (!row.id && !isDirty)}
											iconClass="trash"
											onclick={() => deleteChoice(row.originalIndex)}
										></Button>
									{/if}
								</Td>
							</tr>
						{/if}
					{/snippet}
					{#snippet noRows()}
						<tr>
							<td
								colspan={analysisOptionChoiceTableColumns.length}
								class="text-center"
							>
								{#if selectedOption}
									{translate(
										'analysisManagement.noChoicesForSelectedOption',
										'No choices or thresholds for the selected analysis option',
									)}
								{:else}
									{translate('analysisManagement.selectAnOption', 'Select an Analysis Option')}
								{/if}
							</td>
						</tr>
					{/snippet}
				</Table>
			</div>
			<div class:d-none={$selectedOptionTab !== 'RULES'}>
				<Table
					responsive
					stickyHeader
					columnHidingEnabled
					columnResizingEnabled
					localStorageKey={recipesMode ? 'recipes-rules-table' : 'rules-table'}
					tableId="rules-table"
					rows={selectedOption?.rules ?? []}
					columns={rulesColumns}
					parentClass="mh-60vh"
					lazySort={lazySortRules}
					idProp="uuid"
				>
					{#snippet children({ row })}
						{#if row.active || $showInactive}
							{@const isDeleted = $ruleCrudStore && ruleIsDeleted(row)}
							{@const isDirty = $ruleCrudStore && ruleIsDirty(row)}
							<tr class:table-danger={isDeleted}>
								<Td
									enterGoesDown
									property="id"
								>
									{#if isDirty}
										<i
											class="fas fa-fw fa-save"
											title={translate(
												'analyses.ruleAttnHasUnsavedChangesTitle',
												'This rule/restriction has unsaved changes.',
											)}
										></i>
									{:else if !isDirty && !row.id}
										<i
											class="far fa-fw fa-floppy-disk-circle-xmark text-black-50"
											title={translate(
												'analyses.ruleAttnWillNotBeSavedTitle',
												'This rule/restriction will not be saved until modified.',
											)}
										></i>
									{/if}
								</Td>
								<Td
									enterGoesDown
									property="active"
								>
									<Checkbox
										id="rule-active-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										checked={row.active}
										onchange={event => updateRuleKeypath(row.originalIndex, 'active', getEventChecked(event))}
									/>
								</Td>
								<Td
									enterGoesDown
									property="tags"
									class="d-flex justify-content-between"
									style="white-space: nowrap;"
								>
									<span
										>{#each getDisplayTagsList(row.originalIndex) as tag}
											<TagBadge {tag}></TagBadge>
										{:else}
											<i class="text-muted">{translate('analyses.noTags', 'No Tags')}</i>
										{/each}</span
									>
									<Button
										outline
										id="rule-tags-{row.originalIndex}"
										size="sm"
										class="mx-1"
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										iconClass="pencil"
										onclick={() => tagSelectionModal?.open(row)}
									/>
								</Td>
								<Td
									enterGoesDown
									property="restriction"
								>
									<div style="text-overflow: hidden;">
										<Checkbox
											showLabel={false}
											type="radio"
											disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
											trueLabel={translate('common:present', 'Present')}
											falseLabel={translate('common:absent', 'Absent')}
											checked={row.restriction === 'PRESENT'}
											onchange={event =>
												updateRuleKeypath(
													row.originalIndex,
													'restriction',
													stringToBoolean(getEventValue(event)) ? 'PRESENT' : 'ABSENT',
												)}
										/>
									</div>
								</Td>
								<Td
									enterGoesDown
									property="outcome"
								>
									<Select
										id="rule-outcome-{row.originalIndex}"
										showLabel={false}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										value={row.outcome}
										onchange={event =>
											updateRuleKeypath(
												row.originalIndex,
												'outcome',
												getEventValueEnum(
													event,
													'INACTIVE',
													'HIDDEN',
													'READONLY',
													'REQUIRED_TO_PERFORM',
													'REQUIRED_TO_CLOSE',
												),
											)}
									>
										{#each Object.entries(outcomeMap) as [key, outcome]}
											<option value={key}>{outcome}</option>
										{/each}
									</Select>
								</Td>
								<Td
									enterGoesDown
									property="description"
								>
									<Input
										id="rule-description-{row.originalIndex}"
										showLabel={false}
										placeholder="New Rule"
										value={row.description}
										disabled={!canEditGlobalFields(selectedAnalysisId) || isDeleted}
										onchange={event => updateRuleKeypath(row.originalIndex, 'description', getEventValue(event))}
									/>
								</Td>
								<Td
									property="created"
									style="white-space: nowrap;"
								>
									{row.created?.toLocaleString() ?? ''}
								</Td>
								<Td
									enterGoesDown
									property="uuid"
								>
									{#if isDeleted}
										<Button
											id="rule-delete-{row.originalIndex}"
											outline
											size="sm"
											color="danger"
											disabled={!canEditGlobalFields(selectedAnalysisId) || (!row.id && !isDirty)}
											iconClass="trash-undo"
											onclick={() => undoDeleteRule(row.originalIndex)}
										/>
									{:else}
										<Button
											id="rule-delete-{row.originalIndex}"
											outline
											size="sm"
											color="danger"
											disabled={!canEditGlobalFields(selectedAnalysisId) || (!row.id && !isDirty)}
											iconClass="trash"
											onclick={() => deleteRule(row.originalIndex)}
										></Button>
									{/if}
								</Td>
							</tr>
						{/if}
					{/snippet}
					{#snippet noRows()}
						<tr>
							<td
								colspan="8"
								class="text-center"
							>
								{#if selectedOption}
									{translate(
										'analyses.noRulesForSelectedOption',
										'There are no rules or restrictions for the selected analysis option',
									)}
								{:else}
									{translate('analysisManagement.selectAnOption', 'Select an Analysis Option')}
								{/if}
							</td>
						</tr>
					{/snippet}
				</Table>
			</div>
		</div>
		<div
			class="card-footer d-flex justify-content-between flex-wrap"
			style="gap: 0.25rem"
		>
			{#if $selectedOptionTab === 'THRESHOLDS'}
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					onclick={addChoice}
					disabled={!canEditGlobalFields(-1) || !selectedOption}
					>{translate('analysisManagement.newChoiceThreshold', 'New Choice/Threshold')}</Button
				>
				<Button
					outline
					size="sm"
					disabled={!canEditGlobalFields(selectedAnalysisId) || !selectedOption}
					iconClass="vial-circle-check"
					onclick={() => testThresholdsModal?.open()}
					>{translate('analysisManagement.testThresholds', 'Test Thresholds')}...</Button
				>
			{:else if $selectedOptionTab === 'RULES'}
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					onclick={addRule}
					disabled={!canEditGlobalFields(-1) || !selectedOption}>{translate('common:newRule', 'New Rule')}</Button
				>
				<Button
					outline
					size="sm"
					disabled={!canEditGlobalFields(selectedAnalysisId) || !selectedOption}
					iconClass="tags"
					onclick={() => testRulesModal?.open()}>{translate('analysisManagement.testRules', 'Test Rules')}...</Button
				>
			{/if}
		</div>
	</div>
</div>

<DefaultOptionValueModal
	{recipesMode}
	{analysisOptions}
	bind:this={defaultOptionValueModal}
	on:confirm={event => {
		if (recipesMode && event.detail.defaultType === 'PER_BATCH') {
			updateOptionKeypath(selectedOptionIndex, 'thresholdType', 'PER_BATCH')
		} else if (recipesMode && selectedOption.thresholdType === 'FIXED') {
			updateOptionKeypath(selectedOptionIndex, 'thresholdType', 'FIXED')
		}
		updateOptionKeypath(selectedOptionIndex, 'defaultType', event.detail.defaultType)
		updateOptionKeypath(selectedOptionIndex, 'defaultValue', event.detail.defaultValue)
	}}
></DefaultOptionValueModal>

<TestThresholdsModal
	{selectedOption}
	{thresholdsMap}
	{selectedPlant}
	{plants}
	{products}
	{severityClasses}
	showDirtyWarning={!selectedAnalysisUuid || optionCrudStore.isDirty(selectedAnalysisUuid, selectedOption)}
	bind:this={testThresholdsModal}
></TestThresholdsModal>

<TestRulesModal
	{plants}
	{selectedOption}
	{selectedPlant}
	showDirtyWarning={!selectedAnalysisUuid || optionCrudStore.isDirty(selectedAnalysisUuid, selectedOption)}
	bind:this={testRulesModal}
></TestRulesModal>

<TagSelectionModal
	{tagAddRemoveStore}
	{tagCrudStore}
	{tagStore}
	bind:this={tagSelectionModal}
	on:confirm={event => {
		updateRuleKeypath(event.detail.index, 'tags', event.detail.tags)
	}}
></TagSelectionModal>
