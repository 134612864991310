<script lang="ts">
	import DateRange from '@isoftdata/svelte-date-range'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import { getContext } from 'svelte'
	import type { i18n } from 'i18next'

	interface Props {
		dates?: { from: string; to: string } | undefined
		userAccounts: Array<{ id: number; name: string }>
		userAccountId?: number | null
		statuses?: Array<'INSERT' | 'UPDATE' | 'DELETE'>
		isLoading?: boolean
		fetchData: () => Promise<void>
	}

	let {
		//
		dates = $bindable(undefined),
		userAccounts,
		userAccountId = $bindable(null),
		statuses = $bindable(['INSERT', 'UPDATE', 'DELETE']),
		isLoading = $bindable(false),
		fetchData,
	}: Props = $props()

	const { t: translate } = getContext<i18n>('i18next')
</script>

<div class="form-row align-items-end">
	<div
		class="col-12 col-md-6"
		style="margin-bottom: 0.75rem"
	>
		<DateRange
			rangeColClass="col-12 col-md-4"
			toColClass="col-6 col-md-4"
			fromColClass="col-6 col-md-4"
			excludedRanges={[]}
			defaultRange="Always"
			bind:dates
			on:change={fetchData}
		></DateRange>
	</div>
	<div class="col-12 col-md-3">
		<Select
			label={translate('changelog.performedByUserLabel', 'Performed by User')}
			labelParentClass="mb-3"
			options={userAccounts}
			bind:value={userAccountId}
			onchange={fetchData}
		>
			{#snippet option({ option })}
				{#if option}
					<option value={option.id}>{option.name}</option>
				{/if}
			{/snippet}
		</Select>
	</div>
	<div class="col-auto mb-3">
		{#each ['INSERT', 'UPDATE', 'DELETE'] as status}
			<div class="form-check">
				<input
					class="form-check-input"
					type="checkbox"
					value={status}
					id="show{status}"
					bind:group={statuses}
					onchange={fetchData}
				/>
				<label
					class="form-check-label"
					for="show{status}"
				>
					{#if status === 'INSERT'}
						{translate('changelog.showInsertRows', 'Show inserted rows')}
					{:else if status === 'UPDATE'}
						{translate('changelog.showUpdateRows', 'Show updated rows')}
					{:else if status === 'DELETE'}
						{translate('changelog.showDeleteRows', 'Show deleted rows')}
					{/if}
				</label>
			</div>
		{/each}
	</div>
	<div class="col-auto ml-auto ml-md-0 mb-3">
		<Button
			outline
			size="sm"
			iconClass="refresh"
			{isLoading}
			onclick={fetchData}
		></Button>
	</div>
</div>
