<script lang="ts">
	import Login from '@isoftdata/svelte-login'
	import Button from '@isoftdata/svelte-button'
	import ChangePasswordModal from '@isoftdata/svelte-change-password'
	import type { i18n, SvelteAsr } from 'types/common'
	import { getContext, onMount, tick, type ComponentEvents } from 'svelte'
	import { makeLoginRequest } from 'utility/login-helper'
	import Cookies from 'js-cookie'
	import { getPasswordRules } from 'utility/get-password-rules'
	import { graphql } from '$houdini'
	import { changePasswordMutation } from 'utility/password.js'

	// Both of these types might not be needed, just having it here to make things not error out
	type Plant = {
		id: number
		name: string
	}

	type Account = {
		userName: string
		recoveryEmail: string
	}

	const { t: translate, services } = getContext<i18n>('i18next')

	const passwordRecoveryMutation = graphql(`
		mutation PasswordRecovery($userName: String!) {
			passwordRecovery(userName: $userName) {
				recoveryEmail
				userName
			}
		}
	`)

	export let asr: SvelteAsr
	export let plantList: Plant[] = []
	export let selectedPlantId: number
	export let userName: string
	export let showLanguageSelector: boolean
	export let selectedLanguage: string
	export let apiVersionNumber: string
	export let apiSchemaVersion: string
	export let accountActivationSuccess: boolean = false
	export let rememberMe: boolean = false

	// interface ErrorMessages {
	// 	message: string
	// 	subMessages: string[]
	// }

	let isLoading = false
	let loginComponent: Login
	let newPasswordAfterChangePassword = ''
	let passwordRules = {}
	let passwordValidationFailed = false

	async function loadLanguages() {
		const data: Record<string, { name: string; nativeName: string }> =
			await services.backendConnector.backend.getLanguages()
		console.log(data)
		const arr = Object.entries(data)
			.map(([code, obj]) => ({ code, name: obj.name.split(';')[0], nativeName: obj.nativeName.split(',')[0] }))
			.sort((a, b) => a.nativeName.localeCompare(b.nativeName))
		console.log(arr)
		return arr
	}

	let changePasswordModalComponent: ChangePasswordModal

	onMount(() => {
		if (accountActivationSuccess) {
			loginComponent.setAlert({
				message: translate(
					'login.accountActivatedAlert',
					'Account activated successfully! Please login with your new password.',
				),
				color: 'success',
				icon: 'user-plus',
			})
		}
	})

	async function sendPasswordRecoveryEmail(userName: string): Promise<Account> {
		const { data } = await passwordRecoveryMutation.mutate({ userName })
		if (!data) {
			throw new Error('No data returned from passwordRecovery mutation')
		}
		return data.passwordRecovery
	}

	async function login(event: ComponentEvents<Login>['login']) {
		isLoading = true
		const { username, password, siteId: plantId, rememberMe } = event.detail

		try {
			if (!plantId) {
				throw new Error(translate('login.noPlantSelectedAlert', 'Please select a plant to login.'))
			}

			const sessionToken = await makeLoginRequest({ username, password, plantId })

			Cookies.set('auth-token', sessionToken)

			if (rememberMe) {
				localStorage.setItem('lastUsername', userName)
			} else {
				localStorage.removeItem('lastUsername')
			}

			localStorage.setItem('lastSelectedPlantId', plantId.toString())

			asr.go('app')
		} catch (err) {
			const error = err as { message: string; extensions: { code: string; type: string; notes: string } }
			console.log(error)

			if (error?.extensions?.code === 'LOGIN_ERROR') {
				switch (error?.extensions?.type) {
					case 'BAD_CREDENTIALS':
					case 'INVALID_ACCOUNT':
					case 'INVALID_PASSWORD':
						// Specifically, this is if the login was just typed in wrong, use the more opinionated route
						loginComponent.setAlert({
							message: translate('login.invalidPasswordAlert', 'Invalid username or password. Please try again.'),
						})
						break
					case 'ACCOUNT_LOCKED':
					case 'ACCOUNT_DEACTIVATED':
					case 'INSUFFICIENT_PRIVILEGES':
					default:
						loginComponent.setAlert({ message: error.message, subMessages: [error?.extensions?.notes ?? ''] })
						break
				}
			} else {
				loginComponent.setAlert({ message: error.message, subMessages: [error?.extensions?.notes ?? ''] })
			}
		} finally {
			isLoading = false
		}
	}

	async function initiatePasswordRecovery(event) {
		const userName = event.detail.username
		await changePasswordModalComponent.initiatePasswordRecovery(userName, true)
		try {
			passwordRules = await getPasswordRules()
		} catch (err) {
			console.error(err)
			passwordRules = {}
		}
	}

	async function changePassword(resetToken: string, currentPassword: string, newPassword: string, userName: string) {
		// this changePassword() is in a try catch in the changePasswordModal component, we can just handle the "success" here
		await changePasswordMutation.mutate({
			userName,
			newPassword,
			resetToken,
		})

		newPasswordAfterChangePassword = newPassword
		await tick()
		loginComponent.setAlert({
			message: 'Password changed successfully! Choose your plant to login.',
			color: 'success',
			icon: 'check',
		})
	}
</script>

<Login
	{rememberMe}
	{isLoading}
	password={newPasswordAfterChangePassword}
	siteList={plantList}
	forgotPasswordDisabled={!userName}
	siteLabel={translate('common:plant', 'Plant')}
	logoImagePath="./images/presage_white.svg"
	bind:this={loginComponent}
	bind:username={userName}
	bind:selectedSiteId={selectedPlantId}
	on:login={login}
	on:forgotPassword={initiatePasswordRecovery}
>
	<hr />
	<Button
		class="mb-3"
		size="sm"
		outline
		color="success"
		block
		iconClass="user-plus"
		href={asr.makePath('activate-new-account')}
	>
		{translate('login.activateAccountButton', 'Activate New Account')}...
	</Button>
	<div class="mt-3 row align-items-center justify-content-between">
		<div class="col">
			<a
				href="https://changelog.isoftdata.com/?product=presage-web#__buildVersion__"
				target="presage_web_changelog"
			>
				<small
					class="text-dim"
					title="Built Date: __buildDate__
	Enviroment: __buildEnv__">App: __buildVersion__</small
				>
			</a>
		</div>
		<div class="col">
			{#if apiVersionNumber}
				<a
					href="https://changelog.isoftdata.com/?product=presage-api#{apiVersionNumber}"
					target="presage_api_changelog"
					title="Click to view API Changelog"
				>
					<small class="text-dim">API: {apiVersionNumber}</small>
				</a>
			{/if}
		</div>
		<div
			class="col-auto text-ellipsis"
			style="max-width: 150px;"
		>
			{#if apiSchemaVersion}
				<small
					class="text-dim"
					title={apiSchemaVersion}>Database: {apiSchemaVersion}</small
				>
			{/if}
		</div>
	</div>
	<details open={showLanguageSelector}>
		<summary class="fa fa-language fa-2x"></summary>
		<div
			class="d-flex justify-content-center mt-2 flex-wrap"
			style="gap: 0.25rem"
		>
			{#await loadLanguages() then languages}
				{#each languages as language}
					<a
						href="?lng={language.code}"
						class:badge-secondary={selectedLanguage !== language.code}
						class:badge-primary={selectedLanguage === language.code}
						title={language.name}
						class="badge">{language.nativeName}</a
					>
				{/each}
			{:catch}
				<small class="text-danger">Failed to load languages</small>
			{/await}
		</div>
	</details>
</Login>

<ChangePasswordModal
	bind:this={changePasswordModalComponent}
	bind:passwordValidationFailed
	sendPasswordResetTokenEmail={sendPasswordRecoveryEmail}
	{changePassword}
	{passwordRules}
/>

<style>
	details[open] summary {
		border-bottom: 1px solid #aaa;
	}
</style>
