export default {
    "name": "UserConfigurationData",
    "kind": "HoudiniQuery",
    "hash": "a01e73dc75d765a641896b872d0b8c9b5a1a27905ffd96d8e8892cf9619f6665",

    "raw": `query UserConfigurationData($pagination: PaginatedInput, $userAccountsFilter: UserFilter) {
  userAccounts(filter: $userAccountsFilter, pagination: $pagination) {
    data {
      id
      name
      status
      lastLoginDate
      authorizedPlants {
        id
      }
    }
  }
  permissions(pagination: $pagination) {
    data {
      id
      displayName
      description
      codeName
      category
    }
  }
  groups(pagination: $pagination) {
    data {
      id
      name
      workerGroup
      groupPermissions {
        permissionId
        value
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts(filter: $userAccountsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "UserAccountStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "lastLoginDate": {
                                        "type": "DateTime",
                                        "keyRaw": "lastLoginDate",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "authorizedPlants": {
                                        "type": "Plant",
                                        "keyRaw": "authorizedPlants",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "permissions": {
                "type": "PermissionResponse",
                "keyRaw": "permissions(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Permission",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "workerGroup": {
                                        "type": "Boolean",
                                        "keyRaw": "workerGroup",
                                        "visible": true
                                    },

                                    "groupPermissions": {
                                        "type": "GroupPermission",
                                        "keyRaw": "groupPermissions",

                                        "selection": {
                                            "fields": {
                                                "permissionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "permissionId",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "PermissionValue",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "userAccountsFilter": "UserFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "UserAuthorizedPlantsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserGroupsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserFilter": {
                "activeOnly": "Boolean",
                "authorizedPlants": "UserAuthorizedPlantsFilter",
                "groups": "UserGroupsFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=93032877f5867c7543bb50019ff255fa25f705f7c3f44646b59d4b16412b1f15";