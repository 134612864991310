import type { i18n } from 'i18next'
import type { Entries, Spread } from 'type-fest'
import type {
	ALERT_SUBSCRIPTION$result,
	AlertType$options,
	UserAccounts$result,
	NewAlertSubscription,
	Locations$result,
	AlertTiming$options,
} from '$houdini'
import { graphql } from '$houdini'

export const locationsQuery = graphql(`
	query Locations($filter: LocationFilter, $pagination: PaginatedInput) {
		locations(filter: $filter, pagination: $pagination) {
			data {
				id
				plantId
				code
				location
			}
		}
	}
`)

graphql(`
	fragment AlertSubscriptionData on AlertSubscription {
		id
		emailAddresses
		phoneNumbers {
			number
			carrier {
				domain
			}
		}
		active
		body
		description
		expirationHourOffset
		method
		rank
		requireAcceptable
		requireFailed
		requireMarginal
		requireMissing
		sendAcceptable
		sendFailed
		sendMarginal
		timing
		title
		type
		template {
			id
			name
		}
		plant {
			id
			code
			name
		}
		analysis {
			id
			name
		}
		location {
			id
			location
		}
		workOrderType {
			id
			name
		}
		investigationTrigger {
			id
			description
		}
		group {
			id
			name
		}
		schedule {
			id
			name
		}
		userAccount {
			id
			name
		}
		product {
			id
			name
		}
	}
`)

export const createAlertSubscription = graphql(`
	mutation CreateAlertSubscription($input: NewAlertSubscription!) {
		savedAlertSubscription: createAlertSubscription(input: $input) {
			...AlertSubscriptionData
		}
	}
`)

export const updateAlertSubscription = graphql(`
	mutation UpdateAlertSubscription($input: AlertSubscriptionUpdate!) {
		savedAlertSubscription: updateAlertSubscription(input: $input) {
			...AlertSubscriptionData
		}
	}
`)

export type AlertType = AlertType$options
export type AlertSubscriptionApi = ALERT_SUBSCRIPTION$result['alertSubscriptions'][0]
export type AlertTemplate = ALERT_SUBSCRIPTION$result['alertTemplates'][0]
export type UserAccount = UserAccounts$result['userAccounts']['data'][0]
export type Schedule = ALERT_SUBSCRIPTION$result['schedules']['data'][0]
export type Group = ALERT_SUBSCRIPTION$result['groups']['data'][0]
export type Analysis = ALERT_SUBSCRIPTION$result['analyses']['data'][0]
export type Plant = ALERT_SUBSCRIPTION$result['plants']['data'][0]
export type Product = ALERT_SUBSCRIPTION$result['products']['data'][0]
export type WorkOrderType = ALERT_SUBSCRIPTION$result['workOrderTypes']['data'][0]
export type InvestigationRule = ALERT_SUBSCRIPTION$result['investigationTriggers']['data'][0]
export type AlertTiming = AlertTiming$options
export type AlertMethod = 'EMAIL' | 'TEXT' | 'SHOW'
export type ResultStatus = 'FAILED' | 'ACCEPTABLE' | 'MARGINAL' | 'MISSING'
export type AlertTypeCategory = 'Data' | 'User'
export type AlertTypeMap = Map<AlertType, AlertTypeMetadata>
export type DataResultStatus = Record<ResultStatus, ResultStatusOption>
export type UserResultStatus = Record<'FAILED', ResultStatusOption>
export type LocationData = Locations$result['locations']['data'][0]
export interface SavedAlertSubscriptionForApi extends NewAlertSubscription {
	id: number
}

export interface LoadedPreview {
	to: string
	subject: string
	body: string
	error: string
}

export interface PreviewConfiguration {
	documentId: string
	scheduleId: number | null
	date: string
	loadedPreview: LoadedPreview | null
}

export interface ResultStatusOption {
	name: string
	apiKey: string
}
export interface PhoneCarrier {
	name: string
	domain: string
}
export interface AlertTimingMetadata {
	name: string
}
export interface AlertTimingWithKey extends AlertTimingMetadata {
	key: AlertTiming
}
export interface AlertMethodMetadata {
	name: string
}
export interface AlertTypeMetadata {
	name: string
	category: AlertTypeCategory
	isSummary: boolean
	defaultTiming: AlertTiming | null
	hidden?: boolean
	allowedTimings: AlertTiming[]
}
export interface ShownAlertTypeWithKeys extends AlertTypeMetadata {
	key: AlertType
	hidden?: false
}

export interface AlertSubscription {
	id?: number
	active: boolean
	type: AlertType | null
	timing: AlertTiming | null
	scheduleId: number | null
	description: string
	message: {
		title: string
		body: string
		method: AlertMethod
		emailAddress: string[]
		phone: { number: string; carrierDomain: string; carrier: string }[]
		templateId: number | null
		send: {
			failed: boolean
			marginal: boolean
			acceptable: boolean
		}
	}
	filters: {
		plantId: number | null
		analysisId: number | null
		locationId: number | null
		productId: number | null
		workOrderTypeId: number | null
		groupId: number | null
		investigationTriggerId: number | null
		resultStatus: ResultStatus | null
	}
	hours: number
	tense: 'BEFORE' | 'AFTER'
	assignedToUserAccountId?: number
}

type SavedAlertSubscriptionMessage = Spread<AlertSubscription['message'], { method: AlertMethod }>
export type SavedAlertSubscription = Spread<
	AlertSubscription,
	{
		id: number
		type: AlertType
		timing: AlertTiming
		message: SavedAlertSubscriptionMessage
	}
>

export function canSpecifySchedule(alertTiming: AlertTiming | null, _alertType: AlertType | null) {
	return alertTiming === 'SCHEDULED'
}

export function canSpecifyExpirationOffset(alertTiming: AlertTiming | null) {
	if (typeof alertTiming !== 'string') {
		return false
	}
	return ['EXPIRATION_APPROACHING', 'VERIFICATION_EXPIRATION_APPROACHING'].includes(alertTiming)
}

export function hasValidTiming(
	timing: AlertTiming | null,
	scheduleId: number | null,
	expirationHourOffset: number | undefined,
) {
	if (!timing || (timing === 'SCHEDULED' && !scheduleId)) {
		return false
	}

	//Expiration timings must have an expiration offset number
	if (
		(timing === 'EXPIRATION_APPROACHING' || timing === 'VERIFICATION_EXPIRATION_APPROACHING') &&
		typeof expirationHourOffset !== 'number'
	) {
		return false
	}

	//At this point it's one of the other timings that don't require a schedule or expiration offset
	return true
}

export function getExpirationHourOffset(alertSubscription: AlertSubscription): number {
	const { hours, tense } = alertSubscription
	const computedHours: number = hours ?? 0
	return tense === 'AFTER' ? computedHours * -1 : computedHours
}

const emailAddressRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function emailIsValid(emailAddress: string[]): boolean {
	return emailAddress
		.map(emailAddress => emailAddress.trim())
		.every(emailAddress => emailAddressRegex.test(emailAddress))
}

export function buildMap<Type, Key extends keyof Type>(input: Type[], keyName: Key) {
	const map = new Map<Type[Key], Type>()
	for (const item of input) {
		map.set(item[keyName], item)
	}
	return map
}

export function buildTranslatedHelpers(translate: i18n['t']) {
	const resultStatusOptions: DataResultStatus = {
		FAILED: { name: translate('common:failed', 'Failed'), apiKey: 'requireFailed' },
		ACCEPTABLE: { name: translate('common:acceptable', 'Acceptable'), apiKey: 'requireAcceptable' },
		MARGINAL: { name: translate('common:marginal', 'Marginal'), apiKey: 'requireMarginal' },
		MISSING: { name: translate('common:missing', 'Missing'), apiKey: 'requireMissing' },
	}

	const userResultStatusOptions: UserResultStatus = {
		FAILED: resultStatusOptions.FAILED,
	}

	const alertTypeCategories = new Map<AlertTypeCategory, DataResultStatus | UserResultStatus>([
		['Data', resultStatusOptions],
		['User', userResultStatusOptions],
	])

	const alertTimingMap = new Map<AlertTiming, AlertTimingMetadata>([
		['CREATED', { name: translate('common:created', 'Created') }],
		['OPENED', { name: translate('common:opened', 'Opened') }],
		['CLOSED', { name: translate('common:closed', 'Closed') }],
		['MODIFIED', { name: translate('common:modified', 'Modified') }],
		['SCHEDULED', { name: translate('common:scheduled', 'Scheduled') }],
		['DELETED', { name: translate('common:deleted', 'Deleted') }],
		['EXPIRATION_APPROACHING', { name: translate('common:expirationApproaching', 'Expiration Approaching') }],
		[
			'VERIFICATION_EXPIRATION_APPROACHING',
			{ name: translate('common:verificationExpirationApproaching', 'Verification Expiration Approaching') },
		],
	])

	const alertMethodMap = new Map<AlertMethod, AlertMethodMetadata>([
		['EMAIL', { name: translate('common:email', 'Email') }],
		['TEXT', { name: translate('common:SMSText', 'SMS Text') }],
		['SHOW', { name: translate('common:dashboard', 'Dashboard') }],
	])

	const alertTypeMap: AlertTypeMap = new Map([
		[
			'WORK_ORDER',
			{
				name: translate('common:workOrder', 'Work Order'),
				category: 'Data',
				isSummary: false,
				defaultTiming: null,
				allowedTimings: [
					'CREATED',
					'OPENED',
					'CLOSED',
					'MODIFIED',
					'DELETED',
					'EXPIRATION_APPROACHING',
					'VERIFICATION_EXPIRATION_APPROACHING',
				],
			},
		],
		[
			'SAMPLE',
			{
				name: translate('common:sample', 'Sample'),
				category: 'Data',
				isSummary: false,
				defaultTiming: null,
				allowedTimings: ['CREATED', 'OPENED', 'CLOSED', 'MODIFIED', 'DELETED', 'EXPIRATION_APPROACHING'],
			},
		],
		[
			'SAMPLE_VALUE',
			{
				name: translate('configuration.alertSubscriptions.sampleValue', 'Sample Value'),
				category: 'Data',
				isSummary: false,
				defaultTiming: null,
				allowedTimings: ['CREATED', 'MODIFIED', 'DELETED'],
			},
		],
		[
			'SAMPLE_VALUE_SUMMARY',
			{
				name: translate('configuration.alertSubscriptions.sampleValueSummary', 'Sample Value Summary'),
				category: 'Data',
				isSummary: true,
				defaultTiming: 'SCHEDULED',
				allowedTimings: ['SCHEDULED'],
			},
		],
		[
			'INVESTIGATION',
			{
				name: translate('common:investigation', 'Investigation'),
				category: 'Data',
				isSummary: false,
				defaultTiming: null,
				allowedTimings: ['CREATED', 'OPENED', 'CLOSED', 'MODIFIED', 'DELETED', 'EXPIRATION_APPROACHING'],
			},
		],
		[
			'USER_AUTHENTICATION',
			{
				name: translate('configuration.alertSubscriptions.userAuthentication', 'User Authentication'),
				category: 'User',
				isSummary: false,
				defaultTiming: null,
				allowedTimings: ['CREATED', 'MODIFIED', 'DELETED'],
			},
		],
		[
			'USER_AUTHENTICATION_SUMMARY',
			{
				name: translate('configuration.alertSubscriptions.userAuthenticationSummary', 'User Authentication Summary'),
				category: 'User',
				isSummary: true,
				defaultTiming: 'SCHEDULED',
				allowedTimings: ['SCHEDULED'],
			},
		],
		[
			'SUPPLIER_DOCUMENT',
			{
				name: translate('configuration.alertSubscriptions.supplierDocument', 'Supplier Document'),
				category: 'Data',
				isSummary: false,
				defaultTiming: null,
				hidden: true,
				allowedTimings: ['CREATED', 'MODIFIED', 'DELETED', 'EXPIRATION_APPROACHING'],
			},
		],
	])

	const alertTypeKeys: AlertType[] = Array.from(alertTypeMap.keys())
	const alertTypeOptions = alertTypeKeys.reduce((acc: ShownAlertTypeWithKeys[], alertTypeKey: AlertType) => {
		const alertType = alertTypeMap.get(alertTypeKey)

		if (!alertType) {
			return acc
		}

		function isShownAlertType(alertType: AlertTypeMetadata): alertType is ShownAlertTypeWithKeys {
			return alertType && alertType.hidden !== true
		}

		if (isShownAlertType(alertType)) {
			acc.push({ ...alertType, key: alertTypeKey })
		}
		return acc
	}, [])

	const alertTypesGroupedByCategory = alertTypeOptions.reduce(
		(acc, alertType: ShownAlertTypeWithKeys) => {
			if (alertType.category && acc[alertType.category]) {
				acc[alertType.category].push(alertType)
			} else {
				acc[alertType.category] = [alertType]
			}
			return acc
		},
		{} as Record<AlertTypeCategory, ShownAlertTypeWithKeys[]>,
	)

	function getAlertTimings(alertTypeKey: AlertType): AlertTimingWithKey[] {
		const alertType = alertTypeMap.get(alertTypeKey)
		const isAlertTypeMetadata = (alertType: AlertTypeMetadata | undefined): alertType is AlertTypeMetadata =>
			!!alertType

		if (isAlertTypeMetadata(alertType)) {
			return alertType.allowedTimings.reduce((acc: AlertTimingWithKey[], alertTiming: AlertTiming) => {
				const timing = alertTimingMap.get(alertTiming)
				if (timing) {
					return [...acc, { key: alertTiming, ...timing }]
				}
				return acc
			}, [])
		} else {
			return []
		}
	}

	function buildNewAlertSubscriptionForApiFromViewState(
		alertSubscription: AlertSubscription,
		includeBodyAndTitle: boolean = false,
	): NewAlertSubscription {
		const { active, type, timing, scheduleId, message, filters, assignedToUserAccountId } = alertSubscription
		const { title, body, method, emailAddress, phone, templateId, send } = message
		const { failed: sendFailed, marginal: sendMarginal, acceptable: sendAcceptable } = send
		const {
			plantId,
			analysisId,
			locationId,
			productId,
			workOrderTypeId,
			groupId,
			investigationTriggerId,
			resultStatus,
		} = filters

		if (!type) {
			throw new Error('Alert type is required')
		}

		if (!method) {
			throw new Error('Alert method is required')
		}

		const alertType = alertTypeMap.get(type)

		function orUndefined<Type>(test: boolean, truthyAnswer: Type): Type | undefined {
			return test && truthyAnswer ? truthyAnswer : undefined
		}
		const isDataCategory = alertType?.category === 'Data'

		let computedEmailAddress: string = ''

		if (method === 'EMAIL') {
			computedEmailAddress = emailAddress.join(';')
		} else if (method === 'TEXT') {
			computedEmailAddress =
				Array.isArray(phone) && phone.length > 0
					? phone.map(({ number, carrierDomain }) => `${number}@${carrierDomain}`).join(';')
					: ''
		} else if (method === 'SHOW') {
			computedEmailAddress = ''
		}

		return {
			active,
			type,
			timing:
				alertSubscription.timing && alertType?.allowedTimings?.includes?.(alertSubscription.timing) ? timing : null,
			scheduleId: orUndefined(canSpecifySchedule(alertSubscription.timing, alertSubscription.type), scheduleId),
			expirationHourOffset: canSpecifyExpirationOffset(alertSubscription.timing)
				? getExpirationHourOffset(alertSubscription)
				: undefined,
			description: alertSubscription.description ?? '',
			//filters
			plantId: orUndefined(isDataCategory, plantId),
			analysisId: orUndefined(isDataCategory, analysisId),
			locationId: orUndefined(isDataCategory, locationId),
			productId: orUndefined(isDataCategory, productId),
			workOrderTypeId: orUndefined(isDataCategory, workOrderTypeId),
			groupId: orUndefined(isDataCategory, groupId),
			requireAcceptable: resultStatus === 'ACCEPTABLE',
			requireMarginal: orUndefined(isDataCategory, resultStatus === 'MARGINAL'),
			requireFailed: resultStatus === 'FAILED',
			requireMissing: orUndefined(isDataCategory, resultStatus === 'MISSING'),
			investigationTriggerId: orUndefined(type === 'INVESTIGATION', investigationTriggerId),
			//message
			method,
			emailAddress: computedEmailAddress,
			templateId: orUndefined(!!templateId, templateId),
			title: templateId && !includeBodyAndTitle ? null : title,
			body: templateId && !includeBodyAndTitle ? null : body,
			sendFailed,
			sendMarginal: orUndefined(isDataCategory, sendMarginal),
			sendAcceptable,
			userAccountId: assignedToUserAccountId,
		}
	}

	function buildSavedAlertSubscriptionForApiFromViewState(
		alertSubscription: AlertSubscription,
	): SavedAlertSubscriptionForApi {
		if (!alertSubscription.id) {
			throw new Error('Alert subscription id is required')
		}

		return {
			...buildNewAlertSubscriptionForApiFromViewState(alertSubscription),
			id: alertSubscription?.id,
		} as SavedAlertSubscriptionForApi
	}

	function buildAlertSubscriptionForViewStateFromApi(
		alertSubscription: AlertSubscriptionApi,
		phoneCarriers: PhoneCarrier[] = [],
		alertTemplates: AlertTemplate[] = [],
	): AlertSubscription {
		interface ResultStatusWithKey extends ResultStatusOption {
			key: ResultStatus
		}
		let resultStatus: ResultStatusWithKey | undefined
		for (const [key] of Object.entries(resultStatusOptions) as Entries<typeof resultStatusOptions>) {
			if (alertSubscription[resultStatusOptions[key].apiKey]) {
				resultStatus = { key, ...resultStatusOptions[key] }
				break
			}
		}
		return {
			id: alertSubscription.id,
			active: alertSubscription.active,
			assignedToUserAccountId: alertSubscription?.userAccount?.id,
			type: alertSubscription.type,
			timing: alertSubscription.timing,
			description: alertSubscription.description ?? '',
			scheduleId: alertSubscription.schedule?.id ?? null,
			hours:
				(alertSubscription.expirationHourOffset && alertSubscription.expirationHourOffset) > -1
					? alertSubscription.expirationHourOffset
					: (alertSubscription.expirationHourOffset ?? 0) * -1,
			tense:
				(alertSubscription.expirationHourOffset && alertSubscription.expirationHourOffset) > -1 ? 'BEFORE' : 'AFTER',
			message: {
				title: alertSubscription.template?.id
					? (alertTemplates.find(template => template.id === alertSubscription.template?.id)?.title ?? '')
					: (alertSubscription.title ?? ''),
				body: alertSubscription.template?.id
					? (alertTemplates.find(template => template.id === alertSubscription.template?.id)?.body ?? '')
					: (alertSubscription.body ?? ''),
				method: alertSubscription.method,
				emailAddress: alertSubscription.method === 'EMAIL' ? alertSubscription.emailAddresses : [],
				phone:
					alertSubscription.method === 'TEXT'
						? alertSubscription.phoneNumbers.map(phoneNumber => {
								return {
									number: phoneNumber.number || '',
									carrierDomain: phoneNumber.carrier?.domain || '',
									carrier: phoneNumber.carrier
										? (phoneCarriers.find(phoneCarrier => phoneCarrier.domain === phoneNumber.carrier?.domain)
												?.domain ?? 'CUSTOM')
										: 'CUSTOM',
								}
							})
						: [],
				templateId: alertSubscription.template?.id ?? null,
				send: {
					failed: alertSubscription.sendFailed,
					marginal: alertSubscription.sendMarginal,
					acceptable: alertSubscription.sendAcceptable,
				},
			},
			filters: {
				plantId: alertSubscription.plant?.id ?? null,
				analysisId: alertSubscription.analysis?.id ?? null,
				locationId: alertSubscription.location?.id ?? null,
				productId: alertSubscription.product?.id ?? null,
				workOrderTypeId: alertSubscription.workOrderType?.id ?? null,
				groupId: alertSubscription.group?.id ?? null,
				investigationTriggerId: alertSubscription.investigationTrigger?.id ?? null,
				resultStatus: resultStatus ? resultStatus.key : null,
			},
		}
	}

	return {
		resultStatusOptions,
		alertTypeCategories,
		alertTypeMap,
		alertTimingMap,
		alertMethodMap,
		alertTypeOptions,
		alertTypesGroupedByCategory,
		getAlertTimings,
		buildNewAlertSubscriptionForApiFromViewState,
		buildSavedAlertSubscriptionForApiFromViewState,
		buildAlertSubscriptionForViewStateFromApi,
	}
}
