/** @type {import('houdini').ConfigFile} */
const config = {
	watchSchema: {
		url: 'env:API_URL',
	},
	include: [ 'client/**/*.{svelte,graphql,gql,ts,js}' ],
	plugins: {
		'houdini-svelte': {
			client: './client/services/houdini.ts',
		},
	},
	scalars: {
		PositiveInt: {
			type: 'number',
		},
		NonEmptyString: {
			type: 'string',
		},
		NonNegativeInt: {
			type: 'number',
		},
		NonNegativeFloat: {
			type: 'number',
		},
		Void: {
			type: 'void',
		},
		UnsignedInt: {
			type: 'number',
		},
		SafeInt: {
			type: 'number',
		},
		EmailAddress: {
			type: 'string',
		},
		Date: {
			type: 'string',
		},
		DateTime: {
			type: 'string',
		},
		Timestamp: {
			type: 'Date',
			unmarshal: value => value === null ? null : new Date(value),
			marshal: value => value === null ? null : value.getTime(),
		},
		UUID: {
			type: 'string',
		},
	},
	types: {
		// ReportParameterOption has does not have a unique ID, so disable optimization based on that
		ReportParameterOption: {
			keys: [],
		},
	},
	// Caching can have a lot of side effects such as not updating the UI after reloading a state after a save, so I figure it's best to have it be opt-in.
	defaultCachePolicy: 'NetworkOnly',
	defaultFragmentMasking: 'disable',
}

export default config
