export default {
    "name": "UserAccountFields",
    "kind": "HoudiniFragment",
    "hash": "6414e81825b187ec0ec1244945ac5483c889319eb02c5b1e58cb3cfcf1d0e85f",

    "raw": `fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastLoginDate
  workEmail
  recoveryEmail
  lockNotes
  apiToken
  lastPasswordResetDate: userLastPasswordResetDate
  userActivationData {
    activationPIN
    activationPINExpiration
  }
  userGroups {
    id
  }
  authorizedPlants {
    id
  }
  userPermissions {
    id
    permissionId
    value
  }
  __typename
}
`,

    "rootType": "UserAccount",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "firstName": {
                "type": "String",
                "keyRaw": "firstName",
                "nullable": true,
                "visible": true
            },

            "lastName": {
                "type": "String",
                "keyRaw": "lastName",
                "nullable": true,
                "visible": true
            },

            "status": {
                "type": "UserAccountStatus",
                "keyRaw": "status",
                "visible": true
            },

            "lastLoginDate": {
                "type": "DateTime",
                "keyRaw": "lastLoginDate",
                "nullable": true,
                "visible": true
            },

            "workEmail": {
                "type": "String",
                "keyRaw": "workEmail",
                "nullable": true,
                "visible": true
            },

            "recoveryEmail": {
                "type": "EmailAddress",
                "keyRaw": "recoveryEmail",
                "nullable": true,
                "visible": true
            },

            "lockNotes": {
                "type": "String",
                "keyRaw": "lockNotes",
                "nullable": true,
                "visible": true
            },

            "apiToken": {
                "type": "String",
                "keyRaw": "apiToken",
                "nullable": true,
                "visible": true
            },

            "lastPasswordResetDate": {
                "type": "DateTime",
                "keyRaw": "lastPasswordResetDate",
                "nullable": true,
                "visible": true
            },

            "userActivationData": {
                "type": "UserActivationData",
                "keyRaw": "userActivationData",
                "nullable": true,

                "selection": {
                    "fields": {
                        "activationPIN": {
                            "type": "NonEmptyString",
                            "keyRaw": "activationPIN",
                            "nullable": true,
                            "visible": true
                        },

                        "activationPINExpiration": {
                            "type": "DateTime",
                            "keyRaw": "activationPINExpiration",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userGroups": {
                "type": "Group",
                "keyRaw": "userGroups",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "authorizedPlants": {
                "type": "Plant",
                "keyRaw": "authorizedPlants",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userPermissions": {
                "type": "UserPermission",
                "keyRaw": "userPermissions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "permissionId": {
                            "type": "PositiveInt",
                            "keyRaw": "permissionId",
                            "visible": true
                        },

                        "value": {
                            "type": "PermissionValue",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=8bcb5695224c2e913fd06c9d8dede20279c2ff26559788e910b36c10d8b357f0";