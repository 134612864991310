import type { ManifestVersion } from 'types/common'

export function isManifest(data: unknown): data is ManifestVersion {
	return (
		typeof data === 'object' &&
		data !== null &&
		'client' in data &&
		typeof data.client === 'object' &&
		data.client !== null &&
		'version' in data.client &&
		typeof data.client.version === 'string'
	)
}
