export default {
    "name": "WoGetTotalLotQuantity",
    "kind": "HoudiniQuery",
    "hash": "1e4fbb66c31dcff4baf77968726a74cf44bbed6eb3845e75eef3d4dc588f7199",

    "raw": `query WoGetTotalLotQuantity($input: GetTotalQuantityInput!) {
  getTotalQuantity(input: $input)
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getTotalQuantity": {
                "type": "Float",
                "keyRaw": "getTotalQuantity(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "GetTotalQuantityInput"
        },

        "types": {
            "ProductQuantityInput": {
                "productId": "PositiveInt",
                "quantity": "Float"
            },

            "GetTotalQuantityInput": {
                "productId": "PositiveInt",
                "products": "ProductQuantityInput"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ccff79105df9e8e930b1325de66b42f3345304c08fae7bbcf592e52261862799";