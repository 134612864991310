import component from './DataEntry.svelte'
import type { AppContext } from 'types/common'
import { getSession } from 'stores/session'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.data-entry',
		route: 'data-entry',
		template: component,
		resolve(_data, _parameters) {
			const session = getSession()
			return Promise.resolve({ authToken: session.authToken })
		},
	})
}
