export default {
    "name": "LoadProductsPage",
    "kind": "HoudiniQuery",
    "hash": "fa3e8ae83f667943abc5128ccb54a7738e6bc7328799b7f393954de9464747af",

    "raw": `query LoadProductsPage($pagination: PaginatedInput, $filter: EntityTagFilter, $severityClassFilter: SeverityClassFilter) {
  plants(pagination: $pagination) {
    data {
      id
      code
      name
      private
    }
    info {
      pageSize
      pageNumber
    }
  }
  entityTags(filter: $filter) {
    name
    active
    entityType
    id
  }
  severityClasses(filter: $severityClassFilter, pagination: $pagination) {
    data {
      id
      name
      description
      default
      plantId
    }
  }
  analyses(pagination: $pagination) {
    data {
      id
      name
      inUseAtPlantIDs
      options {
        id
        option
        valueType
      }
    }
  }
  productCategories
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "private": {
                                        "type": "Boolean",
                                        "keyRaw": "private",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "entityTags": {
                "type": "EntityTag",
                "keyRaw": "entityTags(filter: $filter)",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "severityClasses": {
                "type": "SeverityClassResponse",
                "keyRaw": "severityClasses(filter: $severityClassFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "SeverityClass",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "default": {
                                        "type": "Boolean",
                                        "keyRaw": "default",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "productCategories": {
                "type": "String",
                "keyRaw": "productCategories",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "EntityTagFilter",
            "severityClassFilter": "SeverityClassFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "EntityTagFilter": {
                "active": "Boolean",
                "entityTypes": "EntityTagType",
                "ids": "PositiveInt"
            },

            "SeverityClassFilter": {
                "plantIds": "PositiveInt",
                "severityClassName": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0a66319be863827a79a9cb631ed18605ddaa8cccb855dd18203c68862fc7196f";