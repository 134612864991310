export default {
    "name": "WoCreateWorkOrder",
    "kind": "HoudiniMutation",
    "hash": "106748227f36e680d50e5f003a5a627358a15bc372e650b5f2a67a55660fab0e",

    "raw": `mutation WoCreateWorkOrder($workOrder: NewWorkOrder!) {
  createWorkOrder(workOrder: $workOrder) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createWorkOrder": {
                "type": "WorkOrder",
                "keyRaw": "createWorkOrder(workOrder: $workOrder)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "workOrder": "NewWorkOrder"
        },

        "types": {
            "NewSampleValueLot": {
                "expiration": "Date",
                "lotNumber": "String",
                "productId": "PositiveInt",
                "quantity": "Float",
                "supplierItemNumber": "String"
            },

            "NewSampleValue": {
                "analysisOptionId": "PositiveInt",
                "defaultValue": "String",
                "expiration": "Date",
                "lot": "String",
                "lotsToAdd": "NewSampleValueLot",
                "result": "String"
            },

            "NewSampleOnUnsavedWorkOrder": {
                "analysisId": "PositiveInt",
                "collectedByUserId": "PositiveInt",
                "comments": "String",
                "due": "DateTime",
                "findings": "String",
                "incubationBegan": "DateTime",
                "incubationEnded": "DateTime",
                "investigationId": "PositiveInt",
                "locationId": "PositiveInt",
                "performed": "DateTime",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "Float",
                "sampleValuesToAdd": "NewSampleValue",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt",
                "sourceSampleId": "PositiveInt",
                "status": "DocumentStatus",
                "tagNumber": "String"
            },

            "NewWorkOrder": {
                "assignedToGroupId": "PositiveInt",
                "documentStatus": "DocumentStatus",
                "due": "DateTime",
                "favorite": "Boolean",
                "instructions": "String",
                "internalNotes": "String",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "samples": "NewSampleOnUnsavedWorkOrder",
                "scheduled": "DateTime",
                "sourceWorkOrderId": "PositiveInt",
                "title": "String",
                "userAccessId": "PositiveInt",
                "verificationDue": "DateTime",
                "verifiedByUserId": "PositiveInt",
                "verifiedOn": "DateTime",
                "workOrderTypeId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=39827de183d03583def4d0b9c882b272cb5931687e502cd081504117150bf36d";