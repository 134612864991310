<script lang="ts">
	import type { i18n } from 'i18next'
	import { getContext } from 'svelte'
	const { t: translate } = getContext<i18n>('i18next')

	export let url: string
</script>

<div class="d-flex justify-content-end">
	<a
		href={url}
		aria-label={translate('common:openInNewTab', 'Open in new tab')}
		target="_blank"><i class="fa-solid fa-2x fa-arrow-up-right-from-square"></i></a
	>
</div>
<iframe
	src={url}
	style="border: none; margin: 0; padding: 0; width: 100%; height:90vh;"
	title="Graph"
></iframe>
