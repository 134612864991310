export default {
    "name": "UserAccountUpdate",
    "kind": "HoudiniMutation",
    "hash": "73e86e7d451b8caa5a7eef1b67f959c36d1f02475f27d3e358e52a549865c1cf",

    "raw": `mutation UserAccountUpdate($input: UpdateUserAccount!) {
  updateUserAccount(input: $input) {
    ...UserAccountFields
    id
  }
}

fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastLoginDate
  workEmail
  recoveryEmail
  lockNotes
  apiToken
  lastPasswordResetDate: userLastPasswordResetDate
  userActivationData {
    activationPIN
    activationPINExpiration
  }
  userGroups {
    id
  }
  authorizedPlants {
    id
  }
  userPermissions {
    id
    permissionId
    value
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateUserAccount": {
                "type": "UserAccount",
                "keyRaw": "updateUserAccount(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastLoginDate": {
                            "type": "DateTime",
                            "keyRaw": "lastLoginDate",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "EmailAddress",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "lockNotes": {
                            "type": "String",
                            "keyRaw": "lockNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "apiToken": {
                            "type": "String",
                            "keyRaw": "apiToken",
                            "nullable": true,
                            "visible": true
                        },

                        "lastPasswordResetDate": {
                            "type": "DateTime",
                            "keyRaw": "lastPasswordResetDate",
                            "nullable": true,
                            "visible": true
                        },

                        "userActivationData": {
                            "type": "UserActivationData",
                            "keyRaw": "userActivationData",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "activationPIN": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "activationPIN",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "activationPINExpiration": {
                                        "type": "DateTime",
                                        "keyRaw": "activationPINExpiration",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userGroups": {
                            "type": "Group",
                            "keyRaw": "userGroups",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "authorizedPlants": {
                            "type": "Plant",
                            "keyRaw": "authorizedPlants",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userPermissions": {
                            "type": "UserPermission",
                            "keyRaw": "userPermissions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "permissionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "permissionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionValue",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "UserAccountFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "UpdateUserAccount"
        },

        "types": {
            "UpdateUserPermission": {
                "permissionId": "PositiveInt",
                "permissionValue": "PermissionValue"
            },

            "UpdateUserAccount": {
                "apiToken": "UUID",
                "authorizedPlantIds": "PositiveInt",
                "firstName": "String",
                "id": "PositiveInt",
                "lastName": "String",
                "lockNotes": "String",
                "name": "NonEmptyString",
                "newPassword": "NonEmptyString",
                "sendPasswordRecoveryToken": "Boolean",
                "status": "UserAccountStatus",
                "userGroupIds": "PositiveInt",
                "userPermissions": "UpdateUserPermission",
                "workEmail": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=8c5f235160ccdb5d25db300c16208753bb448ffc775336ab22d67b506d78c6f6";