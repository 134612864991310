import type { AppContext, DefaultParameters, ResolveParameters } from 'client/types/common'

import component from './Settings.svelte'
import pProps from 'p-props'
import { getUserSettingValue, type UserSetting } from 'utility/setting'
import { stringToBoolean } from '@isoftdata/utility-string'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'

type SettingsParameters = {
	lastResetTime: string | null
	lastSavedTime: string | null
	selectedCategory: string
}

export default ({ mediator, stateRouter, i18next: { t } }: AppContext<typeof component>) => {
	stateRouter.addState({
		name: 'app.configuration.settings',
		route: 'settings',
		querystringParameters: ['lastResetTime', 'lastSavedTime', 'selectedCategory'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
			selectedCategory: () => t('settings.categories.all', 'All'),
		} satisfies DefaultParameters<SettingsParameters>,
		template: component,
		canLeaveState(domApi) {
			return domApi.canLeaveState()
		},
		async resolve(_data, parameters: ResolveParameters<SettingsParameters>) {
			const { lastSavedTime, selectedCategory } = parameters
			const { user } = getSession()
			const settings = (await getSettingsWithValues.fetch()).data?.getSettingsWithValues ?? []

			const mappedSettings = settings.reduce((acc, setting) => {
				switch (setting.scope) {
					case 'GLOBAL':
						if (user.permissions.SETTINGS_GLOBAL_ADMINISTRATOR === 'GLOBAL') {
							acc.push({
								...setting,
								scope: 'GLOBAL',
								dataType: checkSettingValue(setting.value as string),
								dirty: false,
								defaultDirty: false,
							})
						}
						break
					case 'PLANT':
						if (
							user.permissions.SETTINGS_PLANT_ADMINISTRATOR === 'PLANT' ||
							user.permissions.SETTINGS_GLOBAL_ADMINISTRATOR === 'GLOBAL'
						) {
							acc.push({
								...setting,
								scope: 'PLANT',
								dataType: checkSettingValue(setting.value as string),
								dirty: false,
								defaultDirty: false,
							})
						}
						break
					case 'USER':
						acc.push({
							...setting,
							scope: 'USER',
							dataType: checkSettingValue(setting.value as string),
							dirty: false,
							defaultDirty: false,
						})
						break
				}
				return acc
			}, new Array<UserSetting>())

			const {
				importantConfiguration = 'False',
				interfaceHistory = 'False',
				interfacePreference = 'False',
				optionalConfiguration = 'False',
				preference = 'False',
			} = await pProps({
				importantConfiguration: getUserSettingValue({
					category: 'Configuration',
					name: "Configure Settings Dialog: Show 'important configuration' settings",
					settingType: 'INTERFACE_HISTORY',
					defaultValue: 'True',
				}),
				interfaceHistory: getUserSettingValue({
					category: 'Configuration',
					name: "Configure Settings Dialog: Show 'interface history' settings",
					settingType: 'INTERFACE_HISTORY',
					defaultValue: 'True',
				}),
				interfacePreference: getUserSettingValue({
					category: 'Configuration',
					name: "Configure Settings Dialog: Show 'interface preference' settings",
					settingType: 'INTERFACE_HISTORY',
					defaultValue: 'True',
				}),
				optionalConfiguration: getUserSettingValue({
					category: 'Configuration',
					name: "Configure Settings Dialog: Show 'optional configuration' settings",
					settingType: 'INTERFACE_HISTORY',
					defaultValue: 'True',
				}),
				preference: getUserSettingValue({
					category: 'Configuration',
					name: "Configure Settings Dialog: Show 'preference' settings",
					settingType: 'INTERFACE_HISTORY',
					defaultValue: 'True',
				}),
			})

			const mapppedConfigurationSettings = {
				importantConfiguration: stringToBoolean(importantConfiguration),
				interfaceHistory: stringToBoolean(interfaceHistory),
				interfacePreference: stringToBoolean(interfacePreference),
				optionalConfiguration: stringToBoolean(optionalConfiguration),
				preference: stringToBoolean(preference),
			}

			return {
				settings: mappedSettings,
				configurationSettings: mapppedConfigurationSettings,
				selectedCategory: selectedCategory ?? t('settings.categories.all', 'All'),
			}
		},
	})
}

function checkSettingValue(settingValue: string) {
	// This is an abstraction to check the type of the setting value for display
	const numberRegex = /^\d+$/
	const booleanRegex = /^(True|False)$/
	if (numberRegex.test(settingValue)) {
		return 'number'
	} else if (booleanRegex.test(settingValue)) {
		return 'boolean'
	}
	return 'string'
}

const getSettingsWithValues = graphql(`
	query GetSettingsWithValues {
		getSettingsWithValues {
			id
			name
			category: location
			cascade
			value
			scope
			defaultValue
			type: settingType
		}
	}
`)
