<script lang="ts">
	import Icon from '@isoftdata/svelte-icon'
	import type { ComponentProps, Snippet } from 'svelte'

	interface Props {
		icon: ComponentProps<Icon>['icon'] | ComponentProps<Icon>
		title: any
		right?: Snippet
		children?: Snippet
	}

	let { icon, title, right, children }: Props = $props()

	let iconProps = $derived(typeof icon === 'string' ? { icon } : icon)
</script>

<div class="card-header">
	<div class="d-flex flex-wrap justify-content-between">
		<h4 class="text-ellipsis">
			<Icon
				{...iconProps}
				fixedWidth
			></Icon>
			{title}
		</h4>
		<div class="d-flex flex-wrap">
			{@render right?.()}
		</div>
	</div>
	{@render children?.()}
</div>
