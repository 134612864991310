export default {
    "name": "UserAccountCreate",
    "kind": "HoudiniMutation",
    "hash": "cff81b4e6eac59b3c0dd05540649d4d6f25663847d47c86ed1115e65ad3a9591",

    "raw": `mutation UserAccountCreate($input: NewUserAccount!) {
  createUserAccount(input: $input) {
    ...UserAccountFields
    id
  }
}

fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastLoginDate
  workEmail
  recoveryEmail
  lockNotes
  apiToken
  lastPasswordResetDate: userLastPasswordResetDate
  userActivationData {
    activationPIN
    activationPINExpiration
  }
  userGroups {
    id
  }
  authorizedPlants {
    id
  }
  userPermissions {
    id
    permissionId
    value
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createUserAccount": {
                "type": "UserAccount",
                "keyRaw": "createUserAccount(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastLoginDate": {
                            "type": "DateTime",
                            "keyRaw": "lastLoginDate",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "EmailAddress",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "lockNotes": {
                            "type": "String",
                            "keyRaw": "lockNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "apiToken": {
                            "type": "String",
                            "keyRaw": "apiToken",
                            "nullable": true,
                            "visible": true
                        },

                        "lastPasswordResetDate": {
                            "type": "DateTime",
                            "keyRaw": "lastPasswordResetDate",
                            "nullable": true,
                            "visible": true
                        },

                        "userActivationData": {
                            "type": "UserActivationData",
                            "keyRaw": "userActivationData",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "activationPIN": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "activationPIN",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "activationPINExpiration": {
                                        "type": "DateTime",
                                        "keyRaw": "activationPINExpiration",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userGroups": {
                            "type": "Group",
                            "keyRaw": "userGroups",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "authorizedPlants": {
                            "type": "Plant",
                            "keyRaw": "authorizedPlants",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userPermissions": {
                            "type": "UserPermission",
                            "keyRaw": "userPermissions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "permissionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "permissionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionValue",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "UserAccountFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewUserAccount"
        },

        "types": {
            "UpdateUserPermission": {
                "permissionId": "PositiveInt",
                "permissionValue": "PermissionValue"
            },

            "NewUserAccount": {
                "apiToken": "UUID",
                "authorizedPlantIds": "PositiveInt",
                "firstName": "String",
                "lastName": "String",
                "lockNotes": "String",
                "name": "NonEmptyString",
                "newPassword": "NonEmptyString",
                "sendPasswordRecoveryToken": "Boolean",
                "userGroupIds": "PositiveInt",
                "userPermissions": "UpdateUserPermission",
                "workEmail": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b8a5e792ae111fc167cd45e9086038e4c916732bd4269007e74585bd8a61da5b";