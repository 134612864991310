import type { ClientPluginContext } from '$houdini/runtime/client/documentStore'
import type { RequestHandler } from '$houdini/runtime/client/plugins'
import { HoudiniClient, cache, fetch as defaultFetchPlugin } from '$houdini'
import sessionStore, { getSession } from 'stores/session'

let session = getSession()
sessionStore.subscribe(newSession => {
	session = newSession
	// Clear cache on logout
	if (!session?.authToken) {
		cache.reset()
	}
})

const url = '__apiUrl__'
function fetchParams() {
	return {
		headers: {
			'auth-token': session.authToken,
			'apollographql-client-name': 'PresageWeb',
			'apollographql-client-version': '__buildVersion__',
			'Content-Type': 'application/json',
		},
	}
}

function hasExtensionsNotes(error: unknown): error is { extensions: { notes: string } } {
	return (
		typeof error === 'object' &&
		error !== null &&
		'extensions' in error &&
		(error as { extensions: { notes: string } }).extensions.notes !== undefined
	)
}

const client = new HoudiniClient({
	url,
	fetchParams,
	// This will only catch graphql errors, not HTTP errors like what get thrown by myFetchFn
	throwOnError: {
		operations: ['all'],
		error: errors => {
			if (errors.length > 1) {
				console.error('houdini errors', errors)
			} else {
				console.error('houdini error', errors[0])
			}
			let message = errors[0].message
			if (hasExtensionsNotes(errors[0])) {
				message += `\r\nAdvanced Details:\r\n${errors[0].extensions.notes}`
			}
			throw new Error(message, { cause: errors[0] })
		},
	},
})

export default client
