export default {
    "name": "WoProductBatchData",
    "kind": "HoudiniFragment",
    "hash": "73d3232dc6dd58cc88ea7e07a445598d48c3e00966cb09ac632083a61e585ddf",

    "raw": `fragment WoProductBatchData on ProductBatch {
  id
  name
  product {
    ...WoProductData
    id
  }
  location {
    ...WoLocationData
    id
  }
  plantId
  status
  end
  start
  __typename
}

fragment WoProductData on Product {
  id
  name
  productType
  attachmentCount(fileType: IMAGE)
  __typename
}

fragment WoLocationData on Location {
  id
  location
  description
  plantId
  severityClass {
    id
    default
  }
  attachmentCount(fileType: IMAGE)
  __typename
}
`,

    "rootType": "ProductBatch",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "product": {
                "type": "Product",
                "keyRaw": "product",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "productType": {
                            "type": "ProductType",
                            "keyRaw": "productType",
                            "visible": true
                        },

                        "attachmentCount": {
                            "type": "UnsignedInt",
                            "keyRaw": "attachmentCount(fileType: IMAGE)",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "WoProductData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "location": {
                "type": "Location",
                "keyRaw": "location",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "location": {
                            "type": "String",
                            "keyRaw": "location",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "visible": true
                        },

                        "severityClass": {
                            "type": "SeverityClass",
                            "keyRaw": "severityClass",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "default": {
                                        "type": "Boolean",
                                        "keyRaw": "default",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachmentCount": {
                            "type": "UnsignedInt",
                            "keyRaw": "attachmentCount(fileType: IMAGE)",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "WoLocationData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "plantId": {
                "type": "PositiveInt",
                "keyRaw": "plantId",
                "visible": true
            },

            "status": {
                "type": "ProductBatchStatus",
                "keyRaw": "status",
                "visible": true
            },

            "end": {
                "type": "DateTime",
                "keyRaw": "end",
                "nullable": true,
                "visible": true
            },

            "start": {
                "type": "DateTime",
                "keyRaw": "start",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=a86829e7c2ccd8f6ec1b0a54bb31f1f862d1dd9e03dd123717f60ce09ad9a54f";