export default {
    "name": "ReportSettings",
    "kind": "HoudiniQuery",
    "hash": "296154951b11ecd1e6597b2fd13af17c79f5ca890283a41756ec1827d6cd2329",

    "raw": `query ReportSettings {
  session {
    user {
      favoriteReports: getUserSetting(
        lookup: {category: "Report Viewer", settingType: INTERFACE_HISTORY, name: "Favorite reports", defaultValue: ""}
      ) {
        value
      }
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "favoriteReports": {
                                        "type": "SettingResult",
                                        "keyRaw": "favoriteReports(lookup: {category: \"Report Viewer\", settingType: INTERFACE_HISTORY, name: \"Favorite reports\", defaultValue: \"\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=80b5d3c72036aa83af70c57438cc71fb17bb0df57982a552728eabcdefb37056";