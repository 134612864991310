<script lang="ts">
	import type {
		RestrictionTree,
		DocumentTree,
		AnalysisOptionChoice,
		Analysis,
		AnalysisOptions,
		Product,
		SampleValues,
		Location,
	} from './edit'
	import type {
		WorkOrder,
		Sample,
		SampleValue,
		WoMediatorProviders,
		DisplaySample,
		WoDocumentStore,
		MetaSampleAttachment,
	} from '../work-order'
	import type { GroupedEntityLoader } from 'utility/grouped-entity-loader'
	import type { i18n, Mediator } from 'types/common'
	import type ContextMenu from '@isoftdata/svelte-context-menu'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Writable } from 'svelte/store'
	import type { DocumentStatus$options, ShowProduct$options } from '$houdini'

	import { Table, Td, type Column, type StringKeyOfType } from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import { getContext } from 'svelte'
	import session from 'stores/session'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Textarea from '@isoftdata/svelte-textarea'
	import Input from '@isoftdata/svelte-input'
	import OptionValueInput from './OptionValueInput.svelte'
	import Attention from './Attention.svelte'
	import { DocumentStatus } from '$houdini'
	import { klona } from 'klona'
	import hasPermission from 'utility/has-permission'
	import { getDefaultSampleValuesList } from 'utility/get-default-sample-values'
	import formatDocumentStatus from 'utility/format-document-status'
	import { formatDateTimeForInput, getEventUtcDate } from 'utility/timezone'
	import { getEventValue } from '@isoftdata/browser-event'

	interface Props {
		workOrder: WorkOrder
		selectedRowIds: Array<string>
		showProductLocationImages: (ctx: {
			entityType: 'PRODUCT' | 'LOCATION'
			entityId: number
			entityName: string
			sampleId: number
		}) => void
		samplesPerPage: number
		optionsColumns: Array<Column<DisplaySample>>
		mode: 'TESTING' | 'RECIPE'
		displaySamples: Array<DisplaySample>
		closedSampleCount: number
		initialSampleStatuses: Record<string, DocumentStatus$options>
		sampleContextMenu: ContextMenu | undefined
		contextMenuSample: DisplaySample | undefined
		selectedSample: DisplaySample | null
		hasMultipleAnalyses: boolean
		hasMultiplePlants: boolean
		canEditCurrentWorkOrder: boolean
		showAttachmentsModal: boolean
		optionsToShow: Set<string>
	}

	let {
		workOrder = $bindable(),
		selectedRowIds = $bindable(),
		showProductLocationImages,
		samplesPerPage,
		optionsColumns,
		mode,
		displaySamples,
		closedSampleCount,
		initialSampleStatuses,
		sampleContextMenu,
		contextMenuSample = $bindable(),
		selectedSample = $bindable(),
		hasMultipleAnalyses,
		canEditCurrentWorkOrder,
		showAttachmentsModal = $bindable(),
		optionsToShow,
		hasMultiplePlants,
	}: Props = $props()

	let table: Table<DisplaySample> | undefined = undefined

	// #region getContext
	const { t: translate } = getContext<i18n>('i18next')
	const computeCanEditSample =
		getContext<(canEditWo: boolean, sampleStatus: DocumentStatus$options) => boolean>('computeCanEditSample')
	const computeCanEditSampleValue =
		getContext<
			(
				canEditWo: boolean,
				sampleStatus: DocumentStatus$options,
				sample: DisplaySample,
				sampleValue: SampleValue,
			) => boolean
		>('computeCanEditSampleValue')
	const confirmActionThatRequiresReVerify = getContext<() => boolean>('confirmActionThatRequiresReVerify')
	const sampleCrudStore = getContext<CrudStore<Sample, 'uuid'>>('sampleCrudStore')
	const woDocumentStore = getContext<WoDocumentStore>('woDocumentStore')
	const documentTree = getContext<DocumentTree>('documentTree')
	const restrictionTree = getContext<RestrictionTree>('restrictionTree')
	const showOptions = getContext<Writable<boolean>>('showOptions')
	const showCollectionDetail = getContext<Writable<boolean>>('showCollectionDetail')
	const showTestingDetail = getContext<Writable<boolean>>('showTestingDetail')
	const showClosedSamples = getContext<Writable<boolean>>('showClosedSamples')
	const showOnlyApplicableThresholds = getContext<Writable<boolean>>('showOnlyApplicableThresholds')
	const allowShowThresholdsTable = getContext<boolean>('allowShowThresholdsTable')
	const showModifiedIcons = getContext<Writable<boolean>>('showModifiedIcons')
	const productsLoader = getContext<GroupedEntityLoader<Product>>('productsLoader')
	const locationsLoader = getContext<GroupedEntityLoader<Location>>('locationsLoader')
	const choicesLoader = getContext<GroupedEntityLoader<AnalysisOptionChoice>>('choicesLoader')
	const workOrderStatuses = getContext<Array<DocumentStatus$options>>('workOrderStatuses')
	const analysisOptionsToBlankSampleValues = getContext<
		(analysisOptions: AnalysisOptions, sampleId?: number) => SampleValues
	>('analysisOptionsToBlankSampleValues')
	const loadingAttachments = getContext<
		Writable<{
			type: 'PRODUCT' | 'LOCATION'
			sampleId: number
		} | null>
	>('loadingAttachments')
	const sampleMissingRequiredValuesForStatus = getContext<
		(sample: Sample, status: DocumentStatus$options) => Array<SampleValue>
	>('sampleMissingRequiredValuesForStatus')
	const formatOptionName = getContext<(option: { option: string; unit: string }) => string>('formatOptionName')
	const getApplicableAnalyses = getContext<(plantId: number) => Promise<Array<Analysis>>>('getApplicableAnalyses')
	const mediator = getContext<Mediator<WoMediatorProviders>>('mediator')
	// #endregion

	const recipesMode = $derived(mode === 'RECIPE')
	const testingMode = $derived(mode === 'TESTING')
	$effect(() => {
		if (testingMode) {
			table?.setColumnVisibility(
				['scheduled', 'due', 'performed', 'collectedBy[fullName]'],
				$showCollectionDetail && workOrder.workOrderType.showSamplingDetail,
			)
		}
	})
	$effect(() => {
		if (testingMode) {
			table?.setColumnVisibility(
				['incubationBegan', 'incubationEnded', 'platesReadBy[fullName]'],
				$showTestingDetail && workOrder.workOrderType.showTestingDetail,
			)
		}
	})
	$effect(() => {
		if (testingMode) {
			table?.setColumnVisibility(['location[location]'], workOrder.workOrderType.showLocation)
		}
	})
	$effect(() => {
		if (testingMode) {
			table?.setColumnVisibility(['location[description]'], workOrder.workOrderType.showLocationDescription)
		}
	})
	$effect(() => {
		// Hide product by default in recipe mode, but they can still un-hide it if they want to
		table?.setColumnVisibility(['product[name]'], workOrder.workOrderType.showProduct !== 'NONE' && testingMode)
	})
	$effect(() => {
		table?.setColumnVisibility(['plant[name]'], hasMultiplePlants)
	})

	function buildProductColumn(showProduct: ShowProduct$options): Column<DisplaySample> {
		const productColumn: Column<DisplaySample> = {
			// Name depends on workorder type - either, both, or none
			name: translate('workOrder.productIngredientColumn', 'Product / Ingredient'),
			minWidth: '200px',
			width: '220px',
			property: 'product[name]',
			title: translate('workOrder.productColumnTitle', '(Optional) the product or ingredient this sample relates to'),
		}

		if (showProduct === 'PRODUCT') {
			productColumn.name = translate('workOrder.productColumn', 'Product')
		} else if (showProduct === 'INGREDIENT') {
			productColumn.name = translate('workOrder.ingredientColumn', 'Ingredient')
		}

		return productColumn
	}

	function isDocumentStatus(status: string): status is DocumentStatus$options {
		return status in DocumentStatus
	}

	async function getDisplayProducts(plantId: number) {
		const products = await productsLoader(plantId)
		return products.filter(
			product =>
				product &&
				(workOrder.workOrderType.showProduct === 'BOTH' || workOrder.workOrderType.showProduct === product.productType),
		)
	}

	async function getDefaultSampleValues(sample: Sample, index: number) {
		sample.sampleValues = await getDefaultSampleValuesList(workOrder, sample)
		workOrder.samples[index] = sample
		// If we're updating sample values of the selected sample, the UI won't update if we don't get it from the array again
		// Maybe the need for this can be removed if selectedSample were a $derived? Since I don't think we allow editing it anyways...
		if (index === selectedSample?.unfilteredIndex) {
			selectedSample = displaySamples.find(sample => sample.uuid === selectedSample?.uuid) ?? null
		}
	}

	function setSampleDateFieldFromInput(event: Event, key: StringKeyOfType<Sample, string | null>, index: number) {
		if (event.target instanceof HTMLInputElement) {
			const utcDateString = getEventUtcDate(event, $session.plant.timezone)

			if (key && key !== 'status') {
				workOrder.samples[index][key] = utcDateString
			}
		}
	}

	function isLoadingProductLocationImages(sampleId: number, entityType: 'PRODUCT' | 'LOCATION') {
		return !!$loadingAttachments && $loadingAttachments.type === entityType && $loadingAttachments.sampleId === sampleId
	}

	type SampleColumnArray = Array<Column<DisplaySample>>

	const displayColumns = $derived<SampleColumnArray>([
		// #region Sample Details
		{
			name: '',
			property: 'attn',
			align: 'center',
			icon: 'exclamation-circle',
			title: translate(
				'workOrder.attnColumnTitle',
				'Icons will be displayed here representing various alerts. Hover over an icon to see more information.',
			),
			sortType: false,
			// Each icon is 40px, but we can have two side by side, and we have no way to tell the table to resize it to show both of them 🤷
			minWidth: '80px',
		},
		{
			name: recipesMode ? 'Recipe' : translate('workOrder.analysisColumn', 'Analysis'),
			minWidth: '200px',
			property: 'analysis[name]',
			title: translate('workOrder.analysisColumnTitle', 'Which analysis is being performed'),
		},
		{
			name: translate('workOrder.plantColumn', 'Plant'),
			property: 'plant[name]',
			title: translate('workOrder.plantColumnTitle', 'The plant the analysis was performed at'),
		},
		buildProductColumn(workOrder.workOrderType.showProduct),
		...(recipesMode
			? ([
					{
						property: 'productionVolume',
						name: translate('workOrder.productionVolumeColumn', 'Prod. Volume'),
						width: '150px',
					},
					{
						property: 'analysis[batchUnit]',
						name: translate('workOrder.unitColumn', 'Unit'),
						width: '100px',
					},
				] satisfies SampleColumnArray)
			: []),
		...(testingMode
			? ([
					{
						name: translate('workOrder.locationColumn', 'Location'),
						minWidth: '200px',
						property: 'location[location]',
						width: '220px',
						title: translate('workOrder.locationColumnTitle', 'Where the sample was collected (when appropriate)'),
					},
					{
						name: translate('workOrder.locationDescriptionColumn', 'Location Description'),
						minWidth: '200px',
						property: 'location[description]',
						title: translate(
							'workOrder.locationDescriptionColumnTitle',
							'The description of the location the sample was collected at',
						),
					},
					{
						name: translate('workOrder.testingCommentsColumn', 'Testing Comments'),
						property: 'testingComments',
						minWidth: '200px',
						title: translate(
							'workOrder.testingCommentsColumnTitle',
							'(Optional) additional findings from the testing process',
						),
					},
				] satisfies SampleColumnArray)
			: []),
		{
			name: recipesMode ? 'Comments' : translate('workOrder.samplingCommentsColumn', 'Sampling Comments'),
			property: 'samplingComments',
			minWidth: '200px',
			title: translate('workOrder.samplingCommentsColumnTitle', '(Optional) comments from sample collector'),
		},
		{
			name: translate('workOrder.statusColumn', 'Status'),
			minWidth: '125px',
			property: 'status',
			title: translate('workOrder.statusColumnTitle', 'The current status of the sample collection'),
		},
		// #endregion
		// Options go here! On the desktop, there's a user setting for where it goes, but I ain't doing that right now
		...($showOptions && testingMode ? optionsColumns : []),
		// #region Collection Details
		...(testingMode
			? ([
					{
						name: translate('workOrder.scheduledColumn', 'Scheduled'),
						property: 'scheduled',
						title: translate(
							'workOrder.scheduledColumnTitle',
							'The date and time this sample is scheduled to be collected. The sample should be performed some time between when its scheduled and when its due (if applicable)',
						),
					},
					{
						name: translate('workOrder.dueColumn', 'Due'),
						property: 'due',
						title: translate(
							'workOrder.dueColumnTitle',
							'The latest this sample should be collected/performed (if applicable)',
						),
					},
					{
						name: translate('workOrder.performedColumn', 'Performed On'),
						property: 'performed',
						title: translate('workOrder.performedColumnTitle', 'When the sample was collected (set automatically)'),
					},
					{
						name: translate('workOrder.performedByColumn', 'Performed By'),
						property: 'collectedBy[fullName]',
						title: translate(
							'workOrder.performedByColumnTitle',
							'The user who collected the sample (set automatically)',
						),
					},
				] satisfies SampleColumnArray)
			: []),
		// #endregion Collection Details
		{
			name: translate('workOrder.tagNumberColumn', 'Tag #'),
			property: 'tagNumber',
			minWidth: '100px',
			numeric: true,
			sortType: 'ALPHA_NUM',
			title: translate(
				'workOrder.tagNumberColumnTitle',
				'The value that uniquely identifies this sample. Leave this blank to automatically assign a value on save',
			),
		},
		// #region Testing Details
		...(testingMode
			? ([
					{
						name: translate('workOrder.testingBeganColumn', 'Testing Began'),
						property: 'incubationBegan',
						title: translate('workOrder.testingBeganColumnTitle', 'Start of testing process (such as incubation)'),
					},
					{
						name: translate('workOrder.testingEndedColumn', 'Testing Ended'),
						property: 'incubationEnded',
						title: translate('workOrder.testingEndedColumnTitle', 'End of testing process (such as incubation)'),
					},
					{
						name: translate('workOrder.testedByColumn', 'Tested By'),
						property: 'platesReadBy[fullName]',
						title: translate('workOrder.testedByColumnTitle', 'The user who performed the testing process'),
					},
				] satisfies SampleColumnArray)
			: []),
		// #endregion Testing Details
	])
</script>

<Table
	responsive
	stickyHeader
	filterEnabled
	selectionEnabled
	columnHidingEnabled
	columnResizingEnabled
	tableId="sample-table"
	localStorageKey={`work-order-sample-detail-table-${mode.toLowerCase()}`}
	parentClass="mh-60vh"
	perPageCount={samplesPerPage}
	idProp="uuid"
	rowSelectionIdProp="uuid"
	rows={displaySamples}
	columns={displayColumns}
	headerColumnClass="col d-flex flex-grow-1 mb-3"
	bind:selectedRowIds
	bind:this={table}
>
	{#snippet header()}
		{#if testingMode}
			<div class="d-flex align-self-end flex-grow-1 flex-wrap">
				<Checkbox
					inline
					label={translate('workOrder.showOptionsLabel', 'Show options and values')}
					bind:checked={$showOptions}
				></Checkbox>
				{#if workOrder.workOrderType.showSamplingDetail}
					<Checkbox
						inline
						label={translate('workOrder.showCollectionDetailLabel', 'Show collection detail')}
						bind:checked={$showCollectionDetail}
					></Checkbox>
				{/if}
				{#if workOrder.workOrderType.showTestingDetail}
					<Checkbox
						inline
						label={translate('workOrder.showTestingDetailLabel', 'Show testing detail')}
						bind:checked={$showTestingDetail}
					></Checkbox>
				{/if}
				<!-- This div pushes the last checkbox to the right -->
				<div class="flex-grow-1"></div>
				<Checkbox
					inline
					label={closedSampleCount
						? translate('workOrder.showClosedSamplesCountLabel', 'Show {{closedSampleCount}} closed samples', {
								closedSampleCount,
							})
						: translate('workOrder.showClosedSamplesLabel', 'Show closed samples')}
					disabled={closedSampleCount === 0}
					bind:checked={$showClosedSamples}
				></Checkbox>
			</div>
		{/if}
	{/snippet}
	{#snippet noRows({ visibleColumnsCount })}
		<tr
			><td
				class="text-center"
				colspan={visibleColumnsCount}
				>{translate('workOrder.noSamplesForFilters', 'No samples matching the given filters.')}</td
			></tr
		>
	{/snippet}
	{#snippet children({ row })}
		{@const rowPlantId = row.plant?.id ?? workOrder.plant.id}
		{@const initialSampleStatus = initialSampleStatuses[row.uuid]}
		<tr
			class="text-nowrap"
			class:table-primary={selectedRowIds.includes(row.uuid)}
			oncontextmenu={event => {
				if (!event.ctrlKey) {
					event.preventDefault()
					contextMenuSample = row
					sampleContextMenu?.open(event)
				}
			}}
			onclick={event => {
				// table?.rowClick(row)
				const ctrlKey = event.ctrlKey || event.metaKey
				const shiftKey = event.shiftKey
				if (ctrlKey) {
					selectedRowIds = selectedRowIds.includes(row.uuid)
						? selectedRowIds.filter(id => id !== row.uuid)
						: [...selectedRowIds, row.uuid]
				} else if (shiftKey) {
					event.preventDefault()
					const lastSelectedIndex = displaySamples.findIndex(
						sample => sample.uuid === selectedRowIds[selectedRowIds.length - 1],
					)
					const clickedIndex = displaySamples.findIndex(sample => sample.uuid === row.uuid)
					const minIndex = Math.min(lastSelectedIndex, clickedIndex)
					const maxIndex = Math.max(lastSelectedIndex, clickedIndex)
					selectedRowIds = displaySamples.slice(minIndex, maxIndex + 1).map(sample => sample.uuid)
				} else {
					selectedRowIds = [row.uuid]
				}

				if (selectedRowIds.length === 1) {
					selectedSample = displaySamples.find(sample => sample.uuid === selectedRowIds[0]) ?? null
				} else {
					selectedSample = null
				}
			}}
		>
			<Td property="attn"
				><Attention
					sample={row}
					sampleModified={row.uuid in $sampleCrudStore.updated}
					save={() => mediator.call('saveWorkOrder')}
					closeSample={() => {
						workOrder.samples[row.unfilteredIndex].status = 'CLOSED'
						sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
					}}
					openAttachments={() => {
						selectedSample = row
						showAttachmentsModal = true
					}}
				></Attention></Td
			>
			<Td
				enterGoesDown
				stopPropagation
				property="analysis[name]"
			>
				<Autocomplete
					id="analysis-{row.uuid}"
					label={translate('workOrder.analysisLabel', 'Analysis')}
					showLabel={false}
					options={getApplicableAnalyses(rowPlantId)}
					title={row.analysis?.name ?? ''}
					disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
					getLabel={option => option?.name ?? ''}
					value={row.analysis}
					change={async selectedAnalysis => {
						if (!confirmActionThatRequiresReVerify()) {
							return
						}
						const analysis = (await getApplicableAnalyses(workOrder.plant.id)).find(
							analysis => analysis.id === selectedAnalysis.id,
						)
						if (!analysis) {
							return
						}
						const newSample: Sample = {
							...workOrder.samples[row.unfilteredIndex],
							analysis: {
								id: analysis.id,
								name: analysis.name,
								active: analysis.active,
								requireAuthentication: analysis.requireAuthentication,
								analysisType: analysis.analysisType,
								instructions: analysis.instructions,
								batchUnit: analysis.batchUnit,
								batchVolume: analysis.batchVolume,
								createdProductId: analysis.createdProductId,
							},
							// @ts-expect-error TODO fix this
							sampleValues: analysisOptionsToBlankSampleValues(analysis.options, row.id),
						}

						// Keep the sample's product up to date with the selected analysis if applicable
						if (analysis.analysisType === 'RECIPE' && analysis?.createdProductId) {
							const product = (await productsLoader(rowPlantId)).find(
								product => product.id === analysis.createdProductId,
							)
							newSample.product = product ? klona(product) : null
						}
						// Note: Switching Analyses does not update productionVolume
						// Computes once, and then again after the restrictions come back from the API
						restrictionTree.fetchForSample(newSample, rowPlantId)
						documentTree.fetchForSample(newSample, rowPlantId)
						workOrder.samples[row.unfilteredIndex] = newSample
						sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
					}}
				></Autocomplete>
			</Td>
			<Td property="plant[name]"
				><span style="white-space:nowrap;"
					>{row.plant?.name ?? translate('common:notApplicableAbbreviation', 'N/A')}</span
				></Td
			>
			<Td
				enterGoesDown
				stopPropagation
				property="product[name]"
			>
				<Autocomplete
					label={translate('workOrder.productLabel', 'Product')}
					labelParentClass="product-location-select"
					showLabel={false}
					showAppend={!!row.product?.attachmentCount}
					options={getDisplayProducts(rowPlantId)}
					title={row.product?.name ?? undefined}
					disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
					getLabel={option => option?.name ?? ''}
					emptyValue={null}
					value={row.product}
					change={async selectedProduct => {
						if (!confirmActionThatRequiresReVerify()) {
							return
						}
						if (selectedProduct) {
							const product = (await productsLoader(rowPlantId)).find(product => product.id === selectedProduct?.id)
							workOrder.samples[row.unfilteredIndex].product = product ? klona(product) : null
						}

						sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						restrictionTree.fetchForSample(workOrder.samples[row.unfilteredIndex], rowPlantId)
						documentTree.fetchForSample(workOrder.samples[row.unfilteredIndex], rowPlantId)
					}}
				>
					{#snippet append()}
						<Button
							iconClass="photo-video"
							title={translate('workOrder.productImagesTitle', 'View product images')}
							tabindex={-1}
							isLoading={!!row.product && isLoadingProductLocationImages(row.id, 'PRODUCT')}
							onclick={async () => {
								if (row.product) {
									await showProductLocationImages({
										entityType: 'PRODUCT',
										entityId: row.product.id,
										entityName: row.product.name,
										sampleId: row.id,
									})
								}
							}}>{row.product?.attachmentCount}</Button
						>
					{/snippet}
				</Autocomplete>
			</Td>
			{#if recipesMode}
				<Td
					enterGoesDown
					stopPropagation
					property="productionVolume"
				>
					<Input
						showLabel={false}
						type="number"
						value={row.productionVolume}
						onchange={event => {
							workOrder.samples[row.unfilteredIndex].productionVolume = parseFloat(getEventValue(event))
							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
							// This is used to compute defaults so, recompute them
							getDefaultSampleValues(workOrder.samples[row.unfilteredIndex], row.unfilteredIndex)
						}}
					></Input>
				</Td>
				<Td property="analysis[batchUnit]">{row.analysis.batchUnit}</Td>
			{/if}
			{#if testingMode}
				<Td
					enterGoesDown
					stopPropagation
					property="location[location]"
				>
					<Autocomplete
						label={translate('workOrder.locationLabel', 'Location')}
						labelParentClass="product-location-select"
						showLabel={false}
						options={locationsLoader(rowPlantId)}
						title={row.location?.location ?? undefined}
						value={row.location}
						disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
						showAppend={!!row.location?.attachmentCount}
						getLabel={option => option?.location ?? ''}
						emptyValue={null}
						change={async selectedLocation => {
							if (!confirmActionThatRequiresReVerify()) {
								return
							}
							if (selectedLocation) {
								const location = (await locationsLoader(rowPlantId)).find(
									location => location.id === selectedLocation?.id,
								)
								workOrder.samples[row.unfilteredIndex].location = location ? klona(location) : null
							}

							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
							restrictionTree.fetchForSample(workOrder.samples[row.unfilteredIndex], rowPlantId)
							documentTree.fetchForSample(workOrder.samples[row.unfilteredIndex], rowPlantId)
						}}
					>
						{#snippet append()}
							<Button
								iconClass="photo-video"
								title={translate('workOrder.locationImagesTitle', 'View location images')}
								tabindex={-1}
								isLoading={!!row.location && isLoadingProductLocationImages(row.id, 'LOCATION')}
								onclick={async () => {
									if (row.location) {
										showProductLocationImages({
											entityType: 'LOCATION',
											entityId: row.location.id,
											entityName: row.location.location,
											sampleId: row.id,
										})
									}
								}}
								>{row.location?.attachmentCount}
							</Button>
						{/snippet}
					</Autocomplete>
				</Td>
				<Td property="location[description]">
					<span class:text-muted={!row.location?.description}
						>{row.location?.description ?? translate('common:notApplicableAbbreviation', 'N/A')}</span
					>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="testingComments"
				>
					<Textarea
						placeholder={translate('common:notApplicableAbbreviation', 'N/A')}
						style="min-height: 31px; height: 31px;"
						showLabel={false}
						value={row.testingComments}
						disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
						onchange={event => {
							if (event.target instanceof HTMLTextAreaElement) {
								workOrder.samples[row.unfilteredIndex].testingComments = event.target.value
							}
							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						}}
					></Textarea>
				</Td>
			{/if}
			<Td
				enterGoesDown
				stopPropagation
				property="samplingComments"
			>
				<Textarea
					placeholder={translate('common:notApplicableAbbreviation', 'N/A')}
					style="min-height: 31px; height: 31px;"
					showLabel={false}
					value={row.samplingComments}
					disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
					onchange={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							workOrder.samples[row.unfilteredIndex].samplingComments = event.target.value
						}
						sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
					}}
				></Textarea>
			</Td>
			<Td
				enterGoesDown
				stopPropagation
				property="status"
			>
				<Select
					showLabel={false}
					options={workOrderStatuses}
					disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus) &&
						!(initialSampleStatus === 'CLOSED' && hasPermission('WORK_ORDERS_CAN_REOPEN', rowPlantId))}
					value={row.status}
					onchange={event => {
						if (event.target instanceof HTMLSelectElement) {
							const newStatus = event.target.value
							if (!isDocumentStatus(newStatus)) {
								return alert(translate('workOrder.invalidStatusAlert', 'Invalid status'))
							}

							const oldStatus = workOrder.samples[row.unfilteredIndex].status
							const requiredValuesPresent =
								sampleMissingRequiredValuesForStatus(workOrder.samples[row.unfilteredIndex], newStatus).length === 0
							const canPerformPartial = hasPermission('WORK_ORDERS_CAN_PERFORM_PARTIALLY_COMPLETED', rowPlantId)
							const canClosePartial = hasPermission('WORK_ORDERS_CAN_CLOSE_PARTIALLY_COMPLETED', rowPlantId)

							// If stuff isn't all the way filled out, we might not allow them to change the status
							if (!requiredValuesPresent && newStatus === 'CLOSED' && !canClosePartial) {
								workOrder.samples[row.unfilteredIndex].status = oldStatus
								return alert(
									translate(
										'workOrder.closeMissingRequiredOptionsAlert',
										'All required options must be filled out before the sample can be closed',
									),
								)
							} else if (
								!requiredValuesPresent &&
								newStatus === 'CLOSED' &&
								canPerformPartial &&
								!confirm(
									translate(
										'workOrder.closeMissingRequiredOptionsConfirm',
										'Some required options are not filled out. Are you sure you want to close this sample?',
									),
								)
							) {
								return
							} else if (!requiredValuesPresent && newStatus === 'SAMPLED' && !canPerformPartial) {
								workOrder.samples[row.unfilteredIndex].status = oldStatus
								return alert(
									translate(
										'workOrder.performMissingRequiredOptionsAlert',
										'All required options must be filled out before the sample can be performed',
									),
								)
							} else if (
								!requiredValuesPresent &&
								newStatus === 'SAMPLED' &&
								canPerformPartial &&
								!confirm(
									translate(
										'workOrder.performMissingRequiredOptionsConfirm',
										'Some required options are not filled out. Are you sure you want to perform this sample?',
									),
								)
							) {
								return
							}
							// Now we know they're allowed to change the status
							workOrder.samples[row.unfilteredIndex].status = newStatus

							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						}
					}}
				>
					{#snippet option({ option })}
						<option value={option}>{option && formatDocumentStatus(translate, option, true)}</option>
					{/snippet}
				</Select>
			</Td>

			{#if $showOptions && testingMode}
				{#each row.sampleValues as sampleValue, displaySvIndex}
					{@const optionName = sampleValue?.analysisOption ? formatOptionName(sampleValue?.analysisOption) : null}
					{#if sampleValue && optionName && optionsToShow.has(optionName)}
						{@const svOgIndex = sampleValue.svOgIndex}
						<Td
							enterGoesDown
							stopPropagation
							property="sampleValues[{displaySvIndex}][result]"
						>
							<OptionValueInput
								{allowShowThresholdsTable}
								disabled={!computeCanEditSampleValue(canEditCurrentWorkOrder, initialSampleStatus, row, sampleValue)}
								labelType="COMPACT"
								sample={row}
								sampleValue={workOrder.samples[row.unfilteredIndex]?.sampleValues[svOgIndex]}
								showLabel={hasMultipleAnalyses}
								showModifiedIcons={$showModifiedIcons}
								{workOrder}
								bind:showOnlyApplicableThresholds={$showOnlyApplicableThresholds}
								change={({ value }) => {
									if (!confirmActionThatRequiresReVerify()) {
										return
									}
									workOrder.samples[row.unfilteredIndex].sampleValues[svOgIndex].result = value
									if (workOrder.samples[row.unfilteredIndex].status === 'OPEN') {
										workOrder.samples[row.unfilteredIndex].status = 'SAMPLED'
									}
									sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
									getDefaultSampleValues(workOrder.samples[row.unfilteredIndex], row.unfilteredIndex)
									if (sampleValue?.id) {
										woDocumentStore.sampleValueUpdated(sampleValue.id)
									}
								}}
							></OptionValueInput>
						</Td>
					{:else if !sampleValue || !optionName}
						<Td property="sampleValues[{displaySvIndex}][result]"
							><i class="text-muted">{translate('common:notApplicableAbbreviation', 'N/A')}</i></Td
						>
					{/if}
				{/each}
			{/if}
			{#if testingMode}
				<Td
					enterGoesDown
					stopPropagation
					property="scheduled"
				>
					<Input
						showLabel={false}
						type="datetime-local"
						value={formatDateTimeForInput(row.scheduled, $session.plant.timezone)}
						disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
						onchange={event => {
							setSampleDateFieldFromInput(event, 'scheduled', row.unfilteredIndex)
							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						}}
					></Input>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="due"
				>
					<Input
						showLabel={false}
						type="datetime-local"
						value={formatDateTimeForInput(row.due, $session.plant.timezone)}
						disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
						onchange={event => {
							setSampleDateFieldFromInput(event, 'due', row.unfilteredIndex)
							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						}}
					></Input>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="performed"
				>
					<Input
						readonly
						showLabel={false}
						type="datetime-local"
						value={formatDateTimeForInput(row.performed, $session.plant.timezone)}
					></Input>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="collectedBy[fullName]"
				>
					<span
						style="white-space: nowrap"
						class:text-muted={!row.collectedBy?.fullName}
						>{row.collectedBy?.fullName ?? translate('common:notApplicableAbbreviation', 'N/A')}</span
					>
				</Td>
			{/if}
			<Td
				enterGoesDown
				stopPropagation
				property="tagNumber"
			>
				<Input
					showLabel={false}
					value={row.tagNumber ?? ''}
					placeholder={translate('common:autoAssigned', 'Auto-assigned')}
					disabled={!hasPermission('WORK_ORDERS_CAN_EDIT_TAG_NUMBER', rowPlantId) ||
						!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
					onchange={event => {
						if (event.target instanceof HTMLInputElement) {
							workOrder.samples[row.unfilteredIndex].tagNumber = event.target.value
						}
						sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
					}}
				></Input>
			</Td>
			{#if testingMode}
				<Td
					enterGoesDown
					stopPropagation
					property="incubationBegan"
				>
					<Input
						showLabel={false}
						type="datetime-local"
						value={formatDateTimeForInput(row.incubationBegan, $session.plant.timezone)}
						disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
						onchange={event => {
							setSampleDateFieldFromInput(event, 'incubationBegan', row.unfilteredIndex)
							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						}}
					></Input>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="incubationEnded"
				>
					<Input
						showLabel={false}
						type="datetime-local"
						value={formatDateTimeForInput(row.incubationEnded, $session.plant.timezone)}
						disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
						onchange={event => {
							setSampleDateFieldFromInput(event, 'incubationEnded', row.unfilteredIndex)
							sampleCrudStore.update(workOrder.samples[row.unfilteredIndex])
						}}
					></Input>
				</Td>
				<Td property="platesReadBy[fullName]">
					<span
						style="white-space: nowrap"
						class:text-muted={!row.platesReadBy?.fullName}
						>{row.platesReadBy?.fullName ?? translate('common:notApplicableAbbreviation', 'N/A')}</span
					>
				</Td>
			{/if}
		</tr>
	{/snippet}
</Table>
