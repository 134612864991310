import type { AppContext } from 'types/common'
import type { SampleFilter, SampleOrderBy$options, SamplingHistoryResults$result } from '$houdini'

import { stringToBoolean } from '@isoftdata/utility-string'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import component from './Results.svelte'
import makeSettingValueStore from 'stores/setting-value'

type SplitOrderByOptions<T extends string> = T extends `${infer U}_${string}` ? U : never

export type SampleOrderByField = SplitOrderByOptions<SampleOrderBy$options>

export type SampleResult = SamplingHistoryResults$result['samples']['data'][number]

// Have to map client column property names to db property names for sorting
const tableColumnSortNames: Record<string, SampleOrderByField> = {
	'analysis[name]': 'analysisName',
	comments: 'comments',
	due: 'due',
	findings: 'findings',
	'location[location]': 'location',
	performed: 'performed',
	'plant[name]': 'plantName',
	'product[name]': 'productName',
	scheduled: 'scheduled',
	status: 'status',
	tagNumber: 'tagNumber',
}

function orderByIsValid(orderBy: string): orderBy is SampleOrderByField {
	return orderBy in tableColumnSortNames
}

const reversedTableColumnSortNames: Record<SampleOrderByField, string> = Object.fromEntries(
	Object.entries(tableColumnSortNames).map(([key, value]) => [value, key]),
) as Record<SampleOrderByField, string>

export default ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.sampling-history.results',
		route: 'results',
		querystringParameters: ['filter', 'uuid', 'pageNumber', 'orderBy', 'sortDirection'],
		defaultParameters: {
			pageNumber: '1',
		},
		template: component,
		async resolve(data, parameters) {
			const {
				plant: { timezone: plantTimezone },
			} = getSession()

			const filter = JSON.parse(parameters.filter) as SampleFilter
			const pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = 100 // eventually I'd want to make this a user setting but as it stands now those load at the same time as the resutls themselves

			let orderBy: SampleOrderBy$options | undefined = undefined
			const sortDirection = parameters.sortDirection?.toUpperCase() === 'ASC' ? 'ASC' : 'DESC'
			if (parameters.orderBy && orderByIsValid(parameters.orderBy)) {
				orderBy = `${parameters.orderBy}_${sortDirection}`
			}

			const [{ data: samplesData }, { data: userInterfaceSettingsData }] = await Promise.all([
				samplesQuery.fetch({
					variables: {
						filter,
						pagination: { pageNumber, pageSize },
						orderBy: orderBy ? [orderBy] : undefined,
					},
				}),
				getSettings.fetch(),
			])

			if (!samplesData || !userInterfaceSettingsData?.session?.user) {
				throw showErrorAndRedirect(
					mediator,
					translate('samplingHistory.failedToLoadHeader', 'Failed to load sampling history results'),
					translate('samplingHistory.noDataMessage', 'No data was returned from the server'),
					{ name: 'app.sampling-history' },
				)
			}

			const {
				samples: {
					data: results,
					info: { totalItems },
				},
			} = samplesData
			const userInterfaceSettings = userInterfaceSettingsData.session.user

			return {
				filter,
				pageNumber,
				pageSize,
				totalItems,
				sortDirection,
				sortColumnProperty: reversedTableColumnSortNames[parameters.orderBy as SampleOrderByField],
				results,
				isLoadingCsvExport: false,
				plantTimezone,
				tableColumnSortNames,
				showOptions: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show analysis options and values in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(userInterfaceSettings.optionsAndValues.value),
				}),
				showCollectionDetail: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show collection information in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(userInterfaceSettings.collectionInformation.value),
				}),
				showTestingDetail: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show testing information in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(userInterfaceSettings.testingInformation.value),
				}),
				showWorkOrderDetail: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show work order information in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(userInterfaceSettings.workOrderInformation.value),
				}),
				showImageThumbnails: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show image thumbnails in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(userInterfaceSettings.showImageThumbnails.value),
				}),
				showOnlyApplicableThresholds: makeSettingValueStore<boolean>({
					category: 'Work Orders',
					settingType: 'INTERFACE_HISTORY',
					name: 'Test Thresholds: Show only applicable thresholds',
					scope: 'USER',
					initialValue: stringToBoolean(userInterfaceSettings.showOnlyApplicableThresholds.value),
				}),
				allowShowThresholdsTable: stringToBoolean(userInterfaceSettingsData.allowShowThresholdsTable.value),
				showModifiedIcons: stringToBoolean(userInterfaceSettings.showModifiedIcons.value),
			}
		},
	})
}

const getSettings = graphql(`
	query SamplingHistorySettings {
		session {
			user {
				optionsAndValues: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show analysis options and values in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				collectionInformation: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show collection information in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				workOrderInformation: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show work order information in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				testingInformation: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show testing information in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				showImageThumbnails: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show image thumbnails in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				showOnlyApplicableThresholds: getUserSetting(
					lookup: {
						category: "Work Orders"
						name: "Test Thresholds: Show only applicable thresholds"
						defaultValue: "True"
						settingType: INTERFACE_HISTORY
					}
				) {
					value
				}
				showModifiedIcons: getUserSetting(
					lookup: {
						category: "Sampling"
						settingType: INTERFACE_HISTORY
						name: "Show icon for any values modified from their initial version"
						defaultValue: "True"
					}
				) {
					value
				}
			}
		}
		allowShowThresholdsTable: getGlobalSetting(
			lookup: { category: "Scanner", name: "showthresholds", settingType: INTERFACE_PREFERENCE, defaultValue: "False" }
		) {
			value
		}
	}
`)

const samplesQuery = graphql(`
	query SamplingHistoryResults($filter: SampleFilter, $pagination: PaginatedInput, $orderBy: [SampleOrderBy!]) {
		samples(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			info {
				pageNumber
				pageSize
				totalItems
			}
			data {
				sampleFiles: attachments {
					file {
						path
						mediaType: type
					}
					rank
				}
				plant {
					id
					name
					code
				}
				product {
					id
					name
				}
				location {
					id
					location
					description
					severityClass {
						id
					}
				}
				collectedBy {
					fullName
				}
				findings
				comments
				status
				tagNumber
				sampleValues {
					id
					result
					resultStatus
					defaultValue
					lastModifiedByUserId
					lastModified
					lot
					expiration
					filledOut
					analysisOptionId
					analysisOption {
						id
						option
						unit
						active
						valueType
						thresholdType
						rank
						defaultType
						defaultValue
					}
				}
				id
				workOrder {
					id
					title
					verificationDue
					verifiedOn
					verifiedByUser {
						fullName
					}
					dateCreated
					due
					internalNotes
					instructions
					scheduled
					assignedToGroup {
						name
					}
					workOrderType {
						name
						showTestingDetail
					}
					productBatch {
						id
						name
						# product {
						# 	name
						# }
					}
					plant {
						id
					}
				}
				analysisId
				incubationBegan
				incubationEnded
				performed
				scheduled
				due
				platesReadBy {
					fullName
				}
			}
		}
	}
`)
