import type { SettingType$options } from '$houdini'
import { graphql } from '$houdini'
import { type Writable, writable } from 'svelte/store'
import { booleanToString } from '@isoftdata/utility-boolean'
import { setUserSettingMutation } from 'utility/set-user-setting'

interface InitArgs<T extends string | boolean | number = string> {
	category: string
	name: string
	settingType: SettingType$options
	scope: 'USER' | 'PLANT' | 'GLOBAL'
	/** The initial value of the store - the current value of the setting */
	initialValue: T
}

export type SettingValueStore<T extends string | number | boolean> = Omit<Writable<T>, 'update'>
/**
 * This function creates a store that will automatically update the setting value when the store's value is updated.
 * @param setting An object representing the setting
 * @returns A writable store that contains the setting value. It also has a teardown function that should be called when the store is no longer needed.
 */
export default function makeSettingValueStore<T extends string | boolean | number = string>(
	setting: InitArgs<T>,
): SettingValueStore<T> {
	const { subscribe, set: defaultSet } = writable<T>(setting.initialValue)

	function set(newValue: T) {
		defaultSet(newValue)
		saveSettingValue(setting, newValue).catch(err => console.error('Error setting setting value', err))
	}

	return {
		subscribe,
		set,
	}
}

function toString<T extends string | boolean | number>(value: T): string {
	if (typeof value === 'boolean') {
		return booleanToString(value)
	} else if (typeof value === 'number') {
		return value.toString()
	}

	return value
}

async function saveSettingValue<T extends string | number | boolean>(
	setting: InitArgs<T>,
	newValue: string | boolean | number,
) {
	return await mutations[setting.scope].mutate({
		value: {
			category: setting.category,
			name: setting.name,
			settingType: setting.settingType,
			newValue: toString(newValue),
		},
	})
}

const mutations = {
	USER: setUserSettingMutation,
	PLANT: graphql(`
		mutation SetPlantSetting($value: SettingChange!) {
			setPlantSetting(value: $value)
		}
	`),
	GLOBAL: graphql(`
		mutation SetGlobalSetting($value: SettingChange!) {
			setGlobalSetting(value: $value)
		}
	`),
}
