<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import type { ComponentEvents } from 'svelte'
	import type { SortDirection } from '@isoftdata/svelte-table'
	import type { Writable } from 'svelte/store'

	import { History, type PrintJob } from '@isoftdata/svelte-report-viewer'
	import { graphql } from '$houdini'

	export let history: PrintJob[] = []
	export let asr: SvelteAsr
	export let currentPageNumber: number
	export let sortColumnProperty: string
	export let sortDirection: 'ASC' | 'DESC'
	export let totalItemsCount: number
	export let showCancelledJobs: Writable<boolean>
	export let showSentJobs: Writable<boolean>
	export let showReadyJobs: Writable<boolean>
	export let showFailedJobs: Writable<boolean>
	export let showDelayedJobsOnly: Writable<boolean>

	export let perPageCount: number

	const setPrintJobStatus = graphql(`
		mutation SetPrintJobStatus($input: [SetPrintJobStatusInput!]!) {
			setPrintJobStatus(input: $input)
		}
	`)

	function onPreviewClick(event: CustomEvent<PrintJob>) {
		localStorage.setItem('reportViewerPreviewPrintJob', JSON.stringify(event.detail))
		asr.go('app.report-viewer.reports.preview', { selectedReportId: event.detail.reportId, preview: true })
	}

	function onSetReadyClick(event: CustomEvent<PrintJob>) {
		setPrintJobStatus.mutate({
			input: [
				{
					id: event.detail.id,
					status: 'READY',
				},
			],
		})
	}

	function onCancelClick(event: ComponentEvents<History>['cancelClick']) {
		setPrintJobStatus.mutate({
			input: event.detail.map(id => ({
				id,
				status: 'CANCELLED',
			})),
		})
	}

	function filterChange(
		event: CustomEvent<boolean>,
		parameter: 'showCancelledJobs' | 'showSentJobs' | 'showReadyJobs' | 'showFailedJobs' | 'showDelayedJobsOnly',
	) {
		asr.go(null, { [parameter]: event.detail }, { inherit: true })
	}

	function onPageChange(event: ComponentEvents<History>['pageChange']) {
		asr.go(null, { pageNumber: event.detail.pageNumber }, { inherit: true })
	}

	// TODO: Temp type fix b/c the history copmonent uses table v1 but if we import the type it'd come from v2
	async function columnClickedMethod(clickedColumn: { property: string }, sortDirection: SortDirection) {
		asr.go(null, { sortColumnProperty: clickedColumn.property, sortDirection, pageNumber: 1 }, { inherit: true })
	}
</script>

<History
	showDelayUntil
	showAdditionalStatusFilters
	{history}
	{currentPageNumber}
	{perPageCount}
	{sortColumnProperty}
	{sortDirection}
	{totalItemsCount}
	bind:showCancelledJobs={$showCancelledJobs}
	bind:showSentJobs={$showSentJobs}
	bind:showReadyJobs={$showReadyJobs}
	bind:showFailedJobs={$showFailedJobs}
	bind:showDelayedJobsOnly={$showDelayedJobsOnly}
	{columnClickedMethod}
	refreshHref={asr.makePath(null, { lastRefreshTime: Date.now(), pageNumber: 1 }, { inherit: true })}
	on:previewClick={onPreviewClick}
	on:setReadyClick={onSetReadyClick}
	on:cancelClick={onCancelClick}
	on:showCancelledChange={e => filterChange(e, 'showCancelledJobs')}
	on:showSentChange={e => filterChange(e, 'showSentJobs')}
	on:showReadyChange={e => filterChange(e, 'showReadyJobs')}
	on:showFailedChange={e => filterChange(e, 'showFailedJobs')}
	on:showDelayedOnlyChange={e => filterChange(e, 'showDelayedJobsOnly')}
	on:pageChange={onPageChange}
></History>
