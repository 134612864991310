<script lang="ts">
	import type { DisplaySample, Sample } from '../work-order'
	import type { ComponentProps, Snippet } from 'svelte'
	import type { i18n } from 'i18next'
	import type { Colors } from '@isoftdata/utility-bootstrap'

	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'
	import { getContext } from 'svelte'

	const { t: translate } = getContext<i18n>('i18next')

	interface Props {
		sample: DisplaySample | Sample
		sampleModified: boolean
		inline?: boolean
		children?: Snippet
		save: () => Promise<void>
		closeSample: () => void
		openAttachments: () => void
	}

	let {
		//
		sample,
		inline = false,
		sampleModified,
		children,
		save,
		closeSample,
		openAttachments,
	}: Props = $props()

	const icons: Array<{
		icon: ComponentProps<Icon>['icon']
		title: string
		active: boolean
		color?: Colors
		className?: string
		href?: string
		onClick?: (event: MouseEvent) => void
		disabled?: boolean
	}> = $derived([
		// Has Unsaved Changes - Does the sample, or any of its values, have unsaved changes?
		{
			icon: 'save',
			title: translate(
				'workOrder.attnHasUnsavedChangesTitle',
				'This sample has unsaved changes. Click to save the document & all samples.',
			),
			active: !sample.id || sampleModified,
			onClick: () => save(),
		},
		// All Options Entered - Is the sample editable (not closed), but has all values entered?
		{
			icon: 'list-check',
			title: translate(
				'workOrder.attnAllOptionsEnteredTitle',
				"All analysis options have been filled out, but the sample isn't closed yet. Click to close this sample.",
			),
			active:
				sample.status !== 'CLOSED' && sample.sampleValues.every(sv => !sv || (sv.result !== null && sv.result !== '')),
			onClick: () => closeSample(),
		},
		// Has Attachments - Does the sample have any attachments?
		{
			icon: 'paperclip',
			title: translate('workOrder.attnHasAttachmentsTitle', 'This sample has attachments. Click to view them'),
			active: sample.attachments.length > 0,
			onClick: () => openAttachments(),
		},
		// Created Investigation - if at least 1 of the sample values analysisoptionvalue.analysisoptionvalueid matches an investigation.analysisoptionvalueid meaning that this value ‘caused’ an investigation.
		// TODO, un-disable, and update href when we implement the investigation page
		{
			icon: 'magnifying-glass-arrow-right',
			className: 'fa-flip-horizontal',
			// title: 'An investigation was created based on the results of this sample. Click to view.',
			title: translate(
				'workOrder.attnCreatedInvestigationTitle',
				'An investigation was created based on the results of this sample.',
			),
			active: sample.sampleValues.some(sv => sv?.createdInvestigationId),
			disabled: true,
			// href: asr.makePath('app.investigation.history', { investigationId: 'todo' }),
		},
		// On Investigation - is true if sample.investigationid is set, meaning this sample is a retest that is part of an investigation.
		// TODO, un-disable, and update href when we implement the investigation page
		{
			icon: 'magnifying-glass-arrow-right',
			// title: 'This sample was created to continue an investigation. Click to view.',
			title: translate('workOrder.attnOnInvestigationTitle', 'This sample was created to continue an investigation.'),
			active: !!sample.investigationId,
			disabled: true,
			// href: asr.makePath('app.investigation.history', { investigationId: 'todo' }),
		},
	])
	const activeCount = $derived(icons.filter(({ active }) => active).length)
</script>

<!-- Not sure how to make this component properly expand, so I'm just gonna do it manually /shrug -->
<div
	class="form-row"
	class:row-cols-2={!inline}
	style:min-width={activeCount > 1 && !inline ? '5rem' : ''}
	style:gap={inline ? '0.25rem' : '0'}
>
	{#if children}
		<div class:col={!inline}>
			{@render children?.()}
		</div>
	{/if}
	{#each icons as { icon, className, title, active, onClick, href, disabled }}
		{#if active}
			<div class:col={!inline}>
				<Button
					outline
					size="xs"
					color="dark"
					icon={{
						icon,
						class: className,
					}}
					{title}
					{href}
					{disabled}
					colorGreyDisabled={false}
					style={inline ? 'margin-bottom: 3px;' : ''}
					onclick={event => {
						event.stopPropagation()
						onClick?.(event)
					}}
				/>
			</div>
		{/if}
	{/each}
</div>
