<script lang="ts">
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Icon from '@isoftdata/svelte-icon'
	import Select from '@isoftdata/svelte-select'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Textarea from '@isoftdata/svelte-textarea'
	import { getEventValue } from '@isoftdata/browser-event'

	import type { SvelteAsr } from 'types/common'
	import { v4 as uuid } from '@lukeed/uuid'
	import { getContext, onDestroy, tick } from 'svelte'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { i18n } from 'i18next'
	import type {
		Plant,
		Location,
		SeverityClass,
		Product,
		WorkOrderType,
		Analysis,
		InvestigationTrigger,
		ResultStatus,
		PeriodType,
		DefaultStatus,
		RetestMode,
		ExpansionMethod,
		PlantData,
	} from './configure-investigation'

	const { t: translate } = getContext<i18n>('i18next')

	export let asr: SvelteAsr
	export let hasUnsavedChanges: boolean = false
	export let plants: Plant[]
	export let selectedPlant: Plant
	export let investigationTriggers: InvestigationTrigger[]
	export let allInvestigationTypes: string[]
	export let plantDataMap: Map<number | null, PlantData>
	export let analysisDataMap: Map<number, Analysis>
	export let locationDataMap: Map<number, Location>
	export let severityClassDataMap: Map<number, SeverityClass>
	export let productDataMap: Map<number, Product>
	export let workOrderTypeDataMap: Map<number, WorkOrderType>
	export let investigationTriggersCrudStore: CrudStore<InvestigationTrigger, 'uuid'>

	let investigationTriggerTable: Table<InvestigationTrigger>
	let selectedInvestigationTriggerUuids: string[] = []
	let showEnvironmentalMappingFields = false

	$: hasUnsavedChanges = $investigationTriggersCrudStore && investigationTriggersCrudStore.hasChanges()
	$: investigationTriggerTable &&
		investigationTriggerTable.setColumnVisibility(
			['expansionMethod', 'expansionSize', 'retestDocumentCount', 'daysBetweenRetests', 'sampleCollectionCount'],
			showEnvironmentalMappingFields,
		)

	const resultStatusOptions: { label: string; value: ResultStatus }[] = [
		{ label: translate('configureInvestigation.resultStatus.acceptable', 'Acceptable'), value: 'ACCEPTABLE' },
		{ label: translate('configureInvestigation.resultStatus.unacceptable', 'Unacceptable'), value: 'UNACCEPTABLE' },
		{ label: translate('configureInvestigation.resultStatus.invalid', 'Invalid'), value: 'INVALID' },
		{
			label: translate('configureInvestigation.resultStatus.anyButAcceptable', 'Any but Acceptable'),
			value: 'ANY_BUT_ACCEPTABLE',
		},
		{ label: translate('configureInvestigation.resultStatus.marginal', 'Marginal'), value: 'MARGINAL' },
		{
			label: translate('configureInvestigation.resultStatus.westernElectricRule1', 'Western Electric Rule 1'),
			value: 'WESTERN_ELECTRIC_RULE_1',
		},
		{
			label: translate('configureInvestigation.resultStatus.westernElectricRule2', 'Western Electric Rule 2'),
			value: 'WESTERN_ELECTRIC_RULE_2',
		},
		{
			label: translate('configureInvestigation.resultStatus.westernElectricRule3', 'Western Electric Rule 3'),
			value: 'WESTERN_ELECTRIC_RULE_3',
		},
		{
			label: translate('configureInvestigation.resultStatus.westernElectricRule4', 'Western Electric Rule 4'),
			value: 'WESTERN_ELECTRIC_RULE_4',
		},
	]

	const periodTypeOptions: { label: string; value: PeriodType }[] = [
		{ label: 'Minute', value: 'MINUTE' },
		{ label: 'Hour', value: 'HOUR' },
		{ label: 'Day', value: 'DAY' },
		{ label: 'Sample Value', value: 'SAMPLE_VALUE' },
	]

	const defaultStatusOptions: { label: string; value: DefaultStatus }[] = [
		{ label: 'Open', value: 'OPEN' },
		{ label: 'Cancelled', value: 'CANCELLED' },
		{ label: 'Resolved', value: 'RESOLVED' },
		{ label: 'Ignored', value: 'IGNORED' },
	]

	const retestModeOptions: { label: string; value: RetestMode }[] = [
		{ label: 'Manual', value: 'MANUAL' },
		{ label: 'New Document', value: 'NEW_DOCUMENT' },
		{ label: 'Same Document', value: 'SAME_DOCUMENT' },
	]

	const expansionMethodOptions: { label: string; value: ExpansionMethod }[] = [
		{ label: 'None', value: 'NONE' },
		{ label: 'Nearest Locations', value: 'NEAREST_LOCATIONS' },
	]

	const investigationTriggerColumns: Array<Column<InvestigationTrigger>> = [
		{
			// @ts-expect-error
			property: 'editStatus',
			name: '',
			icon: 'circle-exclamation',
			align: 'center',
			width: '1%',
			sortType: false,
			title: translate(
				'configureInvestigation.editColumnTitle',
				'Icons will be displayed here, rows with floppy disk icon have unsaved changes, rows with trash icon will be deleted on save.',
			),
		},
		{
			property: 'active',
			name: translate('configureInvestigation.activeColumnName', 'Active'),
			align: 'center',
			title: translate(
				'configureInvestigation.activeStatusColumnTitle',
				'Whether this rules is currently being inforced or whether it should be ignored',
			),
		},
		{
			property: 'plantName',
			name: translate('configureInvestigation.plantColumnName', 'Plant'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.plantColumnTitle',
				`Which plant the value(s) must occur at, or 'All' for any plants`,
			),
		},
		{
			property: 'analysisName',
			name: translate('configureInvestigation.analysisColumnName', 'Analysis'),
			minWidth: '200px',
			title: translate('configureInvestigation.analysisColumnTitle', 'The analysis that can trigger an investigation'),
		},
		{
			property: 'analysisOptionName',
			name: translate('configureInvestigation.optionColumnName', 'Option'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.analysisOptionColumnTitle',
				'The analysis option that can trigger an investigation',
			),
		},
		{
			property: 'locationName',
			name: translate('configureInvestigation.locationColumnName', 'Location'),
			sortType: 'ALPHA_NUM',
			minWidth: '200px',
			title: translate(
				'configureInvestigation.locationColumnTitle',
				'A specific location the value(s) must be sampled from to trigger the investigation',
			),
		},
		{
			property: 'severityClassName',
			name: translate('configureInvestigation.severityColumnName', 'Severity Class'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.severityClassColumnTitle',
				'The severity class of the locations the value(s) must be sampled from to trigger the investigation. This allows triggering off more sites than when choosing a specific location.',
			),
		},
		{
			property: 'productName',
			name: translate('configureInvestigation.productColumnName', 'Product'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.productColumnTitle',
				'The product/ingredient the value(s) must be associated with',
			),
		},
		{
			property: 'resultCount',
			name: translate('configureInvestigation.countColumnName', 'Count'),
			numeric: true,
			align: 'center',
			minWidth: '70px',
			title: translate(
				'configureInvestigation.resultCountColumnTitle',
				'The number of results that must have the specified acceptability before an investigation is created',
			),
		},
		{
			property: 'resultStatus',
			name: translate('configureInvestigation.triggerColumnName', 'Trigger'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.resultStatusColumnTitle',
				'The method of triggering investigations. Choose an acceptability type to trigger an investigation if a certain number of matching samples are entered. Or choose, an advanced statistical method, which come with their own internal calculations.',
			),
		},
		{
			property: 'period',
			name: translate('configureInvestigation.intervalColumnName', 'Interval'),
			numeric: true,
			title: translate(
				'configureInvestigation.periodColumnTitle',
				'The length of the period/interval that all results must occur within to trigger the investigation.',
			),
		},
		{
			property: 'periodType',
			name: translate('configureInvestigation.intervalTypeColumnName', 'Interval Type'),
			minWidth: '150px',
			title: translate(
				'configureInvestigation.periodTypeColumnTitle',
				'The unit of time that the interval length is using',
			),
		},
		{
			property: 'defaultStatus',
			name: translate('configureInvestigation.defaultStatusColumnName', 'Default Status'),
			minWidth: '100px',
			title: translate(
				'configureInvestigation.defaultStatusColumnTitle',
				'This allow specification of the default status of any investigations generated by this trigger. This is useful for marking investigations as ignored or resolved that are designed to simply send notifications.',
			),
		},
		{
			property: 'retestMode',
			name: translate('configureInvestigation.retestModeColumnName', 'Retest Mode'),
			minWidth: '150px',
			title: translate(
				'configureInvestigation.retestModeColumnTitle',
				'How retest sampled will be created. Manual: the user must create retest samples from the investigation screen. Same Document: a follow up retest sample will be added to the document that triggered the investigation. New Document: a follow up retest sample will be added to a new document.',
			),
		},
		{
			property: 'retestDocumentCount',
			name: translate('configureInvestigation.retestDocCountColumnName', 'Retest Doc. Count'),
			numeric: true,
			title: translate(
				'configureInvestigation.retestDocumentCountColumnTitle',
				'Default 1. This is the number of times to follow up and re-sample the original site and any expanded sites. Note: if you want to re-test on multiple documents across a period of time, use this field.',
			),
		},
		{
			property: 'daysBetweenRetests',
			name: translate('configureInvestigation.daysBetweenRetestsColumnName', 'Days Between Retests'),
			numeric: true,
			title: translate(
				'configureInvestigation.daysBetweenRetestColumnTitle',
				'Default: 1. This is the number of days between the scheduled date of each retest document (if there is more than one).\r\nNote: if you want your re-test follow-ups to be across a period of time, use this field; use 0 to schedule each retest for the same day',
			),
		},
		{
			property: 'retestWorkOrderTypeName',
			name: translate('configureInvestigation.retestWorkOrderTypeNameColumnName', 'Retest WO Type'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.retestWorkOrderTypeColumnTitle',
				`(Optional) For 'New Document' retests only, this specifies the work order type of the new document to be created.  Default is to inherit from the original document.`,
			),
		},
		{
			property: 'retestAnalysisName',
			name: translate('configureInvestigation.retestAnalysisNameColumnName', 'Retest Analysis'),
			minWidth: '200px',
			title: translate(
				'configureInvestigation.retestAnalysisColumnTitle',
				'(Optional) The analysis type for retest samples.  The default will be to use the original analysis type.',
			),
		},
		{
			property: 'expansionMethod',
			name: translate('configureInvestigation.expansionMethodColumnName', 'Site Expansion Method'),
			title: translate(
				'configureInvestigation.expansionMethodColumnTitle',
				"Default: None.  This controls how to expand retest documents outside the boundaries of the original failed site.\r\nNote: if you'd like to also check the 10 nearest locations on the map on every retest, use this field.",
			),
		},
		{
			property: 'expansionSize',
			name: translate('configureInvestigation.expansionSizeColumnName', 'Site Expansion Count'),
			numeric: true,
			title: translate(
				'configureInvestigation.expansionSizeColumnTitle',
				'Default: 0. When site expansion method is "Nearest Locations" this is how many nearby locations on the map to re-test.\r\nNote: proximity between 2 locations is calculated between the nearest edges of their boxes on any map they share.\r\nNote: if there are not sufficient nearby locations, empty samples will be added to the re-test documents for manual site selection',
			),
		},
		{
			property: 'sampleCollectionCount',
			name: translate('configureInvestigation.sampleCollectionCountColumnName', 'Sample Collection Count'),
			numeric: true,
			title: translate(
				'configureInvestigation.sampleCollectionCountColumnTitle',
				'Default: 1. Each time a site is added to a re-test document, this is the number of samples that need to be collected per site.\r\nNote: if you want to have each site tested 3 times per re-test document, use this field.\r\nNote: This applies to both the original site and any expansion sites.',
			),
		},
		{
			property: 'defaultInvestigationType',
			name: translate('configureInvestigation.defaultInvestigationTypeColumnName', 'Default Investigation Type'),
			minWidth: '300px',
			title: translate(
				'configureInvestigation.defaultInvestigationTypeColumnTitle',
				'(Optional) The initial type of any investigations created by this rule',
			),
		},
		{
			property: 'daysUntilInvestigationDue',
			name: translate('configureInvestigation.daysUntilInvestigationDueColumnName', 'Days until due'),
			numeric: true,
			title: translate(
				'configureInvestigation.daysDueColumnTitle',
				'(Optional) The number of days a newly created investigation will have until it is due for resolution',
			),
		},
		{
			property: 'description',
			name: translate('configureInvestigation.descriptionColumnName', 'Description'),
			minWidth: '300px',
			title: translate('configureInvestigation.descriptionColumnTitle', 'A description of the rule'),
		},
		{
			property: 'defaultComments',
			name: translate('configureInvestigation.defaultCommentsColumnName', 'Default Comments'),
			minWidth: '300px',
			title: translate(
				'configureInvestigation.defaultCommentColumnTitle',
				'The initial comments of any investigations created by this rule',
			),
		},
	]

	function getAutomaticDescription(investigationTrigger: InvestigationTrigger) {
		let description = `${investigationTrigger.resultCount} or more ${investigationTrigger.resultStatus}`

		if (investigationTrigger.analysisId && investigationTrigger.analysisOptionId) {
			description += `"${analysisDataMap.get(investigationTrigger.analysisId)?.name}: ${investigationTrigger.analysisOption?.option}" `
		}

		description += ' result(s)'

		if (investigationTrigger.plantId || investigationTrigger.locationId || investigationTrigger.severityClassId) {
			description += ` at "`

			if (investigationTrigger.plantId) {
				description += ` ${plantDataMap.get(investigationTrigger.plantId)?.plantName}`
				if (investigationTrigger.locationId || investigationTrigger.severityClassId) {
					description += ': '
				}
			}
			if (investigationTrigger.locationId) {
				description += `${locationDataMap.get(investigationTrigger.locationId)?.location}`
			} else if (investigationTrigger.severityClassId) {
				description += `All "${severityClassDataMap.get(investigationTrigger.severityClassId)?.name}" locations`
			}
			description += `"`
		}

		if (investigationTrigger.productId) {
			description += ` for ${productDataMap.get(investigationTrigger.productId)?.name}`
		}

		description += ` within ${investigationTrigger.period} ${investigationTrigger.periodType}(s)`
		return description
	}

	async function createNewInvestigationTrigger() {
		const plantData = plantDataMap.get(null)
		const newInvestigationTrigger: InvestigationTrigger = {
			id: null,
			description: '',
			active: true,
			resultStatus: 'UNACCEPTABLE',
			resultCount: 1,
			period: 1,
			periodType: 'DAY',
			defaultComments: `[AUTO] Sample(s): {?violated_sample_ids} with value(s) {?violated_sample_values} triggered rule #{?violated_rule_id}:\r\n{?violated_trigger_description}\r\n({?population_statistics})`,
			defaultInvestigationType: '',
			defaultStatus: 'OPEN',
			retestMode: 'MANUAL',
			retestDocumentCount: 1,
			retestWorkOrderTypeId: null,
			retestWorkOrderTypeName: null,
			retestAnalysisId: null,
			retestAnalysisName: null,
			daysBetweenRetests: 1,
			daysUntilInvestigationDue: null,
			expansionMethod: 'NONE',
			expansionSize: 0,
			sampleCollectionCount: 1,
			analysisId: null,
			analysisName: null,
			analysisOptionsInAnalysis: [],
			analysisOptionId: null,
			analysisOptionName: null,
			analysisOption: null,
			plantId: null,
			plantName: 'All Plants',
			locationId: null,
			locationName: null,
			severityClassId: null,
			severityClassName: null,
			productId: null,
			productName: null,
			analysesInPlant: plantData?.analysesInPlant ?? [],
			locationsInPlant: plantData?.locationsInPlant ?? [],
			severityClassesInPlant: plantData?.severityClassesInPlant ?? [],
			productsInPlant: plantData?.productsInPlant ?? [],
			workOrderTypesInPlant: plantData?.workOrderTypesInPlant ?? [],
			uuid: uuid(),
		}
		newInvestigationTrigger.description = getAutomaticDescription(newInvestigationTrigger)
		investigationTriggers.push(newInvestigationTrigger)
		investigationTriggers = investigationTriggers
		investigationTriggersCrudStore.create(newInvestigationTrigger)
		await tick()
		//Focus the first textarea, select, or input(minus checkbox) in the row.
		const newRowId = `investigationTriggerTableRow-${newInvestigationTrigger.uuid}`
		document
			.querySelectorAll<
				HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
			>(`#${newRowId} input:not([type="checkbox"]), #${newRowId} textarea, #${newRowId} select`)[0]
			?.focus()
	}

	// this function is called when the user selects a new plant for a new or existing investigation trigger
	function updateSelectedPlantDataOnSelectedTrigger(selectedPlantId: number | null, selectedTriggerIndex: number) {
		const selectedPlantData = plantDataMap.get(selectedPlantId)

		let analysisId = investigationTriggers[selectedTriggerIndex].analysisId
		let description = investigationTriggers[selectedTriggerIndex].description
		let analysisName = investigationTriggers[selectedTriggerIndex].analysisName
		let analysisOptionId = investigationTriggers[selectedTriggerIndex].analysisOptionId
		let analysisOption = investigationTriggers[selectedTriggerIndex].analysisOption
		let analysisOptionsInAnalysis = investigationTriggers[selectedTriggerIndex].analysisOptionsInAnalysis
		let severityClassId = investigationTriggers[selectedTriggerIndex].severityClassId
		let severityClassName = investigationTriggers[selectedTriggerIndex].severityClassName
		let productId = investigationTriggers[selectedTriggerIndex].productId
		let productName = investigationTriggers[selectedTriggerIndex].productName
		let retestWorkOrderTypeId = investigationTriggers[selectedTriggerIndex].retestWorkOrderTypeId
		let retestWorkOrderTypeName = investigationTriggers[selectedTriggerIndex].retestWorkOrderTypeName
		let retestAnalysisId = investigationTriggers[selectedTriggerIndex].retestAnalysisId
		let retestAnalysisName = investigationTriggers[selectedTriggerIndex].retestAnalysisName

		if (!selectedPlantData) {
			analysisId = null
			description = ''
			analysisName = null
			analysisOptionId = null
			analysisOption = null
			analysisOptionsInAnalysis = []
			severityClassId = null
			severityClassName = null
			productId = null
			productName = null
			retestWorkOrderTypeId = null
			retestWorkOrderTypeName = null
			retestAnalysisId = null
			retestAnalysisName = null
		} else {
			// if plant data is not null, we will now need to check each of the selected values to see if they exist in the new plant
			if (!selectedPlantData.analysesInPlant.find(analysis => analysis.id === analysisId)) {
				description = ''
				analysisId = null
				analysisName = null
				analysisOptionId = null
				analysisOption = null
				analysisOptionsInAnalysis = []
			}

			// severity class might have similar name but different id in different plants
			// the goal here is to pre-select the severity class with the same name if it exists in the new plant
			const foundSimilarSeverityClass = selectedPlantData.severityClassesInPlant.find(
				severityClass => severityClass.name === severityClassName,
			)
			if (foundSimilarSeverityClass) {
				severityClassId = foundSimilarSeverityClass.id
				severityClassName = foundSimilarSeverityClass.name
			} else {
				severityClassId = null
				severityClassName = null
			}

			if (!selectedPlantData.productsInPlant.find(product => product.id === productId)) {
				productId = null
				productName = null
			}

			if (!selectedPlantData.workOrderTypesInPlant.find(workOrderType => workOrderType.id === retestWorkOrderTypeId)) {
				retestWorkOrderTypeId = null
				retestWorkOrderTypeName = null
			}

			if (!selectedPlantData.analysesInPlant.find(analysis => analysis.id === retestAnalysisId)) {
				retestAnalysisId = null
				retestAnalysisName = null
			}
		}

		investigationTriggers[selectedTriggerIndex] = {
			...investigationTriggers[selectedTriggerIndex],
			...selectedPlantData,
			plantId: selectedPlantId,
			analysisId,
			analysisOptionId,
			analysisOption,
			analysisOptionsInAnalysis,
			description,
			analysisName,
			locationId: null,
			locationName: null,
			severityClassId,
			severityClassName,
			productId,
			productName,
			retestAnalysisId,
			retestAnalysisName,
			retestWorkOrderTypeId,
			retestWorkOrderTypeName,
		}
		investigationTriggersCrudStore.update(investigationTriggers[selectedTriggerIndex])
	}

	// this function is called when the user selects a new analysis for a new or existing investigation trigger
	function updateSelectedAnalysisDataOnSelectedTrigger(event: Event, selectedTriggerIndex: number) {
		const element = event.target

		if (!(element instanceof HTMLSelectElement)) {
			return
		}

		const selectedAnalysisId = element.value ? Number(element.value) : null

		const analysisData = selectedAnalysisId ? analysisDataMap.get(selectedAnalysisId) : null
		const analysisOptionName = investigationTriggers[selectedTriggerIndex].analysisOption?.option
		const selectedAnalysisOption =
			analysisData?.options?.find(analysisOption => analysisOption.option === analysisOptionName) ?? null

		investigationTriggers[selectedTriggerIndex] = {
			...investigationTriggers[selectedTriggerIndex],
			analysisId: selectedAnalysisId,
			analysisName: analysisData?.name ?? null,
			analysisOptionId: selectedAnalysisOption?.id ?? null,
			analysisOption: selectedAnalysisOption ?? null,
			analysisOptionName: selectedAnalysisOption?.option ?? null,
			analysisOptionsInAnalysis: analysisData?.options ?? [],
		}
		investigationTriggers[selectedTriggerIndex].description = getAutomaticDescription(
			investigationTriggers[selectedTriggerIndex],
		)
		investigationTriggersCrudStore.update(investigationTriggers[selectedTriggerIndex])
	}

	// this function is called when the user selects a new analysis option for a new or existing investigation trigger
	function updateAnalysisOptionOnSelectedTrigger(event: Event, selectedTriggerIndex: number) {
		const element = event.target

		if (!(element instanceof HTMLSelectElement)) {
			return
		}

		const selectedAnalysisOptionId = Number(element.value)

		const analysisOption =
			investigationTriggers[selectedTriggerIndex].analysisOptionsInAnalysis?.find(
				analysisOption => analysisOption.id === selectedAnalysisOptionId,
			) ?? null
		const analysisOptionName = analysisOption?.option ?? null

		investigationTriggers[selectedTriggerIndex] = {
			...investigationTriggers[selectedTriggerIndex],
			analysisOptionId: selectedAnalysisOptionId,
			analysisOptionName,
			analysisOption,
		}
		investigationTriggers[selectedTriggerIndex].description = getAutomaticDescription(
			investigationTriggers[selectedTriggerIndex],
		)
		investigationTriggersCrudStore.update(investigationTriggers[selectedTriggerIndex])
	}

	function updateInvestigationTriggerProperty(event: Event, property: string, selectedTriggerIndex: number) {
		const element = event.target

		if (
			!(
				element instanceof HTMLInputElement ||
				element instanceof HTMLSelectElement ||
				element instanceof HTMLTextAreaElement
			)
		) {
			return
		}

		let value: string | number | null = element.value || null

		// If the element is a number input, convert the value to a number
		if (element.type === 'number') {
			value = Number(value)
		}

		if (property === 'locationId') {
			let locationName: string | null
			if (value === null) {
				locationName = null
			} else {
				value = Number(value)
				locationName = locationDataMap.get(value)?.location ?? null
			}
			investigationTriggers[selectedTriggerIndex] = {
				...investigationTriggers[selectedTriggerIndex],
				[property]: value,
				locationName,
			}
		} else if (property === 'severityClassId') {
			let severityClassName: string | null
			if (value === null) {
				severityClassName = null
			} else {
				value = Number(value)
				severityClassName = severityClassDataMap.get(value)?.name ?? null
			}
			investigationTriggers[selectedTriggerIndex] = {
				...investigationTriggers[selectedTriggerIndex],
				[property]: value,
				severityClassName,
			}
		} else if (property === 'productId') {
			let productName: string | null
			if (value === null) {
				productName = null
			} else {
				value = Number(value)
				productName = productDataMap.get(value)?.name ?? null
			}
			investigationTriggers[selectedTriggerIndex] = {
				...investigationTriggers[selectedTriggerIndex],
				[property]: value,
				productName,
			}
		} else if (property === 'retestWorkOrderTypeId') {
			let retestWorkOrderTypeName: string | null
			if (value === null) {
				retestWorkOrderTypeName = null
			} else {
				value = Number(value)
				retestWorkOrderTypeName = workOrderTypeDataMap.get(value)?.name ?? null
			}
			investigationTriggers[selectedTriggerIndex] = {
				...investigationTriggers[selectedTriggerIndex],
				[property]: value,
				retestWorkOrderTypeName,
			}
		} else if (property === 'retestAnalysisId') {
			let retestAnalysisName: string | null
			if (value === null) {
				retestAnalysisName = null
			} else {
				value = Number(value)
				retestAnalysisName = analysisDataMap.get(value)?.name ?? null
			}
			investigationTriggers[selectedTriggerIndex] = {
				...investigationTriggers[selectedTriggerIndex],
				[property]: value,
				retestAnalysisName,
			}
		} else {
			investigationTriggers[selectedTriggerIndex] = {
				...investigationTriggers[selectedTriggerIndex],
				[property]: value,
			}
		}

		if (property !== 'description') {
			investigationTriggers[selectedTriggerIndex].description = getAutomaticDescription(
				investigationTriggers[selectedTriggerIndex],
			)
		}
		investigationTriggersCrudStore.update(investigationTriggers[selectedTriggerIndex])
	}

	function deleteInvestigationTrigger() {
		selectedInvestigationTriggerUuids.forEach(uuid => {
			const index = investigationTriggers.findIndex(investigationTrigger => investigationTrigger.uuid === uuid)
			if (index !== -1) {
				investigationTriggersCrudStore.delete(investigationTriggers[index])
			}
		})
		selectedInvestigationTriggerUuids = []
	}

	export function canLeaveState() {
		return (
			!hasUnsavedChanges ||
			confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		)
	}

	onDestroy(() => {
		investigationTriggersCrudStore.clear()
	})
</script>

<div class="card">
	<div class="card-header">
		<h5>{translate('configureInvestigation.investigationRulesTableHeader', 'Automatic Investigation Rules')}</h5>
	</div>
	<div class="card-body">
		<Table
			columnHidingEnabled
			filterEnabled
			responsive
			stickyHeader
			selectionEnabled
			rows={investigationTriggers}
			columns={investigationTriggerColumns}
			bind:selectedRowIds={selectedInvestigationTriggerUuids}
			idProp="uuid"
			rowSelectionIdProp="uuid"
			parentStyle="max-height: 65vh;"
			filterColumnClass="col-12 col-md-6 col-lg-4 align-self-end"
			bind:this={investigationTriggerTable}
		>
			{#snippet header()}
				<div class="form-row align-items-end mb-2">
					<div class="col-12 col-md-6">
						<SiteAutocomplete
							label={translate('common:plant', 'Plant')}
							bind:value={selectedPlant}
							options={plants}
							showEmptyOption
							emptyText="All Plants"
							emptyValue={null}
							change={plant => {
								const plantId = plant?.id ?? null
								asr.go(null, { plantId }, { replace: true })
							}}
						/>
					</div>
					<div class="col-12 col-md-6">
						<Checkbox
							bind:checked={showEnvironmentalMappingFields}
							label={translate(
								'configureInvestigation.showEnvironmentalMappingFieldsLabel',
								'Show Environmental Mapping Fields',
							)}
						/>
					</div>
				</div>
			{/snippet}
			{#snippet children({ row })}
				{@const isDeleted = $investigationTriggersCrudStore && investigationTriggersCrudStore.isDeleted(row)}
				{@const isCreated = $investigationTriggersCrudStore && investigationTriggersCrudStore.isCreated(row)}
				{@const isUpdated = $investigationTriggersCrudStore && investigationTriggersCrudStore.isUpdated(row)}
				<tr
					id="investigationTriggerTableRow-{row.uuid}"
					class:table-danger={$investigationTriggersCrudStore.deleted[row.uuid]}
					class:table-primary={selectedInvestigationTriggerUuids.includes(row.uuid)}
					class:cursor-pointer={!$investigationTriggersCrudStore.deleted[row.uuid]}
					onclick={event => {
						event.stopPropagation()
						if (!$investigationTriggersCrudStore.deleted[row.uuid]) {
							investigationTriggerTable.rowClick(row)
						}
					}}
				>
					<Td property="editStatus">
						{#if isCreated || isUpdated}
							<Icon
								icon="save"
								title="This investigation rule has unsaved changes"
							/>
						{:else if isDeleted}
							<Icon
								icon="trash"
								title="This investigation rule will be deleted on save"
							/>
						{/if}
					</Td>
					<Td
						stopPropagation
						property="active"
					>
						<input
							type="checkbox"
							checked={row.active}
							disabled={isDeleted}
							onchange={() => {
								investigationTriggers[row.originalIndex] = {
									...row,
									active: !row.active,
								}
								investigationTriggersCrudStore.update(investigationTriggers[row.originalIndex])
							}}
						/>
					</Td>
					<Td
						stopPropagation
						property="plantName"
					>
						<SiteAutocomplete
							showLabel={false}
							value={row.plantId ? (plants.find(plant => row.plantId === plant.id) ?? null) : null}
							options={plants}
							emptyValue={null}
							emptyText="All Plants"
							disabled={isDeleted}
							change={plant => {
								if (plant) {
									//No id on this type, so we've gotta look up the id by the code, ugh.
									const plant = plants.find(plant => plant.code === plant?.code)
									updateSelectedPlantDataOnSelectedTrigger(plant?.id ?? null, row.originalIndex)
								}
							}}
						/>
						<!--
						<Select
							id={`investigationTriggerPlantId-${row.uuid}`}
							showLabel={false}
							value={row.plantId}
							options={plants}
							emptyText="All Plants"
							emptyValue={null}
							disabled={isDeleted}

							onchange={event => updateSelectedPlantDataOnSelectedTrigger(event, row.originalIndex)}
						>
						{#snippet option({option})}
						<option value={option?.id}>{option?.name}</option>
						{/snippet}
						</Select>
						-->
					</Td>
					<Td
						stopPropagation
						property="analysisName"
					>
						<Select
							showLabel={false}
							label={translate('common:analysis', 'Analysis')}
							value={row.analysisId}
							showEmptyOption={true}
							emptyValue={null}
							disabled={isDeleted}
							onchange={event => updateSelectedAnalysisDataOnSelectedTrigger(event, row.originalIndex)}
						>
							{#each row.analysesInPlant ?? [] as option (option.id)}
								<option value={option?.id}>{option?.name}</option>
							{/each}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="analysisOptionName"
					>
						<Select
							showLabel={false}
							label={translate('common:analysisOption', 'Analysis Option')}
							value={row.analysisOptionId}
							options={row.analysisOptionsInAnalysis}
							showEmptyOption={true}
							emptyValue={null}
							disabled={isDeleted}
							onchange={event => updateAnalysisOptionOnSelectedTrigger(event, row.originalIndex)}
						>
							{#snippet option({ option })}
								{#if row.analysisOptionsInAnalysis?.length === 0}
									<option
										disabled
										value={null}
									>
										-- No Active Option --
									</option>
								{:else}
									<option value={option?.id}>{option?.option}</option>
								{/if}
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="locationName"
					>
						<Select
							showLabel={false}
							label={translate('common:location', 'Location')}
							value={row.locationId}
							options={row.locationsInPlant}
							showEmptyOption={true}
							emptyValue={null}
							disabled={!(row.plantId !== null && (row.locationId !== null || row.severityClassId === null)) ||
								isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'locationId', row.originalIndex)}
						>
							{#snippet option({ option })}
								{#if row.locationsInPlant?.length === 0}
									<option
										disabled
										value={null}
									>
										-- No Testable Location --
									</option>
								{:else}
									<option value={option?.id}>{option?.location}</option>
								{/if}
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="severityClassName"
					>
						<Select
							showLabel={false}
							label={translate('common:severityClass', 'Severity Class')}
							value={row.severityClassId}
							options={row.severityClassesInPlant}
							showEmptyOption={true}
							emptyValue={null}
							disabled={(row.locationId !== null && row.severityClassId === null) || isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'severityClassId', row.originalIndex)}
						>
							{#snippet option({ option })}
								{#if row.severityClassesInPlant?.length === 0}
									<option
										disabled
										value={null}
									>
										-- No Severity Class --
									</option>
								{:else}
									<option value={option?.id}>{option?.name}</option>
								{/if}
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="productName"
					>
						<Select
							showLabel={false}
							label={translate('common:product', 'Product')}
							value={row.productId}
							options={row.productsInPlant}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'productId', row.originalIndex)}
						>
							{#snippet option({ option })}
								<option value={option?.id}>{option?.name}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="resultCount"
					>
						<Input
							showLabel={false}
							type="number"
							min="1"
							value={row.resultCount}
							disabled={row.resultStatus.includes('WESTERN_ELECTRIC_RULE') || isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'resultCount', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="resultStatus"
					>
						<Select
							showLabel={false}
							label={translate('common:trigger', 'Trigger')}
							value={row.resultStatus}
							options={resultStatusOptions}
							showEmptyOption={false}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'resultStatus', row.originalIndex)}
						>
							{#snippet option({ option })}
								<option value={option?.value}>{option?.label}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="period"
					>
						<Input
							showLabel={false}
							type="number"
							min="0"
							value={row.period}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'period', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="periodType"
					>
						<Select
							showLabel={false}
							label={translate('configureInvestigation.intervalTypeColumnName', 'Interval Type')}
							value={row.periodType}
							options={periodTypeOptions}
							showEmptyOption={false}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'periodType', row.originalIndex)}
						>
							{#snippet option({ option })}
								<option value={option?.value}>{option?.label}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="defaultStatus"
					>
						<Select
							showLabel={false}
							label={translate('common:status', 'Status')}
							value={row.defaultStatus}
							options={defaultStatusOptions}
							showEmptyOption={false}
							disabled={isDeleted}
							textEllipsis
							onchange={event => updateInvestigationTriggerProperty(event, 'defaultStatus', row.originalIndex)}
						>
							{#snippet option({ option })}
								<option value={option?.value}>{option?.label}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="retestMode"
					>
						<Select
							showLabel={false}
							label={translate('configureInvestigation.retestModeColumnName', 'Retest Mode')}
							value={row.retestMode}
							options={retestModeOptions}
							showEmptyOption={false}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'retestMode', row.originalIndex)}
						>
							{#snippet option({ option })}
								<option value={option?.value}>{option?.label}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="retestDocumentCount"
					>
						<Input
							showLabel={false}
							type="number"
							min="1"
							value={row.retestDocumentCount}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'retestDocumentCount', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="daysBetweenRetests"
					>
						<Input
							showLabel={false}
							type="number"
							min="1"
							value={row.daysBetweenRetests}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'daysBetweenRetests', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="retestWorkOrderTypeName"
					>
						<Select
							showLabel={false}
							label={translate('configureInvestigation.retestWorkOrderTypeNameColumnName', 'Retest WO Type')}
							value={row.retestWorkOrderTypeId}
							options={row.workOrderTypesInPlant}
							showEmptyOption={true}
							emptyValue={null}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'retestWorkOrderTypeId', row.originalIndex)}
						>
							{#snippet option({ option })}
								{#if row.workOrderTypesInPlant?.length === 0}
									<option
										disabled
										value={null}
									>
										-- No Work Order Type --
									</option>
								{:else}
									<option value={option?.id}>{option?.name}</option>
								{/if}
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="retestAnalysisName"
					>
						<Select
							showLabel={false}
							label={translate('configureInvestigation.retestAnalysisNameColumnName', 'Retest Analysis')}
							value={row.retestAnalysisId}
							showEmptyOption={true}
							emptyValue={null}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'retestAnalysisId', row.originalIndex)}
						>
							{#each row.analysesInPlant ?? [] as option (option.id)}
								<option value={option?.id}>{option?.name}</option>
							{/each}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="expansionMethod"
					>
						<Select
							showLabel={false}
							label={translate('configureInvestigation.expansionMethodColumnName', 'Site Expansion Method')}
							value={row.expansionMethod}
							options={expansionMethodOptions}
							showEmptyOption={false}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'expansionMethod', row.originalIndex)}
						>
							{#snippet option({ option })}
								<option value={option?.value}>{option?.label}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						stopPropagation
						property="expansionSize"
					>
						<Input
							showLabel={false}
							type="number"
							min="0"
							value={row.expansionSize}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'expansionSize', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="sampleCollectionCount"
					>
						<Input
							showLabel={false}
							type="number"
							min="1"
							value={row.sampleCollectionCount}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'sampleCollectionCount', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="defaultInvestigationType"
					>
						<Autocomplete
							showLabel={false}
							label={translate('common:type', 'Type')}
							value={row.defaultInvestigationType}
							bind:options={allInvestigationTypes}
							getLabel={option => (option ? option : '')}
							emptyValue={null}
							canAddNew
							placeholder="-- Select or enter to add a new Type --"
							disabled={isDeleted}
							change={type => {
								investigationTriggers[row.originalIndex] = {
									...row,
									defaultInvestigationType: type ?? '',
								}
								investigationTriggersCrudStore.update(investigationTriggers[row.originalIndex])
							}}
						></Autocomplete>
					</Td>
					<Td
						stopPropagation
						property="daysUntilInvestigationDue"
					>
						<Input
							showLabel={false}
							type="number"
							value={row.daysUntilInvestigationDue ?? ''}
							disabled={isDeleted}
							onchange={event =>
								updateInvestigationTriggerProperty(event, 'daysUntilInvestigationDue', row.originalIndex)}
							selectOnFocus
						/>
					</Td>
					<Td
						stopPropagation
						property="description"
					>
						<Textarea
							showLabel={false}
							style="min-height: 31px; height: 31px;"
							value={row.description}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'description', row.originalIndex)}
						></Textarea>
					</Td>
					<Td
						stopPropagation
						property="defaultComments"
					>
						<Textarea
							showLabel={false}
							style="min-height: 31px; height: 31px;"
							value={row.defaultComments}
							disabled={isDeleted}
							onchange={event => updateInvestigationTriggerProperty(event, 'defaultComments', row.originalIndex)}
						></Textarea>
					</Td>
				</tr>
			{/snippet}
		</Table>
	</div>
	<div class="card-footer">
		<Button
			outline
			size="sm"
			color="success"
			iconClass="plus"
			onclick={createNewInvestigationTrigger}
		>
			{translate('common:newRule', 'New Rule')}
		</Button>
		<Button
			outline
			size="sm"
			color="danger"
			iconClass="trash"
			disabled={selectedInvestigationTriggerUuids.length === 0}
			onclick={deleteInvestigationTrigger}
		>
			{translate('configureInvestigation.deleteButton', 'Delete')}
		</Button>
	</div>
</div>
