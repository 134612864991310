import makeSettingValueStore from './setting-value'
import { derived, type Writable } from 'svelte/store'

export default function makeFavoriteReportsStore(initialValue: string = ''): FavoriteReportsStore {
	const settingStore = makeSettingValueStore<string>({
		category: 'Report Viewer',
		settingType: 'INTERFACE_HISTORY',
		name: 'Favorite reports',
		scope: 'USER',
		initialValue,
	})

	const setStore = derived(
		settingStore,
		(newString, set) => {
			set(dedupeArray(newString.split(';')))
		},
		dedupeArray(initialValue.split(';')),
	)

	return {
		subscribe: setStore.subscribe,
		set: (value: string[]) => settingStore.set(dedupeArray(value).join(';')),
	}
}

function dedupeArray<T>(array: T[]): T[] {
	return Array.from(new Set(array)).filter(Boolean)
}

export type FavoriteReportsStore = Omit<Writable<string[]>, 'update'>
