import type { AppContext } from 'types/common'
import component from './Verify.svelte'
import { getSession } from 'stores/session'
import { buildUrl } from '@isoftdata/utility-url'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.verify',
		route: 'verify',
		querystringParameters: ['workOrderId'],
		template: component,
		resolve(_data, { workOrderId }) {
			const params: { session_token: string; embedded: boolean; woid?: string } = {
				session_token: getSession().authToken,
				embedded: true,
				...(workOrderId ? { woid: workOrderId } : {}),
			}
			return Promise.resolve({
				iframeURL: buildUrl(`/verify/${workOrderId ? 'workorderdetail' : 'index'}.php`, params),
			})
		},
	})
}
