export default {
    "name": "ActivateUserAccount",
    "kind": "HoudiniMutation",
    "hash": "b5355da8ebd7ce8df5887f967ba88455673387b3b6135d2c906497799d32e8f1",

    "raw": `mutation ActivateUserAccount($input: ActivateUserAccountInput!) {
  activateUserAccount(input: $input)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "activateUserAccount": {
                "type": "Boolean",
                "keyRaw": "activateUserAccount(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "ActivateUserAccountInput"
        },

        "types": {
            "ActivateUserAccountInput": {
                "activationPIN": "String",
                "newPassword": "String",
                "recoveryEmail": "String",
                "userName": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=2b161c4fd8a22912fd78a0199a819297277b75eb44e22576c93bdc4a65df7e0d";