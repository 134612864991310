import type { AppContext } from 'types/common'
import component from './Dashboard.svelte'
import { getSession } from 'stores/session'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.home.dashboard',
		route: 'dashboard',
		template: component,
		resolve(_data, _parameters) {
			return Promise.resolve({ authToken: getSession().authToken })
		},
	})
}
