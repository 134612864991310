export default {
    "name": "WoAnalyses",
    "kind": "HoudiniQuery",
    "hash": "df4a97b15884af1c06c135d360c6f66d71a326bb62581213429d33234553ec0c",

    "raw": `query WoAnalyses($analysesFilter: AnalysisFilter, $pagination: PaginatedInput, $viewAsPlantId: ID!) {
  analyses(filter: $analysesFilter, pagination: $pagination) {
    data {
      ...WoAnalysisData
      sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      id
    }
  }
}

fragment WoAnalysisData on Analysis {
  id
  name
  active
  analysisType
  instructions
  requireAuthentication
  batchUnit
  batchVolume
  createdProductId
  options {
    ...WoAnalysisOptionData
    id
  }
  __typename
}

fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  inventoryMode
  entryMethod
  unit
  sectionName
  requiredToCompleteSection
  product {
    id
    name
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "analysisType": {
                                        "type": "AnalysisType",
                                        "keyRaw": "analysisType",
                                        "visible": true
                                    },

                                    "instructions": {
                                        "type": "String",
                                        "keyRaw": "instructions",
                                        "visible": true
                                    },

                                    "requireAuthentication": {
                                        "type": "Boolean",
                                        "keyRaw": "requireAuthentication",
                                        "visible": true
                                    },

                                    "batchUnit": {
                                        "type": "String",
                                        "keyRaw": "batchUnit",
                                        "visible": true
                                    },

                                    "batchVolume": {
                                        "type": "Float",
                                        "keyRaw": "batchVolume",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "createdProductId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "createdProductId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                },

                                                "thresholdType": {
                                                    "type": "ThresholdType",
                                                    "keyRaw": "thresholdType",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "defaultType": {
                                                    "type": "DefaultType",
                                                    "keyRaw": "defaultType",
                                                    "visible": true
                                                },

                                                "defaultValue": {
                                                    "type": "String",
                                                    "keyRaw": "defaultValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "inventoryMode": {
                                                    "type": "InventoryMode",
                                                    "keyRaw": "inventoryMode",
                                                    "visible": true
                                                },

                                                "entryMethod": {
                                                    "type": "EntryMethod",
                                                    "keyRaw": "entryMethod",
                                                    "visible": true
                                                },

                                                "unit": {
                                                    "type": "String",
                                                    "keyRaw": "unit",
                                                    "visible": true
                                                },

                                                "sectionName": {
                                                    "type": "String",
                                                    "keyRaw": "sectionName",
                                                    "visible": true
                                                },

                                                "requiredToCompleteSection": {
                                                    "type": "Boolean",
                                                    "keyRaw": "requiredToCompleteSection",
                                                    "visible": true
                                                },

                                                "product": {
                                                    "type": "Product",
                                                    "keyRaw": "product",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoAnalysisOptionData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    },

                                    "sampleTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    },

                                    "testingTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoAnalysisData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "analysesFilter": "AnalysisFilter",
            "pagination": "PaginatedInput",
            "viewAsPlantId": "ID"
        },

        "types": {
            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=38b8b9d1f2f09bd92164fea19cd86b91095e24911b3b212c75ffbe5d50ceb384";