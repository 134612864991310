export default {
    "name": "Login",
    "kind": "HoudiniMutation",
    "hash": "a7966550122a6322bd8b679690645ba3f057d3706552986865484d5deb5f2b80",

    "raw": `mutation Login($userName: String!, $passPhrase: String!, $plantId: PositiveInt!) {
  createSession(userName: $userName, password: $passPhrase, plantId: $plantId) {
    sessionToken
    userName
    userAccountId
    user {
      workEmail
      recoveryEmail
      firstName
      fullName
      lastName
      lastPasswordResetDate: userLastPasswordResetDate
      permissions: userRoles {
        permissionName
        value
      }
      authorizedPlants {
        id
        code
        name
        timezone: computedTimezone
      }
      userGroups {
        id
      }
      id
    }
    plant {
      id
      code
      name
      timezone: computedTimezone
    }
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createSession": {
                "type": "UserAccess",
                "keyRaw": "createSession(password: $passPhrase, plantId: $plantId, userName: $userName)",

                "selection": {
                    "fields": {
                        "sessionToken": {
                            "type": "UUID",
                            "keyRaw": "sessionToken",
                            "nullable": true,
                            "visible": true
                        },

                        "userName": {
                            "type": "String",
                            "keyRaw": "userName",
                            "visible": true
                        },

                        "userAccountId": {
                            "type": "PositiveInt",
                            "keyRaw": "userAccountId",
                            "nullable": true,
                            "visible": true
                        },

                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "workEmail": {
                                        "type": "String",
                                        "keyRaw": "workEmail",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "recoveryEmail": {
                                        "type": "EmailAddress",
                                        "keyRaw": "recoveryEmail",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "fullName": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "fullName",
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastPasswordResetDate": {
                                        "type": "DateTime",
                                        "keyRaw": "lastPasswordResetDate",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "permissions": {
                                        "type": "UserRole",
                                        "keyRaw": "permissions",

                                        "selection": {
                                            "fields": {
                                                "permissionName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "permissionName",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "PermissionValue",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "authorizedPlants": {
                                        "type": "Plant",
                                        "keyRaw": "authorizedPlants",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "timezone": {
                                                    "type": "String",
                                                    "keyRaw": "timezone",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userGroups": {
                                        "type": "Group",
                                        "keyRaw": "userGroups",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "plant": {
                            "type": "Plant",
                            "keyRaw": "plant",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "timezone": {
                                        "type": "String",
                                        "keyRaw": "timezone",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userName": "String",
            "passPhrase": "String",
            "plantId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=88ab47c5050503f0b4892bab3631c5606ca55bd6fec54b032de77a8c0520fcbe";