import type { GetDefaultSampleValues$result, GetValueAcceptability, GetValueAcceptabilities$result } from '$houdini'
import { graphql } from '$houdini'
import { WorkOrder, Sample, type SampleValue } from '../states/work-order/work-order'
import { acceptabilityToResultStatus } from './value-acceptability-map'

type DefaultSampleValue = GetDefaultSampleValues$result['getDefaultSampleValues']['samples'][number]

export async function getDefaultSampleValuesMap(
	{
		assignedToGroup,
		documentStatus: status,
		due,
		favorite,
		id,
		instructions,
		internalNotes,
		plant: { id: plantId },
		productBatch,
		scheduled,
		title,
		verifiedOn,
		verificationDue,
		verifiedByUser,
		workOrderType: { id: workOrderTypeId },
	}: WorkOrder,
	sample: Sample,
): Promise<Record<number, DefaultSampleValue>> {
	const { data } = await getDefaultSamplesValuesStore.fetch({
		variables: {
			input: {
				analysisId: sample.analysis.id,
				currentWorkOrder: {
					assignedToGroupId: assignedToGroup?.id,
					due,
					favorite,
					id: id || null,
					instructions,
					internalNotes,
					plantId,
					productBatchId: productBatch?.id,
					scheduled,
					status,
					title,
					verificationDue,
					verifiedByUserId: verifiedByUser?.id,
					verifiedOn,
					workOrderTypeId,
				},
				sample: {
					collectedByUserId: sample.collectedBy?.id,
					due: sample.due,
					incubationBegan: sample.incubationBegan,
					investigationId: sample.investigationId,
					incubationEnded: sample.incubationEnded,
					locationId: sample.location?.id,
					performed: sample.performed,
					plantId: sample.plant?.id ?? plantId,
					platesReadByUserId: sample.platesReadBy?.id,
					productId: sample.product?.id,
					scheduled: sample.scheduled,
					// only used for recipes, so we don't need them right now
					productionVolume: sample.productionVolume,
					// scheduledAnalysisId: sample.sch,
					// sequenceId: sample.s,
				},
				currentSampleValues: sample.sampleValues
					.map(sv => ({
						analysisOptionId: sv.analysisOption.id,
						result: (sv.result ?? '').toString(),
						defaultValue: sv.defaultValue,
					}))
					// Only send values that have been filled out or had a default calculated already
					// It should return calculated defaults for the ones that aren't sent just fine
					.filter(sv => sv.result || sv.defaultValue !== null),
			},
		},
	})

	if (!data) {
		return {}
	}
	return data.getDefaultSampleValues.samples.reduce((map: Record<number, DefaultSampleValue>, result) => {
		map[result.analysisOptionId] = result
		return map
	}, {})
}

type ValueAcceptability = GetValueAcceptabilities$result['getValueAcceptabilities'][number]

async function getNewAcceptabilitiesMap(options: GetValueAcceptability[]) {
	if (!options.length) {
		return {}
	}

	const { data } = await getValueAcceptabilitiesStore.fetch({
		variables: {
			options,
		},
	})

	if (!data) {
		return {}
	}

	return data.getValueAcceptabilities.reduce((map: Record<number, ValueAcceptability>, acc) => {
		map[acc.analysisOptionId] = acc
		return map
	}, {})
}

export async function getDefaultSampleValuesList(workOrder: WorkOrder, sample: Sample): Promise<Array<SampleValue>> {
	const defaultValueMap = await getDefaultSampleValuesMap(workOrder, sample)
	const acceptabilitiesMap = await getNewAcceptabilitiesMap(
		sample.sampleValues.reduce((arr, sv) => {
			if (sv.result !== defaultValueMap[sv.analysisOption.id]?.result) {
				arr.push({
					analysisOptionId: sv.analysisOption.id,
					currentResult: defaultValueMap[sv.analysisOption.id]?.result ?? '',
					plantId: workOrder.plant.id,
					productId: sample.product?.id,
					severityClassId: sample.location?.severityClass?.id,
					// Recipe stuff
					productionVolume: sample.productionVolume,
				})
			}
			return arr
		}, new Array<GetValueAcceptability>()),
	)
	return sample.sampleValues.map(sv => {
		// Just in case the API goofs up, don't overwrite 'Calculate Once' values that have been calculated already
		if (sv.analysisOption.defaultType === 'STATIC_SQL' && sv.defaultValue) {
			return {
				...sv,
				defaultValue: sv.defaultValue,
				result: sv.result,
			}
		}

		// API will return us the old result if it was intentionally set, so just use that. defaultValue should always be updated
		return {
			...sv,
			defaultValue: defaultValueMap[sv.analysisOption.id]?.defaultValue ?? sv.defaultValue,
			result: defaultValueMap[sv.analysisOption.id]?.result ?? sv.result,
			resultStatus:
				acceptabilityToResultStatus[acceptabilitiesMap[sv.analysisOption.id]?.acceptability] ?? sv.resultStatus,
		}
	})
}

const getDefaultSamplesValuesStore = graphql(`
	query GetDefaultSampleValues($input: GetDefaultSampleValueComputation!) {
		getDefaultSampleValues(input: $input) {
			analysisId
			samples {
				analysisOptionId
				defaultValue
				result
			}
		}
	}
`)

const getValueAcceptabilitiesStore = graphql(`
	query GetValueAcceptabilities($options: [GetValueAcceptability!]!) {
		getValueAcceptabilities(options: $options) {
			acceptability
			analysisOptionId
			plantId
			productBatchId
			productId
			productionVolume
			currentResult
			severityClassId
		}
	}
`)
