import type { AppContext } from 'types/common'
import component from './ReportViewer.svelte'

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer',
		route: 'report-viewer',
		defaultChild: 'reports',
		template: component,
		async resolve() {
			return Promise.resolve()
		},
	})
}
