import type { AppContext, ResolveParameters } from 'types/common'
import type { PrintJob } from '@isoftdata/svelte-report-viewer'

import component from './Preview.svelte'
import type { LiteralUnion, WritableDeep } from 'type-fest'
import { klona } from 'klona'
import { stringToBoolean } from '@isoftdata/utility-string'
import { reportParameterQuery } from 'utility/report-viewer-helper'
import camelCase from 'just-camel-case'

export default function ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports.preview',
		route: ':selectedReportId',
		querystringParameters: ['selectedReportId'],
		defaultParameters: {
			selectedReportId: null,
			selectedCategory: null,
		},
		template: component,
		async resolve(_data, stateParameters: ResolveParameters<{ selectedReportId: string; preview: boolean }>) {
			const preview = stringToBoolean(stateParameters.preview)
			if (stateParameters.selectedReportId && stateParameters.selectedReportId !== 'null') {
				const { data } = await reportParameterQuery.fetch({
					variables: { reportId: stateParameters.selectedReportId },
					policy: 'CacheOrNetwork',
				})

				const selectedReportId = parseInt(stateParameters.selectedReportId, 10)

				let storedParameters: Record<string, string> | null = null
				let storedPrintJob: PrintJob | null = null

				try {
					const storedPrintJobString = localStorage.getItem('reportViewerPreviewPrintJob')

					if (storedPrintJobString) {
						storedPrintJob = JSON.parse(storedPrintJobString) as PrintJob
						if (storedPrintJob.reportId === selectedReportId) {
							storedParameters = storedPrintJob.parameters.reduce((acc: Record<string, string>, parameter) => {
								acc[parameter.key] = parameter.value
								return acc
							}, {})
						}
						localStorage.removeItem('reportViewerPreviewPrintJob')
					}
				} catch (error) {
					console.error('Failed to parse stored parameters', error)
				}

				const parameters =
					data?.report?.parameters.map(parameter => {
						type Parameter = WritableDeep<typeof parameter>
						type Option = LiteralUnion<Parameter['options'][number], string>
						type NewOptionObject = Omit<Parameter['options'][number], 'value'> & { value: string }
						const newParameter: Omit<Parameter, 'options'> & { value: string; options: Array<Option> } = {
							...klona(parameter),
							value: '',
						}

						// Put parameters in the right shape for the frontend, get default values
						if (parameter.type === 'KEY_CHOICE') {
							const newOptions = parameter.options.reduce((acc: Array<NewOptionObject>, option) => {
								const key = option.id ?? option.label
								if (key !== null) {
									const value = option.id ?? option.value ?? option.label ?? ''
									acc.push({ ...option, value: value.toString() })
									if (option.isDefault) {
										newParameter.value = value?.toString?.() ?? ''
									}
								}
								return acc
							}, new Array<NewOptionObject>())
							newParameter.options = newOptions
						} else if (parameter.type === 'CHOICE') {
							const newOptions = parameter.options.map(option => {
								const value = option.value ?? option.label ?? ''
								if (option.isDefault && value) {
									newParameter.value = value
								}
								return value
							})
							newParameter.options = newOptions
						} else {
							const defaultOption = parameter.options.find(option => option.isDefault)
							if (defaultOption) {
								newParameter.value = (defaultOption.id ?? defaultOption.label ?? defaultOption.value)?.toString() ?? ''
							}
						}

						if (storedParameters?.[parameter.name]) {
							newParameter.value = storedParameters[parameter.name]
						}

						return {
							...newParameter,
							label: translate(`reports:parameters.${camelCase(parameter.name)}Label`, parameter.label),
						}
					}) ?? []

				return {
					parameters,
					selectedReportId,
					preview,
				}
			}

			return { selectedReportId: null }
		},
	})
}
