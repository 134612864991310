<script lang="ts">
	import type { EntityTag } from 'utility/analysis-management-helper.svelte'
	import type { Colors } from '@isoftdata/utility-bootstrap'

	import Icon from '@isoftdata/svelte-icon'

	export let tag: Omit<EntityTag, 'uuid'>
	export let color: Colors = 'secondary'

	const iconMap = {
		PLANT: 'industry-windows',
		LOCATION: 'shelves',
		PRODUCT: 'boxes-stacked',
	} as const
</script>

<span class="badge badge-{color}">
	<Icon
		prefix="fas"
		fixedWidth
		icon={iconMap[tag.entityType]}
	/>
	{tag.name}</span
>
