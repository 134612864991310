<script lang="ts">
	import type {
		Analysis,
		Plant,
		Group,
		EntityTag,
		Option,
		Choice,
		Rule,
		ParentCrudStore,
		TagStore,
		Product,
	} from 'utility/analysis-management-helper.svelte'
	import type { i18n, Mediator, SvelteAsr, MediatorProviders } from 'types/common'
	import type {
		NewAnalysis,
		AnalysisUpdate,
		NewAnalysisOption,
		AnalysisOptionUpdate,
		NestedNewAnalysisOptionChoice,
		AnalysisOptionChoiceUpdate,
		NestedNewAnalysisOptionRule,
		AnalysisOptionRuleUpdate,
		AmAnalysisOptionsChoicesQuery$result,
		EntityTagUpdate,
		NewEntityTag,
	} from '$houdini'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { AddRemoveStore } from 'stores/add-remove-store'
	import type { Readable, Writable } from 'svelte/store'
	import type { Simplify } from 'type-fest'
	import type SaveResetButton from '@isoftdata/svelte-save-reset-button'

	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import { Table, Td, type Column } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import RecalculateSamplesModal from './RecalculateSamplesModal.svelte'
	// import ConfirmNavigationModal from 'components/ConfirmNavigationModal.svelte'
	import { Dropdown } from '@isoftdata/svelte-dropdown'
	import { DropdownCheckboxItem } from '@isoftdata/svelte-context-menu'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'

	import { analysisTemplate, optionMatchesFilter } from 'utility/analysis-management-helper.svelte'
	import userLocalWritable from '@isoftdata/svelte-store-user-local-writable'
	import session from 'stores/session'
	import { klona } from 'klona'
	import hasPermission from 'utility/has-permission'
	import { getContext, onDestroy, onMount, tick, type ComponentProps } from 'svelte'
	import { getEventChecked, getEventValue } from '@isoftdata/browser-event'
	import { v4 as uuid } from '@lukeed/uuid'
	import { graphql } from '$houdini'

	export let analyses: Array<Analysis>
	export let asr: SvelteAsr
	export let categories: Array<string>
	export let groups: Array<Group>
	export let plantId: number
	export let plants: Array<Plant>
	export let products: Array<Product>
	export let selectedAnalysisId: number | null
	export let selectedAnalysisUuid: string | null
	export let showInactive: Writable<boolean>
	export let showUnused: Writable<boolean>
	export let computeCanEditPlantSpecificFields: () => boolean
	export let canEditGlobalFields: (analysisId: number | null) => boolean
	export let analysisCrudStore: CrudStore<Analysis, 'uuid'>
	export let optionCrudStore: ParentCrudStore<Option>
	export let choiceCrudStore: ParentCrudStore<Choice>
	export let ruleCrudStore: ParentCrudStore<Rule>
	export let tagCrudStore: CrudStore<EntityTag, 'uuid'>
	export let tagAddRemoveStore: AddRemoveStore
	export let hasUnsavedChanges: Writable<boolean>
	export let tagStore: TagStore
	export let plantTagUuids: Readable<Record<string, string>>
	export let productTagUuids: Readable<Record<string, string>>
	export let locationTagUuids: Readable<Record<string, string>>
	export let recipesMode = false
	export let saveResetProps: Writable<ComponentProps<typeof SaveResetButton>>
	export let stateName: string

	let cloneWithThresholds = true
	let cloneWithPlants = true
	let lazySortAnalyses = true
	let analysisTable: Table<Analysis> | undefined = undefined
	let recalculateSamplesModal: RecalculateSamplesModal | undefined = undefined
	// let confirmNavigationModal: ConfirmNavigationModal | undefined = undefined

	$: selectedPlant = plants.find(plant => plant.id === plantId)!
	$: selectedAnalysisIndex = analyses.findIndex(
		analysis => analysis.id === selectedAnalysisId && analysis.uuid === selectedAnalysisUuid,
	)
	$: selectedAnalysis = selectedAnalysisIndex > -1 ? (analyses[selectedAnalysisIndex] ?? null) : null
	$: canRecalculateSamples = hasPermission('ANALYSIS_CAN_RECALCULATE_SAMPLE_HISTORY', plantId)
	$: canEditPlantSpecificFields = computeCanEditPlantSpecificFields()

	$: $hasUnsavedChanges =
		$hasUnsavedChanges ||
		($analysisCrudStore && analysisCrudStore.hasChanges()) ||
		($tagCrudStore && tagCrudStore.hasChanges()) ||
		($optionCrudStore && optionCrudStore.hasChanges()) ||
		($choiceCrudStore && choiceCrudStore.hasChanges()) ||
		($ruleCrudStore && ruleCrudStore.hasChanges())
	$: tagsByUuid = {
		...$tagStore.PLANT,
		...$tagStore.LOCATION,
		...$tagStore.PRODUCT,
	}

	const mediator = getContext<
		Mediator<
			MediatorProviders & {
				'analysis-management-save': () => Promise<void>
			}
		>
	>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const analysesCardExpanded = userLocalWritable($session.userAccountId, '$analysesCardExpanded', true)

	export function canLeaveState() {
		if ($hasUnsavedChanges) {
			return confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		}
		return true
	}

	function stateParameterChanged(parameter: string, value: string | number | boolean) {
		if (typeof value === 'number') {
			value = value.toString()
		} else if (typeof value === 'boolean') {
			value = value ? 'True' : 'False'
		}
		asr.go(null, { [parameter]: value }, { inherit: true })
	}

	function selectedAnalysisChanged(newSelectedAnalysisIndex: number, lastSelectedAnalysisIndex: number) {
		if (newSelectedAnalysisIndex === lastSelectedAnalysisIndex || newSelectedAnalysisIndex < 0) {
			return
		}
		const newSelectedAnalysis = analyses[newSelectedAnalysisIndex]
		const lastSelectedAnalysis = analyses[lastSelectedAnalysisIndex] ?? null
		const newSelectedAnalysisId = newSelectedAnalysis?.id ?? null
		const lastSelectedAnalysisId = lastSelectedAnalysis?.id ?? null
		const newSelectedAnalysisUuid = newSelectedAnalysis?.uuid ?? null
		const lastSelectedAnalysisUuid = lastSelectedAnalysis?.uuid ?? null

		// Cache any modified options from the last selected Analysis and reload with new selection
		if (
			!analysisCrudStore.isDeleted(newSelectedAnalysis) &&
			(newSelectedAnalysisId !== lastSelectedAnalysisId || newSelectedAnalysisUuid !== lastSelectedAnalysisUuid)
		) {
			// Analyses state is not reloaded by this, set them manually
			selectedAnalysisId = newSelectedAnalysisId
			selectedAnalysisUuid = newSelectedAnalysisUuid
			document
				.getElementById(`analysis-row-${newSelectedAnalysisIndex}`)
				?.scrollIntoView({ block: 'center', behavior: 'smooth' })
			// Reload options state
			asr.go(
				`${stateName}.options`,
				{
					selectedAnalysisId: newSelectedAnalysisId,
					selectedAnalysisUuid: newSelectedAnalysisUuid,
				},
				{ inherit: true },
			)
		}
	}

	function updateAnalysisKeypath<K extends keyof Analysis>(index: number, keypath: K, value: Analysis[K]) {
		analyses[index][keypath] = value
		if (analyses[index].id) {
			analysisCrudStore.update(analyses[index])
		} else {
			analysisCrudStore.create(analyses[index])
		}

		if (index === analyses.length - 1) {
			addAnalysis(false)
		}
	}

	function addAnalysis(focus = true) {
		if (!canEditPlantSpecificFields) {
			return
		}

		let newAnalysisIndex = analyses.findIndex(
			analysis => !analysis.id && !analysisCrudStore.isCreated(analysis) && !analysisCrudStore.isDeleted(analysis),
		)
		if (newAnalysisIndex === -1) {
			analyses.push({
				...klona(analysisTemplate),
				analysisType: recipesMode ? 'RECIPE' : 'TESTING',
				uuid: uuid(),
				inUseAtPlantIDs: [plantId],
				batchVolume: recipesMode ? 1 : null,
			})
			analyses = analyses
			newAnalysisIndex = analyses.length - 1
		}

		if (focus) {
			document.getElementById(`analysis-name-${newAnalysisIndex}`)?.focus()
		}
	}

	type OptionsWithChoices = AmAnalysisOptionsChoicesQuery$result['analysisOptions']['data']
	type OptionsMaybeWithChoices = Simplify<
		Omit<OptionsWithChoices, 'choices'> & { choices?: OptionsWithChoices[number]['choices'] }
	>

	function getTagUuid(entityType: EntityTag['entityType'], tagName: string): string {
		if (entityType === 'PLANT') {
			return $plantTagUuids[tagName]
		} else if (entityType === 'LOCATION') {
			return $locationTagUuids[tagName]
		} else if (entityType === 'PRODUCT') {
			return $productTagUuids[tagName]
		}
		throw new Error(`Unknown entity type: ${entityType}`)
	}

	async function cloneAnalysis() {
		if (!selectedAnalysis || !selectedAnalysisUuid) {
			return
		}

		const originalAnalysisId = selectedAnalysisId
		const clonedAnalysis: Analysis = klona({
			...selectedAnalysis,
			id: null,
			uuid: uuid(),
		})
		if (cloneWithPlants && !canEditGlobalFields(originalAnalysisId)) {
			// Make sure we don't let them set this as in use at a plant they can't edit at
			clonedAnalysis.inUseAtPlantIDs = clonedAnalysis.inUseAtPlantIDs.filter(id =>
				hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', id),
			)

			const inUseAtPlantCodes = clonedAnalysis.inUseAtPlantIDs
				.map(id => plants.find(plant => plant.id === id)?.code)
				.filter(Boolean)
			alert(
				translate(
					'analyses.clonePlantPermissionWarning',
					`You do not have permission to edit this analysis at all plants it is in use at. The cloned analysis will only be in use at ${inUseAtPlantCodes.join(', ')}.`,
				),
			)
		} else if (!cloneWithPlants) {
			// strip plants if not cloning with plants
			clonedAnalysis.inUseAtPlantIDs = [plantId]
		}

		const optionsCrud = $optionCrudStore[selectedAnalysisUuid]
		const updatedOptionsMap = new Map(Object.values(optionsCrud?.updated ?? {}).map(option => [option.id, option]))
		const newOptionsArr = Object.values(optionsCrud?.created ?? {})
		const deletedOptionIds = new Set(Object.values(optionsCrud?.deleted ?? {}).map(option => option.id))

		// strip choices if not cloning with thresholds
		// Have to fetch options, because they're not included in the analysis query and options crud store only tracks choices that were modified
		const { data } = originalAnalysisId
			? await (cloneWithThresholds ? getAnalysisOptionsWithChoicesForClone : getAnalysisOptionsForClone).fetch({
					variables: {
						filter: { analysisIds: [originalAnalysisId] },
						pagination: {
							pageSize: 0,
						},
					},
				})
			: { data: null }

		// Types weren't playing nice here when I was doing a union, so I made a type that just has choices as optional
		const canonOptions: Array<Option> = ((data?.analysisOptions.data ?? []) as OptionsMaybeWithChoices).map(option => ({
			...option,
			choices:
				option.choices?.map(
					(choice): Choice => ({
						...choice,
						uuid: uuid(),
						id: null,
					}),
				) ?? [],
			rules: option.rules.map(
				(rule): Rule => ({
					...rule,
					uuid: uuid(),
					tags: rule.tags.map(
						(tag): EntityTag => ({
							...tag,
							uuid: getTagUuid(tag.entityType, tag.name),
						}),
					),
				}),
			),
			uuid: uuid(),
		}))

		const mergedOptions: Array<Option> = canonOptions
			.map(
				(option): Option => ({
					...(updatedOptionsMap.get(option.id) ?? option),
					id: null,
				}),
			)
			.concat(newOptionsArr)

		// Copy the options, choices, and rules & mark them as created
		// We don't actually need to update the list of options in this state, the child state will get them from the crud store
		mergedOptions
			.filter(option => !deletedOptionIds.has(option.id))
			.forEach(option => {
				const optionUuid = uuid()
				const newOption = {
					...option,
					id: null,
					uuid: optionUuid,
					// Since we've already enforced plant permissions on the new analysis, we can just filter out the choices that don't match the new analysis's plants
					choices: cloneWithThresholds
						? (option.choices
								?.filter(
									choice =>
										(choice.plantId === null || clonedAnalysis.inUseAtPlantIDs.includes(choice.plantId)) &&
										!choiceCrudStore.isDeleted(option.uuid, choice),
								)
								.map(choice => {
									const newChoice = {
										...choice,
										id: null,
										uuid: uuid(),
									}
									choiceCrudStore.create(optionUuid, newChoice)
									return newChoice
								}) ?? [])
						: [],
					rules:
						option.rules.map(rule => {
							const newRule = {
								...rule,
								id: null,
								uuid: uuid(),
							}

							ruleCrudStore.create(optionUuid, newRule)
							return newRule
						}) ?? [],
				}
				optionCrudStore.create(clonedAnalysis.uuid, newOption)
			})

		// Rename the new analysis
		const duplicateRegex = /\s\((\d+)\)$/
		const analysisNameNoCount = clonedAnalysis.name.replace(duplicateRegex, '')
		const duplicateCount =
			analyses.reduce((duplicateCount, analysis) => {
				const match = analysis.name.includes(analysisNameNoCount) && analysis.name.match(duplicateRegex)
				if (match) {
					const num = parseInt(match[1], 10)
					if (num > duplicateCount) {
						return num
					}
				}
				return duplicateCount
			}, 0) + 1
		clonedAnalysis.name = analysisNameNoCount ? `${analysisNameNoCount} (${duplicateCount})` : ''

		// Insert it after what we're cloning. Have to turn off lazy sorting for a sec so it sorts correctly
		lazySortAnalyses = false
		const newSelectedAnalysisIndex = selectedAnalysisIndex + 1
		analyses.splice(newSelectedAnalysisIndex, 0, clonedAnalysis)
		analysisCrudStore.create(clonedAnalysis)
		analyses = analyses
		lazySortAnalyses = true

		selectedAnalysisChanged(newSelectedAnalysisIndex, selectedAnalysisIndex)
	}

	function formatAnalysisForSave(analysis: Analysis, isNew: true): NewAnalysis
	function formatAnalysisForSave(analysis: Analysis, isNew?: false): AnalysisUpdate
	function formatAnalysisForSave(analysis: Analysis, isNew: boolean = false): NewAnalysis | AnalysisUpdate {
		if (recipesMode && (!analysis.batchVolume || analysis.batchVolume <= 0)) {
			throw new Error(
				translate('analyses.batchVolumeRequired', 'Batch Volume must be greater than 0 on analysis "{{- analysis}}"', {
					analysis: analysis.name,
				}),
			)
		}

		const {
			id: analysisId,
			sampleTagPrintQuantity,
			testingTagPrintQuantity,
			inUseAtPlantIDs,
			visibleGroup,
			inUse,
			uuid,
			options,
			__typename,
			createdProduct,
			' $fragments': fragments,
			...globalAnalysisProperties
		} = klona(analysis)

		const optionCrudMap = $optionCrudStore[uuid]

		const optionsToCreate = Object.values(optionCrudMap?.created ?? {}).map(option =>
			formatOptionForSave({ ...option, analysisId: null, id: null }, true),
		)
		const optionsToUpdate = Object.values(optionCrudMap?.updated ?? {}).reduce(
			(acc: Array<AnalysisOptionUpdate>, option) => {
				if (option.id) {
					acc.push(formatOptionForSave({ ...option, id: option.id }, false))
				}
				return acc
			},
			[],
		)
		const optionsToDelete = Object.values(optionCrudMap?.deleted ?? {}).reduce((acc: Array<number>, option) => {
			if (option.id) {
				acc.push(option.id)
			}
			return acc
		}, [])

		if (isNew && hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', inUseAtPlantIDs[0])) {
			return {
				...globalAnalysisProperties,
				inUseAtPlantIDs,
				createdProductId: createdProduct?.id ?? null,
				options: optionsToCreate,
				visibleGroupId: visibleGroup?.id,
				printQuantityInput: [
					{
						plantId,
						sampleTagQuantity: sampleTagPrintQuantity,
						testingTagQuantity: testingTagPrintQuantity,
					},
				],
			}
		} else if (!isNew && analysisId) {
			let analysisToSave: AnalysisUpdate = {
				id: analysisId,
				inUseAtPlantIDs,
				// update can include choices, which can be plant specific
				optionsToUpdate,
			}

			// If they have global permission, they can edit everything (does not account for private plants)
			const hasGlobalEditPermission = hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', null)

			// Only send "global" fields if they have edit perm at every plant it's in use at
			const canEditAtAllPlants =
				hasGlobalEditPermission ||
				analysis.inUseAtPlantIDs.every(inUseAtPlantId => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', inUseAtPlantId))
			if (canEditAtAllPlants) {
				analysisToSave = {
					...analysisToSave,
					...globalAnalysisProperties,
					createdProductId: createdProduct?.id ?? null,
					visibleGroupId: visibleGroup?.id,
					optionsToCreate,
					optionsToDelete,
				}
			}
			// Only send print qty fields if they have edit perm at current plant
			if (hasGlobalEditPermission || hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId)) {
				analysisToSave.printQuantityInput = [
					{
						plantId,
						sampleTagQuantity: sampleTagPrintQuantity,
						testingTagQuantity: testingTagPrintQuantity,
					},
				]
			}
			return analysisToSave
		}
		throw new Error('Invalid analysis to save')
	}

	function formatOptionForSave(option: Option & { id: null }, isNew?: true): NewAnalysisOption
	function formatOptionForSave(option: Option & { id: number }, isNew?: false): AnalysisOptionUpdate
	function formatOptionForSave(option: Option, isNew = false): NewAnalysisOption | AnalysisOptionUpdate {
		let optionToSave: NewAnalysisOption | AnalysisOptionUpdate = option.id
			? { id: option.id, option: option.option, rank: option.rank }
			: { option: option.option, rank: option.rank }
		const analysisOptionId = option.id

		const choiceCrud = $choiceCrudStore[option.uuid]
		let choicesToCreate = Object.values(choiceCrud?.created ?? {}).map(choice =>
			formatChoiceForSave({ ...choice, valueType: option.valueType }, true),
		)
		const choicesToUpdate = Object.values(choiceCrud?.updated ?? {}).map(choice =>
			formatChoiceForSave({ ...choice, analysisOptionId, valueType: option.valueType }),
		)
		const choicesToDelete = Object.values(choiceCrud?.deleted ?? {}).reduce((acc: Array<number>, choice) => {
			if (choice.id) {
				acc.push(choice.id)
			}
			return acc
		}, [])
		const ruleCrud = $ruleCrudStore[option.uuid]
		const rulesToCreate = Object.values(ruleCrud?.created ?? {}).map(rule => formatRuleForSave({ ...rule, id: null }))
		const rulesToUpdate = Object.values(ruleCrud?.updated ?? {}).reduce(
			(acc: Array<AnalysisOptionRuleUpdate>, rule) => {
				if (rule.id) {
					acc.push(formatRuleForSave({ ...rule, id: rule.id }))
				}
				return acc
			},
			[],
		)
		const rulesToDelete = Object.values(ruleCrud?.deleted ?? {}).reduce((acc: Array<number>, rule) => {
			if (rule.id) {
				acc.push(rule.id)
			}
			return acc
		}, [])
		// if they can't modify this at all plants, keep the choices that are plant specific, but nothing else.
		if (!canEditGlobalFields(option.analysisId)) {
			choicesToCreate = choicesToCreate.filter(choice => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', choice.plantId))
		} else {
			optionToSave = {
				active: option.active,
				option: option.option,
				unit: option.unit,
				valueType: option.valueType,
				defaultValue: option.defaultValue,
				defaultType: option.defaultType,
				thresholdType: option.thresholdType,
				requiredToPerform: option.requiredToPerform,
				requiredToClose: option.requiredToClose,
				informational: option.informational,
				rank: option.rank,
				// recipes only
				productId: option.product?.id ?? null,
				entryMethod: option.entryMethod,
				inventoryMode: option.inventoryMode,
			}
		}

		if (analysisOptionId === null) {
			optionToSave = {
				...optionToSave,
				choices: choicesToCreate,
				rules: rulesToCreate,
			}
		} else {
			optionToSave = {
				...optionToSave,
				id: analysisOptionId,
				choicesToCreate,
				choicesToUpdate,
				choicesToDelete,
				rulesToCreate,
				rulesToUpdate,
				rulesToDelete,
			}
		}

		if (optionToSave.inventoryMode === 'MULTIPLE_LOTS' && !optionToSave.productId) {
			throw new Error(
				translate('analyses.ingredientRequired', 'Ingredient is required for option "{{option}}"', {
					option: option.option,
				}),
			)
		}

		return optionToSave
	}
	function formatChoiceForSave(choice: Choice & Pick<Option, 'valueType'>, isNew: true): NestedNewAnalysisOptionChoice
	function formatChoiceForSave(choice: Choice & Pick<Option, 'valueType'>, isNew?: false): AnalysisOptionChoiceUpdate
	function formatChoiceForSave(
		choice: Choice & Pick<Option, 'valueType'>,
		isNew: boolean = false,
	): NestedNewAnalysisOptionChoice | AnalysisOptionChoiceUpdate {
		function transformValue(value: string | number | boolean | null): string {
			if (typeof choice.choice === 'boolean') {
				return choice.choice ? 'True' : 'False'
			} else if (choice.valueType === 'BOOLEAN' && !choice.choice) {
				// If they create a new boolean option and don't click on 'False', it will be saved as empty string, but they probably want to save 'False'
				return 'False'
			}
			return value?.toString() ?? ''
		}
		const choiceToSave: NestedNewAnalysisOptionChoice = {
			active: choice.active,
			plantId: choice.plantId ?? null,
			constraint: choice.constraint,
			boundaryType: choice.boundaryType,
			productId: choice.product?.id ?? null,
			severityClassId: choice.severityClass?.id ?? null,
			choice: transformValue(choice.choice),
		}

		if (!isNew && choice.id && choice.analysisOptionId) {
			return {
				...choiceToSave,
				id: choice.id,
				analysisOptionId: choice.analysisOptionId,
			}
		}
		return choiceToSave
	}

	function formatRuleForSave(rule: Rule & { id: number }): AnalysisOptionRuleUpdate
	function formatRuleForSave(rule: Rule & { id: null }): NestedNewAnalysisOptionRule
	function formatRuleForSave(rule: Rule): NestedNewAnalysisOptionRule | AnalysisOptionRuleUpdate {
		let ruleToSave: NestedNewAnalysisOptionRule | AnalysisOptionRuleUpdate = {
			active: rule.active,
			restriction: rule.restriction,
			outcome: rule.outcome,
			description: rule.description,
		}

		const tagsToAdd = tagAddRemoveStore.getAddIds(rule.uuid).map(tagUuid => {
			const tag = tagsByUuid[tagUuid]
			if (!tag?.id) {
				throw new Error(`Tag ${tagUuid} not found in tag maps`)
			}
			return tag.id
		})

		const tagsToRemove = tagAddRemoveStore.getRemoveIds(rule.uuid).map(tagUuid => {
			const tag = tagsByUuid[tagUuid]
			if (!tag?.id) {
				throw new Error(`Tag ${tagUuid} not found in tag maps`)
			}
			return tag.id
		})

		if (rule.id) {
			return {
				...ruleToSave,
				id: rule.id,
				tagsToAdd,
				tagsToRemove,
			}
		}

		return {
			...ruleToSave,
			tags: tagsToAdd,
		}
	}

	async function saveAnalyses() {
		// Do this first so we can abort before saving if validation fails
		const analysesToCreate = analysisCrudStore.createdValues.map(analysis => formatAnalysisForSave(analysis, true))
		const analysesToUpdate = analysisCrudStore.updatedValues.map(analysis => formatAnalysisForSave(analysis))
		const analysesToDelete = analysisCrudStore.deletedValues.reduce((acc: Array<string>, analysis) => {
			if (analysis.id) {
				acc.push(analysis.id.toString())
			}
			return acc
		}, [])

		// Save Tags
		const tagCrudCreatedValues = tagCrudStore.createdValues

		const createdTagsByName = tagCrudCreatedValues.reduce((acc, tag) => {
			acc.set(tag.name, tag.uuid)
			$tagStore[tag.entityType][tag.uuid] = tag
			return acc
		}, new Map<string, string>())

		const tagsToCreate: Array<NewEntityTag> = tagCrudCreatedValues.map(tag => ({
			name: tag.name,
			entityType: tag.entityType,
			active: tag.active,
		}))

		const tagsToUpdate = tagCrudStore.updatedValues.reduce((acc: Array<EntityTagUpdate>, tag) => {
			if (tag.id) {
				acc.push({
					id: tag.id,
					name: tag.name,
					entityType: tag.entityType,
					active: tag.active,
				})
			}
			return acc
		}, [])

		const tagsToDelete = tagCrudStore.deletedValues.reduce((acc: Array<number>, tag) => {
			if (tag.id) {
				acc.push(tag.id)
			}
			return acc
		}, [])

		try {
			// need to save tags first so we can associate the new tag id with the analysis > option > rule > tag
			const [createTagsRes] = await Promise.all([
				tagsToCreate.length ? createEntityTags.mutate({ input: tagsToCreate }) : null,
				tagsToUpdate.length ? updateTags.mutate({ input: tagsToUpdate }) : null,
				tagsToDelete.length ? deleteTags.mutate({ ids: tagsToDelete }) : null,
			])
			// update maps with new tags' ids
			if (createTagsRes?.data?.createEntityTags.length) {
				for (const tag of createTagsRes.data.createEntityTags) {
					const uuid = createdTagsByName.get(tag.name)
					$tagStore[tag.entityType][uuid] = {
						...tag,
						uuid,
					}
				}
			}
		} catch (err: any) {
			mediator.call('showMessage', {
				color: 'danger',
				heading: translate('analyses.errorSavingTagsHeading', 'Error saving tags; Analyses not saved'),
				message: err.message,
				time: false,
			})
			console.error('Error saving tags', err)
			return
		}
		await tick()
		// Save Analyses
		try {
			const [createRes] = await Promise.all([
				analysesToCreate.length ? createAnalyses.mutate({ input: analysesToCreate }) : null,
				analysesToUpdate.length ? updateAnalyses.mutate({ input: { analyses: analysesToUpdate } }) : null,
				analysesToDelete.length ? deleteAnalyses.mutate({ ids: analysesToDelete }) : null,
			])

			// If the last selected analysis was new, find the new id before we reload the page
			if (selectedAnalysis && !selectedAnalysis.id) {
				const newAnalysisId = createRes?.data?.createAnalyses.find(
					analysis => analysis.name === selectedAnalysis.name,
				)?.id
				if (newAnalysisId) {
					selectedAnalysisId = newAnalysisId
				}
			}

			mediator.call('showMessage', {
				color: 'success',
				heading: translate('common:savedHeading', 'Saved!'),
				message: translate('analyses.savedMessage', 'Analyses saved successfully.'),
				time: 10,
			})

			analysisCrudStore.clear()
			tagCrudStore.clear()
			optionCrudStore.clear()
			choiceCrudStore.clear()
			ruleCrudStore.clear()
			$hasUnsavedChanges = false
			asr.go(null, { lastSavedTime: Date.now(), selectedAnalysisId }, { inherit: true })
		} catch (err: any) {
			mediator.call('showMessage', {
				color: 'danger',
				heading: translate('analyses.errorSavingAnalysesHeading', 'Error saving Analyses'),
				message: err.message,
				time: false,
			})
			console.error('Error saving Analyses', err)
		}
	}

	const analysesTableColumns: Array<Column<Analysis>> = [
		{
			property: 'id',
			name: '',
			icon: 'save',
			width: '1rem',
			title: translate(
				'analysisManagment.dirtyColumnTooltip',
				'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
			),
			sortType: false,
			align: 'center',
			minWidth: '40px',
		},
		{
			property: 'name',
			name: translate('analyses.nameColumnName', 'Name'),
			minWidth: '200px',
			title: translate(
				'analyses.nameColumnTitle',
				'A unique name describing a test (called an analysis) to be performed',
			),
		},
		{
			property: 'inUse',
			name: translate('analyses.inUseColumnName', 'In Use'),
			width: '60px',
			align: 'center',
			title: translate('analyses.inUseColumnTitle', 'Whether this analysis is in use at the currently selected plant'),
		},
		{
			property: 'active',
			name: translate('analyses.activeColumnName', 'Active'),
			width: '60px',
			align: 'center',
			title: translate(
				'analyses.activeColumnTitle',
				'Whether this analysis is active (active analyses can be used on new samples)',
			),
		},
		{
			property: 'requireAuthentication',
			name: translate('analyses.authColumnName', 'Auth. Required'),
			width: '125px',
			align: 'center',
			title: translate(
				'analyses.authColumnTitle',
				'If checked, if the user makes changes to samples with this analysis, they must authenticate with their password',
			),
		},
		{
			property: 'category',
			name: translate('analyses.categoryColumnName', 'Category'),
			minWidth: '150px',
			title: translate('analyses.categoryColumnTitle', 'Categories are used to group similar items together'),
		},
		{
			property: 'visibleGroup[name]',
			name: translate('analyses.visibleGroupColumnName', 'Visible Group'),
			minWidth: '150px',
			title: translate(
				'analyses.visibleGroupColumnTitle',
				'(Optional) If specified, only users in this group will be able to see/use this analysis',
			),
		},
		{
			property: 'groupSamples',
			name: translate('analyses.groupSamplesColumnName', 'Group Tag #s'),
			width: '110px',
			title: translate(
				'analyses.groupSamplesColumnTitle',
				'Samples collected for this analysis will be grouped into one sample tag # if they are being collected from the same location',
			),
			align: 'center',
		},
		{
			property: 'testingPeriod',
			name: translate('analyses.testingPeriodColumnName', 'Testing Period'),
			width: '120px',
			title: translate(
				'analyses.testingPeriodColumnTitle',
				"The amount of time (in fractional hours) a sample spends in testing/incubation.Enter 0 for analyses that don't need testing.",
			),
			align: 'center',
		},
		{
			property: 'sampleTagPrintQuantity',
			name: translate('analyses.sampleTagQtyColumnName', 'Sample Tag Print Qty'),
			width: '1rem',
			title: translate(
				'analyses.sampleTagQtyColumnTitle',
				'The default number of sample tags to print for this type of analysis',
			),
			align: 'center',
		},
		{
			property: 'testingTagPrintQuantity',
			name: translate('analyses.testingTagQtyColumnName', 'Testing Tag Print Qty'),
			width: '1rem',
			title: translate(
				'analyses.testingTagQtyColumnTitle',
				'The default number of testing tags to print for this type of analysis',
			),
			align: 'center',
		},
		{
			property: 'uuid',
			name: '',
			icon: 'trash',
			align: 'center',
			sortType: false,
			width: '1rem',
			title: translate('analyses.deleteColumnTitle', 'Mark this for deletion. It will be deleted on save.'),
		},
	]

	if (recipesMode) {
		analysesTableColumns.splice(
			7,
			0,
			{
				name: translate('analyses.batchVolumeColumnName', 'Batch Volume'),
				property: 'batchVolume',
				title: translate('analyses.batchVolumeColumnTitle', 'The amount produced in a single batch of this recipe'),
				numeric: true,
			},
			{
				name: translate('analyses.unitColumnName', 'Unit'),
				property: 'batchUnit',
				minWidth: '150px',
				title: translate('analyses.batchUnitColumnTitle', 'The unit of the batch volume produced by this recipe'),
			},
			{
				name: translate('analyses.productCreatedColumnName', 'Product Created'),
				property: 'createdProduct[name]',
				minWidth: '150px',
				title: translate(
					'analyses.createdProductColumnTitle',
					'(Optional) The product that is the output of this recipe',
				),
			},
			{
				name: translate('analyses.instructionsColumnName', 'Instructions'),
				property: 'instructions',
				minWidth: '200px',
				title: translate(
					'analyses.instructionsColumnTitle',
					'(Optional) A set of additional instructions for work orders',
				),
			},
		)
		analysesTableColumns.splice(11, 4)
	}

	const removeSaveProvider = mediator.provide('analysis-management-save', saveAnalyses)

	// #region Queries
	const createAnalyses = graphql(`
		mutation AmCreateAnalyses($input: [NewAnalysis!]!) {
			createAnalyses(input: $input) {
				id
				name
			}
		}
	`)

	const updateAnalyses = graphql(`
		mutation AmUpdateAnalyses($input: UpdateAnalysesInput!) {
			updateAnalyses(input: $input) {
				id
			}
		}
	`)

	const deleteAnalyses = graphql(`
		mutation AmDeleteAnalyses($ids: [ID!]!) {
			deleteAnalyses(ids: $ids)
		}
	`)

	const createEntityTags = graphql(`
		mutation AmCreateEntityTags($input: [NewEntityTag!]!) {
			createEntityTags(input: $input) {
				active
				entityType
				id
				name
			}
		}
	`)

	const updateTags = graphql(`
		mutation AmUpdateEntityTags($input: [EntityTagUpdate!]!) {
			updateEntityTags(input: $input) {
				id
			}
		}
	`)

	const deleteTags = graphql(`
		mutation AmDeleteEntityTags($ids: [PositiveInt!]!) {
			deleteEntityTags(ids: $ids)
		}
	`)

	const getAnalysisOptionsForClone = graphql(`
		query AmAnalysisOptionsForCloneQuery($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
			analysisOptions(filter: $filter, pagination: $pagination) {
				data {
					...AnalysisManagementOptionData
				}
			}
		}
	`)

	const getAnalysisOptionsWithChoicesForClone = graphql(`
		query AmAnalysisOptionsChoicesQuery($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
			analysisOptions(filter: $filter, pagination: $pagination) {
				data {
					...AnalysisManagementOptionData
					choices {
						...AnalysisManagementChoiceData
					}
				}
			}
		}
	`)
	//#endregion

	$: $saveResetProps = {
		save: saveAnalyses,
		disabled: !$hasUnsavedChanges,
	}

	onMount(async () => {
		await tick()
		selectedAnalysisChanged(selectedAnalysisIndex, -1)
	})

	onDestroy(() => {
		removeSaveProvider()
	})
</script>

<CollapsibleCard
	footerHides
	entireHeaderToggles
	cardClass="w-100 mb-2"
	cardHeaderClass="card-header d-flex justify-content-between h5 border-radius-2rem"
	rightDivClass="d-flex"
	bind:bodyShown={$analysesCardExpanded}
	show={() => {
		setTimeout(() => {
			document.querySelector(`#analysis-row-${selectedAnalysisIndex}`)?.scrollIntoView({ block: 'center' })
		}, 250)
	}}
>
	{#snippet cardHeader()}
		<div>
			<h5>{recipesMode ? translate('common:recipes', 'Recipes') : translate('common:analyses', 'Analyses')}</h5>
			{#if !$analysesCardExpanded}
				<h6>{selectedPlant.code} - {selectedPlant.name}, {selectedAnalysis?.name}</h6>
			{/if}
		</div>
	{/snippet}

	<Table
		responsive
		stickyHeader
		filterEnabled
		columnHidingEnabled
		columnResizingEnabled
		localStorageKey={recipesMode ? 'recipes-table' : 'analyses-table'}
		tableId="analyses-table"
		rows={analyses}
		columns={analysesTableColumns}
		filterPlaceholder={recipesMode
			? translate('analyses.filterRecipesLabel', 'Filter Recipes')
			: translate('analyses.filterLabel', 'Filter Analyses')}
		parentClass="mh-40vh"
		lazySort={lazySortAnalyses}
		idProp="uuid"
		rowMatchesFilterMethod={(filter, row, props) =>
			(analysisTable?.defaultRowMatchesFilter(filter, row, props) || optionMatchesFilter(filter, row.options ?? [])) ??
			false}
		bind:this={analysisTable}
	>
		{#snippet header()}
			<div class="form-row mb-3">
				<div class="col-12 col-md-3">
					<SiteAutocomplete
						label={translate('common:plant', 'Plant')}
						options={plants}
						disabled={!plants.length}
						bind:value={selectedPlant}
						change={() => stateParameterChanged('plantId', selectedPlant.id)}
					></SiteAutocomplete>
				</div>
				<div class="col-12 col-md-auto align-self-end">
					<Checkbox
						label={recipesMode
							? translate('recipes.showInactiveRecipesLabel', 'Show inactive recipes & children')
							: translate('analyses.showInactiveLabel', 'Show inactive analyses & children')}
						title={recipesMode
							? translate(
									'recipes.showInactiveRecipesTitle',
									'Show inactive recipes, options, choices/thresholds, and rules',
								)
							: translate(
									'analyses.showInactiveTitle',
									'Show inactive analyses, options, choices/thresholds, and rules',
								)}
						bind:checked={$showInactive}
						onchange={() => stateParameterChanged('showInactive', $showInactive)}
					/>
					<Checkbox
						label={recipesMode
							? translate('analyses.showRecipesNotInUseLabel', 'Show recipes not in use at {{- plantCode}}', {
									plantCode: selectedPlant.code,
								})
							: translate('analyses.showNotInUseLabel', 'Show analyses not in use at {{- plantCode}}', {
									plantCode: selectedPlant.code,
								})}
						bind:checked={$showUnused}
						onchange={() => stateParameterChanged('showUnused', $showUnused)}
					/>
				</div>
			</div>
		{/snippet}
		{#snippet children({ row })}
			{@const isDirty = $analysisCrudStore && (analysisCrudStore.isUpdated(row) || analysisCrudStore.isCreated(row))}
			{@const isDeleted = $analysisCrudStore && analysisCrudStore.isDeleted(row)}
			{@const isPlaceholder = !isDirty && !isDeleted && !row.id}

			<tr
				id="analysis-row-{row.originalIndex}"
				class:table-primary={selectedAnalysisId === row.id && selectedAnalysisUuid === row.uuid}
				class:table-danger={isDeleted}
				onclick={() => selectedAnalysisChanged(row.originalIndex, selectedAnalysisIndex)}
			>
				<Td
					enterGoesDown
					stopPropagation
					property="id"
				>
					{#if isDirty}
						<i
							class="fas fa-fw fa-save"
							title={translate('analyses.attnHasUnsavedChangesTitle', 'This analysis has unsaved changes')}
						></i>
					{:else if isPlaceholder}
						<i
							class="far fa-fw fa-floppy-disk-circle-xmark text-black-50"
							title={translate('analyses.attnWillNotBeSavedTitle', 'This analysis will not be saved until modified')}
						></i>
					{/if}
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="name"
					class="text-nowrap"
					style="max-width: 400px;"
					title={row.name}
				>
					<Input
						id="analysis-name-{row.originalIndex}"
						showLabel={false}
						placeholder={recipesMode
							? translate('analyses.newRecipe', 'New Recipe')
							: translate('analyses.newAnalysis', 'New Analysis')}
						title={row.name}
						value={row.name}
						disabled={!canEditGlobalFields(row.id) || isDeleted}
						onchange={event => updateAnalysisKeypath(row.originalIndex, 'name', getEventValue(event))}
					/>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="inUse"
				>
					<input
						id="analysis-in-use-{row.originalIndex}"
						type="checkbox"
						checked={row.inUse}
						disabled={!computeCanEditPlantSpecificFields || isDeleted}
						onchange={event => {
							const inUse = getEventChecked(event)
							if (!canEditPlantSpecificFields) {
								console.warn('User does not have permission to edit this analysis at this plant')
								return
							}
							if (inUse) {
								analyses[row.originalIndex].inUseAtPlantIDs.splice(0, 0, plantId)
							} else {
								analyses[row.originalIndex].inUseAtPlantIDs = analyses[row.originalIndex].inUseAtPlantIDs.filter(
									id => id !== plantId,
								)
							}
							analyses[row.originalIndex] = analyses[row.originalIndex]
							updateAnalysisKeypath(row.originalIndex, 'inUse', inUse)
						}}
					/>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="active"
				>
					<input
						id="analysis-active-{row.originalIndex}"
						type="checkbox"
						checked={row.active}
						disabled={!canEditGlobalFields(row.id) || isDeleted}
						onchange={event => updateAnalysisKeypath(row.originalIndex, 'active', getEventChecked(event))}
					/>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="requireAuthentication"
				>
					<input
						id="analysis-require-authentication-{row.originalIndex}"
						type="checkbox"
						checked={row.requireAuthentication}
						disabled={!canEditGlobalFields(row.id) || isDeleted}
						onchange={event =>
							updateAnalysisKeypath(row.originalIndex, 'requireAuthentication', getEventChecked(event))}
					/>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="category"
				>
					<Input
						id="analysis-category-{row.originalIndex}"
						showLabel={false}
						title={row.category}
						value={row.category}
						list="categoryList{row.id}"
						disabled={!canEditGlobalFields(row.id) || isDeleted}
						onchange={event => updateAnalysisKeypath(row.originalIndex, 'category', getEventValue(event))}
					/>
					<datalist id="categoryList{row.id}">
						{#each categories as category}
							<option value={category}> </option>{/each}
					</datalist>
				</Td>
				<Td
					enterGoesDown
					stopPropagation
					property="visibleGroup[name]"
				>
					<Select
						id="analysis-visible-group-{row.originalIndex}"
						showLabel={false}
						value={row.visibleGroup?.id ?? null}
						disabled={!canEditGlobalFields(row.id) || isDeleted}
						showEmptyOption={true}
						emptyText={translate('common:allGroups', 'All Groups')}
						onchange={event => {
							const groupId = parseInt(getEventValue(event), 10)
							const group = groups.find(group => group.id === groupId) ?? null
							updateAnalysisKeypath(row.originalIndex, 'visibleGroup', group)
						}}
					>
						{#each groups as { id, name }}
							<option value={id}>{name}</option>
						{/each}
					</Select>
				</Td>
				{#if recipesMode}
					<Td
						enterGoesDown
						stopPropagation
						property="batchVolume"
					>
						<Input
							required
							id="analysis-batch-volume-{row.originalIndex}"
							showLabel={false}
							validation={{
								value: row.batchVolume,
								// Don't show validation error on placeholder row / rows not saved
								validator: val => (!!val && Number(val) > 0 ? true : ''),
							}}
							value={row.batchVolume}
							disabled={!canEditGlobalFields(row.id) || isDeleted}
							onchange={event =>
								updateAnalysisKeypath(row.originalIndex, 'batchVolume', parseInt(getEventValue(event), 10) || null)}
						/>
					</Td>
					<Td
						enterGoesDown
						stopPropagation
						property="batchUnit"
					>
						<Input
							id="analysis-batch-unit-{row.originalIndex}"
							showLabel={false}
							title={row.batchUnit}
							value={row.batchUnit}
							disabled={!canEditGlobalFields(row.id) || isDeleted}
							onchange={event => updateAnalysisKeypath(row.originalIndex, 'batchUnit', getEventValue(event))}
						/>
					</Td>
					<Td
						enterGoesDown
						stopPropagation
						property="createdProduct[name]"
					>
						<Select
							id="analysis-created-product-{row.originalIndex}"
							showLabel={false}
							emptyText="-- {translate('common:selectProduct', 'Select Product')} --"
							disabled={!canEditGlobalFields(row.id) || isDeleted}
							showEmptyOption={true}
							options={products.filter(product => product.productType === 'PRODUCT')}
							value={row.createdProduct?.id ?? null}
							onchange={event => {
								const productId = parseInt(getEventValue(event), 10)
								const product = products.find(product => product.id === productId) ?? null
								updateAnalysisKeypath(row.originalIndex, 'createdProduct', product)
							}}
						>
							{#snippet option({ option })}
								<option value={option.id}>{option.name}</option>
							{/snippet}
						</Select>
					</Td>
					<Td
						enterGoesDown
						stopPropagation
						property="instructions"
					>
						<Input
							id="analysis-instructions-{row.originalIndex}"
							showLabel={false}
							title={row.instructions}
							value={row.instructions}
							disabled={!canEditGlobalFields(row.id) || isDeleted}
							onchange={event => updateAnalysisKeypath(row.originalIndex, 'instructions', getEventValue(event))}
						/>
					</Td>
				{/if}
				{#if !recipesMode}
					<Td
						enterGoesDown
						stopPropagation
						property="groupSamples"
						class="text-center"
					>
						<input
							id="analysis-group-samples-{row.originalIndex}"
							type="checkbox"
							checked={row.groupSamples}
							disabled={!canEditGlobalFields(row.id) || isDeleted}
							onchange={event => updateAnalysisKeypath(row.originalIndex, 'groupSamples', getEventChecked(event))}
						/>
					</Td>
					<Td
						enterGoesDown
						stopPropagation
						property="testingPeriod"
					>
						<Input
							id="analysis-testing-period-{row.originalIndex}"
							class="text-right"
							showLabel={false}
							type="number"
							value={row.testingPeriod}
							disabled={!canEditGlobalFields(row.id) || isDeleted}
							onchange={event =>
								updateAnalysisKeypath(row.originalIndex, 'testingPeriod', parseFloat(getEventValue(event)))}
						/>
					</Td>
					<Td
						enterGoesDown
						stopPropagation
						property="sampleTagPrintQuantity"
					>
						<Input
							id="analysis-sample-tag-print-quantity-{row.originalIndex}"
							min="0"
							class="text-right"
							showLabel={false}
							type="number"
							value={row.sampleTagPrintQuantity}
							disabled={!computeCanEditPlantSpecificFields || isDeleted}
							onchange={event =>
								updateAnalysisKeypath(row.originalIndex, 'sampleTagPrintQuantity', parseInt(getEventValue(event), 10))}
						/>
					</Td>
					<Td
						enterGoesDown
						stopPropagation
						property="testingTagPrintQuantity"
					>
						<Input
							id="analysis-testing-tag-print-quantity-{row.originalIndex}"
							min="0"
							class="text-right"
							showLabel={false}
							type="number"
							value={row.testingTagPrintQuantity}
							disabled={!computeCanEditPlantSpecificFields || isDeleted}
							onchange={event =>
								updateAnalysisKeypath(row.originalIndex, 'testingTagPrintQuantity', parseInt(getEventValue(event), 10))}
						/>
					</Td>
				{/if}
				<Td
					enterGoesDown
					stopPropagation
					property="uuid"
				>
					{#if isDeleted}
						<Button
							outline
							size="sm"
							color="danger"
							id="analysis-delete-{row.originalIndex}"
							disabled={!canEditGlobalFields(row.id) || (!row.id && !isDirty)}
							iconClass="trash-undo"
							onclick={() => {
								analysisCrudStore.unDelete(row)
							}}
						/>
					{:else}
						<Button
							outline
							size="sm"
							color="danger"
							id="analysis-delete-{row.originalIndex}"
							disabled={!canEditGlobalFields(row.id) || (!row.id && !isDirty && !isDeleted)}
							iconClass="trash"
							onclick={() => {
								if (
									row.id &&
									!confirm(
										translate(
											'analyses.confirmDeleteAnalysis',
											'Are you sure you want to delete this analysis? \r\n\r\nIt will be removed from all schedules and work orders, and all samples that use it will be deleted. \r\n\r\nAfter saving, this action cannot be undone.',
										),
									)
								) {
									return
								}
								analysisCrudStore.delete(row)
								if (row.originalIndex === selectedAnalysisIndex) {
									let newSelectedAnalysisIndex = analyses
										.slice(selectedAnalysisIndex + 1)
										.findIndex(analysis => !analysisCrudStore.isDeleted(analysis))
									console.log(
										'newSelectedAnalysisIndex',
										newSelectedAnalysisIndex,
										analyses.slice(selectedAnalysisIndex + 1),
									)
									if (newSelectedAnalysisIndex === -1) {
										newSelectedAnalysisIndex =
											analyses
												.slice(0, selectedAnalysisIndex)
												.reverse()
												.findIndex(analysis => !analysisCrudStore.isDeleted(analysis)) -
											selectedAnalysisIndex -
											1
									} else {
										newSelectedAnalysisIndex += selectedAnalysisIndex + 1
									}

									const newSelectedAnalysis = analyses[newSelectedAnalysisIndex]

									selectedAnalysisId = newSelectedAnalysis?.id ?? null
									selectedAnalysisUuid = newSelectedAnalysis?.uuid ?? null
									document
										.getElementById(`analysis-row-${newSelectedAnalysisIndex}`)
										?.scrollIntoView({ block: 'center' })
									asr.go(
										`${stateName}.options`,
										{
											selectedAnalysisId: newSelectedAnalysis?.id ?? null,
											selectedAnalysisUuid: newSelectedAnalysis?.uuid ?? null,
										},
										{ inherit: true },
									)
								}
							}}
						/>
					{/if}
				</Td>
			</tr>
		{/snippet}
		{#snippet noRows()}
			<tr>
				<td
					colspan={analysesTableColumns.length}
					class="text-center"
				>
					{translate('analyses.noAnalysesFound', 'No analyses found matching filters')}
				</td>
			</tr>
		{/snippet}
	</Table>
	{#snippet cardFooter()}
		<div
			class="card-footer d-flex flex-wrap justify-content-between"
			style="gap: 0.25rem"
		>
			<div
				class="d-flex flex-wrap"
				style="gap: 0.25rem"
			>
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					disabled={!canEditGlobalFields(-1)}
					onclick={() => addAnalysis()}
					>{recipesMode
						? translate('analyses.newRecipe', 'New Recipe')
						: translate('analyses.newAnalysis', 'New Analysis')}
				</Button>
				<Dropdown
					split
					outline
					size="sm"
					color="success"
					iconClass="clone"
					menuItemClickCloses={false}
					disabled={!canEditGlobalFields(-1)}
					onclick={cloneAnalysis}
				>
					{recipesMode
						? translate('analyses.cloneRecipeButton', 'Clone Recipe')
						: translate('analyses.cloneAnalysisButton', 'Clone Analysis')}
					{#snippet dropdownItems()}
						<DropdownCheckboxItem bind:checked={cloneWithThresholds}
							>{translate('analyses.includeThresholdsButton', 'Include Thresholds')}</DropdownCheckboxItem
						>
						<DropdownCheckboxItem bind:checked={cloneWithPlants}
							>{translate('analyses.includePlantsButton', 'Include Plants In Use')}</DropdownCheckboxItem
						>
					{/snippet}
				</Dropdown>
			</div>

			{#if !recipesMode}
				<div class="ml-auto">
					<Button
						outline
						size="sm"
						iconClass="abacus"
						disabled={!canRecalculateSamples}
						onclick={() => recalculateSamplesModal?.open(plantId, selectedAnalysisId)}
						>{translate('analyses.recalculateSamples', 'Recalculate Samples')}...
					</Button>
				</div>
			{/if}
		</div>
	{/snippet}
</CollapsibleCard>

<RecalculateSamplesModal
	{plants}
	showDirtyWarning={$hasUnsavedChanges}
	bind:this={recalculateSamplesModal}
></RecalculateSamplesModal>

<!-- <ConfirmNavigationModal
	save={saveAnalyses}
	hasUnsavedChanges={$hasUnsavedChanges}
	bind:this={confirmNavigationModal}
></ConfirmNavigationModal> -->

<uiView></uiView>
