import type { Mediator } from 'client/types/common'

/** This function will show an "Error loading <your state>" error, and provide the redirect object to the state of your choosing, which you can then `throw`.
 * @param mediator The mediator object
 * @param heading The error heading to display
 * @param message The error message to display
 * @param redirectTo The state to redirect to. Defaults to the app state.
 *
 * @example
 * throw showErrorAndRedirect(mediator, 'Error Loading Location Management', error.message);
 */
export default function showErrorAndRedirect(
	mediator: Mediator,
	heading: string,
	message: string,
	redirectTo: { name: string; params?: Record<string, unknown> } = { name: 'app' },
) {
	void mediator.call('showMessage', {
		heading,
		message,
		color: 'danger',
		time: false,
	})
	return {
		redirectTo,
	}
}
