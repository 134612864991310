<script lang="ts">
	import type {
		PreviewConfiguration,
		Schedule,
		AlertSubscription,
		AlertTypeMap,
	} from 'utility/alert-subscription-helper'
	import type { i18n } from 'i18next'

	import PreviewButton from './PreviewButton.svelte'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import { klona } from 'klona'
	import { getContext, onMount, createEventDispatcher } from 'svelte'

	const { t: translate } = getContext<i18n>('i18next')
	const dispatch = createEventDispatcher<{ preview: PreviewConfiguration }>()

	const defaultPreviewConfiguration = Object.freeze({
		documentId: '',
		scheduleId: null,
		date: '',
		loadedPreview: null,
	})

	let preview: PreviewConfiguration = klona(defaultPreviewConfiguration)
	export let alertSubscription: AlertSubscription
	export let alertTypeMap: AlertTypeMap
	export let isLoading: boolean = false
	export let schedules: Schedule[] = []
	let canPreviewAlert: boolean = false

	onMount(() => {
		if (alertSubscription.scheduleId) {
			preview.scheduleId = alertSubscription.scheduleId
		}
	})
</script>

<form on:submit|preventDefault={() => dispatch('preview', preview)}>
	<div class="form-row align-items-end">
		<div class="col-12 col-sm-6">
			<Select
				id="firstPreviewFormControl"
				label={translate('common:schedule', 'Schedule')}
				bind:value={preview.scheduleId}
				options={schedules}
			>
				{#snippet option({ option: schedule })}
					<option value={schedule?.id}>{schedule?.name ?? ''}</option>
				{/snippet}
			</Select>
		</div>
		<div class="col-12 col-sm-auto flex-fill">
			<Input
				label={translate('common:date', 'Date')}
				type="date"
				bind:value={preview.date}
				class="form-control form-control-sm"
			/>
		</div>
		<div class="col-12 col-sm-auto mb-1">
			<PreviewButton
				{alertSubscription}
				{alertTypeMap}
				{preview}
				{isLoading}
				bind:canPreviewAlert
			/>
		</div>
	</div>
</form>
