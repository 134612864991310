<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import type {
		WorkOrderData$result,
		DocumentStatus$options,
		ShowProduct$options,
		WoAnalyses$result,
		WorkOrder$result,
		WorkOrderTypesAndPlants$result,
		ChangeEventType$options,
	} from '$houdini'
	import type { WritableDeep } from 'type-fest'
	import type { SettingValueStore } from 'stores/setting-value'
	import type { GroupedEntityLoader } from 'utility/grouped-entity-loader'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type {
		WoDocumentStore,
		WorkOrder,
		Sample,
		MetaSampleAttachment,
		SampleValue,
		DisplaySample,
		DisplaySampleValue,
		WoMediatorProviders,
		SampleValueSublot,
	} from '../work-order'
	import type { i18n } from 'i18next'
	import type { UserLocalWritable } from '@isoftdata/svelte-store-user-local-writable'

	type Analysis = WorkOrderData$result['analyses']['data'][number]
	type Product = WorkOrderData$result['products']['data'][number]
	type Location = WorkOrderData$result['locations']['data'][number]

	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Textarea from '@isoftdata/svelte-textarea'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import ContextMenu, { DropdownItem } from '@isoftdata/svelte-context-menu'
	import ReportJobModal from 'components/ReportJobModal.svelte'
	import Table, { Td, type Column, type StringKeyOfType, type IndexedRowProps } from '@isoftdata/svelte-table'
	import Dropdown from '@isoftdata/svelte-dropdown'
	import Modal from '@isoftdata/svelte-modal'
	import TableCardFooter from '@isoftdata/svelte-table-card-footer'
	import SampleListGroup from './SampleListGroup.svelte'
	import Changelog from './Changelog.svelte'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import NavTabBar from '@isoftdata/svelte-nav-tab-bar'
	import ProductionSamples from './ProductionSamples.svelte'
	import SampleAttachments from './SampleAttachments.svelte'
	import SampleTable from './SampleTable.svelte'

	import hasPermission from 'utility/has-permission'
	import session from 'stores/session'
	import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
	import { getContext, onDestroy, onMount, setContext, tick } from 'svelte'
	import { graphql } from '$houdini'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import formatDocumentStatus, { getDocumentStatusHint } from 'utility/format-document-status'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import { RestrictionTree, DocumentTree, type AnalysisOptionChoice } from './edit'
	import userLocalWritable from '@isoftdata/svelte-store-user-local-writable'
	import { isValidTimeZone } from 'utility/timezone'
	import { formatDateTimeForInput, getEventUtcDate } from 'utility/timezone'
	import { writable } from 'svelte/store'

	interface Props {
		asr: SvelteAsr
		workOrder: WorkOrder
		workOrderStatuses: Array<DocumentStatus$options>
		plants: WorkOrderTypesAndPlants$result['plants']['data']
		workOrderTypes: WorkOrderTypesAndPlants$result['workOrderTypes']['data']
		workerGroups: WorkOrderData$result['groups']['data']
		analysesLoader: GroupedEntityLoader<Analysis>
		productsLoader: GroupedEntityLoader<Product>
		productBatchesLoader: GroupedEntityLoader<WorkOrderData$result['productBatches']['data'][number]>
		productDescendantsLoader: GroupedEntityLoader<Product>
		locationsLoader: GroupedEntityLoader<Location>
		choicesLoader: GroupedEntityLoader<AnalysisOptionChoice>
		analysisPrintQuantities: Record<number, { 'Work Order Tags': number; 'Work Order Testing Tags': number }>
		restrictionTree: RestrictionTree
		userAccounts: WorkOrderData$result['userAccounts']['data']
		showOptions: SettingValueStore<boolean>
		showCollectionDetail: SettingValueStore<boolean>
		showTestingDetail: SettingValueStore<boolean>
		showModifiedIcons: SettingValueStore<boolean>
		showOnlyApplicableThresholds: SettingValueStore<boolean>
		allowShowThresholdsTable: boolean
		emailSuggestions: Array<string>
		showWorkOrderDetail: SettingValueStore<boolean>
		sampleCrudStore: CrudStore<Sample, 'uuid'>
		sampleAttachmentCrudStore: CrudStore<MetaSampleAttachment, 'uuid'>
		woDocumentStore: WoDocumentStore
		documentTree: DocumentTree
		initialDocumentStatus: DocumentStatus$options
		initialSampleStatuses: Record<string, DocumentStatus$options>
		lastWorkOrderType: UserLocalWritable<number>
		changelogStatuses: Array<ChangeEventType$options>
		changeLogShowHistoricalValues: boolean
		selectedTab?: 'TESTING' | 'RECIPE' | 'CHANGELOG'
		samplesPerPage: number
	}

	let {
		asr,
		workOrder = $bindable(),
		workOrderStatuses,
		plants,
		workOrderTypes,
		workerGroups,
		analysesLoader,
		productsLoader,
		productBatchesLoader,
		productDescendantsLoader,
		locationsLoader,
		choicesLoader,
		analysisPrintQuantities,
		restrictionTree,
		userAccounts,
		showOptions,
		showCollectionDetail,
		showTestingDetail,
		showModifiedIcons,
		showOnlyApplicableThresholds,
		allowShowThresholdsTable,
		emailSuggestions,
		showWorkOrderDetail,
		sampleCrudStore,
		sampleAttachmentCrudStore,
		woDocumentStore,
		documentTree,
		initialDocumentStatus,
		initialSampleStatuses = $bindable(),
		lastWorkOrderType,
		changelogStatuses = $bindable(),
		changeLogShowHistoricalValues = $bindable(),
		selectedTab = $bindable('TESTING'),
		samplesPerPage,
	}: Props = $props()

	const showClosedSamples = userLocalWritable($session.userAccountId, 'showClosedSamples', true)
	// actual default will be set onMount based on screen size
	const sampleViewMode = userLocalWritable<'LIST' | 'TABLE' | null>(
		$session.userAccountId,
		'lastWorkOrderViewMode',
		null,
	)

	setContext('sampleAttachmentCrudStore', sampleAttachmentCrudStore)
	setContext('sampleCrudStore', sampleCrudStore)
	setContext('woDocumentStore', woDocumentStore)
	setContext('documentTree', documentTree)
	setContext('restrictionTree', restrictionTree)
	setContext('showOptions', showOptions)
	setContext('showCollectionDetail', showCollectionDetail)
	setContext('showTestingDetail', showTestingDetail)
	setContext('showClosedSamples', showClosedSamples)
	setContext('showOnlyApplicableThresholds', showOnlyApplicableThresholds)
	setContext('allowShowThresholdsTable', allowShowThresholdsTable)
	setContext('showModifiedIcons', showModifiedIcons)
	setContext('sampleViewMode', sampleViewMode)
	setContext('analysesLoader', analysesLoader)
	setContext('productsLoader', productsLoader)
	setContext('productDescendantsLoader', productDescendantsLoader)
	setContext('locationsLoader', locationsLoader)
	setContext('choicesLoader', choicesLoader)
	setContext('workOrderStatuses', workOrderStatuses)

	const { t: translate } = getContext<i18n>('i18next')

	let timeZoneIsValid = isValidTimeZone($session.plant.timezone)

	let showConfirmCopyModal = $state(false)
	let table: Table<DisplaySample> | undefined = $state()
	let sampleContextMenu: ContextMenu | undefined = $state()
	let contextMenuSample: DisplaySample | undefined = $state(undefined)
	let selectedRowIds: Array<string> = $state([])

	let reportJobModal: ReportJobModal | undefined = $state()
	let tableCardFooter: TableCardFooter<SampleCsvLine> | undefined = $state()
	let showAttachmentsModal = $state(false)
	// THE sample for the attachments modal
	let selectedSample: DisplaySample | null = $state(null)
	let showNewSampleModal = $state(false)
	let newSampleTemplate: {
		analysis?: Analysis
		product?: Product
		location?: Location
		productionVolume?: number
	} = $state({})
	let productImages: Record<number, Array<string>> = {}
	let locationImages: Record<number, Array<string>> = {}
	let productLocationAttachmentModal: {
		show: boolean
		isLoading: boolean
		entityType: 'PRODUCT' | 'LOCATION'
		entityId: number | null
		entityName: string
		files: Array<string>
		currentPhotoIndex: number
	} = $state({
		show: false,
		isLoading: false,
		entityType: 'PRODUCT',
		entityId: null,
		entityName: '',
		files: [],
		currentPhotoIndex: 0,
	})
	let changelogDates: { from: string; to: string } | undefined = $state(undefined)

	// #region Sample Reactive Statements - stuff that responds to changes in workOrder.samples
	const selectedTabAnalysisType = $derived(selectedTab === 'RECIPE' ? 'RECIPE' : 'TESTING')
	const samplesMode = $derived(selectedTabAnalysisType === 'TESTING')
	const recipesMode = $derived(selectedTabAnalysisType === 'RECIPE')
	const testingSamplesCount = $derived(
		workOrder.samples.filter(sample => sample.analysis.analysisType === 'TESTING').length,
	)
	const productionSamplesCount = $derived(
		workOrder.samples.filter(sample => sample.analysis.analysisType === 'RECIPE').length,
	)

	function getDisplaySamples(
		samples: Array<Sample>,
		optionsToShow: Set<string>,
		maxOptionCount: number,
	): Array<DisplaySample> {
		return samples.reduce((acc: Array<DisplaySample>, sample, unfilteredIndex) => {
			if (
				($showClosedSamples || sample.status !== 'CLOSED') &&
				sample.analysis.analysisType === selectedTabAnalysisType
			) {
				acc.push({
					...sample,
					sampleValues: getDisplaySampleValues(sample.sampleValues, optionsToShow, maxOptionCount),
					unfilteredIndex,
				})
			}
			return acc
		}, [])
	}

	/** Make all the sample value arrays the same length by right padding with null */
	function getDisplaySampleValues(
		sampleValues: Array<SampleValue>,
		optionsToShow: Set<string>,
		maxOptionCount: number,
	): Array<DisplaySampleValue | null> {
		if (!optionsToShow?.size) {
			return []
		}
		const displaySvIndeces = sampleValues.reduce((indexSet: Set<number>, sv, index) => {
			if (optionsToShow.has(formatOptionName(sv.analysisOption))) {
				indexSet.add(index)
			}
			return indexSet
		}, new Set<number>())

		const newDisplaySampleValues: Array<DisplaySampleValue | null> = Array.from(displaySvIndeces).map(svOgIndex => ({
			...sampleValues[svOgIndex],
			svOgIndex,
		}))

		const padding = maxOptionCount - newDisplaySampleValues.length
		if (padding > 0) {
			newDisplaySampleValues.push(...new Array(padding).fill(null))
		}

		return newDisplaySampleValues
	}

	/** Computes almost everything that depends on workOrder.samples reactively in "one" go. */
	function computeSampleDependencies(samples: Array<Sample>): {
		hasMultiplePlants: boolean
		hasMultipleAnalyses: boolean
		closedSampleCount: number
		optionsToShow: Set<string>
		displaySamples: Array<DisplaySample>
		optionsColumns: Array<Column<DisplaySample>>
	} {
		// read restrictionTree value so it responds to restriction updates
		$restrictionTree
		// Parallelize most of the work
		const { analysisIds, closedSampleCount, optionsToShow, plantIds } = workOrder.samples.reduce(
			(acc, sample: Sample) => {
				if (sample.analysis.analysisType !== selectedTabAnalysisType) {
					return acc
				}

				acc.analysisIds.add(sample.analysis.id)

				if (sample.plant) {
					acc.plantIds.add(sample.plant.id)
				}

				if (sample.status === 'CLOSED') {
					acc.closedSampleCount++
				}

				if (sample.status === 'OPEN') {
					acc.allSamplesPerformed = false
				}

				sample.sampleValues.forEach(sv => {
					const restriction = restrictionTree.get({
						analysisOptionId: sv.analysisOption.id,
						plantId: sample.plant?.id ?? workOrder.plant.id,
						locationId: sample.location?.id,
						productId: sample.product?.id,
					})
					// if not hidden or inactive (unless it has a historical value) put it in the set
					if (restriction !== 'HIDDEN' && (restriction !== 'INACTIVE' || sv.result)) {
						acc.optionsToShow.add(formatOptionName(sv.analysisOption))
					}
				})

				return acc
			},
			{
				analysisIds: new Set<number>(),
				plantIds: new Set<number>(),
				closedSampleCount: 0,
				optionsToShow: new Set<string>(),
				allSamplesPerformed: true,
			},
		)
		// Now we can compute the dependent values
		const hasMultipleAnalyses = analysisIds.size > 1
		const maxOptionCount = Math.max(
			...samples.map(
				sample => sample.sampleValues.filter(sv => optionsToShow.has(formatOptionName(sv.analysisOption))).length,
			),
		)
		const displaySamples = getDisplaySamples(samples, optionsToShow, maxOptionCount)
		const optionsColumns = buildOptionsColumns(displaySamples, {
			hasMultipleAnalyses,
			maxOptionCount,
			optionsToShow,
		})

		return {
			hasMultiplePlants: plantIds.size > 1,
			hasMultipleAnalyses,
			closedSampleCount,
			optionsToShow,
			displaySamples,
			optionsColumns,
		}
	}

	const {
		//
		hasMultiplePlants,
		hasMultipleAnalyses,
		closedSampleCount,
		optionsToShow,
		optionsColumns,
		displaySamples,
	} = $derived(computeSampleDependencies(workOrder.samples))

	const hasUnsavedChanges = $derived(
		($sampleCrudStore && sampleCrudStore.hasChanges()) || ($woDocumentStore && woDocumentStore.hasChanges()) || false,
	)
	const canEditCurrentWorkOrder = $derived(
		computeCanEditCurrentWorkOrder(workOrder.plant.id, workOrder.assignedToGroup?.id ?? null, workOrder.documentStatus),
	)
	// #endregion

	const mediator = getContext<Mediator<WoMediatorProviders>>('mediator')
	const fileBaseUrl = getContext<string>('fileBaseUrl')

	function formatOptionName({ option, unit }: { option: string; unit: string }) {
		return unit ? `${option} (${unit.trim()})` : option
	}
	setContext('formatOptionName', formatOptionName)

	function canDeleteSelectedSamples(selectedRowIds: Array<string>) {
		return selectedRowIds.every(uuid => {
			const sample = workOrder.samples.find(sample => sample.uuid === uuid)
			return (
				!sample?.id ||
				(hasPermission('WORK_ORDERS_CAN_DELETE_SAMPLES', sample.plant?.id ?? workOrder.plant.id) &&
					sample.status !== 'CLOSED')
			)
		})
	}

	/** Check that a sample has all required values for the current status (Closed/Performed) */
	function sampleMissingRequiredValuesForStatus(
		sample: Sample,
		sampleStatus: DocumentStatus$options,
	): Array<SampleValue> {
		// Don't need to validate these statuses
		if (sampleStatus === 'CANCELLED' || sampleStatus === 'OPEN') {
			return []
		}

		return sample.sampleValues.filter(sv => {
			const restriction = restrictionTree.get({
				analysisOptionId: sv.analysisOption.id,
				plantId: sample.plant?.id ?? workOrder.plant.id,
				locationId: sample.location?.id,
				productId: sample.product?.id,
			})

			let required = false
			if (sampleStatus === 'CLOSED') {
				// Closing a sample also requires all required values for performing
				required ||= restriction === 'REQUIRED_TO_PERFORM' || restriction === 'REQUIRED_TO_CLOSE'
			} else if (sampleStatus === 'SAMPLED') {
				required ||= restriction === 'REQUIRED_TO_PERFORM'
			}

			return required && !sv.result
		})
	}
	setContext('sampleMissingRequiredValuesForStatus', sampleMissingRequiredValuesForStatus)

	function allSamplesPerformed() {
		// This hsould technically be checking if every sample is 100% filled out
		const acceptableStatuses = ['CANCELLED', 'SAMPLED', 'CLOSED']
		return workOrder.samples.every(sample => acceptableStatuses.includes(sample.status))
	}

	function allSamplesCompleted() {
		return workOrder.samples.every(sample =>
			sample.sampleValues.every(sv => {
				if (sv.result) {
					return true
				}
				const restriction = restrictionTree.get({
					analysisOptionId: sv.analysisOption.id,
					plantId: sample.plant?.id ?? workOrder.plant.id,
					locationId: sample.location?.id,
					productId: sample.product?.id,
				})
				return restriction === 'HIDDEN' || restriction === 'INACTIVE'
			}),
		)
	}

	function allSamplesClosed() {
		return workOrder.samples.every(sample => sample.status === 'CLOSED' || sample.status === 'CANCELLED')
	}

	/** Set all samples that aren't already sampled or closed to that status */
	function updateSampleStatuses(newStatus: 'CLOSED' | 'SAMPLED') {
		workOrder.samples.forEach(sample => {
			if (newStatus === 'CLOSED' && sample.status !== 'CLOSED' && sample.status !== 'CANCELLED') {
				sample.status = 'CLOSED'
				sampleCrudStore.update(sample)
			} else if (newStatus === 'SAMPLED' && sample.status !== 'CANCELLED' && sample.status !== 'CLOSED') {
				sample.status = 'SAMPLED'
				sampleCrudStore.update(sample)
			}
		})
		workOrder.samples = workOrder.samples
	}

	// If they're required, do we have them?
	function woTypeFieldsMissing() {
		let titleMissing = workOrder.workOrderType.titleRequired && !workOrder.title
		let productBatchMissing = workOrder.workOrderType.productBatchRequired === 'REQUIRE' && !workOrder.productBatch
		let verificationMissing = workOrder.workOrderType.verificationRequired && !workOrder.verifiedOn

		return { titleMissing, productBatchMissing, verificationMissing }
	}

	const removeRequiredValuesProvider = mediator.provide(
		'sampleMissingRequiredValuesForStatus',
		sampleMissingRequiredValuesForStatus,
	)
	const removeSamplesPerformedProvider = mediator.provide('allSamplesPerformed', allSamplesPerformed)
	const removeSamplesCompleteProvider = mediator.provide('allSamplesCompleted', allSamplesCompleted)
	const removeSamplesClosedProvider = mediator.provide('allSamplesClosed', allSamplesClosed)
	const removeUpdateSampleStatusesProvider = mediator.provide('updateSampleStatuses', updateSampleStatuses)
	const removeWoTypeFieldsMissingProvider = mediator.provide('woTypeFieldsMissing', woTypeFieldsMissing)

	function computeCanEditCurrentWorkOrder(
		plantId: number,
		groupId: number | null,
		documentStatus: DocumentStatus$options,
	) {
		if (initialDocumentStatus === 'CLOSED') {
			return false
		}
		if (!hasPermission('WORK_ORDERS_CAN_EDIT', plantId)) {
			return false
		}
		if (
			groupId &&
			!$session.user.userGroupIds.includes(groupId) &&
			!hasPermission('WORK_ORDERS_CAN_EDIT_OTHER_GROUPS', plantId)
		) {
			return false
		}
		return true
	}

	function computeCanEditSample(canEditWo: boolean, sampleStatus: DocumentStatus$options) {
		if (!canEditWo) {
			return false
		}
		if (sampleStatus === 'CLOSED') {
			return false
		}
		return true
	}

	function computeCanEditSampleValue(
		canEditWo: boolean,
		sampleStatus: DocumentStatus$options,
		sample: DisplaySample,
		sampleValue: SampleValue,
	) {
		if (!canEditWo) {
			return false
		}
		if (!computeCanEditSample(canEditWo, sampleStatus)) {
			return false
		}
		let plantId = sample.plant?.id ?? workOrder.plant.id
		let required = restrictionTree
			.get({
				analysisOptionId: sampleValue.analysisOption.id,
				plantId,
				locationId: sample.location?.id,
				productId: sample.product?.id,
			})
			.includes('REQUIRED')
		if (sampleValue.id && required && !hasPermission('WORK_ORDERS_CAN_CHANGE_REQUIRED_OPTIONS', plantId)) {
			return false
		}
		if (sampleValue.id && !required && !hasPermission('WORK_ORDERS_CAN_CHANGE_UNREQUIRED_OPTIONS', plantId)) {
			return false
		}

		if (!sampleValue.analysisOption.active && !sampleValue.result) {
			return false
		}
		return true
	}
	setContext('computeCanEditCurrentWorkOrder', computeCanEditCurrentWorkOrder)
	setContext('computeCanEditSample', computeCanEditSample)
	setContext('computeCanEditSampleValue', computeCanEditSampleValue)

	type AnalysisOptions = WoAnalyses$result['analyses']['data'][number]['options']
	function analysisOptionsToBlankSampleValues(analysisOptions: AnalysisOptions, sampleId = 0): Array<SampleValue> {
		return (
			analysisOptions
				// Need to include inactive options that are being shown on another line so columns line up
				.filter(o => o.active || optionsToShow.has(o.option))
				.map(option => {
					return {
						id: 0,
						lot: '',
						sampleId,
						result: option.entryMethod === 'TOTAL_QUANTITY' ? '1' : '',
						resultStatus: 'NOT_CALCULATED',
						analysisOption: option,
						defaultValue: null,
						filledOut: '',
						totalQuantity: null,
						lastModified: null,
						expiration: null,
						createdInvestigationId: null,
						lastModifiedByUser: null,
						uuid: uuid(),
						lots:
							option.inventoryMode === 'MULTIPLE_LOTS' && option.product
								? [
										{
											id: 0,
											created: new Date().toISOString(),
											product: klona(option.product),
											productBatch: {
												id: 0,
												name: '',
												expiration: '',
											},
											supplierItemNumber: '',
											quantity: 1,
											uuid: uuid(),
										} satisfies SampleValueSublot,
									]
								: [],
					}
				})
		)
	}
	setContext('analysisOptionsToBlankSampleValues', analysisOptionsToBlankSampleValues)

	function buildOptionsColumns(
		samples: Array<DisplaySample>,
		{
			hasMultipleAnalyses,
			maxOptionCount,
			optionsToShow,
		}: { hasMultipleAnalyses: boolean; maxOptionCount: number; optionsToShow: Set<string> },
	): Array<Column<DisplaySample>> {
		if (!samples.length) {
			return []
		}
		const newArr: Array<Column<DisplaySample>> = []
		if (hasMultipleAnalyses) {
			for (let svIndex = 0; svIndex < maxOptionCount; svIndex++) {
				newArr.push({
					name: translate('workOrder.optionColumn', 'Option {{number}}', { number: svIndex + 1 }),
					property: `sampleValues[${svIndex}][result]`,
					minWidth: '100px',
					width: '200px',
				})
			}

			return newArr
		}

		// One analysis, so we can just use the sampleValues directly
		for (let svIndex = 0; svIndex < maxOptionCount; svIndex++) {
			const option = samples[0].sampleValues[svIndex]?.analysisOption
			const optionName = option ? formatOptionName(option) : ''
			if (option && optionsToShow.has(optionName)) {
				newArr.push({
					name: optionName,
					property: `sampleValues[${svIndex}][result]`,
					minWidth: '100px',
					width: '200px',
				})
			}
		}
		return newArr
	}

	interface SampleCsvLine extends IndexedRowProps {
		Analysis: string
		Product: string
		Location: string
		'Location Description': string
		'Testing Comments': string
		'Sampling Comments': string
		Status: string
		Scheduled: string
		Due: string
		'Performed On': string
		'Performed By': string
		'Tag #': string
		'Testing Began': string
		'Testing Ended': string
		'Tested By': string
		originalIndex: number
		uuid: string
	}

	function buildCsvItems(samples: Array<Sample>) {
		const maxOptionCount = hasMultipleAnalyses
			? Math.max(...samples.map(sample => sample.sampleValues.length))
			: (samples[0]?.sampleValues.length ?? 0)

		function buildCsvOptions(sample: Sample) {
			const options = {}
			if (hasMultipleAnalyses) {
				for (let index = 0; index < Math.min(maxOptionCount, sample.sampleValues.length); index++) {
					options[`Option ${index + 1}`] = sample?.sampleValues[index]?.result
				}
			} else {
				for (let index = 0; index < Math.min(maxOptionCount, sample.sampleValues.length); index++) {
					const analysisOption = sample?.sampleValues[index].analysisOption.option
					options[analysisOption] = sample?.sampleValues[index].result
				}
			}
			return options
		}

		return samples.map((sample, originalIndex): SampleCsvLine => {
			// This is more or less the format the desktop exports in
			return {
				Analysis: sample.analysis.name,
				Product: sample.product?.name ?? '',
				Location: sample.location?.location ?? '',
				'Location Description': sample.location?.description ?? '',
				'Testing Comments': sample.testingComments,
				'Sampling Comments': sample.samplingComments,
				Status: sample.status,
				...buildCsvOptions(sample),
				Scheduled: sample.scheduled ?? '',
				Due: sample.due ?? '',
				'Performed On': sample.performed ?? '',
				'Performed By': sample.collectedBy?.fullName ?? '',
				'Tag #': sample.tagNumber ?? '',
				'Testing Began': sample.incubationBegan ?? '',
				'Testing Ended': sample.incubationEnded ?? '',
				'Tested By': sample.platesReadBy?.fullName ?? '',
				// These aren't needed for the CSV, but we need them for it to work
				originalIndex,
				uuid: sample.uuid,
			}
		})
	}

	function onVerifiedChange(event: Event) {
		if (event.target instanceof HTMLInputElement && event.target.checked) {
			workOrder.verifiedByUser = {
				id: $session.userAccountId,
				fullName: $session.user.fullName ?? $session.userName,
			}
			workOrder.verifiedOn = new Date().toISOString()
		} else {
			workOrder.verifiedByUser = null
			workOrder.verifiedOn = null
		}
		woDocumentStore.setFromDocument(workOrder)
	}

	// input gives us a date string in the user's timezone. Convert to UTC and save on the work order
	function setWoDateFieldFromInput(event: Event, key: StringKeyOfType<WritableDeep<WorkOrder>, string | null>) {
		if (event.target instanceof HTMLInputElement) {
			const utcDateString = getEventUtcDate(event, $session.plant.timezone)

			// exclude document status since TS was getting hinky with the string union type
			if (key && key !== 'documentStatus') {
				workOrder[key] = utcDateString
			}
		}
		woDocumentStore.setFromDocument(workOrder)
	}

	// #region Cloning
	const cloneWoMutation = graphql(`
		mutation CopyWorkOrders($input: [CopyWorkOrderInput!]!) {
			copyWorkOrders(input: $input) {
				lastClonedId
			}
		}
	`)

	async function cloneWo(copyValues: boolean = false) {
		try {
			const res = await cloneWoMutation.mutate({
				input: [
					{
						id: workOrder.id,
						copyValues,
						copyMostRecent: false,
					},
				],
			})

			const workOrderId = res.data?.copyWorkOrders?.[0]?.lastClonedId

			if (!workOrderId) {
				throw new Error(translate('workOrder.serverDidNotReturnWoIdError', 'The server did not return a WO #.'))
			}
			asr.go(null, { workOrderId }, { inherit: false })
		} catch (err) {
			console.error(err)
			mediator.call('showMessage', {
				color: 'danger',
				heading: translate('workOrder.failedToCopyWoErrorHeading', 'Failed to Copy Work Order'),
				message: err instanceof Error ? err.message : translate('workOrder.unknownError', 'An unknown error occurred.'),
				time: false,
			})
		}
	}
	//#endregion Cloning
	/** If the Work Order has beeen Verified, we need to tell the user that certain changes will require it be re-verified.
	 * @returns {boolean} - True if the operation should continue, false if it should be cancelled.
	 */
	function confirmActionThatRequiresReVerify() {
		const verified = !!workOrder.verifiedOn

		if (!verified) {
			return true
		}

		const confirmed = confirm(
			translate(
				'workOrder.confirmReVerificationAlert',
				`
Re-Verification Required.

This change will require that the work order be verified again.
Are you sure you want to continue?
`,
			),
		)
		if (confirmed) {
			workOrder.verifiedByUser = null
			workOrder.verifiedOn = null
			woDocumentStore.setFromDocument(workOrder)
			return true
		}
		return false
	}
	setContext('confirmActionThatRequiresReVerify', confirmActionThatRequiresReVerify)

	function newSampleClick() {
		// Just create the sample if they're in "table" view, skipping the modal
		if ($sampleViewMode === 'TABLE') {
			if (!confirmActionThatRequiresReVerify()) {
				return
			}

			newSample({})
		} else {
			openNewSampleModal()
		}
	}

	async function openNewSampleModal() {
		if (!confirmActionThatRequiresReVerify()) {
			return
		}

		showNewSampleModal = true
		const analyses = await getApplicableAnalyses(workOrder.plant.id)
		const analysis = analyses.find(analysis => analysis.id === workOrder.workOrderType.defaultAnalysisId) ?? analyses[0]
		newSampleTemplate = {
			analysis,
			product: workOrder.productBatch?.product ?? undefined,
			location: workOrder.productBatch?.location ?? undefined,
			productionVolume: recipesMode ? (analysis?.batchVolume ?? undefined) : undefined,
		}
		await tick()
	}

	async function getApplicableAnalyses(plantId: number) {
		const analyses = await analysesLoader(plantId)
		return analyses.filter(analysis => analysis.analysisType === selectedTabAnalysisType && analysis)
	}
	setContext('getApplicableAnalyses', getApplicableAnalyses)

	// In mobile view, a modal will ask them to select an analysis/product/location, which is passed here as the "template"
	async function newSample(template: typeof newSampleTemplate) {
		const analyses = await analysesLoader(workOrder.plant.id)
		let analysis =
			template.analysis ??
			analyses.find(
				analysis =>
					analysis.id === workOrder.workOrderType.defaultAnalysisId &&
					analysis.analysisType === selectedTabAnalysisType,
			) ??
			analyses.find(analysis => analysis.analysisType === selectedTabAnalysisType)

		if (!analysis) {
			mediator.call('showMessage', {
				color: 'danger',
				heading: 'No Analysis Available',
				message: 'No analysis is available for this work order.',
				time: false,
			})

			return
		}

		// TODO expand type to allow null id
		const newSample: Sample = {
			uuid: uuid(),
			id: 0,
			plant: klona(workOrder.plant),
			analysis: {
				id: analysis.id,
				name: analysis.name,
				active: analysis.active,
				requireAuthentication: analysis.requireAuthentication,
				analysisType: analysis.analysisType,
				instructions: analysis.instructions,
				batchUnit: analysis.batchUnit,
				batchVolume: analysis.batchVolume,
				createdProductId: analysis.createdProductId,
			},
			product: null,
			location: null,
			testingComments: '',
			samplingComments: '',
			status: 'OPEN',
			tagNumber: '',
			scheduled: null,
			due: null,
			performed: null,
			collectedBy: null,
			incubationBegan: null,
			incubationEnded: null,
			investigationId: null,
			platesReadBy: null,
			sampleValues: analysisOptionsToBlankSampleValues(analysis.options),
			attachments: [],
			productionVolume: template.productionVolume ?? analysis.batchVolume,
		}

		newSample.product = template.product ?? null
		newSample.location = template.location ?? null

		// Get default product and location from the product batch, if they didn't specify them in a template
		if (workOrder.productBatch) {
			newSample.product ??= klona(workOrder.productBatch.product)
			newSample.location ??= klona(workOrder.productBatch.location)
		}

		restrictionTree.fetchForSample(newSample, workOrder.plant.id)
		documentTree.fetchForSample(newSample, workOrder.plant.id)

		workOrder.samples.push(newSample)
		sampleCrudStore.create(newSample)
		workOrder.samples = workOrder.samples
		initialSampleStatuses[newSample.uuid] = newSample.status
		await tick()
		document.querySelector<HTMLSelectElement>(`#analysis-${newSample.uuid}`)?.focus()
	}
	// #region Product & Location Images
	const loadingAttachments = writable<{
		type: 'PRODUCT' | 'LOCATION'
		sampleId: number
	} | null>(null)
	setContext('loadingAttachments', loadingAttachments)
	async function showProductLocationImages({
		entityType,
		entityId,
		entityName,
		sampleId,
	}: {
		entityType: 'PRODUCT' | 'LOCATION'
		entityId: number
		entityName: string
		sampleId: number
	}) {
		$loadingAttachments = {
			type: entityType,
			sampleId: sampleId,
		}
		productLocationAttachmentModal = {
			show: false,
			isLoading: true,
			entityType,
			entityId,
			entityName,
			files: [],
			currentPhotoIndex: 0,
		}
		await tick()
		const files = entityType === 'PRODUCT' ? await getProductImages(entityId) : await getLocationImages(entityId)
		// assigning stuff weird here so the right images show even if you mash the buttons
		productLocationAttachmentModal = {
			show: true,
			isLoading: false,
			entityType,
			entityId,
			entityName,
			files,
			currentPhotoIndex: 0,
		}
		await tick()
		productLocationAttachmentModal.files = files
		$loadingAttachments = null
		await tick()
	}

	async function getProductImages(productId: number) {
		if (productImages[productId]) {
			return productImages[productId]
		}
		const { data } = await getProductAttachments.fetch({ variables: { productId: productId.toString() } })

		if (!data?.product?.attachments) {
			productImages[productId] = []
			return []
		}

		const images = data.product.attachments.reduce((paths, attachment) => {
			if (attachment.file.type === 'IMAGE') {
				paths.push(`${fileBaseUrl}${attachment.file.path}`)
			}
			return paths
		}, new Array<string>())

		productImages[productId] = images
		return images
	}

	async function getLocationImages(locationId: number) {
		if (locationImages[locationId]) {
			return locationImages[locationId]
		}
		const { data } = await getLocationAttachments.fetch({ variables: { locationId: locationId.toString() } })

		if (!data?.location?.attachments) {
			locationImages[locationId] = []
			return []
		}

		const images = data.location.attachments.reduce((paths, attachment) => {
			if (attachment.file.type === 'IMAGE') {
				paths.push(`${fileBaseUrl}${attachment.file.path}`)
			}
			return paths
		}, new Array<string>())

		locationImages[locationId] = images
		return images
	}
	//#endregion Product & Location Images

	//#region Printing
	function printWorkOrder() {
		if (!workOrder.id) {
			return alert(translate('workOrder.saveWorkOrderPrintError', 'Please save the work order before printing.'))
		}
		let parameters: Record<string, string> = {
			workorderid: workOrder.id.toString(),
		}
		const reportJob = {
			type: 'Work Order',
			parameters,
		}
		reportJobModal?.open(reportJob, { emails: emailSuggestions })
	}

	function printTags(type: 'Work Order Tags' | 'Work Order Testing Tags', mode: 'ALL' | 'SELECTED' | 'SINGLE') {
		let samples: Array<Sample | DisplaySample> = []
		if (mode === 'SINGLE' && contextMenuSample?.tagNumber) {
			samples = [contextMenuSample]
		} else if (mode === 'SINGLE') {
			alert(translate('workOrder.noSampleOrTagNumberPrintError', 'No sample selected, or no tag number found.'))
		} else if (mode === 'SELECTED' && selectedRowIds.length) {
			samples = selectedRowIds
				.map(uuid => workOrder.samples.find(sample => sample.uuid === uuid))
				.filter(Boolean) as Array<Sample>
		} else if (mode === 'SELECTED') {
			alert(translate('workOrder.noSamplePrintError', 'No samples selected.'))
		} else if (mode === 'ALL') {
			samples = workOrder.samples
		}
		const allSamplesLength = samples.length
		samples = samples.filter(sample => sample.tagNumber)
		if (samples.length !== allSamplesLength) {
			alert(
				translate(
					'workOrder.unsavedSamplePrintError',
					'Some samples do not have tag numbers and must be saved before printing tags.',
				),
			)
		}

		const reportJobs = samples.map(({ tagNumber, analysis }) => {
			return {
				type,
				parameters: {
					workorderid: workOrder.id.toString(),
					tagnumber: tagNumber,
					// TODO, on the fly quantity adjustment?
					quantity: analysisPrintQuantities[analysis.id]?.[type].toString() ?? '1',
				},
			}
		})
		reportJobModal?.open(reportJobs)
	}

	export function canLeaveState() {
		return (
			!hasUnsavedChanges ||
			confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		)
	}

	const getLocationAttachments = graphql(`
		query WoLocationAttachments($locationId: ID!) {
			location(id: $locationId) {
				attachments {
					file {
						path
						type
					}
				}
			}
		}
	`)

	const getProductAttachments = graphql(`
		query WoProductAttachments($productId: ID!) {
			product(id: $productId) {
				attachments {
					file {
						path
						type
					}
				}
			}
		}
	`)
	// #endregion Printing
	onMount(() => {
		const breakpoint = getBootstrapBreakpoint()
		if (['xs', 'sm', 'md'].includes(breakpoint)) {
			$sampleViewMode = 'LIST'
		} else if (!$sampleViewMode) {
			$sampleViewMode = 'TABLE'
		}

		if (workOrder.id) {
			mediator.call('activity', {
				title: `WO# ${workOrder.id} (${workOrder.title || workOrder.workOrderType.name})`,
				type: 'WORK_ORDER',
				route: 'app.work-order.edit',
				name: translate('workOrder.recentActivityName', 'Work Order'),
				parameters: {
					workOrderId: workOrder.id.toString(),
				},
			})
		}
	})
	// If the first/only change they make on the screen is a sample or attachment, set the WO store so we can save
	const sampleCrudUnsub = sampleCrudStore.subscribe(() => {
		if ($woDocumentStore === null && sampleCrudStore.hasChanges()) {
			woDocumentStore.setFromDocument(workOrder)
		}
	})
	const sampleAttachmentCrudUnsub = sampleAttachmentCrudStore.subscribe(() => {
		if ($woDocumentStore === null && sampleAttachmentCrudStore.hasChanges()) {
			woDocumentStore.setFromDocument(workOrder)
		}
	})

	onDestroy(() => {
		sampleCrudStore.clear()
		woDocumentStore.clear()
		sampleCrudUnsub()
		sampleAttachmentCrudUnsub()
		removeRequiredValuesProvider()
		removeSamplesPerformedProvider()
		removeSamplesClosedProvider()
		removeUpdateSampleStatusesProvider()
		removeWoTypeFieldsMissingProvider()
		removeSamplesCompleteProvider()
	})
</script>

<div class="card-body">
	{#if !timeZoneIsValid}
		<h6 class="text-danger text-center">
			Plant time zone "{$session.plant.timezone}" is not valid and should be changed. Dates may not be shown in the
			correct time zone. Please contact your administrator.
		</h6>
	{/if}
	<CollapsibleCard
		bind:bodyShown={$showWorkOrderDetail}
		cardClass="mb-2"
		entireHeaderToggles
		cardHeaderClass="card-header nowrap d-flex h5"
		headerText=""
	>
		{#snippet cardHeader()}
			<div class="ml-sm-2 mr-auto d-flex flex-wrap">
				<div
					inert
					class="mr-2"
				>
					{workOrder.id ? `WO #${workOrder.id}${workOrder.title ? ` - "${workOrder.title}"` : ''}` : 'Work Order'} Details
				</div>
				<div>
					<span
						class="badge badge-pill"
						class:badge-success={workOrder.documentStatus === 'OPEN'}
						class:badge-primary={workOrder.documentStatus === 'SAMPLED'}
						class:badge-danger={workOrder.documentStatus === 'CLOSED' || workOrder.documentStatus === 'CANCELLED'}
						title={getDocumentStatusHint(translate, workOrder.documentStatus)}
						>{formatDocumentStatus(translate, workOrder.documentStatus)}</span
					>
					{#if workOrder.verifiedOn}
						<span
							class="badge badge-pill badge-info"
							title={translate('workOrder.verifiedBadgeTitle', 'This work order has been inspected and verified')}
							>{translate('workOrder.verifiedBadge', 'Verified')}</span
						>
					{/if}
				</div>
			</div>
		{/snippet}

		{#snippet headerRight()}
			<Button
				outline
				size="sm"
				class="mr-2"
				iconClass="search"
				title={translate('workOrder.woLookupTitle', 'Search for a work order by number')}
				onclick={event => {
					event.stopPropagation()
					const workOrderId = prompt(
						translate('workOrder.woLookupPrompt', 'Enter WO #:'),
						(workOrder.id || '')?.toString(),
					)
					if (workOrderId && workOrderId) {
						asr.go(null, { workOrderId, lastResetTime: Date.now() })
					}
				}}
			></Button>
		{/snippet}
		<div class="form-row">
			<div class="col-12 col-md-6 col-xl-2">
				<Input
					label={translate('workOrder.titleLabel', 'Title')}
					placeholder={translate('workOrder.titlePlaceholder', 'Enter a title for this work order')}
					title={translate(
						'workOrder.titleTitle',
						'An optional title or description of this document for later reference. This is used to label the WO on the home screen and other places.',
					)}
					required={!!workOrder.workOrderType.titleRequired}
					value={workOrder.title}
					disabled={!canEditCurrentWorkOrder || !hasPermission('WORK_ORDERS_CAN_EDIT_TITLE', workOrder.plant.id)}
					validation={{
						validator: value => {
							const translated = translate('workOrder.requiredToClose', 'Required to Close')
							return !!value ? true : typeof translated === 'string' ? translated : 'Required to Close'
						},
					}}
					onchange={event => {
						if (event.target instanceof HTMLInputElement) {
							workOrder.title = event.target.value
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				/>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<Select
					label={translate('workOrder.woTypeLabel', 'WO Type')}
					title={translate(
						'workOrder.woTypeTitle',
						"The work order's type can be set here. This dictates many defaults and settings for the work order.",
					)}
					options={workOrderTypes}
					required
					disabled={!canEditCurrentWorkOrder}
					showAppend={hasPermission('CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES', workOrder.plant.id)}
					showEmptyOption={false}
					bind:value={workOrder.workOrderType}
					onchange={() => {
						woDocumentStore.setFromDocument(workOrder)
						$lastWorkOrderType = workOrder.workOrderType.id
					}}
				>
					{#snippet option({ option })}
						<option value={option}>{option?.name}</option>
					{/snippet}
					{#snippet append()}
						<Button
							outline
							iconClass="cog"
							href={asr.makePath('app.configuration.work-order-type', { plantId: workOrder.plant.id })}
							title={translate('workOrder.configureWoTypesTitle', 'Configure Work Order Types')}
						></Button>
					{/snippet}
				</Select>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				{#if workOrder.workOrderType.productBatchRequired !== 'HIDE'}
					<Autocomplete
						label={translate('workOrder.productBatchLabel', 'Product Batch')}
						title={translate(
							'workOrder.productBatchTitle',
							'(Optional) Choose a product batch for this work order. This will automatically set the product of any samples and add additional batch based thresholds to the calculations.',
						)}
						options={productBatchesLoader(workOrder.plant.id)}
						required={workOrder.workOrderType.productBatchRequired === 'REQUIRE'}
						disabled={!canEditCurrentWorkOrder ||
							(workOrder.productBatch && !hasPermission('WORK_ORDERS_CAN_CHANGE_BATCH', workOrder.plant.id)) ||
							(!workOrder.productBatch && !hasPermission('WORK_ORDERS_CAN_SET_BATCH', workOrder.plant.id))}
						getLabel={option => (option ? `${option?.product?.name} - ${option?.name}` : '')}
						bind:value={workOrder.productBatch}
						change={() => woDocumentStore.setFromDocument(workOrder)}
					></Autocomplete>
				{/if}
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<!--TODO title="Which plant the current work order is taking place at." -->
				<SiteAutocomplete
					label={translate('common:plant', 'Plant')}
					options={plants}
					disabled={!canEditCurrentWorkOrder}
					bind:value={workOrder.plant}
					change={() => woDocumentStore.setFromDocument(workOrder)}
				/>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<Select
					label={translate('workOrder.assignToGroupLabel', 'Assign to WO Group')}
					emptyText={translate('workOrder.assignToGroupEmptyText', 'All Users')}
					title={translate(
						'workOrder.assignToGroupTitle',
						'The group of users this work order is currently assigned to. Generally, only users within this group can edit the work order.',
					)}
					value={workOrder.assignedToGroup?.id ?? null}
					disabled={!canEditCurrentWorkOrder}
					onchange={event => {
						if (event.target instanceof HTMLSelectElement) {
							const value = parseInt(event.target.value, 10)
							workOrder.assignedToGroup = value ? (workerGroups.find(group => group.id === value) ?? null) : null
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				>
					{#each workerGroups as option}
						<option value={option?.id}>{option?.name}</option>
					{/each}
					{#if workOrder.assignedToGroup && !workerGroups.find(group => group.id === workOrder.assignedToGroup?.id)}
						<option
							disabled
							value={workOrder.assignedToGroup.id}>{workOrder.assignedToGroup.name}</option
						>
					{/if}
				</Select>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<Select
					label={translate('workOrder.statusLabel', 'Status')}
					title={translate('workOrder.statusTitle', 'Set the status of all or some of the items on this work order.')}
					options={workOrderStatuses}
					showEmptyOption={false}
					bind:value={workOrder.documentStatus}
					onchange={() => {
						if (
							workOrder.documentStatus === 'CLOSED' &&
							!confirm(
								translate(
									'workOrder.confirmCloseWo',
									'Are you sure you want to close this work order and all samples on it?',
								),
							)
						) {
							workOrder.documentStatus = initialDocumentStatus
							return
						} else if (workOrder.documentStatus === 'CLOSED') {
							updateSampleStatuses('CLOSED')
						}
						woDocumentStore.setFromDocument(workOrder, true)
					}}
					disabled={(initialDocumentStatus !== 'CLOSED' && !canEditCurrentWorkOrder) ||
						(initialDocumentStatus === 'CLOSED' && !hasPermission('WORK_ORDERS_CAN_REOPEN', workOrder.plant.id))}
				>
					{#snippet option({ option })}
						<option value={option}>{option && formatDocumentStatus(translate, option)} </option>
					{/snippet}
				</Select>
			</div>
		</div>
		<div class="form-row">
			<div class="col-12 col-md-6 col-xl-2">
				<Input
					readonly
					label={translate('workOrder.dateCreatedLabel', 'Date Created')}
					title={translate('workOrder.dateCreatedTitle', 'When the work order was initially created.')}
					type="datetime-local"
					value={formatDateTimeForInput(workOrder.dateCreated, $session.plant.timezone)}
					disabled={!canEditCurrentWorkOrder}
					onchange={event => setWoDateFieldFromInput(event, 'dateCreated')}
				></Input>
				<Input
					label={translate('workOrder.dateScheduledLabel', 'Date Scheduled')}
					title={translate(
						'workOrder.dateScheduledTitle',
						"The scheduled date/time when the work order should be performed. Note: if this work order gets cloned, the new work order's scheduled date/time will be moved to the time of copy, and all other date/time values will be based on the new scheduled date/time.",
					)}
					type="datetime-local"
					value={formatDateTimeForInput(workOrder.scheduled, $session.plant.timezone)}
					disabled={!canEditCurrentWorkOrder}
					onchange={event => setWoDateFieldFromInput(event, 'scheduled')}
				></Input>
			</div>
			{#if workOrder.workOrderType.showDue || workOrder.workOrderType.verificationRequired}
				<div class="col-12 col-md-6 col-xl-2">
					{#if workOrder.workOrderType.showDue}
						<Input
							label={translate('workOrder.dateDueLabel', 'Date Due')}
							title={translate('workOrder.dateDueTitle', 'The date the work order is due and must be completed by.')}
							hint={workOrder.due && new Date() > new Date(workOrder.due)
								? translate('workOrder.pastDue', 'Past due')
								: ''}
							hintClass="text-danger"
							type="datetime-local"
							value={formatDateTimeForInput(workOrder.due, $session.plant.timezone)}
							disabled={!canEditCurrentWorkOrder}
							onchange={event => setWoDateFieldFromInput(event, 'due')}
						></Input>
					{/if}
					{#if workOrder.workOrderType.verificationRequired}
						<Input
							label={translate('workOrder.verificationDueLabel', 'Verification Due')}
							title={translate(
								'workOrder.verificationDueTitle',
								'The date the work order must be completed and verified by.',
							)}
							hint={workOrder.verificationDue && new Date() > new Date(workOrder.verificationDue)
								? translate('workOrder.pastDue', 'Past due')
								: ''}
							hintClass={'text-danger'}
							type="datetime-local"
							value={formatDateTimeForInput(workOrder.verificationDue, $session.plant.timezone)}
							disabled={!canEditCurrentWorkOrder}
							onchange={event => setWoDateFieldFromInput(event, 'verificationDue')}
						></Input>
					{/if}
				</div>
			{/if}
			<div class="col-12 col-md-6 col-xl-2">
				<div class="label-padding">
					<Checkbox
						label={translate('workOrder.verifyLabel', 'Verified by:')}
						title={translate(
							'workOrder.verifyTitle',
							'Check this box to certify that this work order has been inspected and verified.',
						)}
						checked={!!workOrder.verifiedByUser?.id}
						disabled={!canEditCurrentWorkOrder || !hasPermission('WORK_ORDERS_CAN_VERIFY', workOrder.plant.id)}
						required={workOrder.workOrderType.verificationRequired}
						onchange={onVerifiedChange}
					></Checkbox>
				</div>
				<Input
					readonly
					labelParentClass="mb-1"
					title={translate(
						'workOrder.verifiedByTitle',
						'The user that inspected and verified this work order (if applicable).',
					)}
					showLabel={false}
					value={workOrder.verifiedByUser?.fullName ??
						translate('workOrder.notVerified', 'Not Verified', {
							context: workOrder.workOrderType.verificationRequired ? 'required' : undefined,
						})}
				></Input>
				<Input
					readonly
					label={translate('workOrder.verifiedOnLabel', 'On:')}
					title={translate(
						'workOrder.verifiedOnTitle',
						'The date on which this work order was inspected and verified (if applicable).',
					)}
					type="datetime-local"
					value={formatDateTimeForInput(workOrder.verifiedOn, $session.plant.timezone)}
				></Input>
			</div>
			<div
				class="col-12 col-md-6"
				class:col-xl-3={workOrder.workOrderType.showDue || workOrder.workOrderType.verificationRequired}
				class:col-xl-4={!workOrder.workOrderType.showDue && !workOrder.workOrderType.verificationRequired}
			>
				<Textarea
					class="flex-grow-1"
					labelParentClass="h-100 d-flex flex-column"
					label={translate('workOrder.internalNotesLabel', 'Internal Notes')}
					title={translate(
						'workOrder.internalNotesTitle',
						'The internal notes of a work order track any information that should be kept internal to the software.',
					)}
					value={workOrder.internalNotes}
					disabled={!canEditCurrentWorkOrder}
					onchange={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							workOrder.internalNotes = event.target.value
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				></Textarea>
			</div>
			<div
				class="col-12 col-md-6"
				class:col-xl-3={workOrder.workOrderType.showDue || workOrder.workOrderType.verificationRequired}
				class:col-xl-4={!workOrder.workOrderType.showDue && !workOrder.workOrderType.verificationRequired}
			>
				<Textarea
					class="flex-grow-1"
					labelParentClass="h-100 d-flex flex-column"
					label={translate('workOrder.samplingInstructionsLabel', 'Sampling Instructions')}
					title={translate(
						'workOrder.samplingInstructionsTitle',
						'The sampling instructions can contain any information to be given to the individuals performing the work order.',
					)}
					value={workOrder.instructions}
					disabled={!canEditCurrentWorkOrder}
					onchange={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							workOrder.instructions = event.target.value
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				></Textarea>
			</div>
		</div>
	</CollapsibleCard>

	<div class="card">
		<div class="card-header d-flex justify-content-between">
			<div class="w-100">
				<NavTabBar
					breakpoint={false}
					tabs={[
						{
							name: 'TESTING',
							title: translate('workOrder.samplesCount', 'Samples ({{sampleCount}})', {
								sampleCount: testingSamplesCount,
							}),
						},
						{
							name: 'RECIPE',
							title: translate('workOrder.recipesCount', 'Recipes ({{recipeCount}})', {
								recipeCount: productionSamplesCount,
							}),
						},
						{ name: 'CHANGELOG', title: translate('workOrder.changelogLabel', 'Changelog') },
					]}
					{selectedTab}
					on:tabChange={event => {
						if (event.detail === 'TESTING' || event.detail === 'RECIPE' || event.detail === 'CHANGELOG') {
							selectedTab = event.detail
							// Will not change state, this is just to update the URL
							asr.go(null, { selectedTab }, { inherit: true, replace: true })
							// I can't think of any reason we'd want multiple types of samples selected; clearing them is easier than handling that.
							selectedSample = null
							selectedRowIds = []
						}
					}}
				>
					<svelte:fragment slot="append">
						<!-- {#snippet append()} -->
						<div class="ml-auto">
							<Checkbox
								inline
								type="radio"
								label={translate('workOrder.viewButtonLabel', 'View')}
								trueLabel=""
								trueIcon="table"
								falseLabel=""
								falseIcon="list"
								labelClass="d-none d-sm-inline-block"
								title={translate(
									'workOrder.viewButtonTitle',
									'Whether to view the samples in a table or a list view. List view is recommended for mobile devices.',
								)}
								checked={$sampleViewMode === 'TABLE'}
								onchange={event => {
									if (event.target instanceof HTMLInputElement) {
										$sampleViewMode = stringToBoolean(event.target.value) ? 'TABLE' : 'LIST'
									}
									woDocumentStore.setFromDocument(workOrder)
								}}
							></Checkbox>
						</div>
						<!-- {/snippet} -->
					</svelte:fragment>
				</NavTabBar>
			</div>
		</div>
		<div
			class="card-body"
			class:p-0={$sampleViewMode === 'LIST'}
		>
			{#if selectedTab === 'TESTING' && $sampleViewMode === 'TABLE'}
				<SampleTable
					{samplesPerPage}
					{optionsColumns}
					mode="TESTING"
					bind:workOrder
					bind:selectedRowIds
					bind:selectedSample
					bind:contextMenuSample
					{showProductLocationImages}
					{displaySamples}
					{closedSampleCount}
					{initialSampleStatuses}
					{sampleContextMenu}
					{hasMultipleAnalyses}
					{hasMultiplePlants}
					bind:showAttachmentsModal
					{canEditCurrentWorkOrder}
					{optionsToShow}
				></SampleTable>
			{:else if selectedTab === 'TESTING' && $sampleViewMode === 'LIST'}
				<SampleListGroup
					mode="TESTING"
					bind:workOrder
					bind:selectedRowIds
					bind:showClosedSamples={$showClosedSamples}
					{showProductLocationImages}
				></SampleListGroup>
			{:else if selectedTab === 'RECIPE'}
				<ProductionSamples
					{samplesPerPage}
					{optionsColumns}
					bind:workOrder
					bind:selectedRowIds
					bind:selectedSample
					bind:contextMenuSample
					{showProductLocationImages}
					{displaySamples}
					{closedSampleCount}
					{initialSampleStatuses}
					{sampleContextMenu}
					{hasMultipleAnalyses}
					bind:showAttachmentsModal
					{canEditCurrentWorkOrder}
					{optionsToShow}
					{hasMultiplePlants}
				></ProductionSamples>
			{:else if selectedTab === 'CHANGELOG'}
				<Changelog
					mode={$sampleViewMode ?? 'TABLE'}
					workOrderId={workOrder.id}
					{userAccounts}
					bind:showHistoricalValues={changeLogShowHistoricalValues}
					bind:statuses={changelogStatuses}
					bind:dates={changelogDates}
				></Changelog>
			{/if}
		</div>
		{#if selectedTab === 'TESTING' || selectedTab === 'RECIPE'}
			<TableCardFooter
				showCount={false}
				itemDescriptor={translate('workOrder.footerSamplesLabel', 'Samples')}
				exportFileName="work-order-{workOrder.id ?? 'unsaved'}-{workOrder.title ??
					workOrder.workOrderType.name}-samples"
				idKey="uuid"
				clearSelectionButtonClass="mobile-button-100"
				exportButtonClass="mobile-button-100"
				items={buildCsvItems(workOrder.samples)}
				footerClass="flex-column flex-sm-row"
				bind:selectedIds={selectedRowIds}
				bind:this={tableCardFooter}
			>
				<Button
					outline
					color="success"
					size="sm"
					iconClass="plus"
					class="mobile-button-100"
					title={translate('workOrder.newSampleTitle', 'Create a New Sample')}
					disabled={!canEditCurrentWorkOrder}
					onclick={newSampleClick}
					>{translate('workOrder.newSampleLabel', 'New Sample')}...
				</Button>
				<Button
					outline
					color="danger"
					size="sm"
					iconClass="trash"
					class="mobile-button-100"
					disabled={!selectedRowIds.length || !canDeleteSelectedSamples(selectedRowIds)}
					title={translate(
						'workOrder.deleteSampleTitle',
						'Delete the selected sample(s) from the system? This is permanent and cannot be undone.',
					)}
					onclick={() => {
						if (
							confirm(
								selectedRowIds.length > 1
									? translate(
											'workOrder.deleteSelectedSamplesConfirm',
											`Are you sure you want to delete {{sampleCount}} samples?`,
											{ sampleCount: selectedRowIds.length },
										)
									: translate(
											'workOrder.deleteSelectedSampleConfirm',
											'Are you sure you want to delete the selected sample?',
										),
							) &&
							confirmActionThatRequiresReVerify()
						) {
							const indeces = selectedRowIds.map(uuid => workOrder.samples.findIndex(sample => sample.uuid === uuid))
							const samplesToDelete = indeces.map(index => workOrder.samples[index])
							samplesToDelete.forEach(sample => {
								if (sample.id) {
									sampleCrudStore.delete(sample)
								}
							})
							indeces.forEach(index => {
								workOrder.samples.splice(index, 1)
								workOrder.samples = workOrder.samples
							})
							selectedRowIds = []
						}
					}}
					>{selectedRowIds.length > 1
						? translate('workOrder.deleteSamples', 'Delete Samples')
						: translate('workOrder.deleteSample', 'Delete Sample')}...
				</Button>
				<Button
					outline
					size="sm"
					iconClass="check-double"
					class="mobile-button-100"
					disabled={!displaySamples.length}
					onclick={() => {
						if (selectedRowIds.length === workOrder.samples.length) {
							selectedRowIds = []
						} else {
							selectedRowIds = workOrder.samples.map(sample => sample.uuid)
						}
					}}
				>
					{selectedRowIds.length && selectedRowIds.length === workOrder.samples.length
						? translate('workOrder.selectNone', 'Select None')
						: translate('workOrder.selectAll', 'Select All')}
				</Button>

				<svelte:fragment slot="right">
					<!-- {#snippet right()} -->
					{#if $sampleViewMode === 'TABLE'}
						<Button
							outline
							size="sm"
							iconClass="paperclip"
							title={translate(
								'workOrder.attachmentsButtonTitle',
								'Open the attachment interface, where you can view and manage attachments for the selected sample.',
							)}
							class="mobile-button-100"
							disabled={!selectedSample ||
								!selectedSample.plant ||
								!hasPermission('WORK_ORDERS_CAN_EDIT', selectedSample.plant.id)}
							onclick={() => (showAttachmentsModal = true)}
						>
							{translate('workOrder.attachmentsButtonLabel', 'Attachments')}...
						</Button>
					{/if}
					<!-- 						<Button
								outline
								disabled
								size="sm"
								iconClass="file-magnifying-glass"
								title="Investigation screen has not been implemented yet"
								href={asr.makePath(null)}
								>Begin Investigation...
							</Button> -->
					<!-- {/snippet} -->
				</svelte:fragment>
			</TableCardFooter>
		{/if}
	</div>
</div>
<div class="card-footer d-flex justify-content-between flex-fill border-bottom">
	<Dropdown
		split
		outline
		size="sm"
		color="success"
		iconClass="plus"
		href={asr.makePath(null, { workOrderId: null, lastResetTime: Date.now() })}
		disabled={!hasPermission('WORK_ORDERS_CAN_ADD', $session.siteId)}
		>{translate('workOrder.newWorkOrderLabel', 'New Work Order')}
		{#snippet dropdownItems()}
			<DropdownItem
				icon="copy"
				disabled={!workOrder.id}
				onclick={async () => {
					showConfirmCopyModal = true
					await tick()
					document.getElementById('copy-wo-and-values-button')?.focus()
				}}>{translate('workOrder.cloneWorkOrderLabel', 'Clone Work Order')}</DropdownItem
			>
		{/snippet}
	</Dropdown>
	<Dropdown
		split
		outline
		size="sm"
		iconClass="print"
		title={translate('workOrder.printTitle', 'Print the current work order, or select a type of sample tag to print.')}
		onclick={() => printWorkOrder()}
	>
		{translate('workOrder.printLabel', 'Print')}
		{#snippet dropdownItems()}
			<DropdownItem
				icon="clipboard"
				onclick={() => printWorkOrder()}>{translate('workOrder.printWoLabel', 'Print WO')}</DropdownItem
			>
			<DropdownItem
				icon="tag"
				title={translate('workOrder.printSampleTagTitle', 'Prints a sample tag for each sample in the work order.')}
				onclick={() => printTags('Work Order Tags', 'ALL')}
				>{translate('workOrder.printSampleTagsLabel', 'Print Sample Tags')}</DropdownItem
			>
			<DropdownItem
				icon="tag"
				title={translate('workOrder.printTestingTagTitle', 'Prints a testing tag for each sample in the work order.')}
				onclick={() => printTags('Work Order Testing Tags', 'ALL')}
				>{translate('workOrder.printTestingTagsLabel', 'Print Testing Tags')}</DropdownItem
			>
		{/snippet}
	</Dropdown>
</div>

<ContextMenu bind:this={sampleContextMenu}>
	<h6 class="dropdown-header">
		{translate('workOrder.sampleTagNumberTitle', 'Sample #{{- tagNumber}}', {
			tagNumber: contextMenuSample?.tagNumber,
		})}
	</h6>
	<DropdownItem
		icon="print"
		onclick={() => printTags('Work Order Tags', 'SINGLE')}
		>{translate('workOrder.printSampleTagLabel', 'Print Sample Tag')}</DropdownItem
	>
	<DropdownItem
		icon="print"
		onclick={() => printTags('Work Order Testing Tags', 'SINGLE')}
		>{translate('workOrder.printTestingTagLabel', 'Print Testing Tag')}</DropdownItem
	>
	<!-- <DropdownItem disabled>Begin Investigation</DropdownItem> -->
	<DropdownItem
		icon="file-csv"
		onclick={() => tableCardFooter?.downloadCsv()}
		>{translate('workOrder.exportAllToCsvLabel', 'Export All Samples to CSV')}</DropdownItem
	>
	<DropdownItem
		icon="file-csv"
		disabled={!selectedRowIds.length}
		onclick={() => tableCardFooter?.downloadSelectedCsv()}
		>{translate('workOrder.exportSelectedToCsvLabel', 'Export Selected Samples to CSV')}</DropdownItem
	>
</ContextMenu>

<Modal
	modalId="confirm-copy-modal"
	bind:show={showConfirmCopyModal}
	title={translate('workOrder.confirmCloneTitle', 'Confirm Manual Work Order Copy')}
	modalDialogClass="min-width-fit-content"
	confirmShown={false}
	cancelShown={false}
>
	<div class="text-center">
		{translate('workOrder.confirmCloneSubtitle', 'Manually copy the current work order document?')}
	</div>

	{#snippet footer()}
		<div>
			<Button
				id="copy-wo-and-values-button"
				outline
				class="mobile-button-100"
				iconClass="folder-plus"
				onclick={() => {
					cloneWo(true)
				}}>{translate('workOrder.copyWoValuesLabel', 'Copy WO and Values')}</Button
			>
			<Button
				outline
				class="mobile-button-100"
				iconClass="folder"
				onclick={() => {
					cloneWo()
				}}>{translate('workOrder.copyWoLabel', 'Copy WO Only')}</Button
			>
			<Button
				color="secondary"
				class="mobile-button-100"
				iconClass="xmark"
				onclick={() => {
					showConfirmCopyModal = false
				}}>{translate('workOrder.cancelLabel', 'Cancel')}</Button
			>
		</div>
	{/snippet}
</Modal>

<Modal
	closeShown={false}
	cancelShown={false}
	title={translate('workOrder.attachmentsTitle', 'Attachments')}
	backdropClickCancels={false}
	bind:show={showAttachmentsModal}
	modalSize="xxl"
	confirm={() => (showAttachmentsModal = false)}
>
	{#if selectedSample}
		<SampleAttachments
			sample={selectedSample}
			attachmentChange={({ attachments }) => {
				if (selectedSample) {
					selectedSample.attachments = attachments
					const index = workOrder.samples.findIndex(sample => selectedSample && sample.uuid === selectedSample.uuid)
					if (index !== -1) {
						workOrder.samples[index].attachments = attachments
					}
				}
			}}
		></SampleAttachments>
	{:else}
		<div class="text-center">{translate('workOrder.noSampleSelected', 'No Sample Selected.')}</div>
	{/if}
</Modal>

<Modal
	modalId="new-sample-modal"
	bind:show={showNewSampleModal}
	title={translate('workOrder.newSampleTitle', 'New Sample')}
	confirmButtonDisabled={!newSampleTemplate.analysis}
	confirm={() => {
		newSample(newSampleTemplate)
		showNewSampleModal = false
	}}
	close={() => {
		showNewSampleModal = false
	}}
>
	<Autocomplete
		required
		label={translate('workOrder.analysisLabel', 'Analysis')}
		id="new-sample-analysis"
		options={getApplicableAnalyses(workOrder.plant.id)}
		getLabel={option => option?.name ?? ''}
		bind:value={newSampleTemplate.analysis}
		change={async () => {
			if (newSampleTemplate.analysis?.createdProductId) {
				newSampleTemplate.product = (await productsLoader(workOrder.plant.id)).find(
					product => product.id === newSampleTemplate.analysis?.createdProductId,
				)
			}

			if (newSampleTemplate.analysis?.batchVolume) {
				newSampleTemplate.productionVolume = newSampleTemplate.analysis?.batchVolume
			}
		}}
	></Autocomplete>
	{#if workOrder.workOrderType.showProduct !== 'NONE' && samplesMode}
		<Autocomplete
			label={translate('workOrder.productLabel', 'Product')}
			options={productsLoader(workOrder.plant.id)}
			getLabel={option => option?.name ?? ''}
			emptyValue={null}
			bind:value={newSampleTemplate.product}
		></Autocomplete>
	{/if}
	{#if workOrder.workOrderType.showLocation}
		<Autocomplete
			label={translate('workOrder.locationLabel', 'Location')}
			options={locationsLoader(workOrder.plant.id)}
			emptyValue={null}
			getLabel={option => option?.location ?? ''}
			bind:value={newSampleTemplate.location}
		></Autocomplete>
		{#if workOrder.workOrderType.showLocationDescription && newSampleTemplate.location?.description}
			{newSampleTemplate.location?.description}
		{/if}
	{/if}
	{#if recipesMode}
		<Input
			readonly
			label="Batch Volume"
			title="The quantity produced in a single batch of this recipe"
			showAppend={!!newSampleTemplate.analysis?.batchUnit}
			value={newSampleTemplate.analysis?.batchVolume ?? ''}
		>
			{#snippet append()}
				<span class="input-group-text">{newSampleTemplate.analysis?.batchUnit}</span>
			{/snippet}
		</Input>
		<Input
			label="Production Volume"
			title="The quantity being produced in this production run"
			showAppend={!!newSampleTemplate.analysis?.batchUnit}
			bind:value={() => newSampleTemplate.productionVolume ?? 0, v => (newSampleTemplate.productionVolume = v)}
		>
			{#snippet append()}
				<span class="input-group-text">{newSampleTemplate.analysis?.batchUnit}</span>
			{/snippet}
		</Input>
	{/if}
</Modal>

<ImageViewer
	title={translate('workOrder.entityImagesTitle', '{{- entityName}} Images', {
		entityName: productLocationAttachmentModal.entityName,
	})}
	files={productLocationAttachmentModal.files}
	bind:show={productLocationAttachmentModal.show}
	bind:currentPhotoIndex={productLocationAttachmentModal.currentPhotoIndex}
/>

<ReportJobModal bind:this={reportJobModal}></ReportJobModal>

<style>
	.label-padding {
		padding-top: calc(0.375rem + 1px);
		padding-bottom: calc(0.375rem + 1px);
	}

	:global(.min-width-fit-content) {
		min-width: fit-content;
	}

	:global(.form-group.product-location-select .input-group) {
		flex-wrap: nowrap !important;
	}

	:global(.form-group.product-location-select select),
	:global(.form-group.product-location-select input) {
		min-width: 145px !important;
	}

	:global(#sample-table td) {
		vertical-align: bottom !important;
	}

	:global(#sample-table td .form-group) {
		margin-bottom: 0 !important;
	}
</style>
