<script lang="ts">
	import type { Merge } from 'type-fest'
	import type { SaveResetProps } from '../configuration'
	import type { i18n, SvelteAsr, Mediator } from 'types/common'
	import type { UserAccount, Group, GroupPermissionMap } from './user'
	import type { PasswordValidationRules } from 'utility/get-password-rules'
	import type { PermissionValueMap } from '@isoftdata/svelte-user-configuration'
	import type { PlantsForDropdown$result, UserConfigurationData$result, UserAccountCreate$input } from '$houdini'

	//TODO remove this type if Presage Web ever changes to have Houdini handle marshal/unmarshal of DateTime scalers as lastLoginDate will already be a JS Date object
	type UserAccountList = Array<
		Merge<UserConfigurationData$result['userAccounts']['data'][number], { lastLoginDate: Date | null }>
	>

	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { graphql } from '$houdini'
	import session from 'stores/session'
	import toTitleCase from 'to-title-case'
	import { v4 as uuid } from '@lukeed/uuid'
	import { getContext, onMount } from 'svelte'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Fieldset from '@isoftdata/svelte-fieldset'
	import { getEventValue } from '@isoftdata/browser-event'
	import { SvelteSet, SvelteMap } from 'svelte/reactivity'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import { UserConfiguration, getGroupHighestPermissionValueMap } from '@isoftdata/svelte-user-configuration'

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const absoluteDateTimeFormatter = (date: Date) =>
		new Intl.DateTimeFormat(i18next.language || 'en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)
	const sortUserAccountList = (userAccounts: UserAccountList) =>
		userAccounts.slice().sort((a, b) => a.name.localeCompare(b.name))

	interface Props {
		plants: PlantsForDropdown$result['plants']['data']
		groups: Array<Group>
		permissions: UserConfigurationData$result['permissions']['data']
		userAccounts: UserAccountList
		selectedPlantId: number | null
		userAccount: UserAccount
		hasPermissionToChangePassword?: boolean
		manageAPIKeyPermissionLevel?: string
		asr: SvelteAsr
		permissionValueMap?: PermissionValueMap
		groupPermissionMap?: GroupPermissionMap
		authorizedSitesSet?: Set<number>
		groupMembershipSet?: Set<number>
		i18next: i18n
		saveResetProps: SaveResetProps
		passwordValidationRules: PasswordValidationRules
	}

	let {
		plants,
		groups,
		permissions,
		userAccounts,
		selectedPlantId,
		userAccount = $bindable(),
		hasPermissionToChangePassword = false,
		manageAPIKeyPermissionLevel = 'NONE',
		asr,
		permissionValueMap = $bindable(new SvelteMap()),
		groupPermissionMap = new SvelteMap(),
		authorizedSitesSet = $bindable(new SvelteSet<number>()),
		groupMembershipSet = $bindable(new SvelteSet<number>()),
		i18next,
		saveResetProps,
		passwordValidationRules,
	}: Props = $props()

	export function canLeaveState() {
		if (hasUnsavedChanges) {
			return confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		}
		return true
	}

	//Make a frozen deep copy of the mutable data so we can compare it later to see if there are any changes
	let originalData = $state(
		Object.freeze(
			klona({
				userAccount,
				authorizedSitesSet,
				groupMembershipSet,
				permissionValueMap,
			}),
		),
	)

	let showApiKeyManagement: boolean = $state(false)
	let apiTokenInput: HTMLInputElement | undefined = $state()
	let usernameInput: HTMLInputElement | undefined = $state()
	let sendPasswordRecoveryToken: boolean = $state(false)

	function getUserAccountForApi(userAccount: UserAccount): UserAccountCreate$input['input'] {
		return {
			firstName: userAccount.firstName,
			lastName: userAccount.lastName,
			name: userAccount.name,
			userGroupIds: Array.from(groupMembershipSet),
			authorizedPlantIds: Array.from(authorizedSitesSet),
			userPermissions: Array.from(permissionValueMap).map(([permissionId, permissionValue]) => ({
				permissionId,
				permissionValue: permissionValue === 'SITE' ? 'PLANT' : permissionValue,
			})),
			lockNotes: userAccount.lockNotes,
			workEmail: userAccount.workEmail,
			apiToken: userAccount.apiToken ?? null,
		}
	}

	async function createNewUserAccount(userAccount: UserAccount, hasPermissionToChangePassword: boolean) {
		if (!userAccount.name) {
			throw new Error(
				translate('configuration.user.usernameRequiredError', 'You must set a username to create a new user account.'),
			)
		}
		if (hasPermissionToChangePassword && !userAccount.newPassword) {
			throw new Error(
				translate(
					'configuration.user.newPasswordRequiredError',
					'You must set a password to create a new user account.',
				),
			)
		}

		const res = await createNewUserAccountMutation.mutate({
			input: {
				...getUserAccountForApi(userAccount),
				newPassword: userAccount.newPassword ?? null,
			},
		})

		return res.data?.createUserAccount.id
	}

	async function updateUserAccount(userAccount: UserAccount, sendPasswordRecoveryToken: boolean) {
		if (userAccount.id) {
			await updateUserAccountMutation.mutate({
				input: {
					...getUserAccountForApi(userAccount),
					id: userAccount.id,
					sendPasswordRecoveryToken,
					status: userAccount.status,
				},
			})
		}
	}

	async function generateNewActivationPIN(userName: UserAccount['name'], hasWorkEmail: boolean) {
		const res = await generateNewActivationPINMutation.mutate({
			inputs: {
				userName,
				hasWorkEmail,
			},
		})

		return res.data?.generateNewActivationPIN
	}

	function error({ heading, message }: { heading: string; message: string }) {
		mediator.call('showMessage', { heading, color: 'danger', time: false, message })
	}

	function success({ heading, message }: { heading: string; message: string }) {
		mediator.call('showMessage', { heading, color: 'success', time: 3, message })
	}

	function generateNewAPIKey() {
		const existingToken = userAccount.apiToken
		const wantsNewToken =
			existingToken &&
			confirm(
				translate(
					'configuration.user.generateNewAPIKEYPromptMessage',
					'This account has a valid API key, generating a new one will deactivate the previous one. Are you sure you want to continue?',
				),
			)

		if (!existingToken || wantsNewToken) {
			userAccount.apiToken = uuid()
		}
	}

	function copyTextToClipboard() {
		if (apiTokenInput) {
			navigator.clipboard.writeText(apiTokenInput?.value)
			mediator.call('showMessage', {
				heading: translate('common:copiedToClipboard', 'Copied to clipboard successfully'),
				color: 'success',
				time: 3,
			})
		}
	}

	function deactivateAPIKey() {
		if (
			confirm(
				translate(
					'configuration.user.deactivateAPIKeyPromptMessage',
					'Are you sure to deactivate the current API key? (Future access attempts with this key will fail)',
				),
			)
		) {
			userAccount.apiToken = null
		}
	}

	function getUserAccountsGroupedByStatus(userAccounts: UserAccountList) {
		const groupedUserAccount = sortUserAccountList(userAccounts).reduce(
			(acc, userAccount) => {
				const status = toTitleCase(userAccount.status)
				if (!acc[status]) {
					acc[status] = []
				}

				acc[status].push(userAccount)
				return acc
			},
			{} as Record<string, UserAccountList>,
		)

		// sort groupedUserAccount by status in ascending order
		return Object.keys(groupedUserAccount)
			.sort()
			.reduce(
				(acc, status) => {
					acc[status] = groupedUserAccount[status]
					return acc
				},
				{} as Record<string, UserAccountList>,
			)
	}

	async function saveChanges() {
		try {
			let asrParams: { lastSavedTime: number | null; lastResetTime: number | null; userAccountId?: number } = {
				lastSavedTime: Date.now(),
				lastResetTime: null,
			}
			if (userAccount.id === null) {
				const userAccountId = await createNewUserAccount(userAccount, hasPermissionToChangePassword)
				asrParams.userAccountId = userAccountId
			} else {
				await updateUserAccount(userAccount, sendPasswordRecoveryToken)
			}
			//Reset the originalData so we don't have unsaved changes before we call go on asr
			originalData = Object.freeze(
				klona({
					userAccount,
					authorizedSitesSet,
					groupMembershipSet,
					permissionValueMap,
				}),
			)
			asr.go(null, asrParams, { inherit: true })
		} catch (err) {
			if (err instanceof Error) {
				error({ heading: translate('common:error', 'Error'), message: err.message })
			} else {
				console.error(err)
			}
		}
	}

	const createNewUserAccountMutation = graphql(`
		mutation UserAccountCreate($input: NewUserAccount!) {
			createUserAccount(input: $input) {
				...UserAccountFields
			}
		}
	`)

	const updateUserAccountMutation = graphql(`
		mutation UserAccountUpdate($input: UpdateUserAccount!) {
			updateUserAccount(input: $input) {
				...UserAccountFields
			}
		}
	`)

	const generateNewActivationPINMutation = graphql(`
		mutation ActivationPINGenerate($inputs: GenerateNewActivationPIN!) {
			generateNewActivationPIN(inputs: $inputs) {
				activationPIN
				activationPINExpiration
			}
		}
	`)

	onMount(() => {
		if (userAccount.id === null) {
			usernameInput?.focus()
		}
	})
	let userAccountsGroupedByStatus = $derived(getUserAccountsGroupedByStatus(userAccounts))
	let userSites = $derived(
		plants.map(({ id, code, name }) => {
			return { id, code, name, isAuthorized: authorizedSitesSet.has(id) }
		}),
	)
	let groupMembership = $derived(
		groups.map(({ id, name }) => {
			return { id, name, isMember: groupMembershipSet.has(id) }
		}),
	)
	let groupPermissionValueMap = $derived(getGroupHighestPermissionValueMap(groupMembershipSet, groupPermissionMap))
	let hasUnsavedChanges = $derived(
		!dequal(originalData, {
			userAccount,
			authorizedSitesSet,
			groupMembershipSet,
			permissionValueMap,
		}),
	)

	$effect(() => {
		$saveResetProps = {
			save: saveChanges,
			disabled: !hasUnsavedChanges,
			resetHref: asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true }),
		}
	})
</script>

<div class="form-row flex-wrap align-items-end mb-2">
	<div class="col-sm-6 col-md-6 col-lg-3">
		<SiteAutocomplete
			label={translate('common:plant', 'Plant')}
			showEmptyOption
			emptyText={translate('configuration.user.accountInfo.allPlantsPlaceholder', 'All Plants')}
			emptyValue={null}
			options={plants}
			value={selectedPlantId ? (plants.find(plant => plant.id === selectedPlantId) ?? null) : null}
			change={plant => {
				//null plantId means "any plant". If we dont't pass the plantId, the state will default to the session plant id
				asr.go(null, { plantId: plant?.id ?? null }, { inherit: true })
			}}
		/>
	</div>
	<div class="col-12 col-sm-6 col-md-6 col-lg-4">
		<Select
			label={translate('common:userAccount', 'User Account')}
			value={userAccount.id}
			onchange={e => asr.go(null, { userAccountId: getEventValue(e) }, { inherit: true })}
			emptyText={userAccount.id === null
				? userAccount.name
					? userAccount.name
					: translate('configuration.user.newAccount', 'New Account')
				: `-- ${translate('configuration.user.selectUserAccount', 'Select User Account')} --`}
		>
			{#each Object.keys(userAccountsGroupedByStatus) as statusKey}
				<optgroup label={translate(`configuration.user.status.${statusKey}`, statusKey)}>
					{#each userAccountsGroupedByStatus[statusKey] as { id, name, lastLoginDate }}
						<option value={id}
							>{name}{$session.userAccountId === id ? ` (${translate('common:you', 'You')})` : ''}{lastLoginDate
								? ` - ${translate('configuration.user.lastLoginDate', {
										defaultValue: 'Last Accessed on {{- lastLoginDate}}',
										lastLoginDate: absoluteDateTimeFormatter(lastLoginDate),
									})}`
								: ''}</option
						>
					{/each}
				</optgroup>
			{/each}
		</Select>
	</div>
	<div class="col-12 col-sm-auto mt-2 mt-lg-0 mb-1">
		{#if userAccount.id !== null}
			<Button
				outline
				size="sm"
				color="success"
				iconClass="plus"
				onclick={() => {
					asr.go(null, { userAccountId: null }, { inherit: true })
				}}>{translate('configuration.user.newAccount', 'New Account')}</Button
			>
		{/if}
	</div>
</div>

<UserConfiguration
	siteLabel={translate('common:plant', 'Plant')}
	bind:userAccount
	bind:usernameInput
	bind:doSendPasswordRecoveryToken={sendPasswordRecoveryToken}
	{userSites}
	{groupMembership}
	{permissions}
	{permissionValueMap}
	{groupPermissionValueMap}
	{hasPermissionToChangePassword}
	{passwordValidationRules}
	confirmPasswordSet={async ({ newPassword }) => {
		if (userAccount.id) {
			await updateUserAccountMutation.mutate({
				input: {
					id: userAccount.id,
					newPassword,
				},
			})
		} else {
			userAccount.newPassword = newPassword
		}
	}}
	{error}
	{success}
	generateNewActivationPIN={async (username, hasWorkEmail) => {
		const userActivationData = await generateNewActivationPIN(username, hasWorkEmail)
		if (userActivationData) {
			const { activationPIN, activationPINExpiration } = userActivationData
			userAccount.userActivationData = {
				activationPIN,
				activationPINExpiration: activationPINExpiration ? new Date(activationPINExpiration) : null,
			}
		} else {
			userAccount.userActivationData = null
		}
	}}
	siteAccessChange={async site => {
		site.isAuthorized ? authorizedSitesSet.add(site.id) : authorizedSitesSet.delete(site.id)
		authorizedSitesSet = new Set(authorizedSitesSet)
	}}
	groupMembershipChange={async ({ id, isMember }) => {
		//The user can't adjust the group permissions in this UI, and we've got the permissions for each group in a Map in memory, so we just need to update their groupMembershipSet here
		isMember ? groupMembershipSet.add(id) : groupMembershipSet.delete(id)
		//Just doing mySet = mySet doesn't seem to trigger a reactivity update, so we need to create a new Set. Ugh.
		//This was the only workaround I could find that worked. I also tried using SvelteSet, and writing groupMembershipSet to a tmp var and then assigning that tmp to groupMembershipSet, but neither worked.
		//But also I tried to reproduce this in a Svelte 5 playground and could not, so I'm not sure what's going on here.
		groupMembershipSet = new Set(groupMembershipSet)
	}}
	permissionValueChange={async ({ permissionIds, value }) => {
		for (const id of permissionIds) {
			permissionValueMap.set(id, value)
		}
		permissionValueMap = new Map(permissionValueMap)
	}}
>
	{#snippet userAccountInfo()}
		{#if manageAPIKeyPermissionLevel === 'GLOBAL'}
			{#if !showApiKeyManagement}
				<Button
					size="sm"
					color="link"
					class="p-0 mt-2"
					onclick={() => (showApiKeyManagement = true)}
				>
					{translate('configuration.user.manageApiKey', 'Manage API Key')}...
				</Button>
			{:else}
				<Fieldset label={translate('configuration.user.apiKeyManagement', 'API Key Management')}>
					<div class="input-group input-group-sm mb-2">
						<input
							id="apiToken"
							class="form-control"
							bind:value={userAccount.apiToken}
							readonly
							bind:this={apiTokenInput}
						/>
						<div class="input-group-append">
							<Button
								outline
								size="sm"
								iconClass="copy"
								onclick={() => copyTextToClipboard()}
								disabled={!userAccount.apiToken}
								title={translate('configuration.user.copyAPIKey', 'Copy API Key to Clipboard')}
							></Button>
						</div>
					</div>
					<div class="d-flex justify-content-between flex-wrap">
						<Button
							outline
							size="sm"
							iconClass="key"
							onclick={() => generateNewAPIKey()}
						>
							{translate('configuration.user.generateNewAPIKey', 'Generate New')}
						</Button>
						<Button
							outline
							color="danger"
							size="sm"
							iconClass="xmark"
							onclick={() => deactivateAPIKey()}
							disabled={!userAccount.apiToken}
						>
							{translate('configuration.user.deactivateAPIKey', 'Deactivate Key')}
						</Button>
					</div>
				</Fieldset>
			{/if}
		{/if}
	{/snippet}
</UserConfiguration>
