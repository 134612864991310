import type { AppContext } from 'types/common'

import { getSession } from 'stores/session'
import component from './MyAccount.svelte'
import { getPasswordRules } from 'utility/get-password-rules'

export default function ({ stateRouter }: AppContext<typeof component>) {
	stateRouter.addState({
		name: 'app.configuration.my-account',
		route: 'my-account',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		template: component,
		canLeaveState: domApi => {
			return domApi.canLeaveState()
		},
		async resolve(_data, _parameters) {
			const session = getSession()

			return {
				passwordValidationRules: await getPasswordRules(),
				user: {
					id: session.userAccountId,
					name: session.userName,
					lockNotes: '',
					status: 'ACTIVE',
					currentPassword: '',
					newPassword: '',
					confirmPassword: '',
					...session.user,
				},
			}
		},
	})
}
