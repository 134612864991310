export default {
    "name": "WorkOrder",
    "kind": "HoudiniQuery",
    "hash": "d6395d9d59c4bdc74e1b4a30e2ed2e1571704183f2eb746b89941e53a51ffaf8",

    "raw": `query WorkOrder($workOrderId: ID!) {
  workOrder(id: $workOrderId) {
    assignedToGroup {
      id
      name
    }
    dateCreated
    documentStatus
    due
    favorite
    id
    instructions
    internalNotes
    scheduled
    title
    verificationDue
    verifiedOn
    plant {
      id
      code
      name
    }
    productBatch {
      id
      name
      product {
        ...WoProductData
        id
      }
      location {
        ...WoLocationData
        id
      }
    }
    samples {
      id
      plant {
        id
        code
        name
      }
      analysis {
        id
        name
        active
        analysisType
        instructions
        requireAuthentication
        batchUnit
        batchVolume
        createdProductId
      }
      product {
        ...WoProductData
        id
      }
      location {
        ...WoLocationData
        id
      }
      testingComments: findings
      samplingComments: comments
      status
      tagNumber
      scheduled
      due
      performed
      collectedBy {
        id
        fullName
      }
      incubationBegan
      incubationEnded
      investigationId
      platesReadBy {
        id
        fullName
      }
      productionVolume
      sampleValues {
        id
        lot
        sampleId
        result
        resultStatus
        filledOut
        expiration
        defaultValue
        lastModified
        lastModifiedByUser {
          id
          name
        }
        totalQuantity
        createdInvestigationId
        lots {
          id
          created
          quantity
          supplierItemNumber
          productBatch {
            id
            name
            expiration
          }
          product {
            id
            name
          }
        }
        analysisOption {
          ...WoAnalysisOptionData
          id
        }
      }
      attachments {
        fileId
        public
        rank
        file {
          created
          mimeType
          name
          path
          size
          id
        }
        id
      }
    }
    verifiedByUser {
      id
      fullName
    }
    workOrderType {
      ...WorkOrderTypeData
      id
    }
  }
}

fragment WoProductData on Product {
  id
  name
  productType
  attachmentCount(fileType: IMAGE)
  __typename
}

fragment WoLocationData on Location {
  id
  location
  description
  plantId
  severityClass {
    id
    default
  }
  attachmentCount(fileType: IMAGE)
  __typename
}

fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  inventoryMode
  entryMethod
  unit
  sectionName
  requiredToCompleteSection
  product {
    id
    name
  }
  __typename
}

fragment WorkOrderTypeData on WorkOrderType {
  daysTillDue
  daysTillVerificationDue
  defaultAnalysisId
  defaultGroupId
  defaultReport
  defaultVerificationGroupId
  id
  inUseAtPlantIDs
  name
  productBatchRequired
  showDue
  showLocation
  showLocationDescription
  showProduct
  showSamplingDetail
  showTestingDetail
  titleRequired
  verificationRequired
  visibleGroupId
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "workOrder": {
                "type": "WorkOrder",
                "keyRaw": "workOrder(id: $workOrderId)",

                "selection": {
                    "fields": {
                        "assignedToGroup": {
                            "type": "Group",
                            "keyRaw": "assignedToGroup",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "dateCreated": {
                            "type": "DateTime",
                            "keyRaw": "dateCreated",
                            "visible": true
                        },

                        "documentStatus": {
                            "type": "DocumentStatus",
                            "keyRaw": "documentStatus",
                            "visible": true
                        },

                        "due": {
                            "type": "DateTime",
                            "keyRaw": "due",
                            "nullable": true,
                            "visible": true
                        },

                        "favorite": {
                            "type": "Boolean",
                            "keyRaw": "favorite",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "instructions": {
                            "type": "String",
                            "keyRaw": "instructions",
                            "visible": true
                        },

                        "internalNotes": {
                            "type": "String",
                            "keyRaw": "internalNotes",
                            "visible": true
                        },

                        "scheduled": {
                            "type": "DateTime",
                            "keyRaw": "scheduled",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "verificationDue": {
                            "type": "DateTime",
                            "keyRaw": "verificationDue",
                            "nullable": true,
                            "visible": true
                        },

                        "verifiedOn": {
                            "type": "DateTime",
                            "keyRaw": "verifiedOn",
                            "nullable": true,
                            "visible": true
                        },

                        "plant": {
                            "type": "Plant",
                            "keyRaw": "plant",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "productBatch": {
                            "type": "ProductBatch",
                            "keyRaw": "productBatch",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "productType": {
                                                    "type": "ProductType",
                                                    "keyRaw": "productType",
                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoProductData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "location": {
                                                    "type": "String",
                                                    "keyRaw": "location",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "plantId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "plantId",
                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "default": {
                                                                "type": "Boolean",
                                                                "keyRaw": "default",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoLocationData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "samples": {
                            "type": "Sample",
                            "keyRaw": "samples",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "plant": {
                                        "type": "Plant",
                                        "keyRaw": "plant",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "analysis": {
                                        "type": "Analysis",
                                        "keyRaw": "analysis",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "analysisType": {
                                                    "type": "AnalysisType",
                                                    "keyRaw": "analysisType",
                                                    "visible": true
                                                },

                                                "instructions": {
                                                    "type": "String",
                                                    "keyRaw": "instructions",
                                                    "visible": true
                                                },

                                                "requireAuthentication": {
                                                    "type": "Boolean",
                                                    "keyRaw": "requireAuthentication",
                                                    "visible": true
                                                },

                                                "batchUnit": {
                                                    "type": "String",
                                                    "keyRaw": "batchUnit",
                                                    "visible": true
                                                },

                                                "batchVolume": {
                                                    "type": "Float",
                                                    "keyRaw": "batchVolume",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "createdProductId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "createdProductId",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "productType": {
                                                    "type": "ProductType",
                                                    "keyRaw": "productType",
                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoProductData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "location": {
                                                    "type": "String",
                                                    "keyRaw": "location",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "plantId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "plantId",
                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "default": {
                                                                "type": "Boolean",
                                                                "keyRaw": "default",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoLocationData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "testingComments": {
                                        "type": "String",
                                        "keyRaw": "testingComments",
                                        "visible": true
                                    },

                                    "samplingComments": {
                                        "type": "String",
                                        "keyRaw": "samplingComments",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "DocumentStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "tagNumber": {
                                        "type": "String",
                                        "keyRaw": "tagNumber",
                                        "visible": true
                                    },

                                    "scheduled": {
                                        "type": "DateTime",
                                        "keyRaw": "scheduled",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "due": {
                                        "type": "DateTime",
                                        "keyRaw": "due",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "performed": {
                                        "type": "DateTime",
                                        "keyRaw": "performed",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "collectedBy": {
                                        "type": "UserAccount",
                                        "keyRaw": "collectedBy",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "incubationBegan": {
                                        "type": "DateTime",
                                        "keyRaw": "incubationBegan",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "incubationEnded": {
                                        "type": "DateTime",
                                        "keyRaw": "incubationEnded",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "investigationId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "investigationId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "platesReadBy": {
                                        "type": "UserAccount",
                                        "keyRaw": "platesReadBy",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "productionVolume": {
                                        "type": "Float",
                                        "keyRaw": "productionVolume",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sampleValues": {
                                        "type": "SampleValue",
                                        "keyRaw": "sampleValues",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "lot": {
                                                    "type": "String",
                                                    "keyRaw": "lot",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "sampleId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "sampleId",
                                                    "visible": true
                                                },

                                                "result": {
                                                    "type": "String",
                                                    "keyRaw": "result",
                                                    "visible": true
                                                },

                                                "resultStatus": {
                                                    "type": "ResultStatus",
                                                    "keyRaw": "resultStatus",
                                                    "visible": true
                                                },

                                                "filledOut": {
                                                    "type": "DateTime",
                                                    "keyRaw": "filledOut",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "expiration": {
                                                    "type": "Date",
                                                    "keyRaw": "expiration",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "defaultValue": {
                                                    "type": "String",
                                                    "keyRaw": "defaultValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lastModified": {
                                                    "type": "DateTime",
                                                    "keyRaw": "lastModified",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lastModifiedByUser": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "lastModifiedByUser",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "totalQuantity": {
                                                    "type": "Float",
                                                    "keyRaw": "totalQuantity",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "createdInvestigationId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "createdInvestigationId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lots": {
                                                    "type": "SampleValueLot",
                                                    "keyRaw": "lots",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "created": {
                                                                "type": "DateTime",
                                                                "keyRaw": "created",
                                                                "visible": true
                                                            },

                                                            "quantity": {
                                                                "type": "Float",
                                                                "keyRaw": "quantity",
                                                                "visible": true
                                                            },

                                                            "supplierItemNumber": {
                                                                "type": "String",
                                                                "keyRaw": "supplierItemNumber",
                                                                "visible": true
                                                            },

                                                            "productBatch": {
                                                                "type": "ProductBatch",
                                                                "keyRaw": "productBatch",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "expiration": {
                                                                            "type": "Date",
                                                                            "keyRaw": "expiration",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "product": {
                                                                "type": "Product",
                                                                "keyRaw": "product",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "analysisOption": {
                                                    "type": "AnalysisOption",
                                                    "keyRaw": "analysisOption",

                                                    "selection": {
                                                        "fields": {
                                                            "active": {
                                                                "type": "Boolean",
                                                                "keyRaw": "active",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "option": {
                                                                "type": "String",
                                                                "keyRaw": "option",
                                                                "visible": true
                                                            },

                                                            "valueType": {
                                                                "type": "ValueType",
                                                                "keyRaw": "valueType",
                                                                "visible": true
                                                            },

                                                            "thresholdType": {
                                                                "type": "ThresholdType",
                                                                "keyRaw": "thresholdType",
                                                                "visible": true
                                                            },

                                                            "rank": {
                                                                "type": "NonNegativeInt",
                                                                "keyRaw": "rank",
                                                                "visible": true
                                                            },

                                                            "defaultType": {
                                                                "type": "DefaultType",
                                                                "keyRaw": "defaultType",
                                                                "visible": true
                                                            },

                                                            "defaultValue": {
                                                                "type": "String",
                                                                "keyRaw": "defaultValue",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "inventoryMode": {
                                                                "type": "InventoryMode",
                                                                "keyRaw": "inventoryMode",
                                                                "visible": true
                                                            },

                                                            "entryMethod": {
                                                                "type": "EntryMethod",
                                                                "keyRaw": "entryMethod",
                                                                "visible": true
                                                            },

                                                            "unit": {
                                                                "type": "String",
                                                                "keyRaw": "unit",
                                                                "visible": true
                                                            },

                                                            "sectionName": {
                                                                "type": "String",
                                                                "keyRaw": "sectionName",
                                                                "visible": true
                                                            },

                                                            "requiredToCompleteSection": {
                                                                "type": "Boolean",
                                                                "keyRaw": "requiredToCompleteSection",
                                                                "visible": true
                                                            },

                                                            "product": {
                                                                "type": "Product",
                                                                "keyRaw": "product",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "WoAnalysisOptionData": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "attachments": {
                                        "type": "SampleFile",
                                        "keyRaw": "attachments",

                                        "selection": {
                                            "fields": {
                                                "fileId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "fileId",
                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "SafeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "file": {
                                                    "type": "File",
                                                    "keyRaw": "file",

                                                    "selection": {
                                                        "fields": {
                                                            "created": {
                                                                "type": "DateTime",
                                                                "keyRaw": "created",
                                                                "visible": true
                                                            },

                                                            "mimeType": {
                                                                "type": "String",
                                                                "keyRaw": "mimeType",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "UnsignedInt",
                                                                "keyRaw": "size",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "verifiedByUser": {
                            "type": "UserAccount",
                            "keyRaw": "verifiedByUser",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "fullName": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "fullName",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "workOrderType": {
                            "type": "WorkOrderType",
                            "keyRaw": "workOrderType",

                            "selection": {
                                "fields": {
                                    "daysTillDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysTillDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "daysTillVerificationDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysTillVerificationDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultAnalysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "defaultAnalysisId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultGroupId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "defaultGroupId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultReport": {
                                        "type": "String",
                                        "keyRaw": "defaultReport",
                                        "visible": true
                                    },

                                    "defaultVerificationGroupId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "defaultVerificationGroupId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "productBatchRequired": {
                                        "type": "ProductBatchRequired",
                                        "keyRaw": "productBatchRequired",
                                        "visible": true
                                    },

                                    "showDue": {
                                        "type": "Boolean",
                                        "keyRaw": "showDue",
                                        "visible": true
                                    },

                                    "showLocation": {
                                        "type": "Boolean",
                                        "keyRaw": "showLocation",
                                        "visible": true
                                    },

                                    "showLocationDescription": {
                                        "type": "Boolean",
                                        "keyRaw": "showLocationDescription",
                                        "visible": true
                                    },

                                    "showProduct": {
                                        "type": "ShowProduct",
                                        "keyRaw": "showProduct",
                                        "visible": true
                                    },

                                    "showSamplingDetail": {
                                        "type": "Boolean",
                                        "keyRaw": "showSamplingDetail",
                                        "visible": true
                                    },

                                    "showTestingDetail": {
                                        "type": "Boolean",
                                        "keyRaw": "showTestingDetail",
                                        "visible": true
                                    },

                                    "titleRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "titleRequired",
                                        "visible": true
                                    },

                                    "verificationRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "verificationRequired",
                                        "visible": true
                                    },

                                    "visibleGroupId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "visibleGroupId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WorkOrderTypeData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "workOrderId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1996f6f133c83fc55a1b7914426d783639b31f6c297fc7dd439c11b219afff0e";