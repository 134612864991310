export default {
    "name": "ReplaceLotBatchSearch",
    "kind": "HoudiniQuery",
    "hash": "28169572bff19a8208a5de31020f99d36342f110e3f43e9b9a422984616c0b9a",

    "raw": `query ReplaceLotBatchSearch($filter: ProductBatchFilter) {
  productBatches(filter: $filter) {
    data {
      id
      name
      expiration
      product {
        id
        name
        unitConversion
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "productBatches": {
                "type": "ProductBatchResponse",
                "keyRaw": "productBatches(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProductBatch",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "expiration": {
                                        "type": "Date",
                                        "keyRaw": "expiration",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "unitConversion": {
                                                    "type": "Float",
                                                    "keyRaw": "unitConversion",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "ProductBatchFilter"
        },

        "types": {
            "ProductBatchFilter": {
                "name": "String",
                "plantIds": "PositiveInt",
                "productIds": "PositiveInt",
                "statuses": "ProductBatchStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c962ac60982cf6938756389c50449b0eddcf78e02d64a193c18f4991e39a2620";