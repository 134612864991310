export default {
    "name": "ShDeleteSavedSampleSearch",
    "kind": "HoudiniMutation",
    "hash": "998d6dfc4956fc0d9223f4c9c01ce0fee687f386d7b220310da2a10f5b65957b",

    "raw": `mutation ShDeleteSavedSampleSearch($deleteSavedSampleSearchId: Float!) {
  deleteSavedSampleSearch(id: $deleteSavedSampleSearchId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteSavedSampleSearch": {
                "type": "Void",
                "keyRaw": "deleteSavedSampleSearch(id: $deleteSavedSampleSearchId)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "deleteSavedSampleSearchId": "Float"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=d43309f26755c8f77acb7dc7063d718683c12dde519929654ca21c4d4dc0d308";