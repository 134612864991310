export default {
    "name": "ReportViewerHistorySettings",
    "kind": "HoudiniQuery",
    "hash": "cdcf95d9fe4b5b5df976cdd6bb5b038b2c1c192cc5ef230067c669145f5707be",

    "raw": `query ReportViewerHistorySettings($userId: Float!) {
  pageSize: getUserSetting(
    lookup: {category: "Printing", name: "Print Queue: Number of print jobs to show per page", defaultValue: "100", settingType: INTERFACE_HISTORY, userId: $userId}
  ) {
    value
  }
  showSentJobs: getUserSetting(
    lookup: {category: "Printing", name: "Print Queue: show previously printed print jobs", defaultValue: "False", settingType: INTERFACE_HISTORY, userId: $userId}
  ) {
    value
  }
  showCancelledJobs: getUserSetting(
    lookup: {category: "Printing", name: "Print Queue: show cancelled print jobs", defaultValue: "False", settingType: INTERFACE_HISTORY, userId: $userId}
  ) {
    value
  }
  showReadyJobs: getUserSetting(
    lookup: {category: "Printing", name: "Print Queue: show ready print jobs", defaultValue: "True", settingType: INTERFACE_HISTORY, userId: $userId}
  ) {
    value
  }
  showFailedJobs: getUserSetting(
    lookup: {category: "Printing", name: "Print Queue: show failed print jobs", defaultValue: "True", settingType: INTERFACE_HISTORY, userId: $userId}
  ) {
    value
  }
  showDelayedJobsOnly: getUserSetting(
    lookup: {category: "Printing", name: "Print Queue: only delayed print jobs", defaultValue: "False", settingType: INTERFACE_HISTORY, userId: $userId}
  ) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "pageSize": {
                "type": "SettingResult",
                "keyRaw": "pageSize(lookup: {category: \"Printing\", name: \"Print Queue: Number of print jobs to show per page\", defaultValue: \"100\", settingType: INTERFACE_HISTORY, userId: $userId})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "showSentJobs": {
                "type": "SettingResult",
                "keyRaw": "showSentJobs(lookup: {category: \"Printing\", name: \"Print Queue: show previously printed print jobs\", defaultValue: \"False\", settingType: INTERFACE_HISTORY, userId: $userId})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "showCancelledJobs": {
                "type": "SettingResult",
                "keyRaw": "showCancelledJobs(lookup: {category: \"Printing\", name: \"Print Queue: show cancelled print jobs\", defaultValue: \"False\", settingType: INTERFACE_HISTORY, userId: $userId})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "showReadyJobs": {
                "type": "SettingResult",
                "keyRaw": "showReadyJobs(lookup: {category: \"Printing\", name: \"Print Queue: show ready print jobs\", defaultValue: \"True\", settingType: INTERFACE_HISTORY, userId: $userId})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "showFailedJobs": {
                "type": "SettingResult",
                "keyRaw": "showFailedJobs(lookup: {category: \"Printing\", name: \"Print Queue: show failed print jobs\", defaultValue: \"True\", settingType: INTERFACE_HISTORY, userId: $userId})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "showDelayedJobsOnly": {
                "type": "SettingResult",
                "keyRaw": "showDelayedJobsOnly(lookup: {category: \"Printing\", name: \"Print Queue: only delayed print jobs\", defaultValue: \"False\", settingType: INTERFACE_HISTORY, userId: $userId})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userId": "Float"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=13c7c140e63b849379dffc4385b8e013527706d0e867fcea56dce49fad022e9f";