<script lang="ts">
	import type {
		ConstraintType$options,
		CheckApplicableThresholds$input,
		CheckApplicableThresholds$result,
		BoundaryType$options,
		Violated$options,
		Applicability$options,
	} from '$houdini'
	import type { ComponentProps } from 'svelte'
	import type { IconName } from '@fortawesome/fontawesome-common-types'
	import type { i18n } from 'i18next'

	type Threshold = {
		boundaryType: BoundaryType$options
		constraint: ConstraintType$options
		severity: string
		plant: string
		product: string
		choice: string
		violated: Violated$options
		applicability: Applicability$options
		sortRank: '1' | '2' | '3'
	}

	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Icon from '@isoftdata/svelte-icon'
	import { graphql } from '$houdini'
	import { boundaryTypeToResultStatus, type ValueAcceptabilityMap } from 'utility/value-acceptability-map'
	import { getContext } from 'svelte'

	export let fullSize = false
	export let plants: Array<{ id: number; code: string; name: string }> | undefined = undefined
	export let products: Array<{ id: number; name: string }> | undefined = undefined
	export let severityClasses: Array<{ id: number; name: string }> | undefined = undefined

	let thresholds: Array<Threshold> = []
	let table: Table<Threshold>

	$: table?.setColumnVisibility('plant', fullSize && !!plants)
	$: table?.setColumnVisibility('product', fullSize && !!products)
	$: table?.setColumnVisibility('severity', fullSize && !!severityClasses)

	$: plantsMap =
		plants?.reduce(
			(acc, plant) => {
				acc[plant.id] = plant
				return acc
			},
			{} as Record<number, { id: number; code: string; name: string }>,
		) ?? {}

	$: productsMap =
		products?.reduce(
			(acc, product) => {
				acc[product.id] = product
				return acc
			},
			{} as Record<number, { id: number; name: string }>,
		) ?? {}

	$: severityClassesMap =
		severityClasses?.reduce(
			(acc, severityClass) => {
				acc[severityClass.id] = severityClass
				return acc
			},
			{} as Record<number, { id: number; name: string }>,
		) ?? {}

	const { t: translate } = getContext<i18n>('i18next')
	const valueAcceptabilityMap = getContext<ValueAcceptabilityMap>('valueAcceptabilityMap')

	const columns: Array<Column<Threshold>> = [
		{
			property: 'boundaryType',
			name: translate('thresholds.thresholdColumn', 'Threshold'),
			title: translate('thresholds.thresholdColumnTitle', 'How this threshold applies to a value'),
			sortType: false,
		},
		{
			property: 'constraint',
			name: '',
			icon: 'question',
			title: translate('thresholds.constraintColumnTitle', 'Condition for the threshold to be violated'),
			align: 'center',
			sortType: false,
		},
		{
			property: 'choice',
			name: translate('thresholds.valueColumn', 'Value'),
			title: translate('thresholds.valueColumnTitle', 'The value of the threshold'),
			numeric: true,
			sortType: false,
		},
		{
			property: 'plant',
			name: translate('thresholds.plantColumn', 'Plant'),
			title: translate('thresholds.plantColumnTitle', 'The plant a threshold applies to'),
			sortType: false,
		},
		{
			property: 'severity',
			name: translate('thresholds.severityColumn', 'Severity'),
			title: translate('thresholds.severityColumnTitle', 'Which severity class this threshold/choice applies to'),
			sortType: false,
		},
		{
			property: 'product',
			name: translate('thresholds.productColumn', 'Product'),
			title: translate('thresholds.productColumnTitle', 'Which product this threshold/choice applies to'),
			sortType: false,
		},
		{
			property: 'sortRank',
			//TODO: At some point in the future, after all customers are on a version > 6.5.1, we should remove the `thresholds.volatedColumn` typo'd key from Locize.
			name: translate('thresholds.violatedColumn', 'Violated?'),
			title: translate('thresholds.violatedColumnTitle', 'Whether this threshold was triggered by the entered value'),
			sortType: false,
			defaultSortColumn: true,
			defaultSortDirection: 'DESC',
		},
		{
			property: 'applicability',
			name: translate('thresholds.applicableColumn', 'Applicable?'),
			title: translate('thresholds.applicableColumnTitle', 'When a threshold does not apply, this field will say why'),
			sortType: false,
		},
	]
	const applicabilityMap = Object.freeze({
		APPLICABLE: translate('thresholds.applicable', 'Applicable'),
		DEACTIVATED: translate('thresholds.deactivated', 'Deactivated'),
		WRONG_BATCH: translate('thresholds.wrongBatch', 'Wrong Batch'),
		WRONG_PLANT: translate('thresholds.wrongPlant', 'Wrong Plant'),
		WRONG_PRODUCT: translate('thresholds.wrongProduct', 'Wrong Product'),
		WRONG_SEVERITY_CLASS: translate('thresholds.wrongSeverity', 'Wrong Severity Class'),
	})

	const constraintMap: Record<ConstraintType$options, { icon: IconName; title: string }> = Object.freeze({
		MINIMUM: { icon: 'greater-than', title: translate('thresholds.greaterThan', 'Greater Than') },
		MAXIMUM: { icon: 'less-than', title: translate('thresholds.lessThan', 'Less Than') },
		NOT_EQUAL: { icon: 'not-equal', title: translate('thresholds.notEqualTo', 'Not Equal To') },
		NONE: { icon: 'equals', title: translate('thresholds.equalTo', 'Equal To') },
	})

	function getBoundaryTypeIcon(boundaryType: BoundaryType$options): ComponentProps<Icon> {
		const acceptability = valueAcceptabilityMap.get(boundaryTypeToResultStatus[boundaryType])!
		return {
			fixedWidth: true,
			icon: acceptability.icon,
			title: acceptability.label,
			class: `text-${acceptability.colorClass}`,
		}
	}

	/** Always put non-applicable thresholds at the bottom of the list */
	function getSortRank(violated: Violated$options, applicability: Applicability$options) {
		if (violated === 'TRUE' && applicability === 'APPLICABLE') {
			return '3'
		} else if (applicability === 'APPLICABLE') {
			return '2'
		} else {
			return '1'
		}
	}

	export async function loadData(variables: CheckApplicableThresholds$input) {
		table?.setColumnVisibility('applicability', !variables.onlyApplicable)
		await checkApplicableThresholds.fetch({
			variables: {
				...variables,
				currentResult: variables.currentResult.toString(),
			},
		})
		thresholds = ($checkApplicableThresholds.data?.checkApplicableThresholds ?? []).map(threshold => ({
			...threshold,
			severity: threshold.severityClassId ? (severityClassesMap[threshold.severityClassId]?.name ?? 'All') : 'All',
			plant: threshold.plantId ? (plantsMap[threshold.plantId]?.name ?? 'All') : 'All',
			product: threshold.productId ? (productsMap[threshold.productId]?.name ?? 'All') : 'All',
			sortRank: getSortRank(threshold.violated, threshold.applicability),
		}))
	}

	const checkApplicableThresholds = graphql(`
		query CheckApplicableThresholds(
			$analysisOptionId: PositiveInt!
			$currentResult: String!
			$onlyApplicable: Boolean
			$productBatchId: PositiveInt
			$productId: PositiveInt
			$severityClassId: PositiveInt
			$plantId: PositiveInt
			$boundaryTypes: [BoundaryType!]
			$productionVolume: NonNegativeFloat
		) {
			checkApplicableThresholds(
				analysisOptionId: $analysisOptionId
				currentResult: $currentResult
				onlyApplicable: $onlyApplicable
				productBatchId: $productBatchId
				productId: $productId
				severityClassId: $severityClassId
				plantId: $plantId
				boundaryTypes: $boundaryTypes
				productionVolume: $productionVolume
			) {
				id
				choice
				constraint
				boundaryType
				applicability
				violated
				severityClassId
				plantId
				productId
			}
		}
	`)
</script>

<Table
	responsive
	bind:this={table}
	{columns}
	rows={thresholds}
	parentStyle="background-color: white;{fullSize ? '' : ' width: fit-content;'}"
>
	{#snippet children({ row })}
		<tr class:table-secondary={row.applicability !== 'APPLICABLE'}>
			<Td property="boundaryType">
				<span class="text-nowrap">
					<Icon {...getBoundaryTypeIcon(row.boundaryType)} />
					{getBoundaryTypeIcon(row.boundaryType).title}
				</span>
			</Td>
			<Td property="constraint">
				<Icon
					fixedWidth
					{...constraintMap[row.constraint]}
				/>
			</Td>
			<Td property="choice">
				<span class="text-nowrap">{row.choice}</span>
			</Td>
			<Td property="plant">
				<span class="text-nowrap">{row.plant}</span>
			</Td>
			<Td property="severity">
				<span class="text-nowrap">{row.severity}</span>
			</Td>

			<Td property="product">
				<span class="text-nowrap">{row.product}</span>
			</Td>
			<Td property="sortRank">
				{#if row.violated === 'TRUE'}
					<Icon
						fixedWidth
						icon="check"
						class="text-success"
					/>
					{translate('thresholds.violated', 'Yes')}
				{:else if row.violated === 'FALSE'}
					<Icon
						fixedWidth
						icon="xmark"
						class="text-danger"
					/>
					{translate('thresholds.notViolated', 'No')}
				{:else if row.violated === 'NOT_CALCULATED'}
					<Icon
						fixedWidth
						icon="question"
						class="text-warning"
					/>
					{translate('thresholds.notCalculated', 'Not Calculated')}
				{/if}
			</Td>
			<Td property="applicability">
				<span class="text-nowrap">
					<Icon
						class={row.applicability === 'APPLICABLE' ? 'text-success' : 'text-danger'}
						icon={row.applicability === 'APPLICABLE' ? 'check' : 'xmark'}
					></Icon>
					{applicabilityMap[row.applicability]}
				</span>
			</Td>
		</tr>
	{/snippet}
	{#snippet noRows()}
		<tr>
			<td
				colspan={columns.length}
				class="text-center"
				>{#if $checkApplicableThresholds.fetching}
					<Icon isLoading></Icon> {translate('thresholds.loadingThresholds', 'Loading...')}
				{:else}
					{translate('thresholds.noApplicableThresholds', 'No applicable thresholds.')}
				{/if}</td
			>
		</tr>
	{/snippet}
</Table>
