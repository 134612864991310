import component from './Graph.svelte'
import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.sampling-history.graph',
		querystringParameters: ['url'],
		route: 'graph',
		template: component,
		resolve(_data, { url }) {
			return Promise.resolve({ url })
		},
	})
}
