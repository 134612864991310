export default {
    "name": "WoGetProductDescendants",
    "kind": "HoudiniQuery",
    "hash": "363b31d80d89c2726afd0640b350b483f21c62dcca4f682ac1af314b8ef4e08c",

    "raw": `query WoGetProductDescendants($productId: PositiveInt!) {
  getProductDescendants(productId: $productId) {
    id
    name
    itemNumber
    supplierItemNumber
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getProductDescendants": {
                "type": "Product",
                "keyRaw": "getProductDescendants(productId: $productId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "itemNumber": {
                            "type": "String",
                            "keyRaw": "itemNumber",
                            "visible": true
                        },

                        "supplierItemNumber": {
                            "type": "String",
                            "keyRaw": "supplierItemNumber",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c9b30814e71fc232627f1c8b95b9b8266765e2c1380cf0e669855dead2291f91";