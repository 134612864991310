export default {
    "name": "ActivationPINGenerate",
    "kind": "HoudiniMutation",
    "hash": "8c8b876385d632a8fee6556b53297b73841c47440f6a8a2999c7006844a1b5ef",

    "raw": `mutation ActivationPINGenerate($inputs: GenerateNewActivationPIN!) {
  generateNewActivationPIN(inputs: $inputs) {
    activationPIN
    activationPINExpiration
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "generateNewActivationPIN": {
                "type": "UserActivationData",
                "keyRaw": "generateNewActivationPIN(inputs: $inputs)",

                "selection": {
                    "fields": {
                        "activationPIN": {
                            "type": "NonEmptyString",
                            "keyRaw": "activationPIN",
                            "nullable": true,
                            "visible": true
                        },

                        "activationPINExpiration": {
                            "type": "DateTime",
                            "keyRaw": "activationPINExpiration",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inputs": "GenerateNewActivationPIN"
        },

        "types": {
            "GenerateNewActivationPIN": {
                "hasWorkEmail": "Boolean",
                "userName": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=64c2b901309a3dae2257cf6a3b9dccf17f821e1711017bee2cf6d0580280977e";