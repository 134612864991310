import type { Simplify, WritableDeep } from 'type-fest'
import type { i18n } from 'i18next'
import { graphql, type Reports$input, type Reports$result } from '$houdini'
import camelCase from 'just-camel-case'

type Report = Simplify<WritableDeep<Reports$result['reports']['data'][number]>>

const reportListQuery = graphql(`
	query Reports($pagination: PaginatedInput, $filter: ReportsFilter) {
		reports(pagination: $pagination, filter: $filter) {
			data {
				id
				type
				category
				name
				userVisible
				description
				numberOfParameters
				averageExecutionTime
			}
		}
	}
`)

export function translateReport<R extends Partial<Report> & Pick<Report, 'name'>>(report: R, translate: i18n['t']): R {
	const translatedReport = {
		...report,
		name: report.name ? translate(`reports:${camelCase(report.name)}Name`, report.name) : '',
	}
	// If we're passed a description or category, translate it. Otherwise, leave it off since not all screens show them
	if (report.category) {
		translatedReport.category =
			translate(`reports:categories.${camelCase(report.category)}`, report.category) ||
			translate('reportViewer.uncategorized', 'Uncategorized')
	}
	if (report.description) {
		translatedReport.description = translate(`reports:${camelCase(report.name)}Description`, report.description)
	}

	return translatedReport
}

export default async function loadReports(variables: Reports$input, translate: i18n['t']): Promise<Array<Report>> {
	const { data } = await reportListQuery.fetch({ variables })

	return data?.reports.data.map(report => translateReport(report, translate)) ?? []
}

export const reportParameterQuery = graphql(`
	query GetReportParameters($reportId: ID!) {
		report(id: $reportId) {
			id
			type
			category
			name
			parameters {
				id
				label
				name
				rank
				type
				visible
				editability
				required
				options {
					isDefault
					id
					label
					value
				}
			}
		}
	}
`)
