<script lang="ts">
	import type { SvelteAsr } from 'client/types/common.js'
	import type { WorkOrderList$result } from '$houdini'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type {
		WorkOrderSearchFilter,
		WorkOrderSearchResult,
		WorkOrderSearchPagination,
		WorkOrderSearchOrderBy,
	} from 'components/WorkOrderSearch.svelte'
	import type { SortDirection } from '@isoftdata/svelte-table'

	import { datesFromRange } from '@isoftdata/utility-date-time'
	import WorkOrderSearch from 'components/WorkOrderSearch.svelte'

	export let workOrderFavoritesStore: CrudStore<WorkOrderSearchResult, 'id'>
	export let dateRange: { from: string; to: string } = datesFromRange('Last 30 Days')
	export let plants: WorkOrderList$result['plants']['data']
	export let workOrderTypes: WorkOrderList$result['workOrderTypes']['data']
	export let groups: WorkOrderList$result['groups']['data']
	export let workOrderTypeId: number
	export let groupId: number
	export let showClosed: boolean
	export let showOpen: boolean
	export let pageNumber: number | undefined
	export let pageSize: number | undefined
	export let orderByColumn: string | undefined
	export let orderByDirection: SortDirection
	export let plantId: number
	export let asr: SvelteAsr

	function asrGoToSearchPath(
		filter: WorkOrderSearchFilter,
		pagination: WorkOrderSearchPagination,
		orderBy: WorkOrderSearchOrderBy,
	) {
		console.log(orderBy)
		let asrParams = {}
		if (filter) {
			const { plantId, from, to, openDocuments, closedDocuments, workOrderTypeId, groupId } = filter
			asrParams = {
				plantId,
				fromDate: from,
				toDate: to,
				showOpen: openDocuments,
				showClosed: closedDocuments,
				workOrderTypeId,
				groupId,
			}
		}
		if (pagination) {
			const { pageNumber, pageSize } = pagination
			asrParams = {
				...asrParams,
				pageNumber,
				pageSize,
			}
		}
		if (orderBy) {
			const { columnName, direction } = orderBy
			asrParams = {
				...asrParams,
				orderByColumn: columnName,
				orderByDirection: direction,
			}
		}
		//Must use null here because that means "same state" and this component is used in multiple states
		asr.go(null, asrParams)
	}
</script>

<div class="card-body">
	<WorkOrderSearch
		selectedPlantId={plantId}
		selectedWorkOrderTypeId={workOrderTypeId}
		selectedGroupId={groupId}
		{dateRange}
		{plants}
		{workOrderTypes}
		{groups}
		showOpenDocuments={showOpen}
		showClosedDocuments={showClosed}
		tablePageNumber={pageNumber}
		{pageSize}
		{orderByColumn}
		{orderByDirection}
		showFavoriteCheckbox={false}
		favoriteWorkOrdersOnly={false}
		{workOrderFavoritesStore}
		{asrGoToSearchPath}
		{asr}
		disableSelection
		highlightOverdue
		showEditLink
		visibleColumns={[
			'id',
			'documentStatus',
			'plant[code]',
			'title',
			'workOrderType[name]',
			'scheduled',
			'due',
			'productBatch[name]',
			'assignedToGroup[name]',
			'dateCreated',
			'internalNotes',
			'instructions',
			'verificationDue',
			'verifiedByUser[fullName]',
			'verifiedOn',
		]}
	/>
</div>
