import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ReplaceLotBatchSearch'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ReplaceLotBatchSearchStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ReplaceLotBatchSearchStore",
			variables: false,
		})
	}
}

export async function load_ReplaceLotBatchSearch(params) {
	await initClient()

	const store = new ReplaceLotBatchSearchStore()

	await store.fetch(params)

	return {
		ReplaceLotBatchSearch: store,
	}
}
