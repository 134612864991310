<script lang="ts">
	import type { ScheduleType$options } from '$houdini/graphql'
	import type { Mediator, i18n, HasPermission } from 'types/common'

	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import { datesFromRange } from '@isoftdata/utility-date-time'
	import WorkOrderSearch from 'components/WorkOrderSearch.svelte'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import { getContext, tick, type ComponentEvents, onDestroy } from 'svelte'
	import Table, { Td, type Column, type UuidRowProps } from '@isoftdata/svelte-table'
	import setUserSetting from 'utility/set-user-setting'
	import { graphql } from '$houdini'

	import { booleanToString } from '@isoftdata/utility-boolean'
	import type { WorkOrderSearchResult } from 'components/WorkOrderSearch.svelte'
	import { AutomaticWorkOrder, type DateRangeFilterType } from 'types/automatic-work-order'

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const hasPermission = getContext<HasPermission>('hasPermission')

	export let workOrderFavoritesStore: CrudStore<WorkOrderSearchResult, 'id'>
	export let automaticWorkOrderCrudStore: CrudStore<AutomaticWorkOrder, 'uuid'>

	// #region: Crud Store Handlers
	export let automaticWorkOrdersToCreate: AutomaticWorkOrder[] = []
	export let automaticWorkOrdersToUpdate: AutomaticWorkOrder[] = []
	export let automaticWorkOrdersToDelete: AutomaticWorkOrder[] = []
	export let workOrderFavoritesToUpdate: WorkOrderSearchResult[] = []
	export let unsavedChanges: boolean = false

	$: workOrderFavoritesToUpdate = Object.values($workOrderFavoritesStore.updated)
	$: automaticWorkOrdersToCreate = Object.values($automaticWorkOrderCrudStore.created)
	$: automaticWorkOrdersToUpdate = Object.values($automaticWorkOrderCrudStore.updated)
	$: automaticWorkOrdersToDelete = Object.values($automaticWorkOrderCrudStore.deleted)
	$: unsavedChanges =
		automaticWorkOrdersToCreate.length > 0 ||
		automaticWorkOrdersToUpdate.length > 0 ||
		automaticWorkOrdersToDelete.length > 0 ||
		workOrderFavoritesToUpdate.length > 0
	// #endregion: Crud Store Event Handlers

	// #region: Props
	type Plant = { id: number; name: string; code: string }
	export let automaticWorkOrders: Array<AutomaticWorkOrder & UuidRowProps> = []
	export let timezones: string[] = []
	export let selectedPlantId: number
	export let plants: Plant[]
	export let workOrderTypes: { id: number; name: string }[] = []
	export let groups: { id: number; name: string }[] = []
	export let interfaceSettings: {
		showOpenDocuments: boolean
		showClosedDocuments: boolean
		workOrdersPerPage: number
		dateRangeFilter: DateRangeFilterType
		showInactive: boolean
	}
	// #endregion: Props

	// #region: Internal Data
	let automaticWorkOrdersTable: Table<AutomaticWorkOrder & UuidRowProps>
	let loading: boolean = false
	let modalOpen: boolean = false
	let favoriteCheckboxShown: boolean = false
	let selectedAutomaticWorkOrderUuids: string[] = []
	let modalSelectedWorkOrderIds: number[] = []
	let scheduleTypeOptions: Array<{
		id: ScheduleType$options
		label: string
	}> = [
		{ id: 'DAILY', label: translate('automaticWorkOrder.daily', 'Daily') },
		{ id: 'DAILY_WEEKDAYS', label: translate('automaticWorkOrder.dailyWeekdays', 'Daily (Weekdays)') },
		{ id: 'WEEKLY', label: translate('automaticWorkOrder.weekly', 'Weekly') },
		{ id: 'MONTHLY', label: translate('automaticWorkOrder.monthly', 'Monthly') },
		{ id: 'QUARTERLY', label: translate('automaticWorkOrder.quarterly', 'Quarterly') },
		{ id: 'YEARLY', label: translate('automaticWorkOrder.yearly', 'Yearly') },
	]
	let dayOfWeekOptions = [
		{ id: 0, label: translate('automaticWorkOrder.saturday', 'Saturday') },
		{ id: 1, label: translate('automaticWorkOrder.sunday', 'Sunday') },
		{ id: 2, label: translate('automaticWorkOrder.monday', 'Monday') },
		{ id: 3, label: translate('automaticWorkOrder.tuesday', 'Tuesday') },
		{ id: 4, label: translate('automaticWorkOrder.wednesday', 'Wednesday') },
		{ id: 5, label: translate('automaticWorkOrder.thursday', 'Thursday') },
		{ id: 6, label: translate('automaticWorkOrder.friday', 'Friday') },
	]
	let automaticWorkOrderColumns: Column<AutomaticWorkOrder & UuidRowProps>[] = [
		{
			property: 'status',
			name: '',
			icon: 'save',
			align: 'center',
			width: '1rem',
			sortType: false,
			title: translate(
				'automaticWorkOrder.title.editStatus',
				'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
			),
		},
		{
			property: 'active',
			name: translate('automaticWorkOrder.active', 'Active'),
			align: 'center',
			title: translate('automaticWorkOrder.title.isTheAutomaticWorkOrderActive', 'Is the Automatic Work Order active'),
		},
		{
			property: 'workOrderId',
			name: translate('automaticWorkOrder.wO#', 'WO#'),
			numeric: true,
			minWidth: '100px',
			title: translate('automaticWorkOrder.title.idOfWorkOrderSource', 'ID of Work Order Source'),
		},
		{
			property: 'description',
			name: translate('automaticWorkOrder.description', 'Description'),
			title: translate(
				'automaticWorkOrder.title.descriptionOfAutomaticWorkOrder',
				'Description of Automatic Work Order',
			),
		},
		{
			property: 'scheduleType',
			name: translate('automaticWorkOrder.scheduleType', 'Schedule Type'),
			title: translate('automaticWorkOrder.title.scheduleType', 'Schedule Type'),
		},
		{
			property: 'dayOffset',
			name: translate('automaticWorkOrder.dayOffset', 'Day Offset'),
			numeric: true,
			minWidth: '100px',
			title: translate('automaticWorkOrder.title.dayOffset', 'Day Offset (Number)'),
		},
		{
			property: 'monthOffset',
			name: translate('automaticWorkOrder.monthOffset', 'Month Offset'),
			numeric: true,
			minWidth: '100px',
			title: translate('automaticWorkOrder.title.monthOffset', 'Month Offset (Month)'),
		},
		{
			property: 'dayOfWeek',
			name: translate('automaticWorkOrder.dayOfWeek', 'Day of Week'),
			title: translate('automaticWorkOrder.title.dayOfWeek', 'Day of Week'),
		},
		{
			property: 'timeOffset',
			name: translate('automaticWorkOrder.timeOfDay', 'Time of Day'),
			title: translate('automaticWorkOrder.title.timeOfDay', 'Time of Day'),
		},
		{
			property: 'timezone',
			name: translate('automaticWorkOrder.timezone', 'Time Zone'),
			minWidth: '125px',
			title: translate('automaticWorkOrder.title.timezone', 'Time Zone'),
		},
		{
			property: 'nextOccurance',
			name: translate('automaticWorkOrder.nextOccurance', 'Next Occurrence'),
			title: translate('automaticWorkOrder.title.computedNextOccurance', 'Computed next occurrence'),
		},
		{
			property: 'dateCreated',
			name: translate('automaticWorkOrder.dateCreated', 'Date Created'),
			title: translate('automaticWorkOrder.title.dateCreated', 'Date Created'),
		},
		{
			property: 'cloneAdditionalData',
			name: translate('automaticWorkOrder.cloneValues', 'Clone Values'),
			align: 'center',
			title: translate('automaticWorkOrder.title.cloneValues', 'Clone Values'),
		},
		{
			property: 'cloneMostRecent',
			name: translate('automaticWorkOrder.cloneMostRecent', 'Clone Most Recent'),
			align: 'center',
			title: translate('automaticWorkOrder.title.cloneMostRecent', 'Clone Most Recent'),
		},
	]
	let dateRange: { from: string; to: string } = datesFromRange('Last 30 Days')
	// #endregion: Internal Data

	// #region: Computed Data
	$: selectedPlant = plants.find(plant => plant.id === selectedPlantId) ?? null
	$: automaticWorkOrdersForSelectedPlant = automaticWorkOrders.filter(
		automaticWorkOrder => automaticWorkOrder?.workOrder?.plant?.id === selectedPlantId,
	)
	$: automaticWorkOrdersForDisplay = !interfaceSettings.showInactive
		? automaticWorkOrdersForSelectedPlant.filter(automaticWorkOrder => automaticWorkOrder.active)
		: automaticWorkOrdersForSelectedPlant
	$: canEditAtCurrentPlant = hasPermission('WORK_ORDERS_CAN_MANAGE_AUTOMATIC', selectedPlantId)
	$: canViewWorkOrders = hasPermission('WORK_ORDERS_CAN_VIEW', selectedPlantId)
	$: canCreateNewWorkOrders = hasPermission('WORK_ORDERS_CAN_ADD', selectedPlantId)
	// #endregion: Computed Data

	// #region: Functions
	function openModal(showFavoriteButton: boolean) {
		modalOpen = true
		favoriteCheckboxShown = showFavoriteButton
	}

	function closeModal() {
		modalOpen = false
		favoriteCheckboxShown = false
	}

	function updateProperty(event: Event, automaticWorkOrder: AutomaticWorkOrder, property: string) {
		const element = event.target as HTMLInputElement | HTMLSelectElement
		const foundAutomaticWorkOrder =
			automaticWorkOrders.find(
				findingAutomaticWorkOrder => findingAutomaticWorkOrder.uuid === automaticWorkOrder.uuid,
			) ?? null
		if (foundAutomaticWorkOrder) {
			foundAutomaticWorkOrder[property] = element.value
			automaticWorkOrderCrudStore.update(foundAutomaticWorkOrder)
		}
	}

	async function createBlankAutomaticWorkOrder() {
		const currentPlant = plants.find(plant => plant.id === selectedPlantId) ?? plants[0]
		const newAutomaticWorkOrder = AutomaticWorkOrder.createNewAutomaticWorkOrder(currentPlant)
		automaticWorkOrders.push(newAutomaticWorkOrder as AutomaticWorkOrder & UuidRowProps)
		automaticWorkOrders = automaticWorkOrders
		automaticWorkOrderCrudStore.create(newAutomaticWorkOrder)
		await tick()
		document.getElementById(`${newAutomaticWorkOrder.uuid}-workOrderId`)?.focus()
	}

	async function createAutomaticWorkOrderFromId(workOrderId: number) {
		const currentPlant = plants.find(plant => plant.id === selectedPlantId) ?? plants[0]
		const newAutomaticWorkOrder = AutomaticWorkOrder.createNewAutomaticWorkOrder(currentPlant, workOrderId)
		automaticWorkOrders.push(newAutomaticWorkOrder as AutomaticWorkOrder & UuidRowProps)
		automaticWorkOrders = automaticWorkOrders
		automaticWorkOrderCrudStore.create(newAutomaticWorkOrder)
		await tick()
		document.getElementById(`${newAutomaticWorkOrder.uuid}-workOrderId`)?.focus()
	}

	function deleteAutomaticWorkOrders() {
		selectedAutomaticWorkOrderUuids.forEach(uuid => {
			if ($automaticWorkOrderCrudStore.deleted[uuid]) {
				automaticWorkOrderCrudStore.unDelete({ uuid } as AutomaticWorkOrder)
			} else {
				automaticWorkOrderCrudStore.delete({ uuid } as AutomaticWorkOrder)
			}
		})
	}

	async function copyWorkOrdersNow() {
		// Function to copy scheduled work orders and make a new work order with the same data but don't schedule them
		// Use the WO # listed at time of copy
		// This will be saved on confirm rather than cached for later save
		const formattedAutomaticWorkOrders = selectedAutomaticWorkOrderUuids.reduce(
			(acc, uuid) => {
				const automaticWorkOrder = automaticWorkOrders.find(automaticWorkOrder => automaticWorkOrder.uuid === uuid)
				if (automaticWorkOrder) {
					acc.push({
						id: automaticWorkOrder.workOrderId,
						copyValues: automaticWorkOrder.cloneAdditionalData,
						copyMostRecent: automaticWorkOrder.cloneMostRecent,
					})
				}
				return acc
			},
			[] as { id: number; copyValues: boolean; copyMostRecent: boolean }[],
		)

		const response =
			(
				await copyWorkOrdersMutation.mutate({
					input: formattedAutomaticWorkOrders,
				})
			)?.data?.copyWorkOrders ?? []
		// NOTE: The last response is the first one to fail from the DB function the api calls.
		const lastResponse = response.pop()

		if (!lastResponse?.success) {
			mediator.call('showMessage', {
				color: 'danger',
				heading: translate('automaticWorkOrder.failedToCopyWorkOrdersHeading', 'Failed to copy work orders'),
				message: translate(
					'automaticWorkOrder.failureWhileCopyingWorkOrdersMessage',
					`Failure while copying work orders. The system returned the following error:
Failed to Copy Work Orders, The last work order attempted to be copied was #{{lastClonedId}}`,
					{ lastClonedId: lastResponse?.lastClonedId ?? 'Unknown' },
				),
			})
		} else {
			// Change to a success modal later with the options to go to the work orders page
			mediator.call('showMessage', {
				color: 'success',
				heading: translate('automaticWorkOrder.workOrdersCopied', 'Work Orders Copied'),
				message: translate(
					'automaticWorkOrder.successfullWorkOrderCopyingMessage',
					`Work orders successfully copied. The last work order copied was #{{lastClonedId}}
Work Orders Copied.`,
					{ lastClonedId: lastResponse.lastClonedId },
				),
				time: 10,
			})
		}
	}

	async function rerunScheduleNow() {
		const response = (await rerunScheduleMutation.mutate(null))?.data?.rerunScheduledWorkOrdersNow

		if (response && (!response.success || response.lastCloneError)) {
			mediator.call('showMessage', {
				color: 'danger',
				heading: translate('automaticWorkOrder.failedToRunSchedules', 'Failed to run Schedules'),
				message: translate(
					'automaticWorkOrder.failureWhileRunningSchedulesMessage',
					`Failure while running document copy schedules. {{dataClonesExecuted}} work orders were successfully created;
last ID # {{lastDataCloneId}}.
The system returned the following error: {{lastCloneError}}`,
					{
						dataClonesExecuted: response.dataClonesExecuted,
						lastDataCloneId: response.lastDataCloneId,
						lastCloneError: response.lastCloneError,
					},
				),
			})
		} else if (response && response.dataClonesExecuted > 0) {
			// Change to a success modal later with the options to go to the work orders page
			mediator.call('showMessage', {
				color: 'success',
				heading: translate('automaticWorkOrder.documentsCopiedSuccessfully', 'Documents Copied Successfully'),
				message: translate(
					'automaticWorkOrder.successfullDocumentCopyingMessage',
					`Documents copied successfully. {{dataClonesExecuted}} work order(s) successfully created.
The last document recived ID # {{lastDataCloneId}} Document Copy Successful`,
					{
						dataClonesExecuted: response.dataClonesExecuted,
						lastDataCloneId: response.lastDataCloneId,
					},
				),
				time: 10,
			})
		} else {
			mediator.call('showMessage', {
				color: 'warning',
				heading: translate('automaticWorkOrder.noWorkOrdersCopied', 'No Work Orders Copied'),
				message: translate(
					'automaticWorkOrder.noWorkOrdersCopiedMessage',
					`Schedule run successfully; no new work orders copied at this time.

(This could be because no schedules were set up for this quarter-hour interval, or all work orders were successfully run at the start of the quarte-hour))
`,
				),
				time: 10,
			})
		}
	}

	export function canLeaveState() {
		return (
			!unsavedChanges ||
			confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		)
	}

	async function saveInterfaceHistory() {
		return await setUserSetting({
			category: 'Work Orders',
			name: 'Automatic WOs: show inactive',
			settingType: 'INTERFACE_HISTORY',
			newValue: booleanToString(interfaceSettings.showInactive),
		})
	}

	const rerunScheduleMutation = graphql(`
		mutation RerunScheduleNow {
			rerunScheduledWorkOrdersNow {
				success
				lastDataCloneId
				dataClonesExecuted
				lastCloneError
			}
		}
	`)

	const copyWorkOrdersMutation = graphql(`
		mutation AWCopyWorkOrders($input: [CopyWorkOrderInput!]!) {
			copyWorkOrders(input: $input) {
				success
				lastClonedId
			}
		}
	`)

	onDestroy(async () => {
		workOrderFavoritesStore.clear()
		automaticWorkOrderCrudStore.clear()
		await saveInterfaceHistory()
	})
</script>

<div class="card-body">
	<Table
		columnHidingEnabled
		filterEnabled
		responsive
		stickyHeader
		selectionEnabled
		idProp="uuid"
		rows={automaticWorkOrdersForDisplay}
		columns={automaticWorkOrderColumns}
		perPageCount={interfaceSettings.workOrdersPerPage}
		rowSelectionIdProp="uuid"
		selectionMode="SINGLE"
		bind:selectedRowIds={selectedAutomaticWorkOrderUuids}
		parentStyle="max-height: 60vh;"
		bind:this={automaticWorkOrdersTable}
	>
		{#snippet header()}
			<div class="form-row align-items-end mb-3">
				<div class="col-lg-3 col-12 mb-0">
					<SiteAutocomplete
						label={translate('common:plant', 'Plant')}
						value={selectedPlant}
						options={plants}
						change={plant => {
							if (plant) {
								selectedPlantId = plant.id
							}
						}}
					/>
				</div>
				<div class="col-lg-3 col-12 mb-0">
					<Checkbox
						label={translate(
							'automaticWorkOrder.showInactiveAutomaticWorkOrders',
							'Show inactive Automatic Work Orders',
						)}
						bind:checked={interfaceSettings.showInactive}
						onchange={() => saveInterfaceHistory()}
					/>
				</div>
				<div class="ml-lg-auto mb-0">
					<Button
						title={translate('automaticWorkOrder.findWorkOrdersToSchedule', 'Find Work Orders to Schedule')}
						type="button"
						outline
						size="sm"
						disabled={!canViewWorkOrders}
						iconClass="search"
						onclick={() => openModal(true)}
					>
						{translate('automaticWorkOrder.findWO', 'Find')}
					</Button>
					<Button
						title={translate(
							'automaticWorkOrder.showFavoriteWorkOrdersToSchedule',
							'Show Favorite Work Orders to Schedule',
						)}
						type="button"
						outline
						size="sm"
						disabled={!canViewWorkOrders}
						iconClass="star"
						onclick={() => openModal(false)}
					>
						{translate('automaticWorkOrder.showFavorites', 'Show Favorites')}
					</Button>
				</div>
			</div>
		{/snippet}
		{#snippet children({ row })}
			<tr
				class:table-danger={$automaticWorkOrderCrudStore.deleted[row.uuid] &&
					!selectedAutomaticWorkOrderUuids.includes(row.uuid)}
				class:table-primary={selectedAutomaticWorkOrderUuids.includes(row.uuid)}
				onclick={event => {
					if (!canEditAtCurrentPlant) {
						return
					}
					event.stopPropagation()
					automaticWorkOrdersTable.rowClick(row)
				}}
			>
				<Td property="status">
					{#if $automaticWorkOrderCrudStore.deleted[row.uuid]}
						<i class="fas fa-trash"></i>
					{:else if $automaticWorkOrderCrudStore.created[row.uuid] || $automaticWorkOrderCrudStore.updated[row.uuid]}
						<i class="fas fa-save"></i>
					{/if}
				</Td>
				<Td
					stopPropagation
					property="active"
				>
					<Checkbox
						showLabel={false}
						checked={row.active}
						disabled={!canEditAtCurrentPlant}
						onchange={event => {
							row.active = !row.active
							const foundWorkOrder =
								automaticWorkOrders.find(automaticWorkOrder => automaticWorkOrder.uuid === row.uuid) ?? null
							if (foundWorkOrder) {
								foundWorkOrder.active = row.active
							}
							automaticWorkOrderCrudStore.update(row)
						}}
					/>
				</Td>
				<Td property="workOrderId">
					<Input
						id={`${row.uuid}-workOrderId`}
						showLabel={false}
						type="number"
						value={row.workOrderId}
						disabled={!canEditAtCurrentPlant}
						onclick={event => event.stopPropagation()}
						onchange={event => updateProperty(event, row, 'workOrderId')}
						selectOnFocus
					/>
				</Td>
				<Td property="description">
					<Input
						showLabel={false}
						value={row.description ?? ''}
						disabled={!canEditAtCurrentPlant}
						onclick={event => event.stopPropagation()}
						onchange={event => updateProperty(event, row, 'description')}
					/>
				</Td>
				<Td
					stopPropagation
					property="scheduleType"
				>
					<Select
						showLabel={false}
						value={row.scheduleType}
						options={scheduleTypeOptions}
						disabled={!canEditAtCurrentPlant}
						onchange={event => updateProperty(event, row, 'scheduleType')}
					>
						{#snippet option({ option })}
							<option value={option?.id}>{option?.label}</option>
						{/snippet}
					</Select>
				</Td>
				<Td
					stopPropagation
					property="dayOffset"
				>
					<Input
						showLabel={false}
						type="number"
						value={row.dayOffset}
						disabled={!canEditAtCurrentPlant}
						onchange={event => updateProperty(event, row, 'dayOffset')}
					/>
				</Td>
				<Td
					stopPropagation
					property="monthOffset"
				>
					<Input
						showLabel={false}
						type="number"
						value={row.monthOffset ?? ''}
						disabled={!canEditAtCurrentPlant}
						onchange={event => updateProperty(event, row, 'monthOffset')}
					/>
				</Td>
				<Td
					stopPropagation
					property="dayOfWeek"
				>
					<Select
						showLabel={false}
						value={row.dayOfWeek}
						options={dayOfWeekOptions}
						emptyText={translate('automaticWorkOrder.anyDay', 'Any Day')}
						disabled={!canEditAtCurrentPlant}
						onchange={event => updateProperty(event, row, 'dayOfWeek')}
					>
						{#snippet option({ option })}
							<option value={option?.id}>{option?.label}</option>
						{/snippet}
					</Select>
				</Td>
				<Td
					stopPropagation
					property="timeOffset"
				>
					<Input
						showLabel={false}
						type="time"
						value={row.timeOffset}
						disabled={!canEditAtCurrentPlant}
						onchange={event => updateProperty(event, row, 'timeOffset')}
					/>
				</Td>
				<Td
					stopPropagation
					property="timezone"
				>
					<Select
						showLabel={false}
						value={row.timezone}
						options={timezones}
						disabled={!canEditAtCurrentPlant}
						onchange={event => updateProperty(event, row, 'timezone')}
					>
						{#snippet option({ option })}
							<option value={option}>{option}</option>
						{/snippet}
					</Select>
				</Td>
				<Td
					property="nextOccurance"
					style="white-space: nowrap;"
				>
					{row.nextOccurance?.toLocaleString() ?? ''}
				</Td>
				<Td
					property="dateCreated"
					style="white-space: nowrap;"
				>
					{new Date(row.dateCreated)?.toLocaleString() ?? ''}
				</Td>
				<Td
					stopPropagation
					property="cloneAdditionalData"
				>
					<Checkbox
						showLabel={false}
						checked={row.cloneAdditionalData}
						disabled={!canEditAtCurrentPlant}
						onchange={event => {
							row.cloneAdditionalData = !row.cloneAdditionalData
							const foundWorkOrder =
								automaticWorkOrders.find(automaticWorkOrder => automaticWorkOrder.uuid === row.uuid) ?? null
							if (foundWorkOrder) {
								foundWorkOrder.cloneAdditionalData = row.cloneAdditionalData
							}
							automaticWorkOrderCrudStore.update(row)
						}}
					/>
				</Td>
				<Td
					stopPropagation
					property="cloneMostRecent"
				>
					<Checkbox
						showLabel={false}
						checked={row.cloneMostRecent}
						disabled={!canEditAtCurrentPlant}
						onchange={event => {
							row.cloneMostRecent = !row.cloneMostRecent
							const foundWorkOrder =
								automaticWorkOrders.find(automaticWorkOrder => automaticWorkOrder.uuid === row.uuid) ?? null
							if (foundWorkOrder) {
								foundWorkOrder.cloneMostRecent = row.cloneMostRecent
							}
							automaticWorkOrderCrudStore.update(row)
						}}
					/>
				</Td>
			</tr>
		{/snippet}
		{#snippet noRows()}
			<tr>
				<td
					colspan="14"
					class="text-center"
				>
					{translate('automaticWorkOrder.noAutomaticWorkOrdersFound', 'No Automatic Work Orders Found')}
				</td>
			</tr>
		{/snippet}
	</Table>
</div>

<div class="card-footer">
	<div class="d-flex justify-content-between">
		<div>
			<Button
				title={translate('automaticWorkOrder.createAutomaticWorkOrder', 'Create Automatic Work Order')}
				type="button"
				color="success"
				outline
				size="sm"
				disabled={!canEditAtCurrentPlant}
				iconClass="plus"
				onclick={() => createBlankAutomaticWorkOrder()}
			>
				{translate('automaticWorkOrder.newSchedule', 'New Schedule')}
			</Button>
			<Button
				title={translate('automaticWorkOrder.deleteSchedule', 'Delete Schedule')}
				type="button"
				color="danger"
				outline
				size="sm"
				disabled={!selectedAutomaticWorkOrderUuids.length || !canEditAtCurrentPlant}
				iconClass="trash"
				onclick={() => {
					deleteAutomaticWorkOrders()
					selectedAutomaticWorkOrderUuids = []
				}}
			>
				{translate('automaticWorkOrder.deleteSchedule', 'Delete Schedule')}
			</Button>
		</div>
		<div>
			<Button
				title={translate('automaticWorkOrder.copyNow', 'Copy Now')}
				type="button"
				outline
				size="sm"
				disabled={!selectedAutomaticWorkOrderUuids.length || !canCreateNewWorkOrders}
				iconClass="copy"
				isLoading={loading}
				onclick={() => {
					if (
						confirm(
							translate(
								'automaticWorkOrder.confirmManualCopy',
								`Manually copy the {{selectedAutomaticWorkOrderUuids.length}} selected historical work order document(s)?
	(This will not affect future automatic work order scheduling)`,
								{
									selectedAutomaticWorkOrderUuids: selectedAutomaticWorkOrderUuids.length,
								},
							),
						)
					) {
						loading = true
						copyWorkOrdersNow()
						loading = false
					}
				}}
			>
				{translate('automaticWorkOrder.copyNow', 'Copy Now')}
			</Button>
			<Button
				title={translate('automaticWorkOrder.reRunSchedule', 'Re-Run Schedule')}
				type="button"
				outline
				size="sm"
				disabled={!canEditAtCurrentPlant}
				iconClass="redo"
				onclick={() => {
					if (
						automaticWorkOrdersToCreate.length +
						automaticWorkOrdersToUpdate.length +
						automaticWorkOrdersToDelete.length
					) {
						mediator.call('showMessage', {
							color: 'warning',
							heading: translate('automaticWorkOrder.unsavedChanges', 'Unsaved Changes'),
							message: translate(
								'automaticWorkOrder.unsavedChangesMessage',
								`You have unsaved changes to automatic work orders. Please save your changes before re-running schedules.`,
							),
							time: 10,
						})
						return false
					}
					if (
						confirm(
							translate(
								'automaticWorkOrder.confirmManualReRun',
								`Manually re-run all plants' automatic WO schedules for the current quarter hour?

	(Normally, these schedules are checked every 15 minutes. You should only manually re-run the schedules if documents were missed in the last pass that should've been created.)
	Confirm Manual Re-run Schedules`,
							),
						)
					) {
						rerunScheduleNow()
					} else {
						false
					}
				}}
			>
				{translate('automaticWorkOrder.reRunSchedule', 'Re-Run Schedule')}
			</Button>
		</div>
	</div>
</div>

<Modal
	bind:show={modalOpen}
	title={translate('automaticWorkOrder.workOrders', 'Work Orders')}
	modalSize="xxl"
	confirmButtonText={translate('automaticWorkOrder.scheduleSelectedWorkOrders', 'Schedule Selected Work Order(s)')}
	confirmButtonColor="primary"
	confirmButtonDisabled={!modalSelectedWorkOrderIds.length}
	confirm={() => {
		modalSelectedWorkOrderIds.forEach(workOrderId => {
			createAutomaticWorkOrderFromId(workOrderId)
		})
		modalOpen = false
	}}
	close={() => closeModal()}
>
	<WorkOrderSearch
		bind:selectedPlantId
		bind:selectedWorkOrderIds={modalSelectedWorkOrderIds}
		bind:dateRange
		{plants}
		showFavoriteCheckbox={favoriteCheckboxShown}
		favoriteWorkOrdersOnly={!favoriteCheckboxShown}
		{workOrderTypes}
		{groups}
		pageSize={interfaceSettings.workOrdersPerPage}
		showOpenDocuments={interfaceSettings.showOpenDocuments}
		showClosedDocuments={interfaceSettings.showClosedDocuments}
		{workOrderFavoritesStore}
		visibleColumns={[
			'favorite',
			'plant[name]',
			'id',
			'workOrderType[name]',
			'title',
			'scheduled',
			'dateCreated',
			'due',
			'internalNotes',
			'instructions',
		]}
	/>
	{#snippet footer()}
		<Button
			disabled
			iconClass="copy"
		>
			{translate('automaticWorkOrder.copy', 'Copy')}
		</Button>
	{/snippet}
</Modal>
