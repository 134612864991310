<script lang="ts">
	import type { Merge } from 'type-fest'
	import type { SvelteAsr, i18n, Mediator } from 'types/common'
	import type { ClientSession } from 'stores/session'
	import type { SaveResetProps } from '../configuration'
	import type { PasswordValidationRules } from 'utility/get-password-rules'

	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { UserAccountInfo } from '@isoftdata/svelte-user-configuration'
	import { getContext } from 'svelte'
	import { graphql } from '$houdini'
	import session from 'stores/session'

	const updateSessionUser = graphql(`
		mutation MyAccountUpdateSessionUser($input: UpdateSessionUser!) {
			updateSessionUser(input: $input) {
				id
				name
				firstName
				lastName
				fullName
				recoveryEmail
				workEmail
			}
		}
	`)

	const { t: translate } = getContext<i18n>('i18next')
	const mediator = getContext<Mediator>('mediator')

	type User = Merge<
		ClientSession['user'],
		{
			id: number
			name: string
			lockNotes: string
			status: 'ACTIVE' //They made it here, they are active
			currentPassword: string
			newPassword: string
			confirmPassword: string
			lastPasswordResetDate: Date | undefined
		}
	>

	interface Props {
		user: User
		passwordValidationRules: PasswordValidationRules
		saveResetProps: SaveResetProps
		asr: SvelteAsr
	}

	let { user = $bindable(), passwordValidationRules, saveResetProps, asr }: Props = $props()
	//Make a frozen deep copy of the original data so we can compare it later to see if there are any changes
	let originalData = $state.raw(Object.freeze(klona(user)))
	let hasUnsavedChanges = $derived(!dequal(originalData, user))
	let workEmailIsValid: boolean = $state(true)
	let recoveryEmailIsValid: boolean = $state(true)

	export function canLeaveState() {
		if (hasUnsavedChanges) {
			return confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		}
		return true
	}

	async function saveChanges() {
		if (hasUnsavedChanges) {
			try {
				const { data } = await updateSessionUser.mutate({
					input: {
						firstName: user.firstName,
						lastName: user.lastName,
						recoveryEmail: user.recoveryEmail,
					},
				})

				if (data?.updateSessionUser) {
					const changedFields: Partial<ClientSession['user']> = {
						firstName: data.updateSessionUser.firstName,
						lastName: data.updateSessionUser.lastName,
						fullName: data.updateSessionUser.fullName,
						recoveryEmail: data.updateSessionUser.recoveryEmail,
					}

					session.update(session => {
						session.user = {
							...session.user,
							...changedFields,
						}

						return session
					})
					originalData = Object.freeze(klona(user))

					asr.go(null, { lastSavedTime: Date.now(), lastResetTime: null }, { inherit: true }) //Reload the page after saving
				}
			} catch (err) {
				console.error(err)
				mediator.call('showMessage', {
					heading: translate('common:Eror', 'Error'),
					message: err instanceof Error ? err.message : '',
					color: 'danger',
					time: false,
				})
			}
		}
	}

	$effect(() => {
		$saveResetProps = {
			save: saveChanges,
			disabled: !hasUnsavedChanges || !workEmailIsValid || !recoveryEmailIsValid,
			resetHref: asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true }),
		}
	})
</script>

<div class="row">
	<div class="col-12 col-md-8 col-lg-6 col-xl-5">
		<UserAccountInfo
			bind:userAccount={user}
			icon="address-card"
			myAccountMode
			cardTitle={translate('configuration.titles.myAccount', 'My Account')}
			{passwordValidationRules}
			confirmPasswordSet={async ({ currentPassword, newPassword }) => {
				await updateSessionUser.mutate({
					input: {
						currentPassword,
						newPassword,
					},
				})
			}}
			success={({ heading, message }) => {
				mediator.call('showMessage', { heading, message, color: 'success', time: 10 })
			}}
			error={({ heading, message }) => {
				mediator.call('showMessage', { heading, message, color: 'danger', time: false })
			}}
			bind:workEmailIsValid
			bind:recoveryEmailIsValid
		/>
	</div>
</div>
