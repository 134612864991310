<script lang="ts">
	import type { HasPermission } from 'types/common'
	import type { ComponentEvents } from 'svelte'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { DisplaySample, MetaSampleAttachment, Sample } from '../work-order'

	import Attachments from '@isoftdata/svelte-attachments'
	import { getContext } from 'svelte'

	const hasPermission = getContext<HasPermission>('hasPermission')
	const attachmentCrudStore = getContext<CrudStore<MetaSampleAttachment, 'uuid'>>('sampleAttachmentCrudStore')
	interface Props {
		sample: Sample | DisplaySample
		attachmentChange?: (ctx: { attachments: MetaSampleAttachment[] }) => void
	}

	let {
		//
		sample = $bindable(),
		attachmentChange,
	}: Props = $props()

	function onFilesAdded(event: ComponentEvents<Attachments>['filesAdded']) {
		const filesToAdd = event.detail
		const sampleId = sample.id
		const sampleUuid = sample.uuid
		const metaAttachments: MetaSampleAttachment[] = filesToAdd.map(file => ({
			...file,
			sampleId,
			sampleUuid,
			path: file.path ?? '',
			uuid: file.uuid ?? '',
		}))
		metaAttachments.forEach(file => attachmentCrudStore.create(file))
		sample.attachments.push(...metaAttachments)
		attachmentChange?.({ attachments: sample.attachments })
	}

	function onFilesDeleted(event: ComponentEvents<Attachments>['filesDeleted']) {
		const filesToDelete = event.detail
		const sampleId = sample.id
		const sampleUuid = sample.uuid
		filesToDelete.forEach(file =>
			attachmentCrudStore.delete({ ...file, sampleId, sampleUuid, path: file.path ?? '', uuid: file.uuid ?? '' }),
		)
		if (sample) {
			sample.attachments =
				sample?.attachments?.filter(attachment => !filesToDelete.some(file => file.uuid === attachment.uuid)) ?? []
		}
		attachmentChange?.({ attachments: sample.attachments })
	}
</script>

<Attachments
	hidePublicFeatures
	hideRankFeatures
	modificationDisabled={!sample.plant ||
		!hasPermission('WORK_ORDERS_CAN_EDIT', sample.plant.id) ||
		sample.status === 'CLOSED'}
	on:filesAdded={onFilesAdded}
	on:filesDeleted={onFilesDeleted}
	fileList={sample?.attachments ?? []}
/>
