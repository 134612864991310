<script lang="ts">
	import type { SaveResetProps } from '../configuration'
	import type { i18n, SvelteAsr, Mediator } from 'types/common'
	import type { UserSetting, SettingSaveType, DefaultSettingSaveType } from 'utility/setting'
	import type { Writable } from 'svelte/store'

	import { onDestroy, setContext, getContext } from 'svelte'
	import Setting from '@isoftdata/svelte-setting'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import { writable } from 'svelte/store'
	import setUserSetting from 'utility/set-user-setting'
	import { graphql } from '$houdini'

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')

	let dirtySettings = writable<Array<UserSetting>>([])
	setContext<Writable<Array<UserSetting>>>('dirtySettings', dirtySettings)
	let dirtyDefaultSettings = writable<Array<UserSetting>>([])
	setContext<Writable<Array<UserSetting>>>('dirtyDefaultSettings', dirtyDefaultSettings)

	const setDefaultValuesMutation = graphql(`
		mutation SetSettingsDefaultValues($updateDefaultValueInput: UpdateDefaultValuesInput!) {
			setSettingsDefaultValues(updateDefaultValueInput: $updateDefaultValueInput)
		}
	`)

	const setUpdatedValuesMutation = graphql(`
		mutation SetUpdatedValues($updateSettingValuesInput: UpdateSettingValuesInput!) {
			setSettingsValues(updateSettingValuesInput: $updateSettingValuesInput)
		}
	`)

	export let settingsChanged: boolean = false
	$: settingsChanged = $dirtySettings.length > 0 || $dirtyDefaultSettings?.length > 0

	export let asr: SvelteAsr
	export let configurationSettings: {
		importantConfiguration: boolean
		optionalConfiguration: boolean
		preference: boolean
		interfacePreference: boolean
		interfaceHistory: boolean
	}
	export let settings: UserSetting[]
	export let selectedCategory: string
	export let saveResetProps: SaveResetProps
	$: $saveResetProps = {
		disabled: !settingsChanged,
		resetHref: asr.makePath(
			null,
			{ lastResetTime: Date.now(), lastSavedTime: null, selectedCategory },
			{ inherit: true },
		),
		save: saveSettings,
	}

	let filterChoices = [
		// This is the translation key used in the settings component
		{
			name: translate('settings:typesImportantConfiguration', 'Important Configuration'),
			value: configurationSettings.importantConfiguration ?? true,
		},
		{ name: translate('settings:typesPreference', 'Preference'), value: configurationSettings.preference ?? true },
		{
			name: translate('settings:typesInterfacePreference', 'Interface Preference'),
			value: configurationSettings.interfacePreference ?? true,
		},
		{
			name: translate('settings:typesOptionalConfiguration', 'Optional Configuration'),
			value: configurationSettings.optionalConfiguration ?? true,
		},
		{
			name: translate('settings:typesInterfaceHistory', 'Interface History'),
			value: configurationSettings.interfaceHistory ?? false,
		},
	]

	function formatSettingForSave(setting: UserSetting): SettingSaveType {
		let valueToSave = setting.value
		if (setting.dataType === 'boolean') {
			valueToSave = booleanToString(Boolean(setting.value))
		} else {
			valueToSave = String(setting.value)
		}
		return {
			settingId: setting.id,
			value: valueToSave,
		}
	}

	function formatDefaultSettingForSave(setting: UserSetting): DefaultSettingSaveType {
		let valueToSave = setting.defaultValue
		if (setting.dataType === 'boolean') {
			valueToSave = booleanToString(Boolean(setting.defaultValue))
		} else {
			valueToSave = String(setting.defaultValue)
		}
		return {
			settingId: setting.id,
			newDefaultValue: valueToSave,
		}
	}

	async function saveSettings() {
		if (!settingsChanged) {
			return
		}

		const dirtyValuesToUpdate = $dirtySettings.map(formatSettingForSave)
		const defaultValuesToUpdate = $dirtyDefaultSettings.map(formatDefaultSettingForSave)

		try {
			await Promise.all([
				defaultValuesToUpdate.length
					? setDefaultValuesMutation.mutate({
							updateDefaultValueInput: {
								settings: defaultValuesToUpdate,
							},
						})
					: null,
				dirtyValuesToUpdate.length
					? setUpdatedValuesMutation.mutate({
							updateSettingValuesInput: {
								settings: dirtyValuesToUpdate,
							},
						})
					: null,
			])
		} catch (e: any) {
			const error = e.message ?? ''
			mediator.call('showMessage', {
				color: 'danger',
				heading: translate('settings.saveErrorHeading', 'Error saving settings'),
				message: error,
				time: false,
			})
			console.error(e)
		}

		$dirtySettings = []
		$dirtyDefaultSettings = []

		asr.go(null, { lastSavedTime: Date.now(), selectedCategory }, { inherit: true })
	}

	export function canLeaveState() {
		return (
			!settingsChanged ||
			confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		)
	}

	async function saveInterfaceHistory(): Promise<void> {
		await Promise.all([
			setUserSetting({
				category: 'Configuration',
				name: "Configure Settings Dialog: Show 'important configuration' settings",
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(
					filterChoices.find(choice => choice.name === 'Important Configuration')?.value ?? true,
				),
			}),
			setUserSetting({
				category: 'Configuration',
				name: "Configure Settings Dialog: Show 'optional configuration' settings",
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(
					filterChoices.find(choice => choice.name === 'Optional Configuration')?.value ?? true,
				),
			}),
			setUserSetting({
				category: 'Configuration',
				name: "Configure Settings Dialog: Show 'preference' settings",
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(filterChoices.find(choice => choice.name === 'Preference')?.value ?? true),
			}),
			setUserSetting({
				category: 'Configuration',
				name: "Configure Settings Dialog: Show 'interface preference' settings",
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(filterChoices.find(choice => choice.name === 'Interface Preference')?.value ?? true),
			}),
			setUserSetting({
				category: 'Configuration',
				name: "Configure Settings Dialog: Show 'interface history' settings",
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(filterChoices.find(choice => choice.name === 'Interface History')?.value ?? false),
			}),
		])
	}

	onDestroy(() => {
		saveInterfaceHistory()
	})
</script>

<Setting
	bind:settings
	bind:selectedCategory
	bind:filterChoices
	hideDescription={true}
	defaultValueEditable={true}
	siteAlias={translate('common:plant', 'Plant')}
	scopeBadge
/>
