export default {
    "name": "AmUpdateAnalyses",
    "kind": "HoudiniMutation",
    "hash": "52ee710ce22f3420f223e49e480f754f9a48611740c5b7aa812c1ed34c4878de",

    "raw": `mutation AmUpdateAnalyses($input: UpdateAnalysesInput!) {
  updateAnalyses(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateAnalyses": {
                "type": "Analysis",
                "keyRaw": "updateAnalyses(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "UpdateAnalysesInput"
        },

        "types": {
            "NestedNewAnalysisOptionChoice": {
                "active": "Boolean",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            },

            "NestedNewAnalysisOptionRule": {
                "active": "Boolean",
                "description": "String",
                "outcome": "OutcomeType",
                "restriction": "RestrictionType",
                "tags": "PositiveInt"
            },

            "NewAnalysisOption": {
                "active": "Boolean",
                "choices": "NestedNewAnalysisOptionChoice",
                "defaultType": "DefaultType",
                "defaultValue": "String",
                "entryMethod": "EntryMethod",
                "informational": "Boolean",
                "inventoryMode": "InventoryMode",
                "option": "String",
                "productId": "PositiveInt",
                "rank": "Int",
                "requiredToClose": "Boolean",
                "requiredToCompleteSection": "Boolean",
                "requiredToPerform": "Boolean",
                "rules": "NestedNewAnalysisOptionRule",
                "sectionName": "String",
                "thresholdType": "ThresholdType",
                "unit": "String",
                "valueType": "ValueType"
            },

            "AnalysisOptionChoiceUpdate": {
                "active": "Boolean",
                "analysisOptionId": "PositiveInt",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "id": "PositiveInt",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            },

            "AnalysisOptionRuleUpdate": {
                "active": "Boolean",
                "description": "String",
                "id": "PositiveInt",
                "outcome": "OutcomeType",
                "restriction": "RestrictionType",
                "tagsToAdd": "PositiveInt",
                "tagsToRemove": "PositiveInt"
            },

            "AnalysisOptionUpdate": {
                "active": "Boolean",
                "choicesToCreate": "NestedNewAnalysisOptionChoice",
                "choicesToDelete": "PositiveInt",
                "choicesToUpdate": "AnalysisOptionChoiceUpdate",
                "defaultType": "DefaultType",
                "defaultValue": "String",
                "entryMethod": "EntryMethod",
                "id": "PositiveInt",
                "informational": "Boolean",
                "inventoryMode": "InventoryMode",
                "option": "String",
                "productId": "PositiveInt",
                "rank": "Int",
                "requiredToClose": "Boolean",
                "requiredToCompleteSection": "Boolean",
                "requiredToPerform": "Boolean",
                "rulesToCreate": "NestedNewAnalysisOptionRule",
                "rulesToDelete": "PositiveInt",
                "rulesToUpdate": "AnalysisOptionRuleUpdate",
                "sectionName": "String",
                "thresholdType": "ThresholdType",
                "unit": "String",
                "valueType": "ValueType"
            },

            "PrintQuantityInput": {
                "plantId": "PositiveInt",
                "sampleTagQuantity": "NonNegativeInt",
                "testingTagQuantity": "NonNegativeInt"
            },

            "AnalysisUpdate": {
                "active": "Boolean",
                "analysisType": "AnalysisType",
                "batchUnit": "String",
                "batchVolume": "Int",
                "category": "String",
                "createdProductId": "PositiveInt",
                "groupSamples": "Boolean",
                "id": "PositiveInt",
                "inUseAtPlantIDs": "PositiveInt",
                "instructions": "String",
                "name": "String",
                "optionsToCreate": "NewAnalysisOption",
                "optionsToDelete": "PositiveInt",
                "optionsToUpdate": "AnalysisOptionUpdate",
                "printQuantityInput": "PrintQuantityInput",
                "requireAuthentication": "Boolean",
                "testingPeriod": "Float",
                "visibleGroupId": "PositiveInt"
            },

            "UpdateAnalysesInput": {
                "analyses": "AnalysisUpdate"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=52ac527822322659ab2ce6c906645b5a3e44c059add4f419adff0ef4275dfec2";