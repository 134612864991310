<script lang="ts">
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import SiteDetails from '@isoftdata/svelte-site-details'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { MetaTag } from 'components/TagSelection.svelte'
	import TagSelection from 'components/TagSelection.svelte'
	import { createEntityTags, updateEntityTags, deleteEntityTags } from 'utility/entity-tags'
	import hasPermission from 'utility/has-permission'
	import {
		createPlantsMutation,
		updatePlantsMutation,
		deletePlantsMutation,
		zipCodeLookupQuery,
		type Plant,
		type State,
	} from './plant.js'
	import type { Mediator, SvelteAsr } from 'types/common'
	import { getContext, type ComponentProps } from 'svelte'
	import type { Merge } from 'type-fest'
	import type { CreateEntityTags$result, NewPlantLogo, PlantCreation, PlantUpdate } from '$houdini'
	import type { Writable } from 'svelte/store'
	import { klona } from 'klona'
	import b64ify from 'utility/b64ify'
	import { v4 as uuid } from '@lukeed/uuid'
	import type { EntityTagType$options as EntityTagType } from '$houdini'
	import type SaveResetButton from '@isoftdata/svelte-save-reset-button'

	type SaveResetProps = Writable<ComponentProps<typeof SaveResetButton> | null>
	type CreatedTag = CreateEntityTags$result['createEntityTags'][number]
	type MetaUpdateTag = Merge<MetaTag, { id: number }>

	const mediator = getContext<Mediator>('mediator')
	const i18next = getContext<{ t: (key: string, fallback: string) => string }>('i18next')
	const { t: translate } = i18next

	const newPlantTemplate = Object.freeze<Omit<Plant, 'uuid'>>({
		id: null,
		name: '',
		code: '',
		street: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		phone: '',
		fax: '',
		timezone: '',
		logo: undefined,
		private: false,
		tags: [],
	})
	interface Props {
		asr: SvelteAsr
		plants: Plant[]
		timezones: string[]
		states: State[]
		selectedPlant: Plant
		tags: MetaTag[]
		tagCrudStore: CrudStore<MetaTag, 'uuid'>
		saveResetProps: SaveResetProps
		plantCrudStore: CrudStore<Plant, 'uuid'>
	}

	let {
		asr,
		plants = $bindable(),
		timezones = [],
		states = [],
		selectedPlant = $bindable(),
		tags = $bindable(),
		tagCrudStore,
		saveResetProps,
		plantCrudStore,
	}: Props = $props()

	const canEditPlant = (plantId: number | null) =>
		plantId === null || hasPermission('CONFIGURATION_CAN_CONFIGURE_PLANTS', plantId)

	let hasValidRequiredFields: boolean = $state(true)

	const selectedPlantIndex = $derived(plants.findIndex(plant => plant.uuid === selectedPlant.uuid))
	const canEditSelectedPlant = $derived(canEditPlant(plants[selectedPlantIndex]?.id))
	const canEditAllPlants = $derived(plants.every(plant => canEditPlant(plant.id)))
	const hasTagChanges = $derived(!!$tagCrudStore && tagCrudStore.hasChanges())
	const hasPlantChanges = $derived(!!$plantCrudStore && plantCrudStore.hasChanges())
	const hasUnsavedChanges = $derived(hasTagChanges || hasPlantChanges)

	$effect(() => {
		$saveResetProps = {
			save: savePage,
			disabled: !hasUnsavedChanges || !hasValidRequiredFields,
			resetHref: asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true }),
		}
	})

	async function formatNewPlantForSave(plant: Plant): Promise<PlantCreation> {
		return {
			city: plant.city,
			code: plant.code,
			country: plant.country,
			fax: plant.fax,
			name: plant.name,
			phone: plant.phone,
			private: plant.private,
			state: plant.state,
			street: plant.street,
			timezone: plant.timezone,
			zip: plant.zip,
			logo: plant.logo ? await formatLogoForSave(plant.logo.File) : undefined,
			tagIds: plant.tags
				?.map(plantTag => tags.find(tag => tag.uuid === plantTag.uuid)?.id)
				.filter((id): id is number => !!id),
		}
	}

	async function formatLogoForSave(logo: File | undefined): Promise<NewPlantLogo | undefined> {
		if (!logo) {
			return undefined
		}
		return {
			base64String: await b64ify(logo),
			fileName: logo.name,
		}
	}

	async function formatPlantForUpdate(plant: Plant): Promise<PlantUpdate> {
		return {
			id: plant.id,
			...(await formatNewPlantForSave(plant)),
		}
	}

	function formatNewTagForSave(tag: MetaTag) {
		return {
			name: tag.name,
			entityType: tag.entityType.toUpperCase() as EntityTagType,
			active: tag.active,
		}
	}
	function formatTagForUpdate(tag: MetaUpdateTag) {
		return {
			id: tag.id,
			...formatNewTagForSave(tag),
		}
	}

	function updateTagsArray(tagsToCreate: CreatedTag[]) {
		if (!tagsToCreate.length) {
			return tags
		}
		const tagIdsToUpdate = tags.map(tag => {
			const createdTag = tagsToCreate.find(createdTag => createdTag.name === tag.name)
			if (createdTag) {
				return {
					...tag,
					id: createdTag.id,
				}
			}
			return tag
		})
		tagCrudStore.clear()
		return tagIdsToUpdate
	}

	async function getLocationDataFromZip(zipcode: string) {
		const res = await zipCodeLookupQuery.fetch({ variables: { zipcode } })
		return res?.data?.getCityStateAbbvCountryFromZip ?? null
	}

	async function saveTags() {
		const tagsToCreate = tagCrudStore.createdValues
		const tagsToUpdate = tagCrudStore.updatedValues
		const tagsToDelete = tagCrudStore.deletedValues
		const [createdTags] = await Promise.all([
			tagsToCreate.length
				? createEntityTags
						.mutate({
							input: tagsToCreate.map(tag => formatNewTagForSave(tag)),
						})
						.then(res => res.data?.createEntityTags ?? null)
				: null,
			tagsToUpdate.length
				? updateEntityTags
						.mutate({
							input: tagsToUpdate.filter((tag): tag is MetaUpdateTag => !!tag.id).map(tag => formatTagForUpdate(tag)),
						})
						.then(res => res.data?.updateEntityTags ?? null)
				: null,
			tagsToDelete.length
				? deleteEntityTags
						.mutate({
							ids: tagsToDelete.map(tag => tag.id).filter((id): id is number => !!id),
						})
						.then(res => res.data?.deleteEntityTags ?? null)
				: null,
		])
		tagCrudStore.clear()
		if (createdTags) {
			tags = updateTagsArray(createdTags)
		}
		return tags
	}

	async function savePlants() {
		const plantsToCreate = plantCrudStore.createdValues
		const plantsToUpdate = plantCrudStore.updatedValues
		const plantsToDelete = plantCrudStore.deletedValues
		const formattedPlantsToCreate: PlantCreation[] = await Promise.all(
			plantsToCreate.map(plant => formatNewPlantForSave(plant)),
		)
		const formattedPlantsToUpdate: PlantUpdate[] = await Promise.all(
			plantsToUpdate.map(plant => formatPlantForUpdate(plant)),
		)
		await Promise.all([
			plantsToCreate.length
				? createPlantsMutation.mutate({
						plants: formattedPlantsToCreate,
					})
				: null,
			plantsToUpdate.length
				? updatePlantsMutation.mutate({
						plants: formattedPlantsToUpdate,
					})
				: null,
			plantsToDelete.length
				? //Why is this a Array<string>? Fuck if i know.
					deletePlantsMutation.mutate({
						ids: plantsToDelete.reduce((acc, plant) => {
							if (plant.id) {
								acc.push(plant.id.toString())
							}
							return acc
						}, [] as Array<string>),
					})
				: null,
		])
	}

	async function savePage() {
		try {
			tags = await saveTags() // Saves tags and assigns it so plants can get updated IDs
			await savePlants()
			mediator.call('showMessage', {
				color: 'success',
				heading: translate('common:savedHeading', 'Saved!'),
				message: translate('configuration.plants.savedMessage', 'Plants saved successfully.'),
				time: 10,
			})
			asr.go(null, { lastSavedTime: Date.now(), lastResetTime: null }, { inherit: true }) //Reload the page after saving
		} catch (err: any) {
			const error = err.message ?? ''
			mediator.call('showMessage', {
				color: 'danger',
				heading: 'Error saving; Plants not saved',
				message: error,
				time: 10000,
			})
			console.error(err)
		}
	}

	function createNewPlant() {
		const newPlant = { ...klona(newPlantTemplate), uuid: uuid() }
		plants.push(newPlant)
		plantCrudStore.create(newPlant)
		selectedPlant = plants[plants.length - 1]
	}

	function deleteCurrentPlant() {
		if (plantCrudStore.isDeleted(selectedPlant)) {
			plantCrudStore.unDelete(selectedPlant)
			return
		}
		if (
			plants[selectedPlantIndex].getPlantUsageCount?.analyseCount ||
			plants[selectedPlantIndex].getPlantUsageCount?.productCount
		) {
			alert(
				`This plant is in use by ${plants[selectedPlantIndex]?.getPlantUsageCount?.analyseCount ?? 0} analyses, and ${
					plants[selectedPlantIndex]?.getPlantUsageCount?.productCount ?? 0
				} products. Please remove these references before deleting.`,
			)
			return
		}
		if (confirm('Are you sure you want to delete this plant?')) {
			plantCrudStore.delete(selectedPlant)
			selectedPlant = plants[0]
		}
	}
	async function updateLocationFieldsFromZip() {
		const zip = plants[selectedPlantIndex].zip
		if (!zip) {
			return
		}
		const locationData = await getLocationDataFromZip(zip)
		if (!locationData) {
			return
		}
		plants[selectedPlantIndex].city = locationData.city ?? ''
		plants[selectedPlantIndex].state =
			states.find(state => state.abbreviation === locationData.stateAbbreviation)?.abbreviation ?? ''
		plants[selectedPlantIndex].country = locationData.country ?? ''
	}

	export function canLeaveState() {
		return (
			!hasUnsavedChanges ||
			confirm(
				translate(
					'common:canLeaveState',
					'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.',
				),
			)
		)
	}
</script>

<class class="form-row">
	<SiteAutocomplete
		label={translate('common:plant', 'Plant')}
		bind:value={selectedPlant}
		options={plants}
		getLabel={plant =>
			`${[plant?.code, plant?.name].filter(v => v).join(' - ') || '-- New Plant --'}${plantCrudStore.isDeleted(plant) ? ' (Deleted)' : ''}`}
	>
		{#snippet option({ option })}
			{#if option?.code && option?.name}
				<span class="badge mr-2 bg-secondary text-white">{option.code}</span>{option.name}
			{:else}
				<span class="badge mr-2 bg-secondary text-white">New</span>New Plant
			{/if}
			{#if plantCrudStore.isDeleted(option)}<span class="badge ml-2 text-white bg-danger">Deleted</span>{/if}
		{/snippet}
	</SiteAutocomplete>
</class>
<hr />
<div class="row row-cols-1 row-cols-md-2">
	<div class="col-md-6 col-12 p-1 h-100">
		<SiteDetails
			{timezones}
			{states}
			siteCrudStore={plantCrudStore}
			siteAlias={translate('common:plant', 'Plant')}
			canEditSelectedSite={canEditSelectedPlant}
			canEditLogo={canEditSelectedPlant}
			canEditAllStoresLogo={canEditAllPlants}
			createNewSite={createNewPlant}
			deleteCurrentSite={deleteCurrentPlant}
			{updateLocationFieldsFromZip}
			requiredFields={['name', 'code', 'timezone']}
			bind:sites={plants}
			bind:selectedSiteUuid={selectedPlant.uuid}
			requiredFieldsAreValid={e => (hasValidRequiredFields = e.requiredFieldsAreValid)}
		/>
	</div>
	<div class="col-md-6 col-12 p-1">
		<TagSelection
			entityType="PLANT"
			disabled={!canEditSelectedPlant}
			title={translate('common:plant', 'Plant')}
			bind:tags
			bind:tagCrudStore
			bind:tagsInUse={plants[selectedPlantIndex].tags}
			on:tagsInUseChange={() => plantCrudStore.update(selectedPlant)}
		/>
	</div>
</div>
