import { graphql } from '$houdini'

export type PasswordValidationRules = Record<string, number>

const getPasswordRulesQuery = graphql(`
	query GetPasswordRules {
		getPasswordRules {
			minimumPasswordLength
			maximumPasswordLength
			numberOfRequiredLowercaseCharacters
			numberOfRequiredUppercaseCharacters
			numberOfRequiredNumericCharacters
			numberOfRequiredSpecialCharacters
		}
	}
`)

//This function will load the password rules and parses them,
// throwing away any non-numeric values and returning the valid ones as a number
export async function getPasswordRules(): Promise<PasswordValidationRules> {
	const { data } = await getPasswordRulesQuery.fetch()

	return Object.entries(data?.getPasswordRules ?? {}).reduce((acc, [key, value]) => {
		const numberValue = Number(value)
		if (!isNaN(numberValue)) {
			acc[key] = numberValue
		}
		return acc
	}, {})
}
